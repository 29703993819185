export const appSupportedLanguages = [
  {
    code: "en",
    title: "English",
    country: "us",
  },
  {
    code: "fr",
    title: "Français",
    country: "fr",
  },
  {
    code: "de",
    title: "Deutschland",
    country: "de",
  },
  {
    code: "es",
    title: "Espagne",
    country: "es",
  },
  {
    code: "da",
    title: "dansk",
    country: "da",
  },
  {
    code: "fi",
    title: "Suomalainen",
    country: "fi",
  },
  {
    code: "hu",
    title: "Magyar",
    country: "hu",
  },
  {
    code: "id",
    title: "bahasa Indonesia",
    country: "id",
  },
  {
    code: "ja",
    title: "日本語",
    country: "ja",
  },
  {
    code: "ko",
    title: "한국인",
    country: "ko",
  },
  {
    code: "ms",
    title: "Melayu",
    country: "ms",
  },
  {
    code: "nl",
    title: "Nederlands",
    country: "nl",
  },
  {
    code: "pl",
    title: "Polski",
    country: "pl",
  },
  {
    code: "pt",
    title: "Português",
    country: "pt",
  },
  {
    code: "ro",
    title: "Română",
    country: "ro",
  },
  {
    code: "sk",
    title: "slovenský",
    country: "sk",
  },
  {
    code: "sv",
    title: "svenska",
    country: "sv",
  },
  {
    code: "th",
    title: "แบบไทย",
    country: "th",
  },
  {
    code: "tr",
    title: "Türkçe",
    country: "tr",
  },
  {
    code: "vi",
    title: "Tiếng Việt",
    country: "vi",
  },
  {
    code: "zh",
    title: "简体中文",
    country: "zh",
  },
];

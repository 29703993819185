import React, { useState, createContext, useContext } from "react";

interface SubscribeContextType {
  isSubscribe: number;
  setisSubscribe: React.Dispatch<React.SetStateAction<number>>;
}
export const SubscribeContext = createContext<SubscribeContextType | null>(null);

export const SubscribeProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSubscribe, setisSubscribe] = useState(null);
  return (
    <SubscribeContext.Provider value={{ isSubscribe, setisSubscribe }}>
      {children}
    </SubscribeContext.Provider>
  );
};
export const useSubscribe = () => {
  const context = useContext(SubscribeContext);
  if (!context) {
    throw new Error("useSubscribe must be used within a SubscribeProvider");
  }
  return context;
};

import { axiosClient } from "../../api/api";
export const getNotificationDetails = async (
  page: number,
  langCode: string,
  panelListId: number
) => {
  return axiosClient
    .get("/api/survey/getNotifications", {
      params: { page, langCode, panelListId },
    })
    .then((response) => response.data);
};

export const getMarkAsReadActive = async (studyName: string, panelListId: number) => {
  return axiosClient
    .post("/api/survey/getMarkAsReadActive", {
      studyName,
      panelListId,
    })
    .then((response) => response.data);
};

export const getAttemptSurvey = async (studyName: string, panelListId: number) => {
  return axiosClient
    .post("/api/survey/attemptSurvey", {
      studyName,
      panelListId,
    })
    .then((response) => response.data);
};

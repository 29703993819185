import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth-context";
import { getRedeemRequestHistoryData } from "./MySurveys.api";
import { useQuery } from "@tanstack/react-query";
import { Survey } from "./MySurveys.interface";
import Pagination from "../../components/Pagination/Pagination";
import { dateFormatHandler, YYYY_MM_DD_HH_mm_ss } from "./../../lib/date-formatter-moment";
import { Tag } from "antd";
// import { Link } from "react-router-dom";

export const getStatus = (status: string | number | boolean | React.ReactFragment) => {
  let btn: any = status;
  switch (status) {
    case "Pending":
      btn = <Tag color="blue">{status}</Tag>;
      break;
    case "Reject":
      btn = <Tag color="red">{status}</Tag>;
      break;
    case "Approved":
      btn = <Tag color="green">{status}</Tag>;
      break;
    case "Completed":
      btn = <Tag color="green">{status}</Tag>;
      break;
    default:
      btn = status;
  }
  return btn;
};

export const Surveyvirtualwallet = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const getSurveyvirtualwallet = useQuery(
    ["virtual"],
    () => getRedeemRequestHistoryData(auth.panelistId, currentPage),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    getSurveyvirtualwallet.refetch();
  }, [currentPage]);

  if (getSurveyvirtualwallet.isLoading) {
    return (
      <h1
        className="w-6/12 px-48 font-sans text-2xl font-thin font-light pt-28"
        data-testid="loading"
      >
        {t("page.referral.Loading")}
      </h1>
    );
  }

  return (
    <div className="py-10 font-sans pt-28">
      <div className="py-20">
        <div className="table-1">
          <h2 className="my-5 text-2xl text-center ">{t("survey.virtal.requestedhistory")}</h2>
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="px-5 overflow-hidden">
                  <table className="min-w-full">
                    <thead className="border-b">
                      <tr>
                        <th scope="col" className="table-1-th text-center">
                          {t("survey.virtal.RequestedId")}
                        </th>
                        <th scope="col" className="table-1-th text-center">
                          {t("survey.virtal.Points")}
                        </th>
                        <th scope="col" className="table-1-th text-center">
                          {t("survey.virtal.Status")}
                        </th>
                        <th scope="col" className="table-1-th text-center">
                          {t("survey.virtal.Requesteddate")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getSurveyvirtualwallet?.data?.requestData?.length > 0 ? (
                        getSurveyvirtualwallet?.data?.requestData?.map(
                          (survey: Survey, i: number) => {
                            return (
                              <tr key={i} className="border-b">
                                <td className="table-1-td-col-2 text-center">{survey.requestId}</td>
                                <td className="table-1-td-col-2 text-center">
                                  {survey.claimPoints}
                                </td>
                                <td className="table-1-td-col-2 text-center">
                                  {survey.isVerify ? (
                                    getStatus(survey.status)
                                  ) : (
                                    <Tag color="volcano">Not Verified</Tag>
                                  )}
                                  {survey.status === "Reject" && survey.rejectReason && (
                                    <p className="text-danger reject-reason-span">
                                      Reason:- {survey.rejectReason}
                                    </p>
                                  )}
                                </td>
                                <td className="table-1-td-col-2 text-center">
                                  {dateFormatHandler(survey.createdAt, YYYY_MM_DD_HH_mm_ss)}
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <p className="mt-10 ml-10">{t("page.mysurvey.nodata")}</p>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  setCurrentPage={setCurrentPage}
                  totalPages={getSurveyvirtualwallet?.data?.totalPages}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

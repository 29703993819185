import React from "react";
import { useTranslation } from "react-i18next";
// import Constant from "../../constraints/constant.json";
// import {
//   faSackDollar,
//   faUserCircle,
//   faEnvelopeSquare,
//   faLaptop,
//   faUserSecret,
// } from "@fortawesome/free-solid-svg-icons";
// import { useAuth } from "context/auth-context";
import { Accordion } from "components/Accordian/Accordian";
import moneyBag from "../../assets/FAQ/emojione-monotone_money-bag.svg";
import register from "../../assets/FAQ/mdi_register-outline.svg";
import invite from "../../assets/FAQ/mingcute_invite-line.svg";
import solar from "../../assets/FAQ/solar_money-bag-outline.svg";
import member from "../../assets/FAQ//tdesign_member.svg";
import money from "../../assets/FAQ/tdesign_money.svg";

export const FAQ = () => {
  const { t } = useTranslation();
  // const { auth } = useAuth();
  const items = [
    {
      key: 1,
      title: t("home.faq.title"),
      description: t("home.faq.description"),
      icon: moneyBag,
    },
    {
      key: 2,
      title: t("home.faq.HowdoIbecome"),
      description: t("home.faq.Tobecomeamember"),
      icon: member,
    },
    {
      key: 3,
      title: t("home.faq.CanIregister"),
      description: t("home.faq.Noeachmember"),
      icon: register,
    },

    {
      key: 4,
      title: t("home.faq.Isthereacost"),
      description: t("home.faq.NoBeing"),
      icon: money,
    },

    {
      key: 5,
      title: t("home.faq.HowdoIbenefit"),
      description: t("home.faq.Inaddition"),
      icon: solar,
    },

    {
      key: 6,
      title: t("home.faq.Idontseem"),
      description: t("home.faq.Aftersuccessfully"),
      icon: invite,
    },
  ];

  return (
    // <div id="faq" className="p-3 font-sans pt-28">
    //   <div className="w-full min-h-screen">
    //     <div className="flex flex-wrap justify-center">
    //       <div className="flex flex-col w-full m-6 mt-20 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faSackDollar}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2
    //           data-testid="heading"
    //           className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block"
    //         >
    //           {t("home.faq.title")}
    //         </h2>
    //         <p className="text-gray-700 mt-7">
    //           {t("home.faq.description")} <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 mt-20 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faUserCircle}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.HowdoIbecome")}
    //         </h2>
    //         <p className="mb-8 text-gray-700">
    //           {t("home.faq.Tobecomeamember")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden mt-18 md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faUserCircle}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl  lg:text-2xl font-bold mb-5  before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.CanIregister")}
    //         </h2>
    //         <p className="mb-8 text-gray-700">
    //           {t("home.faq.Noeachmember")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3 mt-18">
    //         <FontAwesomeIcon
    //           icon={faSackDollar}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl  lg:text-2xl font-bold mb-5  before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.Isthereacost")}
    //         </h2>
    //         <p className="mb-8 text-gray-700 mt-7">
    //           {t("home.faq.NoBeing")}
    //           <br />
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <div className="flex flex-wrap justify-center mb-5">
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faSackDollar}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.HowdoIbenefit")}
    //         </h2>
    //         <p className="mt-16 text-gray-700">
    //           {t("home.faq.Inaddition")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faEnvelopeSquare}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.Icompletedone")}
    //         </h2>
    //         <p className="mb-8 text-gray-700">
    //           {t("home.faq.Whenanemail")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faLaptop}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className=" after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.WhenIclick")}
    //         </h2>
    //         <p className="mb-8 text-gray-700">
    //           {t("home.faq.Ifclicking")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faEnvelopeSquare}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.Idontseem")}
    //         </h2>
    //         <p className="mb-8 text-gray-700 mt-7">
    //           {t("home.faq.Aftersuccessfully")}{" "}
    //           <a className="cursor-pointer text-sky-600" href={"mailto:" + auth?.email}>
    //             {" "}
    //             {Constant.FAQ_SUPPORT}
    //           </a>{" "}
    //           {t("home.faq.contacts")}
    //           <br />
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="p-2 ">
    //     <div className="flex flex-wrap justify-center mb-5">
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faUserSecret}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className=" after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.Whydoyou")}
    //         </h2>
    //         <p className="text-gray-700 mt-7">
    //           {t("home.faq.Weaskquestions")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faLaptop}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="text-3xl after:content-['?'] after:ml-0.5 after:text-black-500 lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.HowdoI")}
    //         </h2>
    //         <p className="mb-8 text-gray-700">
    //           {t("home.faq.Youmayupdate")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faEnvelopeSquare}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.HowwillI")}
    //         </h2>
    //         <p className="mb-8 text-gray-700">
    //           {t("home.faq.Youwill")}
    //           <br />
    //         </p>
    //       </div>
    //       <div className="flex flex-col w-full m-6 overflow-hidden md:w-1/2 lg:w-1/3">
    //         <FontAwesomeIcon
    //           icon={faUserCircle}
    //           className="h-10 mb-5 fill-current lg:mx-10 text-sky-900"
    //         />
    //         <h2 className="after:content-['?'] after:ml-0.5 after:text-black-500 text-3xl lg:text-2xl font-bold mb-5 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
    //           {t("home.faq.Doesmyaccount")}
    //         </h2>
    //         <p className="mb-8 text-gray-700 mt-7">
    //           {t("home.faq.Likemost")}
    //           <br />
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div id="faq" className="px-6 py-4 h-auto font-poppins bg-[#F4F8FB]">
      <div className="flex flex-col items-center justify-center w-full min-h-screen mt-4 md:mt-20">
        <h1 className="text-slate-400 sm:text-2xl md:text-3xl font-bold text-center text-gradient">
          {t("home.faq.heading")}
        </h1>
        <p className="my-4 text-zinc-500 text-base md:text-xl font-normal text-center">
          {t("home.faq.heading.description")}
        </p>
        <div className="lg:w-[1124px] px-6 py-6 mt-10">
          {items.map((i) => (
            <Accordion  key={i.key} title={i.title} description={i.description} icon={i.icon} />
          ))}
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { forgotUser } from "./ForgotForm.api";
import { useMutation } from "@tanstack/react-query";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import LoadingOverlay from "react-loading-overlay-ts";
import { ForgotValidation } from "./ForgotValidation";
import forgot from '../../assets/Forgot/forgot.avif';
const intialState = {
  emailId: "",
  app: "",
  errors: {
    email: "",
  },
};

export const  ForgotForm = () => {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState(intialState);
  const { emailId } = formValue;
  const mutation = useMutation(() => forgotUser(emailId), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(response.message);
        setFormValue(intialState);
      } else {
        toastrError(response.message);
      }
    },
    onError: () => {
      toastrError(t("page.unsubscribe.Encountered"));
    },
  });

  const setErrors = (errorData: any) => {
    setFormValue((old) => ({ ...old, errors: { ...errorData } }));
  };
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const valid = ForgotValidation(formValue, setErrors, t);
    if (valid) {
      mutation.mutate();
    }
  };
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={mutation.isLoading}
      spinner
      text="Loading..."
    >
      <div className="font-sans flex justify-center  items-center my-[100px] mt-[200px] sm:w-[70%]  w-[80%] md:w-[70%] lg:w-[45%] lg:h-[330px] mx-auto ">
        
          <form className="font-inter flex flex-row justify-between gap-2 w-full rounded-xl h-full bg-white px-[8%] py-[5%]" onSubmit={handleSubmit} >
            <div className="overflow-hidden flex flex-col gap-5 sm:rounded-sm w-full sm:w-[60%]">
              <div className="">
                <h2 className="text-[#828377] font-semibold  text-3xl">
                  {t("form.forgot.ForgotPassword")}
                </h2>
                <div data-testid="email" className="px-2 mt-10 col-span-30 flex flex-col gap-1 mx-auto">
                  <label
                    htmlFor="email"
                    aria-label="email"
                    className="text-gray-600 inline-block after:content-['*'] after:ml-0.5 after:text-red-500 font-medium"
                  >
                    {t("form.forgot.Enteryouremail")}
                  </label>
                  <input
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    title="Please enter email properly"
                    type="text"
                    name="emailId"
                    id="email"
                    value={emailId}
                    onChange={onInputChange}
                    placeholder="ZampliaSurveys@gmail.com "
                    className=" w-[90%] p-2 mt-1 border placeholder:text-gray-400 border-gray-300 rounded-md shadow-sm focus:outline-none  focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                  />
                  {formValue.errors.email ? (
                    <p className="text-red-700" id="emai_err_msg">
                      {formValue.errors.email}
                    </p>
                  ) : null}
                </div>
              </div>
              <div data-testid="button" className="px-2">
                <button
                  type="submit"
                  className="inline-flex justify-start rounded-md border border-transparent bg-[#10739f] py-2 px-4 text-sm font-medium text-white shadow-sm "
                >
                  {t("forgot.btn.Submit")}
                </button>
              </div>
            </div>
            <img src={forgot} alt="" className="w-[40%] sm:flex hidden"/>
          </form>
        
      </div>
    </LoadingOverlay>
  );
};

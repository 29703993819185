import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "components/Header/Header";
import { Footer } from "components/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "components/Sidebar/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
import { useAuth } from "context/auth-context";
import Surveystreak from "components/Sidebar/Surveystreak/Surveystreak";
import Spinner from "components/Sidebar/Surveystreak/spinner";
import ChatToggle from "components/Sidebar/Chatbot/ChatToggle";

export const Layout = () => {
  const { auth } = useAuth();
  const [showSurveystreak, setShowSurveystreak] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const toggleSurveystreak = () => {
    setShowSurveystreak(!showSurveystreak);
  };

  const closesurveys = () => {
    setShowSurveystreak(false);
  };

  return (
    <div>
      <header>
        {auth ? (
          <Navbar toggleSurveystreak={toggleSurveystreak} showSurveystreak={showSurveystreak} />
        ) : (
          <Header />
        )}
      </header>
      <main>
        {auth ? (
          <div onClick={closesurveys} className="lg:flex">
            {showSurveystreak && <Surveystreak />}
            {showSpinner && <Spinner setShowSpinner={setShowSpinner} />}
            <Sidebar />
            <div>{process.env.REACT_APP_ENV === "production" ? null : <ChatToggle />}</div>
            <Outlet />
            <ToastContainer />
          </div>
        ) : (
          <Outlet />
        )}
        <div>{process.env.REACT_APP_ENV === "production" ? null : <ChatToggle />}</div>
      </main>
      <footer>{auth ? null : <Footer />}</footer>
    </div>
  );
};

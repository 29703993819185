import { AppRoutes } from 'AppRoutes';
import { SubscribeProvider } from 'context/Subscribe-context';
import React from 'react';
function App() {
  return(
    <SubscribeProvider>
      <AppRoutes/>
    </SubscribeProvider>
  );
   
}
export default App;

import { axiosClient } from "../api";

export const checkAndupdateSurveyStatusApi = async (
  UID: any,
  SID: string,
  VID: string,
  hmacValue: any,
  currentUrl: string,
  sessionId: string
) => {
  return axiosClient
    .get("/api/survey/checkAndUpdateSurveyStatus", {
      params: {
        UID,
        SID,
        VID,
        hmacValue,
        currentUrl,
        session_id: sessionId,
      },
    })
    .then((response) => response.data);
};

export const checkUserProfileStatusApi = async (UID: string) => {
  return axiosClient
    .post("/api/user/getUserProfileStatus", { UID })
    .then((response) => response.data);
};

import { axiosClient } from "../../api/api";
export const exportHistory = async (panelListId: number) => {
  return axiosClient
    .get("/api/survey/getSurveyHistory/export", {
      params: {
        panelListId,
      },
    })
    .then((response) => response.data);
};

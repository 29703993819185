import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Survey } from "../../assets/SideBarIcons/Survey.svg";
import { ReactComponent as Vector } from "../../assets/SideBarIcons/Vector.svg";
import { LiaHistorySolid } from "react-icons/lia";
import { MdOutlineUnsubscribe } from "react-icons/md";
import CardFrameimg from "../../assets/SideBarIcons/CardFrameimg.svg";
import { ReactComponent as LogoutIcon } from "../../assets/SideBarIcons/Logout.svg";
import MobileMenu from "./Menus/MobileMenu";
import { useAuth } from "context/auth-context";
import { useMutation } from "@tanstack/react-query";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import { subscribeUser, subscribeStatus } from "./Subscribe.api";
import { useSubscribe } from "context/Subscribe-context";
import { LuLayoutDashboard } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import { GoCrossReference } from "react-icons/go";
import { IoSettingsOutline } from "react-icons/io5";
import { BiHelpCircle } from "react-icons/bi";
function Sidebar() {
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();
  const { auth } = useAuth();
  const { isSubscribe, setisSubscribe } = useSubscribe();

  useEffect(() => {
    const fetchInitialSubscriptionStatus = async () => {
      try {
        const response = await subscribeStatus(auth.panelistId);
        setisSubscribe(response.isSubscribed);
      } catch (error) {
        setisSubscribe(0);
      }
    };
    fetchInitialSubscriptionStatus();
  }, [auth.panelistId, setisSubscribe]);

  const mutation = useMutation(() => subscribeUser(auth.panelistId), {
    onSuccess(data) {
      if (data.newSubscribeStatus === 1) {
        setisSubscribe(data.newSubscribeStatus);
        toastrSuccess("You have been subscribed successfully to the daily notifications");
      }
    },
    onError() {
      toastrError("Failed to update subscription status. Please try again later.");
    },
  });

  const handleClick = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  const menus = [
    {
      name: "Dashboard",
      icon: <LuLayoutDashboard style={{ fontSize: "22px" }} />,
      NavLink: "/Dashboard",
    },
    {
      name: "Profile",
      icon: <CgProfile style={{ fontSize: "24px" }} />,
      NavLink: "/my-profile",
    },
    {
      name: "Surveys",
      icon: <Survey />,
      subMenus: [
        { name: "Best match", NavLink: "/my-surveys" },
        { name: "Short Surveys", NavLink: "/short-survey" },
        { name: "Most Points", NavLink: "/most-point" },
      ],
    },
    {
      name: "Account",
      icon: <IoSettingsOutline style={{ fontSize: "22px" }} />,
      NavLink: "/accountsection",
    },
    {
      name: "Refer a Friend",
      icon: <GoCrossReference style={{ fontSize: "20px" }} />,
      NavLink: "/my-referral",
    },
    {
      name: "Surveys History",
      icon: <LiaHistorySolid style={{ fontSize: "24px" }} />,
      NavLink: "/history",
    },
    {
      name: "Unsubscribe",
      icon: <MdOutlineUnsubscribe style={{ fontSize: "24px" }} />,
      NavLink: "/unsubscribe",
    },
    {
      name: "Help",
      icon: <BiHelpCircle style={{ fontSize: "24px" }} />,
      NavLink: "/raise-query",
    },
    {
      name: "Logout",
      icon: <LogoutIcon />,
      NavLink: "/logout",
    },
  ];

  const isActive = (NavLink) => location.pathname === NavLink;

  const toggleSubMenu = (index) => {
    if (openMenu === index) {
      setOpenMenu(null);
    } else {
      setOpenMenu(index);
    }
  };

  return (
    <>
      <div className="w-[262px] pt-16 bg-white fixed h-full font-poppins font-normal z-10 overflow-y-scroll custom-scrollbar hidden md:hidden lg:block">
        <ul className="flex flex-col pt-6 space-y-3 mt-2">
          {menus.map((menu, index) => (
            <li key={index} className={menu.subMenus ? "group relative" : ""}>
              {menu.subMenus ? (
                <div
                  onClick={() => toggleSubMenu(index)}
                  className={` flex items-center px-7 py-3 gap-2 ml-3 mr-7 text-sm font-normal font-['Poppins'] cursor-pointer relative rounded-[80px] ${
                    isActive(menu.NavLink)
                      ? "bg-[#056E9C] text-white"
                      : "text-zinc-700 hover:bg-[#056E9C] hover:bg-opacity-10"
                  }`}
                >
                  {menu.icon}
                  <Vector
                    className={`absolute left-48 ${
                      openMenu === index ? "rotate-90" : ""
                    } transition-transform pointer-events-none`}
                  />
                  <span>{menu.name}</span>
                </div>
              ) : (
                <NavLink
                  to={menu.NavLink}
                  className={`w-[200px] h-12 px-7 py-3 flex items-center space-x-3 ml-3 text-sm font-normal  font-['Poppins'] rounded-[80px] ${
                    isActive(menu.NavLink)
                      ? "bg-[#056E9C] text-white"
                      : "text-zinc-700 hover:bg-[#056E9C] hover:bg-opacity-10"
                  } ${menu.name === "Help" || menu.name === "Logout" ? " pb-2" : ""}`}
                >
                  {menu.icon && menu.icon}

                  <span>{menu.name}</span>
                </NavLink>
              )}
              {openMenu === index && menu.subMenus && (
                <ul className="pl-8 mt-2">
                  {menu.subMenus.map((subMenu, subIndex) => (
                    <li key={subIndex}>
                      <NavLink
                        to={subMenu.NavLink}
                        className={`w-[190px] h-12 px-9 py-4 mt-2  text-sm font-normal font-['Poppins'] block rounded-[80px] ${
                          isActive(subMenu.NavLink)
                            ? " bg-[#056E9C] text-white"
                            : "text-zinc-700 hover:bg-[#056E9C] hover:bg-opacity-10"
                        }`}
                      >
                        <span>{subMenu.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        <div className="py-10 mt-22 text-sm font-normal text-neutral-600 px-10 pl-3">
          <div className="w-[218px] text-center pl-2">
            <span className="text-neutral-600 text-sm font-normal font-['Poppins']">
              Get the latest updates delivered straight to your inbox.
            </span>
          </div>
        </div>

        <div className="w-[200px] h-[230px] bg-gradient-to-b from-white to-sky-200 rounded-[19px] ml-[1.25rem] flex justify-center items-center">
          <div className="p-10 py-2">
            <img src={CardFrameimg} alt="Image" />
            {isSubscribe === 0 ? (
              <button
                className="w-[180px] h-[40px] bg-[#056E9C] rounded-[38px] shadow mt-5 mb-6 text-white"
                onClick={handleClick}
              >
                Subscribe Now
              </button>
            ) : (
              <button
                disabled={true}
                className="w-[180px] h-[40px] bg-[#056E9C59] bg-opacity-[35%] rounded-[38px] shadow mt-5 mb-6 text-[#FFFFFFB5] text-opacity-[71%]"
              >
                Subscribed
              </button>
            )}
          </div>
        </div>
      </div>
      <MobileMenu />
    </>
  );
}
export default Sidebar;

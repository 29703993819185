import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-sky-50 py-5 pl-5">
      <div className="flex flex-col md:flex-row md:gap-10 lg:gap-28 justify-center md:items-start mx-auto mt-24 mb-6">
        <div className="flex flex-col gap-6 w-[16rem] mb-4">
          <Link to={"/"}>
            {/* <span data-testid="companyName" className="sr-only">
              {t("home.header.Company")}
            </span> */}
            <img className="w-auto h-8 sm:h-10" src={Logo} alt="mainLogo" />
          </Link>
          <div className="text-cyan-600 flex items-center gap-3 pl-3">
            <a href="https://www.facebook.com/zampliasurveys">
              <FaFacebook size={26} />
            </a>
            <a href="https://www.instagram.com/zamplia.surveys/">
              <AiFillInstagram size={30} />
            </a>
            <a href="https://www.linkedin.com/company/zamplia/">
              <FaLinkedin size={26} />
            </a>
          </div>
        </div>
        <div className="my-4 lg:mt-0 flex flex-col gap-4">
          <p className="text-justify text-neutral-700 text-lg font-medium font-['Inter'] leading-normal">
            {t("footer.Company")}
          </p>

          <ul className="mt-0 lg:mt-2 space-y-4 text-sm">
            <li>
              <a
                className="text-justify text-neutral-700 text-base font-normal font-['Inter'] leading-snug  transition hover:text-neutral-700/75"
                href="/#about"
              >
                {t("home.header.About")}
              </a>
            </li>

            <li>
              <a
                className="text-justify text-neutral-700 text-base font-normal font-['Inter'] leading-snug  transition hover:text-neutral-700/75"
                href="/#faq"
              >
                {t("home.header.FAQ")}
              </a>
            </li>

            <li>
              <a
                className="text-justify text-neutral-700 text-base font-normal font-['Inter'] leading-snug  transition hover:text-neutral-700/75"
                href="/#contact"
              >
                {t("home.footers.Contact")}
              </a>
            </li>
          </ul>
        </div>
        <div className="my-4 lg:mt-0 flex flex-col gap-4">
          <p className="text-justify text-neutral-700 text-lg font-medium font-['Inter'] leading-normal">
            {t("footer.Community")}
          </p>

          <ul className="mt-0 lg:mt-2 space-y-4 text-sm">
            <li>
              <a
                className="text-justify text-neutral-700 text-base font-normal font-['Inter'] leading-snug  transition hover:text-neutral-700/75"
                href="/"
              >
                {t("footer.Blog")}
              </a>
            </li>
            <li>
              <a
                className="text-justify text-neutral-700 text-base font-normal font-['Inter'] leading-snug  transition hover:text-neutral-700/75"
                href="/"
              >
                {t("footer.Forum")}
              </a>
            </li>
          </ul>
        </div>

        <div className="my-4 lg:mt-0 flex flex-col lg:gap-4 gap-4 md:gap-3">
          <p className="text-justify text-neutral-700 text-lg font-medium font-['Inter'] leading-normal">
            {t("footer.Important Links")}
          </p>

          <ul className="mt-0 lg:mt-2 space-y-4 text-sm">
            <li>
              <a
                className="text-justify text-neutral-700 text-base font-normal font-['Inter'] leading-snug  transition hover:text-neutral-700/75"
                href="/privacy-policy"
              >
                {t("home.footer.PrivacyPolicy")}
              </a>
            </li>

            <li className="">
              <a
                className="text-justify text-neutral-700 lg:text-base text-base  font-normal font-['Inter'] leading-snug  transition hover:text-neutral-700/75"
                href="/terms-and-conditions"
              >
                {t("home.footer.TermConditions")}
              </a>
            </li>
            <li className=" lg:text-xs  md:mr-1 md:text-[10px]">
              <button className="  h-11 lg:h-11 bg-indigo-600 rounded-[15px]  text-white flex items-center  justify-center py-4 px-4   font-['Inter'] gap-1  ">
                <div className="w-[20] h-[20] flex items-center justify-center ">
                  <FaDiscord size={18} />
                </div>{" "}
                {t("footer.discord.server")}
                {""} &#8599;
              </button>
            </li>
          </ul>
        </div>

        {/* <div className="flex flex-col gap-4 w-[16rem]">
          <p className="text-justify text-neutral-700 text-lg font-medium font-['Inter'] leading-normal">
            Discord Community
          </p>

          <button className="h-10 bg-indigo-600 rounded-[15px] text-white flex items-center  justify-center py-4 px-1 font-['Inter'] gap-1">
            <div className="w-[20] h-[20] flex items-center justify-center ">
              <FaDiscord size={18} />
            </div>
            Join Our Discord Server &#8599;
          </button>
        </div> */}
      </div>
      <div className="border border-solid border-gray-500-zinc-300" />
      <div className="text-center text-neutral-700 text-base font-normal font-['Inter'] leading-snug mt-4">
        {t("footer.copywrite")}
      </div>
    </footer>
  );
}

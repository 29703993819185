import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AccountClose } from "../../assets/SideBarIcons/AccountClose.svg";
import { UpdateDob } from "./Account.api";
import { useAuth } from "context/auth-context";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toastrError, toastrSuccess } from "lib/toastr";
import { useTranslation } from "react-i18next";

const dobRegex = /^\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$/;

interface AccountToggleProps {
  dob: string;
  isPopupOpenDob;
}

const DobToggle: React.FC<AccountToggleProps> = ({ isPopupOpenDob }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const modelRef = useRef();
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [issubmit, setissubmit] = useState(false);

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };
  const dobFormat = `${year}-${month}-${day}`;
  const mutation = useMutation(() => UpdateDob(dobFormat, auth?.panelistId), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(t("dobTogel.success"));
        queryClient.invalidateQueries(["getAccountDetailsapi"]);
      } else {
        toastrError(t("dobTogel.failed"));
      }
    },
    onError: () => {
      toastrError(t("dobTogel.terminate"));
    },
  });

  const handleSubmit = () => {
    if (!dobRegex.test(dobFormat)) {
      toastrError(t("dobTogel.invalid"));
      return;
    }
    setissubmit(true);
    const dobDate = new Date(dobFormat);
    const now = new Date();
    let age = now.getFullYear() - dobDate.getFullYear();
    const monthDiff = now.getMonth() - dobDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dobDate.getDate())) {
      age--;
    }
    // Age validation
    if (age < 18) {
      setError(t("dobTogel.underage.error"));
      return false;
    } else {
      mutation.mutate();
      isPopupOpenDob();
    }
  };

  useEffect(() => {
    if (issubmit) {
      const dobDate = new Date(dobFormat);
      const now = new Date();
      let age = now.getFullYear() - dobDate.getFullYear();
      const monthDiff = now.getMonth() - dobDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dobDate.getDate())) {
        age--;
      }
      if (age < 18) {
        setError(t("dobTogel.underage.error"));
      }
    }
  }, [t]);

  const CloseModal = (e) => {
    if (modelRef.current === e.target) {
      isPopupOpenDob();
    }
  };
  return (
    <>
      {isPopupOpenDob && (
        <div
          className="fixed inset-0 lg:top-[8vh] lg:left-[10vw] w-full h-full flex justify-center items-center bg-black bg-opacity-50"
          ref={modelRef}
          onClick={CloseModal}
        >
          <div className="bg-white p-8 rounded-3xl shadow-md w-[268px] h-[350px] md:w-[420px] md:h-[350px] md:px-14 relative">
            <h1 className="text-center text-[16px] text-[#757575] font-medium font-poppins">
              {t("dobTogel.head")}
            </h1>
            <div className="absolute right-4 md:right-6 top-9 cursor-pointer">
              <AccountClose onClick={isPopupOpenDob} />
            </div>

            <div className="mt-7 md:mt-12 text-sm space-y-5 font-poppins font-normal text-[#413C3C]">
              <div>
                <label>{t("dobTogel.lebel.select")}</label>
                <div className="flex gap-2">
                  {/* Day Dropdown */}
                  <select
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                    className="w-1/3 h-[40px] pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50"
                  >
                    <option value="">{t("dobTogel.option.day")}</option>
                    {generateOptions(1, 31)}
                  </select>
                  {/* Month Dropdown */}
                  <select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="w-1/3 h-[40px] pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50"
                  >
                    <option value="">{t("dobTogel.option.month")}</option>
                    {generateOptions(1, 12)}
                  </select>
                  {/* Year Dropdown */}
                  <select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    className="w-1/3 h-[40px] pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50"
                  >
                    <option value="">{t("dobTogel.option.year")}</option>
                    {generateOptions(new Date().getFullYear() - 100, new Date().getFullYear())}
                  </select>
                </div>
                {error && (
                  <p className="text-red-500 text-[10px] mt-1 ml-2 text-base pb-2 w-full">
                    {error}
                  </p>
                )}
              </div>
              <div>
                <button
                  className="w-full h-[40px] md:h-[50px] mt-2 md:mt-7 pl-3 text-[#FFFFFF] bg-[#056E9C] rounded-2xl"
                  onClick={handleSubmit}
                  type="button"
                >
                  {t("dobTogel.button")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DobToggle;

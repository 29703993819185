import React from "react";
import { useTranslation } from "react-i18next";
import Constant from "../../constraints/constant.json";


export function TermCondition() {
  const { t } = useTranslation();
  return (
    <div id="terms-and-conditions" className=" pt-24 mx-auto font-sans bg-white">
      <div className="flex flex-col w-10/12 mx-auto overflow-hidden bg-white lg:flex lg:w-8/12 rounded-xl">
        <h1
          data-testid="Terms & Conditions"
          className="sm:py-6 sm:mt-24 sm:mr-24 sm:text-5xl font-bold text-2xl"
        >
          {t("page.term.TermsConditions")}
        </h1>
        <div className="mt-5">
          <h2 className=" mt-2 flex font-bold  text-lg flex-wrap mb-2">{t("page.term.Use")}</h2>
          <p>{t("page.term.should")}</p>
        </div>
        <div>
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 mt-2">
            {t("page.term.Membership")}
          </h2>
          <p>{t("page.term.qualify")}</p>
          <p className="py-4">{t("page.term.voluntary")}</p>
        </div>
        <div>
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 mt-2">
            {t("page.term.Registration")}
          </h2>
          <p>{t("page.term.apply")}</p>
          <p className="py-4">{t("page.term.agree")}</p>
          <p className="before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.register")}
          </p>
          <p className="before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.least")}
          </p>
          <p className="before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.provide")}
          </p>
          <p className="before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.become")}
          </p>
          <p className="before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.promptly")}
          </p>
          <p>{t("page.term.terminate")}</p>
          <p className="py-4">
            {t("page.term.sending")}{" "}
            <a className="text-sky-600" href={"mailto:" +"support@Zampliasurveys.com"}>
              {" "}
              {Constant.TERM_SUPPORT}
            </a>{" "}
            {t("page.term.requesting")}
          </p>
        </div>
        <div>
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 mt-2">
            {t("page.term.MembershipRequirements")}
          </h2>
          <p>{t("page.term.choose")}</p>
          <p className="py-4">{t("page.term.agreeto")}</p>
          <p className="py-3">{t("page.term.acknowledge")}</p>
        </div>
        <div>
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 mt-2">{t("page.term.Code")}</h2>
          <p>{t("page.term.obligations")}</p>
          <p className="py-4 before:content-['1.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.directly")}
          </p>
          <p className="py-1 before:content-['2.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.communication")}
          </p>
          <p className="py-1 before:content-['3.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.website")}
          </p>
          <p className="py-1 before:content-['4.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.confidential")}
          </p>
          <p className="py-1 before:content-['5.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.accessible")}
          </p>
          <p className="py-1 before:content-['6.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.information")}
          </p>
          <p className="py-1 before:content-['7.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.notify")}
          </p>
          <p className="py-1 before:content-['8.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.comply")}
          </p>
          <p className="py-1 before:content-['9.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.other")}
          </p>
          <p className="py-1 before:content-['10.'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.term.Market")}
          </p>
        </div>
        <div>
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 mt-2">{t("page.term.Privacy")}</h2>
          <p>{t("page.term.Protection")}</p>
        </div>
        <div>
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 mt-2">
            {t("page.term.Intellectual")}
          </h2>
          <p>{t("page.term.rights")}</p>
          <p className="py-4 mb-24">{t("page.term.Site")}</p>
        </div>
      </div>
    </div>
  );
}

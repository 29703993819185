import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getReferral } from "./MyReferral.api";
import Pagination from "components/Pagination/Pagination";
import { Referral } from "./MyReferral.interface";
import { useAuth } from "context/auth-context";
import { dateFormatHandler, YYYY_MM_DD } from "./../../lib/date-formatter-moment";
import { Tag } from "antd";

function getStatusReferral(status: any) {
  let btn = status;
  switch (status) {
    case 1:
      btn = (
        <Tag color="green" className=" px-5 py-0.5 rounded-2xl border-none font-poppins text-sm">
          Credited
        </Tag>
      );
      break;
    case 0:
    default:
      btn = (
        <Tag color="blue" className=" px-3 py-0.5 rounded-2xl border-none font-poppins text-sm">
          In progress
        </Tag>
      );
      break;
  }
  return btn;
}

export const ReferralUserTable = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [currentReferralPage, setCurrentReferralPage] = useState<number>(1);

  const getReferralData = useQuery(
    ["referral", currentReferralPage],
    () => getReferral(auth.panelistId, currentReferralPage),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    getReferralData.refetch();
  }, [currentReferralPage]);

 

  if (getReferralData.isLoading) {
    return (
      <div className="text-2xl text-center rounded-full animate-bounce mt-[15rem]">
        <span className="visually-hidden">{t("page.referral.Loading")}</span>
      </div>
    );
  }

  const page = 5;
  const startIndex = (currentReferralPage - 1) * page;

  return (
    <div className="mt-6 w-full h-[555px] lg:h-[445px] relative">
      <div className="mt-2 mb-2 ml-4 text-lg text-center text-md font-poppins lg:text-left">
        {t("referFriend.My Referral Users")}
      </div>
      <div data-testid="referral" className="pr-3 overflow-x-auto">
        <table className="mt-2 sm:ml-[4rem] md:ml-[0rem] lg:ml-0">
          <thead className="text-sm">
            <tr className="">
              <th scope="col" className="w-2/12 font-semibold text-center ">
                {t("page.referral.SrNo")}
              </th>
              <th scope="col" className="w-4/12 font-semibold text-center">
                {t("page.referral.Email")}
              </th>
              <th scope="col" className="w-2/12 font-semibold text-center">
                {t("page.referral.Points")}
              </th>
              <th scope="col" className="w-4/12 font-semibold text-center">
                {t("page.referral.Status")}
              </th>
              <th scope="col" className="w-2/12 font-semibold text-center">
                {t("page.referral.Date")}
              </th>
            </tr>
          </thead>
          <tbody className="w-full h-full">
            {getReferralData.data?.data?.length > 0 ? (
              getReferralData.data?.data.map((survey: Referral, i: number) => {
                return (
                  <tr key={startIndex + i + 1} className="">
                    <td className="font-normal text-center table-1-td-col-1">
                      {startIndex + i + 1}
                    </td>
                    <td
                      className="font-normal text-center table-1-td-col-2"
                      title={survey.user_email}
                    >
                      {survey?.user_email?.substring(0, 12) + "..."}
                    </td>
                    <td className="font-normal text-center table-1-td-col-2">
                      {Math.floor(+survey.points)}
                    </td>
                    <td className="font-normal text-center table-1-td-col-2 rounded-2xl">
                      {getStatusReferral(survey.isRewardAdded)}
                    </td>
                    <td className="font-normal text-center table-1-td-col-2">
                      {dateFormatHandler(survey.createdAt, YYYY_MM_DD)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="w-full">
                <div className="absolute lg:bottom-[12rem] lg:left-[14rem] bottom-[20rem] left-[8rem] md:left-[20rem]">
                  <p className="text-sm">{t("page.mysurvey.nodata")}</p>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      {getReferralData.data?.data && getReferralData.data?.data?.length > 0 ? (
        <div className="flex justify-center">
          <Pagination
            setCurrentPage={setCurrentReferralPage}
            totalPages={getReferralData?.data?.totalPages}
            currentPage={currentReferralPage}
          />
        </div>
      ) : null}
    </div>
  );
};

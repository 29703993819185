import React from 'react';
interface SpinnCircleProps {
  spinning: boolean;
  result: number;
  handleSpinClick: () => void;
}

function SpinnCircle({ spinning, handleSpinClick, result }:SpinnCircleProps) {
  return (
    
    <>
     <div>
      <button id='spin' className=' lg:w-[3.75rem] lg:h-[3.75rem] md:w-[3.75rem] md:h-[3.75rem] w-[3rem] h-[3rem] absolute lg:top-[55%] md:top-[52%] top-[54%] left-1/2 z-10  bg-gradient-to-b from-[#303030] to-[#000604] border-4 border-solid border-[#e2e2e2] lg:text-base md:text-base text-xs font-bold text-[#b0acac] font-sans uppercase  rounded-[50%] cursor-pointer outline-none tracking-[0.1rem] transform -translate-x-1/2 -translate-y-1/2 ' onClick={handleSpinClick} disabled={spinning || result !== null } >Spin</button>
      <span className='arrow text-3xl font-extrabold absolute lg:top-[10.20rem] md:top-[7.80rem] top-[6.75rem] left-1/2 transform -translate-x-1/2 text-black'></span>
      <div className='container lg:w-[18.75rem] lg:h-[18.75rem] md:w-[18.75rem] md:h-[18.75rem] w-[14.75rem] h-[14.75rem]  rounded-[50%] border-[0.70rem] border-solid border-[#3a3939]  relative overflow-hidden '>
   
        <div className='one spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          55
          </span>
          </div>

        <div className='two spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          350
          </span>
          </div>

        <div className='three spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          75
          </span>
          </div>

        <div className='four spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          125
          </span>
          </div>

        <div className='five spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          200
          </span>
          </div>

        <div className='six spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          1000
          </span>
          </div>

        <div className='seven spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          150
          </span>
          </div>

        <div className='eight spinn_Cirlce'>
          <span className='text-center h-1/2 '>
            
          500
          </span>
          </div>


      </div>
    </div>
    </>
  );
}

export default SpinnCircle;

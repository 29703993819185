import React from "react";
import { ContactForm } from "components";

export const ContactPage = () => {
  return (
    <div>
      <ContactForm />
    </div>
  );
};

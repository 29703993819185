import React from "react";
import PropTypes from "prop-types";
import { IoMdTime } from "react-icons/io";

const SurveyCard = ({ minutes, points, surveyName, ShowPopup,surveyData }) => {
 

  return (
    <div className=" hover:shadow-2xl transition duration-100 ease-in-out transform hover:scale-105 md:w-[200px] md:h-[230px] lg:w-[185px] lg:h-[225px] w-[150px] h-[225px] bg-white rounded-2xl border border-sky-500 border-opacity-30 flex flex-col items-center text-center mt-0 font-['Poppins']">
      <div className="flex items-center justify-between w-full md:px-4 lg:px-4 px-3 pt-4">
        <div className="flex flex-col items-start ">
          <span className="text-neutral-600 text-base md:font-medium font-['Poppins']">Points</span>
          <span className="text-sky-700 text-sm font-medium  font-['Poppins'] transition-all duration-300">
            {points}
          </span>
        </div>
        <div className="flex items-center pb-5">
          <span className="text-neutral-600 text-xl font-['Poppins']">
            <IoMdTime />
          </span>
          <span className="text-neutral-600 text-sm font-medium md:ml-2 ml-1 font-['Poppins']">
            {minutes} Min
          </span>
        </div>
      </div>
      <div className="my-5">
        <p className="text-sky-700  md:text-base lg:text-base text-sm font-medium md:pr-[55px] lg:pr-[55px] pr-[2rem] font-['Poppins']">
          Survey Name
        </p>
        <p className="text-neutral-600 text-sm pt-1 font-normal md:pr-20 lg:pr-20 pr-[3rem] font-['Poppins']">
          {surveyName}
        </p>
      </div>
      <button
        className="md:w-[142px] h-[42.44px] w-[125px] flex items-center justify-center AttemptNow text-sky-700 text-sm font-medium font-['Poppins'] rounded-[54.68px] border border-sky-700 mt-5 hover:bg-sky-700 hover:bg-opacity-5"
        onClick={() => ShowPopup(surveyData)}
      >
        Attempt Now
      </button>
    </div>
  );
};

SurveyCard.propTypes = {
  minutes: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  surveyName: PropTypes.string.isRequired,
  attemptFunction: PropTypes.func.isRequired,
  surveyData: PropTypes.object.isRequired,
  ShowPopup: PropTypes.func.isRequired,
};

export default SurveyCard;

import React, { FC } from "react";
// import remove from "../../assets/JoinNow/cancel.svg";
import box from "../../assets/JoinNow/giftbox.svg";
import tinyGift from "../../assets/JoinNow/tinyGift.svg";

export interface ICard {
  closepopup: () => void;
  countryname:string
}
export const Successpopup: FC<ICard> = ({ closepopup, countryname }) => {
  return (
    <div>
      <div className="fixed left-0 z-50 flex items-center inset-0 bg-black bg-opacity-50  justify-center w-full h-full">
        <div className="top-[50vh] bg-white opacity-100 rounded-[35px] absolute flex flex-col items-center left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 lg:px-6 lg:py-5 md:px-4 md:py-12 px-4 py-12">
          {/* <div
              className=" text-xl lg:w-auto md:w-6 w-6  absolute font-poppins top-[20px] lg:right-[25px] md:right-[25px] right-5 cursor-pointer"
              onClick={closepopup}
            >
              <img src={remove} alt="remove icon" />
            </div> */}
          <div className="lg:w-auto md:w-24 w-24">
            <img src={box} alt="" />
          </div>
          <div className=" flex flex-col   font-poppins items-center lg:mt-3 md:mt-2">
            <span className="text-[#0B9308] font-medium lg:text-2xl md:text-xl">
              Congratulations
            </span>
            <div className="flex lg:w-[20.50rem] lg:h-10 md:w-[18rem] md:h-8 w-[17rem] h-8 text-[#056E9C] bg-[#AED1E14F] rounded-3xl font-poppins font-normal  items-center justify-center gap-2 lg:text-sm md:text-xs text-xs lg:mt-4 md:mt-3 mt-3">
              <div className="lg:w-5 md:w-4 w-4">
                <img src={tinyGift} alt="gift" />
              </div>
              <span>Login and get up to $5 FREE BONUS!</span>
            </div>
            <div className="flex flex-col items-center justify-center font-poppins mt-8 ">
              <span className="lg:text-base md:text-xs text-xs text-[#056E9C]">
                Earn up to $5 per survey!
              </span>
              <span className=" lg:text-base md:text-sm text-xs">
                Explore 1264 surveys available in the {countryname}!
              </span>
            </div>
            <button
              className="w-44 h-8 lg:w-56 lg:h-14 md:w-48 md:h-12 bg-[#056E9C] lg:text-base md:text-sm text-xs text-white rounded-3xl mt-12"
              onClick={closepopup}
            >
              Start Earning Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import MySurveysTable, { ThElementsType } from "components/MySurveys/MySurveysTable";
import { useState } from "react";
import { usePanelistCompleteStatusData } from "hooks/usePanelistCompleteStatusData-hook";

import Pagination from "../../components/Pagination/Pagination";
import LoadingOverlay from "react-loading-overlay-ts";
import { rejectDataHandler } from "./dataHandler";

const ThColumns: ThElementsType = {
  scope: "col",
  classes: "table-1-th text-center",
  dataKeys: [
    { id: 1, column: "id", label: "id" },
    { id: 2, column: "Points", label: "points" },
    { id: 3, column: "Study Name", label: "studyName" },
    { id: 4, column: "Date", label: "createdAt" },
    { id: 7, column: "Status", label: "status" },
  ],
};

export const ClaimedStudiesRejected = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const data = usePanelistCompleteStatusData({
    page: currentPage,
    fromDate: "2023-12-01+14:58:53",
    type: "reject",
  });

  const statusDisplay = () => {
    return <button className="px-3 py-1 text-white text-sm bg-red-400 rounded-md ">Rejected</button>;
  };

  const dataSet = rejectDataHandler({
    data,
    indexOfFirstRecord,
    indexOfLastRecord,
    status: statusDisplay(),
  });
  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={data?.isLoading || data?.isFetching}
      spinner
      text="Loading..."
    >
      <div className="lg:w-full w-[98vw] mt-5 rounded-lg h-[75vh] overflow-x-auto mb-20 sm:mb-0 overflow-y-auto bg-white">
        <MySurveysTable
          thElements={ThColumns}
          tdElements={dataSet}
          searchQuery="searchQuery"
          tdDataStyle="table-1-td-col-2"
          tdRowStyle=""
          tableStyle=""
        />
        <Pagination
          totalPages={data?.data?.totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </LoadingOverlay>
  );
};

import { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { getCategoryListByProfile } from "./../Myprofilepage/Myprofilepage.api";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import LoadingOverlay from "react-loading-overlay-ts";
import NavCard from "../Dashboard/NavCard";
import { IP_REGISTRY_KEY } from "../../config/constants";

function MyProfileComponents() {
  const { auth } = useAuth();
  const [langCode, setLangCode] = useState("");

  const getCategoryProfile = useQuery(
    ["myprofile"],
    () => getCategoryListByProfile(auth.panelistId, langCode),
    {
      enabled: !!langCode,
      refetchOnWindowFocus: false,
    }
  );

  const getData = async () => {
    const res = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_KEY}`);
    if (res.data.currency.code == "USD") {
      // setCountry("USA");
      setLangCode("En-US");
      localStorage.setItem("zamplia_user_lang_code", "En-US");
      localStorage.setItem("current_user_region", res.data.location.region.name);
      localStorage.setItem("current_user_postal", res.data.location.postal);
    } else if (res.data.currency.code == "CAD") {
      // setCountry("Canada");
      setLangCode("English-CA");
      localStorage.setItem("zamplia_user_lang_code", "English-CA");
      localStorage.setItem("current_user_region", res.data.location.region.name);
      localStorage.setItem("current_user_postal", res.data.location.postal);
    } else if (res.data.currency.code == "GBP") {
      // setCountry("UK");
      setLangCode("English-GB");
      localStorage.setItem("zamplia_user_lang_code", "English-GB");
      localStorage.setItem("current_user_region", res.data.location.region.name);
      localStorage.setItem("current_user_postal", res.data.location.postal);
    } else if (res.data.currency.code == "INR") {
      setLangCode("English-IN");
      localStorage.setItem("zamplia_user_lang_code", "English-IN");
      localStorage.setItem("current_user_region", res.data.location.region.name);
      localStorage.setItem("current_user_postal", res.data.location.postal);
    } else {
      // setCountry(res.data.currency.code);

      setLangCode(res.data.location.country.name + "-" + res.data.location.country.code);
      localStorage.setItem(
        "zamplia_user_lang_code",
        res.data.location.country.name + "-" + res.data.location.country.code
      );

      //this is testing data
      // localStorage.setItem("current_user_region", "New York");
      // localStorage.setItem("current_user_postal", "10014");
      // localStorage.setItem("zamplia_user_lang_code", "En-US");
      // setLangCode("En-US");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={getCategoryProfile.isLoading}
        spinner
        text="Loading..."
      >
        <div className=" flex flex-row items-center justify-center md:px-0 lg:pl-4 scroll-smooth lg:w-[98vw]">
          <div className=" mt-24 mb-6 lg:ml-[16rem] pb-20">
            <div>
              <NavCard />
            </div>
            {/*parent box of Card*/}
            <div className="flex flex-col w-full mt-5 bg-[#FCFDFF] rounded-2xl">
              <div className="p-4 md:ml-14">
                <h1 className="text-2xl leading-10  text-[#404040] font-medium font-poppins">
                  My Profile
                </h1>
                <p className="text-[#4E4E4E] font-poppins font-normal text-base mt-2">
                  Your responses are completely anonymous
                </p>
              </div>
              <div className="flex flex-wrap justify-center py-2 md:px-10 lg:px-2 px-0.5">
                {getCategoryProfile?.data?.categoryList?.length > 0 &&
                  getCategoryProfile?.data?.categoryList?.map((survey, i) => (
                    //Card Box
                    <div
                      key={i}
                      className="h-full flex md:px-2 lg:px-2 px-1 mb-1"
                    >
                      {/*card*/}
                      <a href={"my-profile-category-question?category=" + survey.id}>
                        <div className="mt-4">
                          <div className="bg-[#ffffff] h-[270px] w-[170px] md:h-[300px] md:w-[220px] rounded-3xl  shadow hover:shadow-md border-[1px] border-transparent hover:border-blue-300">
                            <div className="flex flex-col justify-center items-center md:space-y-2 space-y-3">
                              <div className="pt-5">
                                <div className="h-11 w-11 bg-[#F2F7FA] rounded-full px-1.5 py-1.5">
                                  <img
                                    src={survey.imageUrl}
                                    className="w-[30px] h-[30px] px-0.5 py-1"
                                  ></img>
                                </div>
                              </div>
                              <section className="">
                                <p className="color-[#404040] md:font-medium text-center font-poppins text-sm md:text-lg font-medium px-0.5">
                                  {survey.name}
                                </p>
                              </section>
                              <div className="h-10 px-2">
                                <p className="color-[#4E4E4E] text-xs md:text-sm font-normal font-poppins text-center">
                                  {survey.content}
                                </p>
                              </div>
                              <div className=" h-4 w-14  mx-auto  md:w-4/12  md:py-4  py-4">
                                <ProgressBar
                                  val={Math.round(
                                    survey.totalQuestion !== 0 && survey.totalAttemptQuestion !== 0
                                      ? survey.totalAttemptQuestion > survey.totalQuestion
                                        ? (survey.totalQuestion / survey.totalQuestion) * 100
                                        : (survey.totalAttemptQuestion / survey.totalQuestion) * 100
                                      : 0
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}
export default MyProfileComponents;

import { axiosClient } from "../../api/api";
import { AttemptDataType } from "./intial-data";

export const getCountryAddress = async (creData) => {
  const { ipAddress } = creData;
  return axiosClient
    .get("/api/user/geo", {
      params: {
        ipAddress,
      },
    })
    .then((response) => response.data);
};

export const getSurveyData = async (cred) => {
  const { userId, page, userLangCode, sortColumnName, sortColumnOrder, country, ipAddress } = cred;
  return axiosClient
    .get("/api/user/geos", {
      params: { userId, page, userLangCode, sortColumnName, sortColumnOrder, country, ipAddress },
    })
    .then((response) => response.data);
};

export const getRedeemRequestHistoryData = async (panelistId: number, page: number) => {
  return axiosClient
    .get("/api/survey/getZampliaSurveysClaimRequest", {
      params: { panelistId, page, requestType: "Virtual Wallet", panel: "ZampliaSurveys" },
    })
    .then((response) => response.data);
};

export const addParticipant = async (obj: AttemptDataType) => {
  return axiosClient
    .post("/api/user/add_user", {
      obj,
    })
    .then((res) => res.data);
};

import { axiosClient } from "../../api/api";

export const subscribeUser = async (panelListId) => {
  return axiosClient
    .post("/api/user/subscribe",{
        panelListId,
    })
    .then((response) => response.data);
};


export const subscribeStatus = async (panelListId) => {
  return axiosClient
    .get("/api/user/subscribeStatus", {
      params: {
        panelListId: panelListId,
      },
    })
    .then((response) => response.data);
};

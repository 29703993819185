import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthData, useAuth } from "context/auth-context";

import {
  GetDetailsOfVerificationUserApi,
  SendOtpApi,
  VerifyOtpApi,
  registerUser,
  saveUserDetail,
  verifyUser,
} from "./JoinNowForm.api";
import { AxiosError, AxiosResponse } from "axios";
import { toastrSuccess, toastrError } from "./../../lib/toastr";
import arrow from "../../assets/JoinNow/arrow.svg";
import group from "../../assets/JoinNow/group.svg";

// import google from "../../assets/JoinNow/Google.svg";
// import facebook from "../../assets/JoinNow/facebook.svg";
import { Progress } from "antd";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ageValidator } from "controller/ageValidator";
import { loginUser } from "components/LoginForm/LoginForm.api";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { intialState } from "./JoinNowForm.interface";
// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";
// import { LoginSocialFacebook } from "reactjs-social-login";
// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";
// import { LoginSocialFacebook } from "reactjs-social-login";
import { IP_REGISTRY_KEY } from "../../config/constants";

type formVals = typeof intialState;
type JoinNowPayload = Omit<formVals, "errors">;
type ErrorBody = { status: number; message: string };
const initialState = {
  email: "",
  password: "",
  errors: {
    email: "",
    password: "",
  },
};

import { Successpopup } from "./Successpopup";
import { HashLink } from "react-router-hash-link";
import { JoinNowWithGoogle } from "./JoinNowWithGoogle";
type ModalIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const JoinNowForm = () => {
  const { t } = useTranslation();
  const [pageDisplayIndex, setPageDisplayIndex] = useState<ModalIndex | null>(1);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [loginemail, setloginEmail] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");
  const [displayInput, setDisplayInput] = useState(true);
  const [open, setOpen] = useState(false);
  const [openeye, setOpenEyes] = useState(false);
  const days: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  const months: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  const years: number[] = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
  const toggle = () => {
    setOpen(!open);
  };
  const openEyes = () => {
    setOpenEyes(!openeye);
  };
  //  const [email, setEmail]: any = useState("");
  // const [verficationName, setVerficationName]: any = useState("");
  const [email]: any = useState("");
  const [verficationName]: any = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [countryname, setCountryName] = useState();
  const [ipAddress, setIpAddressName] = useState();
  const [isVerified, setIsVarified] = useState(false);
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState(intialState);
  type formValue = typeof initialState;
  type LoginPayload = Omit<formValue, "errors">;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const newDateOfBirth = `${selectedYear}-${selectedMonth}-${selectedDay}`;
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      dateOfBirth: newDateOfBirth,
    }));
  }, [selectedDay, selectedMonth, selectedYear]);

  // const [error, setError] = useState("");

  const [con2, setCon2] = useState(false);

  const handleOtp = (e) => {
    const value = e.target.value;
    const trimmedValue = value.replace(/\s+/g, "");
    const regex = /^\d*$/;

    if (regex.test(trimmedValue)) {
      setOtp(trimmedValue);
    }
  };

  const userVerification = useMutation(
    (payload: JoinNowPayload["email"]) => {
      return verifyUser({
        email: payload,
      });
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setPageDisplayIndex(2);
        } else {
          toastrError(response.message);
        }
      },
      onError: (err: AxiosError) => {
        const repsonse = err.response as AxiosResponse<ErrorBody, Record<string, unknown>>;
        if (repsonse.data && repsonse.data.message) {
          toastrError(repsonse.data.message);
        } else {
          toastrError(t("form.join.password"));
        }
      },
    }
  );
  const userRegisteration = useMutation(
    (payload: JoinNowPayload) => {
      return registerUser({
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        dateOfBirth: payload.dateOfBirth,
        gender: payload.gender,
        referalId: payload.referalId,
        active: payload.active,
        language: payload.language,
        timeZone: payload.timeZone,
        currentUrl: payload.currentUrl,
        sid: payload.sid,
        clickid: payload.clickid,
        geo: payload.geo,
        socialID: payload.socialID,
        country: payload.country,
        state: payload.state,
      });
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setFormValue(intialState);

          setCon2(false);
          toastrSuccess(response.message);
          setShowSuccessPopup(true);
        } else {
          toastrError(response.message);
        }
      },
      onError: (err: AxiosError) => {
        const repsonse = err.response as AxiosResponse<ErrorBody, Record<string, unknown>>;
        if (repsonse.data && repsonse.data.message) {
          toastrError(repsonse.data.message);
        } else {
          toastrError(t("form.join.password"));
        }
      },
    }
  );

  const getData = async () => {
    const res = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_KEY}`);
    setIpAddressName(res.data.ip);
    setCountryName(res.data.location.country.name);

    if (res) {
      const sid = searchParams.get("sid") ? searchParams.get("sid") : "";
      const clickid = searchParams.get("clickid") ? searchParams.get("clickid") : "";
      const geo = searchParams.get("geo") ? searchParams.get("geo") : "";
      const referralBy = searchParams.get("referralBy") ? searchParams.get("referralBy") : "";
      setFormValue({
        ...formValue,
        language: res.data.location.language.name,
        timeZone: res.data.time_zone.id,
        country: res.data.location.country.name,
        state: res.data.location.region.name,
        currentUrl: window.location.href,
        referalId: referralBy,
        sid,
        clickid,
        geo,
      });
    }
  };

  useQuery(["addlandingurl"], () => saveUserDetail(window.location.href, ipAddress, countryname), {
    refetchOnWindowFocus: true,
  });

  React.useEffect(() => {
    getData();
  }, []);

  const fNameValid = (formValue: formVals) => {
    setFormValue((old) => {
      return {
        ...old,
        errors: {
          fname: "",
          lname: "",
          email: "",
          password: "",
          confirmPassword: "",
          gender: "",
          dob: "",
          referral: "",
          con1: "",
          con2: "",
        },
      };
    });
    const valid = true;
    const validregex = /^\S+(?:\s*\S+)*$/;
    if (formValue.firstName.length < 1) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, fname: t("form.join.First") } };
      });
      return false;
    } else if (!validregex.test(formValue.firstName)) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, fname: "Blank space is not allowed" } };
      });
      return false;
    } else if (!/^[a-zA-Z]+$/.test(formValue.firstName.trim())) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, fname: "Numeric value not be allowed" } };
      });
      return false;
    }
    if (formValue.lastName.length < 1 || !validregex.test(formValue.lastName)) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, lname: t("form.join.required") } };
      });
      return false;
    }
    if (!validregex.test(formValue.lastName)) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, lname: "Blank space is not allowed" } };
      });
      return false;
    }
    if (!/^[a-zA-Z]+$/.test(formValue.lastName.trim())) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, lname: "Numeric value not be allowed" } };
      });
      return false;
    }
    return valid;
  };

  //password validation
  const PasswordValid = (formValue: formVals) => {
    setFormValue((old) => {
      return {
        ...old,
        errors: {
          fname: "",
          lname: "",
          email: "",
          password: "",
          confirmPassword: "",
          gender: "",
          dob: "",
          referral: "",
          con1: "",
          con2: "",
        },
      };
    });
    const validregex = /^\S+(?:\s*\S+)*$/;

    // Trim spaces from password and confirm password
    const trimmedPassword = formValue.password.trim();
    const trimmedConfirmPassword = formValue.confirmPassword.trim();

    // Check if password contains spaces
    if (trimmedPassword.includes(" ")) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, password: "Password cannot contain spaces" },
        };
      });
      return false;
    }

    // Check if confirm password contains spaces
    if (trimmedConfirmPassword.includes(" ")) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, confirmPassword: "Password cannot contain spaces" },
        };
      });
      return false;
    }

    // Check if password exceeds 16 characters
    if (trimmedPassword.length > 16) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, password: "Password cannot exceed 16 characters" },
        };
      });
      return false;
    }

    // Check if confirm password exceeds 16 characters
    if (trimmedConfirmPassword.length > 16) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, confirmPassword: "Password cannot exceed 16 characters" },
        };
      });
      return false;
    }

    if (trimmedPassword.length === 0) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, password: "Firstly create password" },
        };
      });
      return false;
    } else if (trimmedPassword.length < 6) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, password: "Password should be at least 6 characters" },
        };
      });
      return false;
    } else if (!validregex.test(trimmedPassword)) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, password: "Blank space is not allowed" },
        };
      });
      return false;
    }

    if (trimmedConfirmPassword.length === 0) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, confirmPassword: "Please confirm password" },
        };
      });
      return false;
    } else if (trimmedConfirmPassword.length < 6) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, confirmPassword: "Password should be at least 6 characters" },
        };
      });
      return false;
    } else if (!validregex.test(trimmedConfirmPassword)) {
      setFormValue((old) => {
        return {
          ...old,
          errors: {
            ...old.errors,
            confirmPassword: "Blank space is not allowed",
          },
        };
      });
      return false;
    }

    if (trimmedPassword !== trimmedConfirmPassword) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, confirmPassword: "Password doesn't match" },
        };
      });
      return false;
    }

    return true;
  };

  const passwordInput = document.getElementById("password") as HTMLInputElement;

  passwordInput?.addEventListener("input", (event) => {
    const target = event.target as HTMLInputElement;
    const valueWithoutSpaces = target.value.replace(/\s/g, "");
    if (target.value !== valueWithoutSpaces) {
      target.value = valueWithoutSpaces;
    }
  });

  const confirmPasswordInput = document.getElementById("confirmPassword");
  confirmPasswordInput?.addEventListener("keydown", (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  });

  // gender and dob validation
  const genderValid = (formValue: formVals) => {
    setFormValue((old) => {
      return {
        ...old,
        errors: {
          fname: "",
          lname: "",
          email: "",
          password: "",
          confirmPassword: "",
          gender: "",
          dob: "",
          referral: "",
          con1: "",
          con2: "",
        },
      };
    });
    const valid = true;
    const dobRegex = /^\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$/;
    if (!formValue.gender) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, gender: t("form.join.gender") },
        };
      });
      return false;
    }
    if (!(formValue.dateOfBirth && dobRegex.test(formValue.dateOfBirth))) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, dob: t("form.join.Date") },
        };
      });
      return false;
    } else if (!ageValidator(formValue.dateOfBirth, 18)) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, dob: t("form.join.underAgeError") },
        };
      });
      return false;
    }
    return valid;
  };

  //

  const validation = async (formValue: formVals) => {
    // setError("");
    setFormValue((old) => {
      return {
        ...old,
        errors: {
          fname: "",
          lname: "",
          email: "",
          password: "",
          confirmPassword: "",
          gender: "",
          dob: "",
          referral: "",
          con1: "",
          con2: "",
        },
      };
    });
    const valid = true;

    // email validation patterns
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const validregex = /^\S+(?:\s*\S+)*$/;
    // email validation

    if (!(formValue.email && regexEmail.test(formValue.email.trim()))) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, email: t("form.join.address") } };
      });
      return false;
    } else if (!validregex.test(formValue.email)) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, email: "Blank space is not allowed" } };
      });
      return false;
    }

    if (!formValue.gender) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, gender: t("form.join.gender") },
        };
      });
      return false;
    }

    if (!con2) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, con2: t("form.join.accepts") },
        };
      });
      return false;
    }

    return valid;
  };

  const checkEmail = () => {
    userVerification.mutate(formValue.email);
  };

  const SendOtp = useMutation(() => SendOtpApi(formValue.email), {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);
      if (response.success) {
        toastrSuccess(response.message);
        setIsVarified(true);
        setDisplayInput(false);
      } else {
        toastrError(response.message);
      }
    },
    onError: () => {
      setLoading(false);
      toastrError("Please write a valid email.");
    },
  });

  const handleSubmit = async () => {
    userRegisteration.mutate({
      email: formValue.email,
      password: formValue.password,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      dateOfBirth: formValue.dateOfBirth,
      gender: formValue.gender,
      referalId: formValue.referalId,
      active: true,
      language: formValue.language,
      timeZone: formValue.timeZone,
      currentUrl: formValue.currentUrl,
      sid: formValue.sid,
      clickid: formValue.clickid,
      geo: formValue.geo,
      socialID: formValue.socialID,
      country: formValue.country,
      state: formValue.state,
      confirmPassword: formValue.confirmPassword,
    });
  };

  const handleChangeModalDisplay = (i: ModalIndex): void => {
    if (i === 0) {
      setPageDisplayIndex(1);
    } else if (i === 1) {
      checkEmail();
    } else if (i === 2) {
      setPageDisplayIndex(3);
    } else if (i === 3) {
      setPageDisplayIndex(4);
    } else if (i === 4) {
      setPageDisplayIndex(6);
    } else if (i === 5) {
      setPageDisplayIndex(6);
    } else {
      setPageDisplayIndex(null);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === "selectedDay") {
      setSelectedDay(value);
    } else if (name === "selectedMonth") {
      setSelectedMonth(value);
    } else if (name === "selectedYear") {
      setSelectedYear(value);
    }
    if (name === "privacy-policy") {
      setCon2((prev) => !prev);
    }

    setIsVarified(false);
    setFormValue({ ...formValue, [name]: value });

    setFormValue((old) => {
      return {
        ...old,
        errors: {
          fname: "",
          lname: "",
          email: "",
          password: "",
          confirmPassword: "",
          gender: "",
          dob: "",
          referral: "",
          con2: "",
        },
      };
    });
    const regex = /^\S+(?:\s*\S+)*$/;
    // email validation patterns
    if (name === "email") {
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

      // email validation
      if (!regexEmail.test(value.trim())) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, email: t("form.join.address") } };
        });
      } else if (!regex.test(value)) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, email: "Blank space is not allowed" } };
        });
      } else {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, email: "" } };
        });
      }
    }
    // fName and lName validation

    if (name === "firstName" && value.length > 0) {
      if (value.length < 1) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, fname: t("form.join.First") } };
        });
      } else if (!regex.test(value)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, fname: "Blank space is not allowed" },
          };
        });
      } else if (!/^[a-zA-Z]+$/.test(value.trim())) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, fname: "Numeric value not be allowed" } };
        });
      }
    } else {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, fname: "" } };
      });
    }

    if (name === "lastName" && value.length > 0) {
      if (value.length < 1) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, lname: t("form.join.required") } };
        });
        return false;
      } else if (!regex.test(value)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, lname: "Blank space is not allowed" },
          };
        });
        return false;
      } else if (!/^[a-zA-Z]+$/.test(value.trim())) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, lname: "Numeric value not be allowed" } };
        });
        return false;
      }
      if (formValue.firstName.length < 1 || !regex.test(formValue.firstName)) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, fname: t("form.join.First") } };
        });
        return false;
      }
      if (!regex.test(formValue.firstName)) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, fname: "Blank space is not allowed" } };
        });
        return false;
      }
      if (!/^[a-zA-Z]+$/.test(formValue.firstName.trim())) {
        setFormValue((old) => {
          return { ...old, errors: { ...old.errors, fname: "Numeric value not be allowed" } };
        });
        return false;
      }
    } else {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, lname: "" } };
      });
    }

    // password validation
    if (name === "password" || name === "confirmPassword") {
      if (
        formValue.password.length < 5 &&
        name === "confirmPassword" &&
        value.length > 0 &&
        regex.test(value)
      ) {
        setFormValue((old) => ({
          ...old,
          errors: { ...old.errors, password: "Please create password" },
        }));
      } else if (value.length < 6 && value.length > 0 && regex.test(value)) {
        setFormValue((old) => ({
          ...old,
          errors: { ...old.errors, password: t("form.join.passwordS") },
        }));
      } else if (!regex.test(value) && value.length > 0 && name === "password") {
        setFormValue((old) => ({
          ...old,
          errors: { ...old.errors, password: "Blank space is not allowed" },
        }));
      } else if (!regex.test(value) && value.length > 0 && name === "confirmPassword") {
        setFormValue((old) => ({
          ...old,
          errors: { ...old.errors, confirmPassword: "Blank space is not allowed" },
        }));
      } else {
        setFormValue((old) => ({
          ...old,
          errors: { ...old.errors, password: "", confirmPassword: "" },
        }));
      }
    }
    if (
      (name === "confirmPassword" || name === "password") &&
      formValue.password !== "" &&
      formValue.confirmPassword !== ""
    ) {
      if (value !== formValue.password && value.length > 5) {
        setFormValue((old) => ({
          ...old,
          errors: { ...old.errors, confirmPassword: "Passwords doesn't match" },
        }));
      }
    }

    // gender validation

    if (name === "gender") {
      if (!value) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, gender: t("form.join.gender") },
          };
        });
      } else {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, gender: "" },
          };
        });
      }
    }
    const dayRegex = /^(0?[1-9]|[1-2][0-9]|3[0-1])$/;
    const monthRegex = /^(0?[1-9]|1[0-2])$/;
    const yearRegex = /^[0-9]{4}$/;

    if (name === "selectedDay") {
      if (!dayRegex.test(value)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your day" },
          };
        });
      } else if (!ageValidator(selectedYear, 13)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: t("form.join.underAgeError") },
          };
        });
      } else if (!monthRegex.test(selectedMonth)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your month" },
          };
        });
      } else if (!yearRegex.test(selectedYear)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your year" },
          };
        });
      }
    } else if (name === "selectedMonth") {
      if (!monthRegex.test(value)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your Month" },
          };
        });
      } else if (!ageValidator(selectedYear, 13)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: t("form.join.underAgeError") },
          };
        });
      } else if (!yearRegex.test(selectedYear)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your year" },
          };
        });
      } else if (!dayRegex.test(selectedDay)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your day" },
          };
        });
      }
    } else if (name === "selectedYear") {
      if (!yearRegex.test(value)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your Year" },
          };
        });
      } else if (!ageValidator(value, 13)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: t("form.join.underAgeError") },
          };
        });
      } else if (!monthRegex.test(selectedMonth)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your month" },
          };
        });
      } else if (!dayRegex.test(selectedDay)) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "Please select your day" },
          };
        });
      } else {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, dob: "" },
          };
        });
      }
    }
    if (name === "privacy-policy") {
      if (!value) {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, con2: t("form.join.accepts") },
          };
        });
      } else {
        setFormValue((old) => {
          return {
            ...old,
            errors: { ...old.errors, con2: "" },
          };
        });
      }
    }
  };

  const CheckDetailsOfVerificationUser = useMutation(
    (data: { emailID: string; name: string }): any => {
      const { emailID, name } = data;
      return GetDetailsOfVerificationUserApi(emailID, name);
    },
    {
      onSuccess: (response: any) => {
        response?.success
          ? toastrSuccess(response?.message)
          : toastrError("Duplicate Entry Found!!");
      },
      onError: () => {
        toastrError("Something went wrong!!");
      },
    }
  );

  // const handleSuccess = (credentialResponse: any) => {
  //   if (credentialResponse) {
  //     let userEmail: any;
  //     if (credentialResponse && credentialResponse?.credential) {
  //       userEmail = jwtDecode(credentialResponse?.credential);
  //       userEmail = userEmail?.email;
  //     } else if (credentialResponse && credentialResponse?.email) {
  //       userEmail = credentialResponse?.email;
  //     }

  //     setEmail(userEmail);
  //     if (userEmail && credentialResponse?.credential) {
  //       let verificationGoogle: any;
  //       verificationGoogle = jwtDecode(credentialResponse?.credential);
  //       verificationGoogle = verificationGoogle?.email_verified;
  //       verificationGoogle ? setVerficationName("Google") : "";
  //     } else if (userEmail && credentialResponse?.graphDomain === "facebook") {
  //       setVerficationName("Facebook");
  //     }
  //   } else {
  //     toastrError("Invalid credentialResponse object");
  //   }
  // };

  // const handleError = () => {
  //   toastrError("Something went wrong!!");
  // };

  const authMutation = useMutation(
    (payload: LoginPayload) => {
      return loginUser(payload.email, payload.password);
    },
    {
      onSuccess: (response: AuthData) => {
        setAuth(response);
        navigate("/dashboard");
      },
      onError: (err: AxiosError) => {
        const repsonse = err.response as AxiosResponse<ErrorBody, Record<string, unknown>>;
        toastrError(repsonse?.data.message);
      },
    }
  );

  useEffect(() => {
    if (email && verficationName) {
      CheckDetailsOfVerificationUser.mutate({ emailID: email, name: verficationName });
    }
  }, [email, verficationName]);

  const closepopup = () => {
    setShowSuccessPopup(false);

    // console.log("the email is",loginemail);
    authMutation.mutate({ email: loginemail, password: loginPassword });
  };

  const [yesSelected, setYesSelected] = useState(false);
  const [noSelected, setNoSelected] = useState(false);

  // if(formValue.referalId){
  //     setYesSelected(true);
  //     setNoSelected(false);
  // }
  const referralBy1 = searchParams.get("referralBy") ? searchParams.get("referralBy") : "";
  const handleYesChange = () => {
    setYesSelected(true);
    setNoSelected(false);
    setFormValue((prevState) => ({
      ...prevState,
      referalId: referralBy1,
    }));
  };
  const handleNoChange = () => {
    setYesSelected(false);
    setNoSelected(true);
    setFormValue((prevState) => ({
      ...prevState,
      referalId: "",
    }));
  };
  const VerifyOtp = useMutation(() => VerifyOtpApi(formValue.email, otp), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(response.message);
        setIsVarified(true);
        handleChangeModalDisplay(1);
      } else {
        toastrError(response.message);
      }
    },
    onError: () => {
      toastrError("please write good email");
    },
  });

  function handleSendOtp(e) {
    e.preventDefault();
    const emailValid = validation(formValue);
    if (emailValid && formValue.errors.email === "" && formValue.email !== "") {
      SendOtp.mutate();
    }
  }

  function handleVarifyOtp(e) {
    e.preventDefault();
    const emailvalid = validation(formValue);
    if (emailvalid && formValue.errors.email === "" && formValue.email !== "") {
      VerifyOtp.mutate();
    }
  }

  return (
    <>
      {/* for login */}
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={authMutation.isLoading}
        spinner
        text="Loading..."
      ></LoadingOverlay>

      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={userRegisteration.isLoading}
        spinner
        text="Loading..."
      >
        {showSuccessPopup && <Successpopup closepopup={closepopup} countryname={countryname} />}
        <div className="  bg-white lg:bg-[#F1F6FA] md:bg-[#F1F6FA] lg:mt-[2rem] mt-[5rem]  lg:h-[50.32rem] md:h-[38rem] h-[38rem] w-full flex flex-col justify-center items-center lg:py-6 md:py-4 py-0">
          <div className="bg-white lg:rounded-[2rem] md:rounded-[2rem]  lg:w-[66.30rem] lg:h-[42rem] lg:top-[9.69rem] w-full h-[38rem] lg:left-[16.75rem] md:w-[46.30rem] md:h-[33rem]  flex  items-center p-4">
            {pageDisplayIndex === 1 ? (
              <div className="lg:w-[73.30rem] lg:h-[42rem] hidden lg:block md:block lg:top-[9.69rem] lg:left-[16.75rem] md:w-[24rem] md:h-[33rem] lg:py-8 lg:px-8 md:py-8 md:px-8 ">
                <div className="text-[rgb(5,110,156)] font-poppins lg:text-2xl md:text-base font-semibold  ">
                  <div className="lg:h-[2rem] lg:w-[29rem]  md:h-[1.50rem] md:w-[15.50rem] flex items-center ">
                    Start earning cash rewards for
                  </div>
                  <div className="lg:h-[2rem] lg:w-[29rem]   md:h-[1.50rem] md:w-[15rem] flex items-center ">
                    {" "}
                    your opinions!{" "}
                  </div>
                </div>
                <div className=" flex flex-col gap-3 lg:mt-8 md:mt-4">
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-4 md:w-4 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[0.70rem] md:h-[0.70rem] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Get Paid with a Visa Card!</div>
                  </div>
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-4 md:w-4 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[0.70rem] md:h-[0.70rem] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Take Surveys Anytime You&apos;d Like</div>
                  </div>
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-4 md:w-4 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[0.70rem] md:h-[0.70rem] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Contribute to New Products & Services</div>
                  </div>
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-4 md:w-4 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[0.70rem] md:h-[0.70rem] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Your Answers Are Confidential!</div>
                  </div>
                </div>
                <div className="lg:mt-10 md:mt-16 lg:w-[27rem] md:w-[19rem]">
                  <img src={group} alt="" />
                </div>
              </div>
            ) : (
              <div className="lg:w-[73.30rem] lg:h-[42rem] lg:top-[9.69rem] lg:left-[16.75rem] md:w-[24rem] md:h-[33rem] lg:py-8 lg:px-8 md:py-8 md:px-8  hidden lg:block md:block">
                <div className="lg:w-[9.15rem] lg:h-[3.35rem] md:w-[9rem] md:h-[3rem] lg:top-[13.07rem] lg:left-[15rem] font-poppins font-semibold lg:text-2xl md:text-base text-[#666666] ">
                  Register
                </div>
                <div className="border-b lg:w-48 md:w-40 border-gray-300 "></div>
                <div className="lg:w-[23.40rem] md:w-[20rem] md:h-[7rem] lg:h-[11.10rem] lg:top-[18.57rem] lg:left-[21.25rem] font-poppins font-normal lg:text-lg md:text-sm text-[#505050] lg:mt-6 md:mt-4">
                  Once you join Zamplia Surveys you will be invited to participate in online studies
                  via email.
                </div>

                <div className="lg:mt-3 md:mt-14 lg:w-[27rem] md:w-[19rem]">
                  <img src={group} alt="" />
                </div>
              </div>
            )}

            <form className=" w-full h-[34rem] lg:w-[73.30rem] lg:h-[42rem] lg:top-[9.69rem] lg:left-[16.75rem] md:w-[24rem] md:h-[33rem] lg:py-8 lg:px-8 md:py-8 md:px-8">
              {pageDisplayIndex === 1 ? (
                <div className="flex flex-col ">
                  <div className="rounded-lg lg:w-[30rem] bg-[#54B94817] flex items-center ">
                    <div className=" w-[0.50rem] h-[2.30rem] lg:w-[0.45rem] lg:h-[2.30rem] md:w-[0.50rem] md:h-[1.80rem]  bg-[#54B948] rounded-l-lg"></div>
                    <div className="font-poppins font-normal text-[0.65rem] lg:text-xs md:text-[0.55rem] lg:ml-4 md:ml-2 ml-2">
                      Let&apos;s find out the opportunity to earn every time you fill
                    </div>
                  </div>
                  <div className="font-poppins font-semibold lg:text-lg md:text-sm text-[#666666] lg:mt-6 md:mt-4 mt-3">
                    Create your account
                  </div>
                  <div className="font-poppins font-medium lg:w-[30rem] text-[#505050] lg:text-base md:text-xs text-base">
                    Let&apos;s find out the opportunity to earn every time you fill out a survey
                    online!
                  </div>
                  <div className="lg:w-[30rem] flex flex-col w-full mt-6 lg:mt-[2rem] md:mt-[2rem]">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => setDisplayInput(true)}
                    >
                      {!displayInput && <img src={arrow} alt="" />}
                      <label className="after:content-['*'] after:ml-0.5 after:text-red-500 font-poppins font-medium text-[#777575] lg:text-sm md:text-[0.70rem] text-xs mt-2 cursor-pointer">
                        Email Address
                      </label>
                    </div>
                    {displayInput && (
                      <div className="flex md:flex-row flex-col gap-4 lg:mt-4 mt-3">
                        <div className="flex flex-col">
                          <input
                            type="text"
                            name="email"
                            id="user-email"
                            value={formValue.email}
                            onChange={onInputChange}
                            placeholder="username@gmail.com"
                            className="border-2 border[#A1A1A1] text-xs lg:text-sm md:text-[0.60rem] rounded-lg lg:rounded-xl md:rounded-md px-4 sm:w-[20vw] lg:w-[20rem] sm:py-2 py-4"
                          />
                          <div>
                            {formValue.errors.email ? (
                              <p className="text-red-700" id="email_err_msg">
                                {formValue.errors.email}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <button
                            className="lg:text-base w-fit h-fit md:text-xs text-center px-5 py-2 bg-[#056E9C] rounded-lg lg:rounded-xl md:rounded-md text-white"
                            onClick={handleSendOtp}
                            disabled={loading}
                          >
                            {loading
                              ? "Loading..."
                              : formValue.email && isVerified
                              ? "Resend OTP"
                              : "Get OTP"}
                          </button>
                        </div>
                      </div>
                    )}
                    {!displayInput && (
                      <div className="flex md:flex-row flex-col gap-4 lg:mt-4 mt-3">
                        <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row">
                          <input
                            type="text"
                            maxLength={6}
                            placeholder="Enter Your 6 digits OTP"
                            value={otp}
                            onChange={handleOtp}
                            className="border-2 border[#A1A1A1] text-xs lg:text-sm md:text-[0.60rem] rounded-lg lg:rounded-xl md:rounded-md px-4 sm:w-[22vw] lg:w-[22rem] sm:py-2 py-4"
                          />
                          <div className="flex justify-end mt-4 sm:mt-0 sm:ml-4">
                            <button
                              className="lg:text-base w-fit md:text-xs text-center px-5 py-2 bg-[#056E9C] rounded-lg lg:rounded-xl md:rounded-md text-[rgb(241,246,250)]"
                              onClick={handleVarifyOtp}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mt-6">
                      <JoinNowWithGoogle />
                    </div>
                    <div className="font-poppins text-xs lg:text-sm flex justify-end md:w-[15rem] lg:w-[27rem] sm:mr-3 lg:mt-14 md:mt-10 mt-8">
                      <span className="lg:font-normal font-light text-[#666666]">
                        Already have account?
                      </span>
                      <HashLink smooth to="/login">
                        <span className="font-bold text-[#056E9C] ml-2 cursor-pointer">Login</span>
                      </HashLink>
                    </div>
                  </div>
                </div>
              ) : null}

              {pageDisplayIndex === 2 ? (
                <div className="flex flex-col ">
                  <div className="flex lg:w-[30rem] md:gap-5 gap-2 text-[#928484] lg:text-sm md:text-[0.55rem] items-center justify-center">
                    <Progress
                      percent={20}
                      showInfo={false}
                      className=""
                      strokeColor="#49A800"
                      trailColor="#BCD2AB24"
                    />
                    <span className="lg:w-16 lg:h-8 md:h-5 md:w-12 w-20 md:right-auto lg:right-auto left-0 top-auto md:pr-4">
                      1 of 4
                    </span>
                  </div>
                  <div className="lg:w-[15.20rem] md:w-[15rem] lg:h-[3.35rem] md:h-[3.20rem] h-[2rem] top-[17.70rem] left-[62.69rem] font-poppins font-semibold lg:text-lg md:text-sm text-[#666666] lg:mt-6 md:mt-4 mt-3">
                    Create your account
                  </div>
                  <div className=" w-[19rem] lg:w-[25rem] lg:h-[3.50rem] md:w-[19rem] md:h-[2.50rem] top-[24.20rem] left-[62.65rem] font-poppins font-medium text-[#505050] lg:text-base md:text-xs text-base">
                    Let&apos;s find out the opportunity to earn every time you fill out a survey
                    online!
                  </div>
                  <div className="flex lg:flex-row md:flex-row flex-col   lg:gap-8 md:gap-8 gap-6 lg:mt-8 md:mt-6 mt-6">
                    <div className="font-poppins font-normal text-sm lg:text-sm md:text-[0.70rem] text-[#777575] flex flex-col ">
                      <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        value={formValue.firstName}
                        onChange={onInputChange}
                        placeholder="Danielle"
                        className="w-[20rem] h-[2.50rem]  lg:w-[11rem] lg:h-[3rem] md:w-[7.80rem] md:h-[1.80rem] border-2 border[#A1A1A1] rounded-md lg:rounded-xl md:rounded-md py-4 px-4"
                      />
                      {formValue.errors.fname ? (
                        <p className="text-red-700" id="">
                          {formValue.errors.fname}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <div className="font-poppins font-normal text-sm lg:text-sm md:text-[0.70rem] text-[#777575] flex flex-col ">
                        <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                          Last Name
                        </label>

                        <input
                          type="text"
                          name="lastName"
                          value={formValue.lastName}
                          onChange={onInputChange}
                          placeholder="Messimer"
                          className="w-[20rem] h-[2.50rem]  lg:w-[11rem] lg:h-[3rem] md:w-[7.80rem] md:h-[1.80rem] border-2 border[#A1A1A1] rounded-md lg:rounded-xl md:rounded-md py-4 px-4"
                        />
                        {formValue.errors.lname ? (
                          <p className="text-red-700" id="">
                            {formValue.errors.lname}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="lg:mt-8 md:mt-6 mt-6 md:ml-[12rem] lg:ml-[16rem] ml-60">
                    <button
                      className="w-20 h-8 text-[0.70rem] lg:h-[3rem] lg:w-[8rem] md:h-[2rem] md:w-[5.50rem] lg:text-base md:text-xs bg-[#056E9C] rounded-md lg:rounded-xl md:rounded-md text-[#F1F6FA] "
                      onClick={(e) => {
                        e.preventDefault();
                        const nameValid = fNameValid(formValue);
                        if (
                          nameValid &&
                          formValue.errors.fname === "" &&
                          formValue.errors.lname === "" &&
                          formValue.firstName !== "" &&
                          formValue.lastName !== ""
                        ) {
                          handleChangeModalDisplay(2);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                  <div
                    className="flex font-poppins font-normal text-[#056E9C] lg:mt-70  md:mt-40 mt-36 cursor-pointer "
                    onClick={() => handleChangeModalDisplay(0)}
                  >
                    <img src={arrow} alt="" />
                    <span>Previous</span>
                  </div>
                </div>
              ) : null}
              {pageDisplayIndex === 3 ? (
                <div className="flex flex-col ">
                  <div className="flex lg:w-[30rem] md:gap-5 gap-2 text-[#928484] lg:text-sm md:text-[0.55rem] items-center justify-center">
                    <Progress
                      percent={40}
                      showInfo={false}
                      className=""
                      strokeColor="#49A800"
                      trailColor="#BCD2AB24"
                    />
                    <span className="lg:w-16 lg:h-8 md:h-5 md:w-12 w-20 md:right-auto lg:right-auto left-0 top-auto md:pr-4">
                      2 of 4
                    </span>
                  </div>
                  <div className="lg:w-[15.20rem] md:w-[15rem] lg:h-[3.35rem] md:h-[3.20rem] h-[2rem] top-[17.70rem] left-[62.69rem] font-poppins font-semibold lg:text-lg md:text-sm text-[#666666] lg:mt-6 md:mt-4 mt-3">
                    Create your account
                  </div>
                  <div className=" w-[19rem] lg:w-[25rem] lg:h-[3.50rem] md:w-[19rem] md:h-[2.50rem] top-[24.20rem] left-[62.65rem] font-poppins font-medium text-[#505050] lg:text-base md:text-xs text-base">
                    Let&apos;s find out the opportunity to earn every time you fill out a survey
                    online!
                  </div>

                  <div className="flex lg:flex-row md:flex-row flex-col gap-4  lg:gap-8 md:gap-5 lg:mt-8 md:mt-6 mt-4">
                    <div className="font-poppins font-normal lg:text-sm md:text-[0.70rem] text-[#777575] flex flex-col">
                      <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                        Create Password
                      </label>
                      <div className="text-xl relative  text-[#CAC9C9]">
                        {open === false ? (
                          <AiFillEyeInvisible
                            className="absolute block lg:mt-3 md:mt-2 mt-2 text-center lg:right-4 md:right-4 right-14"
                            onClick={toggle}
                          />
                        ) : (
                          <AiFillEye
                            className="absolute block lg:mt-3 md:mt-2 mt-2 text-center lg:right-4 md:right-4 right-14"
                            onClick={toggle}
                          />
                        )}
                      </div>

                      <input
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        onCut={(e) => e.preventDefault()}
                        type={open === true ? "text" : "password"}
                        placeholder="******"
                        name="password"
                        id="password"
                        value={formValue.password}
                        onChange={onInputChange}
                        className="w-[20rem] h-[2.50rem] lg:w-[11rem] lg:h-[3rem] md:w-[8.50rem] md:h-[1.70rem] border-2 border-[#A1A1A1]  rounded-md lg:rounded-xl md:rounded-md py-4 px-4  pr-10"
                      />
                      {formValue.errors.password ? (
                        <p
                          className="text-red-700 absolute top-[23.5rem] lg:text-sm md:text-xs text-xs"
                          id=""
                        >
                          {formValue.errors.password}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <div className="font-poppins font-normal text-sm lg:text-sm md:text-[0.70rem] text-[#777575] flex flex-col ">
                        <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                          Confirm Password
                        </label>
                        <div className="text-xl relative  text-[#CAC9C9]">
                          {openeye === false ? (
                            <AiFillEyeInvisible
                              className="absolute block lg:mt-3 md:mt-2 mt-2 text-center lg:right-4 md:right-4 right-14"
                              onClick={openEyes}
                            />
                          ) : (
                            <AiFillEye
                              className="absolute block lg:mt-3 md:mt-2 mt-2 text-center lg:right-4 md:right-4 right-14"
                              onClick={openEyes}
                            />
                          )}
                        </div>

                        <input
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                          onCut={(e) => e.preventDefault()}
                          type={openeye === true ? "text" : "password"}
                          placeholder="******"
                          name="confirmPassword"
                          id="confirmPassword"
                          value={formValue.confirmPassword}
                          onChange={onInputChange}
                          className="w-[20rem] h-[2.50rem] lg:w-[11rem] lg:h-[3rem] md:w-[8.50rem] md:h-[1.70rem] border-2 border-[#A1A1A1] text-justify rounded-md lg:rounded-xl md:rounded-md py-4 px-4 pr-10"
                        />
                        {formValue.errors.confirmPassword ? (
                          <p
                            className="text-red-700 lg:text-sm md:text-xs text-xs absolute lg:top-[23.5rem] md:top-[12rem] top-[21.40rem]  "
                            id=""
                          >
                            {formValue.errors.confirmPassword}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="  gap-4 mt-4 lg:mt-8 md:mt-6 md:gap-6  lg:gap-4 ">
                    {/* <div className="w-[15rem] h-[1.60rem]  lg:w-[15.50rem] lg:h-[2rem] md:w-[10rem] md:h-[1.50rem]  bg-[#FFCA000F] text-[#777575] flex items-center justify-center lg:rounded-md md:rounded-md  rounded-sm text-xs lg:text-xs md:text-[0.50rem]">
                      Strong password: 12+ characters, mix case.
                    </div> */}
                    <button
                      className="w-20 h-8 text-[0.70rem] lg:h-[3rem] lg:w-[8rem] md:h-[2.50rem] md:w-[7rem] bg-[#056E9C] rounded-md lg:rounded-xl md:rounded-md text-[#F1F6FA] lg:text-lg md:text-sm  lg:mt-4 md:mt-2 lg:ml-64 md:ml-44 ml-60"
                      onClick={(e) => {
                        e.preventDefault();
                        const Pvalid = PasswordValid(formValue);
                        if (
                          Pvalid &&
                          formValue.errors.confirmPassword === "" &&
                          formValue.errors.password === "" &&
                          formValue.password !== "" &&
                          formValue.confirmPassword !== ""
                        ) {
                          handleChangeModalDisplay(3);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>

                  <div
                    className="flex font-poppins font-normal text-[#056E9C] lg:mt-44   md:mt-36 mt-28 cursor-pointer"
                    onClick={() => handleChangeModalDisplay(1)}
                  >
                    <img src={arrow} alt="" />
                    <span>Previous</span>
                  </div>
                </div>
              ) : null}
              {pageDisplayIndex === 4 ? (
                <div className="flex flex-col ">
                  <div className="flex lg:w-[30rem] md:gap-5 gap-2 text-[#928484] lg:text-sm md:text-[0.55rem] items-center justify-center">
                    <Progress
                      percent={75}
                      showInfo={false}
                      className=""
                      strokeColor="#49A800"
                      trailColor="#BCD2AB24"
                    />
                    <span className="lg:w-16 lg:h-8 md:h-5 md:w-12 w-20 md:right-auto lg:right-auto left-0 top-auto md:pr-4">
                      3 of 4
                    </span>
                  </div>
                  <div className="lg:w-[15.20rem] md:w-[15rem] lg:h-[3.35rem] md:h-[3.20rem] h-[2rem] top-[17.70rem] left-[62.69rem] font-poppins font-semibold lg:text-lg md:text-sm text-[#666666] lg:mt-6 md:mt-4 mt-3">
                    Create your account
                  </div>
                  <div className=" w-[19rem] lg:w-[25rem] lg:h-[3.50rem] md:w-[19rem] md:h-[2.50rem] top-[24.20rem] left-[62.65rem] font-poppins font-medium text-[#505050] lg:text-base md:text-xs text-base">
                    Let&apos;s find out the opportunity to earn every time you fill out a survey
                    online!
                  </div>
                  <div className="w-[10rem] h-[2rem] lg:w-[15.20rem] md:w-[10rem] lg:h-[3.35rem] md:h-[2.50rem] top-[17.70rem] left-[62.69rem] font-poppins font-semibold lg:text-lg md:text-sm  text-[#666666] lg:mt-8 md:mt-4 mt-4">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                      Gender
                    </span>
                  </div>
                  <div className="flex lg:gap-9 md:gap-5 gap-6  text-[#777777] font-poppins font-medium lg:ml-4 lg:text-base md:text-xs  ">
                    <label htmlFor="Male">Male</label>
                    <input
                      type="radio"
                      id="gender"
                      name="gender"
                      value="Male"
                      onChange={onInputChange}
                      checked={formValue.gender === "Male"}
                      className="lg:mr-2 lg:mt-1 mt-1 h-4 w-4  lg:h-4 lg:w-4  md:mr-3 md:mt-1  md:h-3 md:w-3  text-[#66A7C7]  gender-radio"
                    />

                    <label htmlFor="Female">Female</label>
                    <input
                      type="radio"
                      id="gender"
                      name="gender"
                      value="Female"
                      onChange={onInputChange}
                      checked={formValue.gender === "Female"}
                      className="lg:mr-2 lg:mt-1 mt-1 h-4 w-4 lg:h-4 lg:w-4  md:mr-3 md:mt-1  md:h-3 md:w-3  gender-radio"
                    />

                    <label htmlFor="Other">Other</label>
                    <input
                      type="radio"
                      id="gender"
                      name="gender"
                      value="other"
                      onChange={onInputChange}
                      checked={formValue.gender === "other"}
                      className="lg:mr-2 lg:mt-1 mt-1 h-4 w-4  lg:h-4 lg:w-4  md:mr-3 md:mt-1  md:h-3 md:w-3  gender-radio"
                    />
                  </div>
                  {formValue.errors.gender ? (
                    <p className="text-red-700" id="">
                      {formValue.errors.gender}
                    </p>
                  ) : null}
                  <div className="border-b lg:w-[26.50rem]  md:w-72 border-gray-100 "></div>

                  <div className="w-[10rem] h-[2rem] lg:w-[15.20rem] md:w-[10rem] lg:h-[3.35rem] md:h-[2.50rem] top-[17.70rem] left-[62.69rem] font-poppins font-semibold lg:text-lg md:text-sm text-[#666666] lg:mt-4 md:mt-2 mt-4">
                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                      {" "}
                      Date of Birth
                    </label>
                  </div>
                  <div className="flex ">
                    <div className=" flex font-poppins font-medium text-[#777575] lg:gap-5 md:gap-5 gap-9">
                      <div className="h-[2.50rem] w-[5rem] rounded-md lg:h-[3rem] lg:w-[8rem] md:h-[2rem] md:w-[5rem] text-sm lg:text-base md:text-sm flex items-center justify-center border-2 border-[#BBBBBB]  lg:rounded-xl md:rounded-md">
                        <select
                          value={selectedDay}
                          onChange={onInputChange}
                          className="h-[2.30rem] w-[5rem] lg:h-[2.80rem] lg:w-[8rem] md:h-[1.80rem] md:w-[5rem] lg:rounded-xl md:rounded-md rounded-md lg:px-8"
                          name="selectedDay"
                        >
                          <option>Day</option>
                          {days.map((day) => (
                            <option key={day} value={day.toString()}>
                              {day}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="h-[2.50rem] w-[5rem] rounded-md lg:h-[3rem] lg:w-[8rem] md:h-[2rem] md:w-[5rem] text-sm lg:text-base md:text-sm flex items-center justify-center border-2 border-[#BBBBBB] lg:rounded-xl md:rounded-md">
                        <select
                          value={selectedMonth}
                          name="selectedMonth"
                          onChange={onInputChange}
                          className="h-[2.30rem] w-[5rem] lg:h-[2.80rem] lg:w-[8rem] md:h-[1.80rem] md:w-[5rem] lg:rounded-xl md:rounded-md rounded-md lg:px-6"
                        >
                          <option value="">Month</option>
                          {months.map((month) => (
                            <option key={month} value={month.toString()}>
                              {month}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="h-[2.50rem] w-[5rem] rounded-md lg:h-[3rem] lg:w-[8rem] md:h-[2rem] md:w-[5rem] text-sm lg:text-base md:text-sm border-2 flex items-center justify-center border-[#BBBBBB] lg:rounded-xl md:rounded-md">
                        <select
                          value={selectedYear}
                          onChange={onInputChange}
                          className="h-[2.30rem] w-[5rem] lg:h-[2.80rem] lg:w-[8rem] md:h-[1.80rem] md:w-[5rem] lg:rounded-xl md:rounded-md rounded-md lg:px-6"
                          name="selectedYear"
                        >
                          <option value="">Year</option>
                          {years.map((year) => (
                            <option key={year} value={year.toString()}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {formValue.errors.dob ? (
                    <p className="text-red-700" id="">
                      {formValue.errors.dob}
                    </p>
                  ) : null}

                  <button
                    className="w-20 h-8 text-[0.70rem] lg:h-[3rem] lg:w-[8rem] md:h-[2.40rem] md:w-[6rem] bg-[#056E9C] lg:rounded-xl md:rounded-md rounded-md text-[#F1F6FA] lg:text-lg md:text-sm mt-5 lg:mt-9 md:mt-7 ml-[14.50rem] lg:ml-[18.5rem] md:ml-[12rem]"
                    onClick={(e) => {
                      e.preventDefault();
                      const dobValid = genderValid(formValue);
                      if (
                        dobValid &&
                        formValue.errors.gender === "" &&
                        formValue.errors.dob === "" &&
                        formValue.gender !== "" &&
                        formValue.dateOfBirth !== ""
                      ) {
                        handleChangeModalDisplay(4);
                      }
                    }}
                  >
                    Next
                  </button>
                  <div
                    className="flex font-poppins font-normal text-[#056E9C] lg:mt-16  md:mt-20 mt-32 cursor-pointer"
                    onClick={() => handleChangeModalDisplay(2)}
                  >
                    <img src={arrow} alt="" />
                    <span>Previous</span>
                  </div>
                </div>
              ) : null}
              {pageDisplayIndex === 5 ? (
                <div className="flex flex-col">
                  <div className="flex lg:w-[30rem] md:gap-5 gap-2 text-[#928484] lg:text-sm md:text-[0.55rem] items-center justify-center">
                    <Progress
                      percent={80}
                      showInfo={false}
                      className=""
                      strokeColor="#49A800"
                      trailColor="#BCD2AB24"
                    />
                    <span className="lg:w-16 lg:h-8 md:h-5 md:w-12 w-20 md:right-auto lg:right-auto left-0 top-auto md:pr-4">
                      4 of 5
                    </span>
                  </div>
                  <div className="lg:w-[15.20rem] md:w-[15rem] lg:h-[3.35rem] md:h-[3.20rem] h-[2rem] top-[17.70rem] left-[62.69rem] font-poppins font-semibold lg:text-lg md:text-sm text-[#666666] lg:mt-6 md:mt-4 mt-3">
                    Create your account
                  </div>
                  <div className=" w-[19rem] lg:w-[25rem] lg:h-[3.50rem] md:w-[19rem] md:h-[2.50rem] top-[24.20rem] left-[62.65rem] font-poppins font-medium text-[#505050] lg:text-base md:text-xs text-base">
                    Let&apos;s find out the opportunity to earn every time you fill out a survey
                    online!
                  </div>
                  <div className="font-poppins font-normal lg:text-sm md:text-[0.70rem] text-[#777575] flex flex-col lg:gap-6 md:gap-1 gap-2 lg:mt-8 md:mt-4 mt-4">
                    <div className="flex flex-col  lg:gap-2 md:gap-1 gap-1">
                      <label htmlFor="">Mailing Street Address</label>
                      <input
                        type="text"
                        name="mailingAddress"
                        id="mailingAddress"
                        onChange={onInputChange}
                        placeholder="Mailing"
                        className=" w-[20rem] h-[2.50rem] lg:w-[24rem] lg:h-[3rem] md:w-[18rem] md:h-[2rem] border-2 border-[#A1A1A1] font-poppins font-medium text-[#777575] rounded-md lg:rounded-xl md:rounded-md lg:py-6 lg:px-6 md:py-4 md:px-4 px-6 py-4"
                      />
                    </div>

                    <div className="flex flex-col lg:gap-2 md:gap-1 gap-1">
                      <label htmlFor="state">State</label>
                      <div className=" flex items-center justify-center font-poppins font-medium text-[#777575] w-[20rem] h-[2.50rem]  lg:w-[24rem] lg:h-[3rem] md:w-[18rem] md:h-[2rem] border-2 border-[#A1A1A1] rounded-md lg:rounded-xl md:rounded-md">
                        <select
                          id="state"
                          className="lg:w-[21rem] lg:h-[2rem] md:w-[16rem] md:h-[1.60rem] w-[17rem] h-[1.60]"
                        >
                          <option value="">State</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex   lg:gap-4 md:gap-5 gap-5">
                      <div className="flex flex-col lg:gap-2 md:gap-1 gap-1">
                        <label htmlFor="">City</label>
                        <div className=" flex items-center justify-center w-[9.36rem] h-[2.50rem] lg:w-[11.50rem] lg:h-[3rem] md:w-[8.35rem] md:h-[2rem] border-2 border-[#A1A1A1] font-poppins font-medium text-[#777575] rounded-md lg:rounded-xl md:rounded-md lg:py-6 lg:px-6 md:py-4 md:px-4 py-4 px-4 ">
                          <select
                            id="state"
                            className="lg:w-[10rem] lg:h-[2rem] md:w-[7.70rem] md:h-[1.50rem]"
                          >
                            <option value="">Select</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex flex-col lg:gap-2 md:gap-1 gap-1">
                        <label htmlFor="">Zip Code</label>
                        <input
                          type="text"
                          placeholder="Zip Code"
                          className="   lg:w-[11.50rem] lg:h-[3rem] w-[9.36rem] h-[2.50rem] md:w-[8.35rem] md:h-[2rem] border-2 border-[#A1A1A1] font-poppins font-medium text-[#777575] rounded-md lg:rounded-xl md:rounded-md lg:py-6 lg:px-6 md:py-4 md:px-4 py-4 px-4"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="w-20 h-8 text-[0.70rem] lg:h-[3rem] lg:w-[8rem] md:h-[2.40rem] md:w-[6rem] bg-[#056E9C] lg:rounded-xl md:rounded-md rounded-md text-[#F1F6FA] lg:text-lg md:text-sm  lg:mt-9 md:mt-7 mt-6 ml-[15rem] lg:ml-[16rem] md:ml-[12rem]"
                    onClick={() => handleChangeModalDisplay(5)}
                  >
                    Next
                  </button>
                  <div
                    className="flex font-poppins font-normal text-[#056E9C]  lg:mt-2  md:mt-12 mt-10 cursor-pointer"
                    onClick={() => handleChangeModalDisplay(3)}
                  >
                    <img src={arrow} alt="" />
                    <span>Previous</span>
                  </div>
                </div>
              ) : null}
              {pageDisplayIndex === 6 ? (
                <div className="flex flex-col ">
                  <div className="flex lg:w-[30rem] md:gap-5 gap-2 text-[#928484] lg:text-sm md:text-[0.55rem] items-center justify-center">
                    <Progress
                      percent={100}
                      showInfo={false}
                      className=""
                      strokeColor="#49A800"
                      trailColor="#BCD2AB24"
                    />
                    <span className="lg:w-16 lg:h-8 md:h-5 md:w-12 w-20 md:right-auto lg:right-auto left-0 top-auto md:pr-4">
                      4 of 4
                    </span>
                  </div>
                  <div className="lg:w-[15.20rem] md:w-[15rem] lg:h-[3.35rem] md:h-[3.20rem] h-[2rem] top-[17.70rem] left-[62.69rem] font-poppins font-semibold lg:text-lg md:text-sm text-[#666666] lg:mt-6 md:mt-4 mt-3">
                    Create your account
                  </div>
                  <div className=" w-[19rem] lg:w-[25rem] lg:h-[3.50rem] md:w-[19rem] md:h-[2.50rem] top-[24.20rem] left-[62.65rem] font-poppins font-medium text-[#505050] lg:text-base md:text-xs text-base">
                    Let&apos;s find out the opportunity to earn every time you fill out a survey
                    online!
                  </div>
                  <div className="mt-8 ">
                    <span className="font-poppins   font-medium lg:text-sm md:text-xs text-xs text-[#777575]">
                      Referral I&apos;d (Optional){" "}
                    </span>
                    <div className="flex  lg:gap-8 md:gap-8 gap-8 lg:mt-4 md:mt-2">
                      <div className=" flex items-center justify-center font-poppins  font-medium lg:gap-4 md:gap-2 gap-2 lg:text-base md:text-sm text-[#777575] ">
                        <input
                          type="checkbox"
                          checked={yesSelected || formValue.referalId !== ""}
                          onChange={handleYesChange}
                          className="lg:h-5 lg:w-4 checked:bg-[#056E9C]"
                        />
                        <span>Yes</span>
                      </div>
                      <div className=" flex items-center justify-center lg:gap-4 md:gap-2 gap-2  font-poppins font-medium lg:text-base md:text-sm  text-[#777575]">
                        <input
                          type="checkbox"
                          checked={noSelected}
                          onChange={handleNoChange}
                          className="lg:h-5 lg:w-4  checked:bg-[#056E9C]"
                        />
                        <span>No</span>
                      </div>
                    </div>
                    <div className="lg:mt-8 md:mt-4 mt-4">
                      {yesSelected || formValue.referalId !== "" ? (
                        <input
                          type="text"
                          name="referalId"
                          id="referalId"
                          value={formValue.referalId}
                          onChange={onInputChange}
                          placeholder="Enter Referral ID"
                          className="w-[19rem] lg:w-[24rem] lg:h-[3rem] md:w-[18rem] h-[2.50rem] md:h-[1.85rem] border-2 border-[#A1A1A1] font-poppins font-medium lg:text-sm md:text-xs text-xs text-[#777575] lg:rounded-xl md:rounded-md rounded-md lg:py-6 lg:px-6 md:py-2 md:px-2 py-2 px-2"
                        />
                      ) : null}
                      <div className="flex items-center  font-poppins font-normal text-[#767575]  lg:text-xs md:text-[0.50rem] text-[0.50rem] lg:gap-4 md:gap-2 gap-2  lg:mt-6 md:mt-4 mt-4 ">
                        <input
                          id="privacy-policy"
                          name="privacy-policy"
                          type="checkbox"
                          checked={con2}
                          onChange={onInputChange}
                          className="lg:h-5 lg:w-4  checked:bg-[#056E9C]"
                        />
                        <div className="flex gap-1">
                          <span>I agree to the Zamplia Surveys </span>
                          <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                            <span className="border-b lg:w-16 md:w-40 border-[#767575] cursor-pointer  text-blue-500">
                              terms of service
                            </span>
                          </a>
                          <span>and</span>
                          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            <span className="border-b lg:w-20 md:w-40 border-[#767575] cursor-pointer  text-blue-500">
                              privacy policy
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    {formValue.errors.con2 ? (
                      <p className="text-red-700" id="">
                        {formValue.errors.con2}
                      </p>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    className="w-20 h-8 text-[0.70rem]
 lg:h-[3rem] lg:w-[8rem] md:h-[2.40rem] md:w-[6rem] bg-[#056E9C] lg:rounded-xl md:rounded-md rounded-md text-[#F1F6FA] lg:text-lg md:text-sm  lg:mt-9 md:mt-7 mt-7 lg:ml-[18.7rem] md:ml-[12rem] ml-[14rem]"
                    onClick={(e) => {
                      e.preventDefault();
                      validation(formValue);
                      setLoginPassword(formValue.password);
                      setloginEmail(formValue.email);

                      if (con2) {
                        handleSubmit();
                      }
                    }}
                  >
                    Submit
                  </button>
                  <div
                    className="flex font-poppins font-normal text-[#056E9C]  lg:mt-20  md:mt-24 mt-28 cursor-pointer"
                    onClick={() => handleChangeModalDisplay(3)}
                  >
                    <img src={arrow} alt="" />
                    <span>Previous</span>
                  </div>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

import { useTranslation } from "react-i18next";
import survey from "../../assets/wpf_survey.svg";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";

export const Step = () => {
  const { t } = useTranslation();
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          videoRef.current?.play();
        } else {
          videoRef.current?.pause();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="w-full py-10 px-8 lg:px-32 h-auto font-poppins bg-[#F4F8FB] flex flex-col items-center justify-start gap-3">
      <h2 className="text-slate-400 text-[24px] md:text-[3xl] lg:text-5xl font-bold text-gradient text-center">
        {t("home.Steps.howIt.works")}
      </h2>
      <div className="flex justify-center items-center md:w-[768px]">
        <p className="text-[16px] md:mr-[50px] text-base text-center text-neutral-600 md:text-[22px] md:text-xl font-normal  md:leading-9">
          {t("home.Steps.Opportunity")}
        </p>
      </div>
      <div className="flex flex-col items-start justify-between gap-4 mt-10 lg:flex-row ">
        <div className="flex flex-col gap-3 lg:gap-6">
          <div className="sm:h-[11.625rem] w-[336px] h-[15rem] md:w-[670px] md:h-[410px] rounded-[28px] border-8 border-sky-700 border-opacity-20 overflow-hidden">
            <video ref={videoRef} loop autoPlay controls className="object-cover w-full h-full">
              <source
                src="https://storageaccountzampl852f.blob.core.windows.net/zampliasurvey/Untitled%20design.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="lg:w-[682px] h-[47px] bg-sky-700 bg-opacity-5 rounded-[90px] border border-slate-200 border-opacity-40 flex items-center justify-center lg:justify-start gap-2 px-2 py-1 ">
            <div className="flex items-center justify-center flex-none rounded-full w-7 h-7 lg:w-9 lg:h-9 bg-sky-700 bg-opacity-10">
              <img src={survey} alt="" />
            </div>
            <p className=" lg:text-left text-sky-700 text-[12px] md:text-[15px] font-normal ">
              {t("home.Steps.Average.participent")}
            </p>
          </div>
        </div>
        <div className="flex-col hidden gap-1 lg:flex">
          <div className=" text-sky-700 text-[18px] md:text-[26px] font-medium  leading-9">
            {t("home.Steps.3steps")}{" "}
          </div>
          <div className="w-[123px] h-[0px] border border-sky-700 mb-6"></div>
          <div className="w-[24rem] lg:h-[auto] bg-slate-100 rounded-[13px] border border-l-[#3C97C4] border-l-[16px] mb-4 p-1 relative">
            <h2 className=" text-sky-700 text-[20px] font-semibold  leading-9 ml-1">
              {t("home.step.step1")}
            </h2>
            <p className="text-neutral-600 text-base font-light  leading-relaxed ml-1">
              {t("home.step.joinnow")}
            </p>
          </div>
          <div className="w-[24rem] h-[auto] bg-slate-100 rounded-[13px] border border-l-[#3C97C4] border-l-[16px] mb-4 p-1 relative">
            <h2 className=" text-sky-700 text-[20px] font-semibold  leading-9 ml-1">
              {t("home.step.step2")}
            </h2>
            <p className="text-neutral-600 text-base font-light  leading-relaxed ml-1">
              {t("home.step.confirm")}
            </p>
          </div>
          <div className="w-[24rem] h-[auto] bg-slate-100 rounded-[13px] border border-l-[#3C97C4] border-l-[16px] mb-4 p-1 relative">
            <h2 className=" text-sky-700 text-[20px] font-semibold  leading-9 ml-1">
              {t("home.step.step3")}
            </h2>
            <p className="text-neutral-600 text-base font-light  leading-relaxed ml-1">
              {t("home.step.complete")}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full gap-2 mt-4 lg:hidden">
          <div className=" text-sky-700 text-[18px] md:text-[26px] font-semibold  leading-[36px]">
            {t("home.step.steps")}{" "}
          </div>
          <div className="bg-white px-4 py-8 w-full rounded-[28px]">
            <div className="flex items-start justify-start mx-2 my-2">
              <div className="bg-sky-700 rounded-full w-[28px] h-[28px] flex flex-none items-center justify-center font-['poppins] mr-4">
                <span className=" text-white font-poppins text-[16px]">1.</span>
              </div>

              <p className="font-poppins text-left text-[16px] text-[#505050]">
                {t("home.step.joinnow")}
                {""}
              </p>
            </div>
            <div className="flex items-start justify-start mx-2 my-4">
              <div className="bg-sky-700 rounded-full w-[28px] h-[28px] flex flex-none items-center justify-center font-['poppins] mr-4">
                <span className=" text-white font-poppins text-[16px]">2.</span>
              </div>

              <p className="font-poppins text-left text-[16px] text-[#505050]">
                {t("home.step.confirm")}
              </p>
            </div>
            <div className="flex items-start justify-start mx-2 my-2">
              <div className="bg-sky-700 rounded-full w-[28px] h-[28px] flex flex-none items-center justify-center font-['poppins] mr-4">
                <span className=" text-white  text-[16px]">3.</span>
              </div>
              <p className=" text-left text-[16px] text-[#505050]">{t("home.step.complete")}</p>
            </div>
          </div>
          <Link
            data-testid="button"
            to="/join-now"
            className="flex items-center justify-center w-full text-xl btn "
          >
            {t("btn.join.now")}
          </Link>
        </div>
      </div>
    </div>
  );
};

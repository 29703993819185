import React, { useState, useEffect } from "react";
import ReviewCard from "components/ReviewCard/ReviewCard";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import profile1 from "../../assets/ReviewsCard/Ellipse 7.svg";
import profile2 from "../../assets/ReviewsCard/Ellipse 8.svg";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t } = useTranslation();

  const carouselData = [
    {
      text1: t("home.reviewcard.1.a"),
      text2: t("home.reviewcard.1.b"),
      profile: profile1,
      rating: 4.5
    },
    {
      text1: t("home.reviewcard.2.a"),
      text2: t("home.reviewcard.2.b"),
      profile: profile2,
      rating: 4.3
    }
  ];
  const [currentSlider, setCurrentSlider] = useState(0);

  const prevSlide = () => {
    setCurrentSlider((prev) => (prev === 0 ? carouselData.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlider((prev) => (prev === carouselData.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); 

    return () => clearInterval(interval);
  }, [currentSlider]); 
  return (
    <div className="flex items-center gap-1 lg:gap-4 ">
      <FaChevronLeft
        onClick={prevSlide}
        style={{ color: "grey", flex: "0 0 auto", cursor: "pointer" }}
        size={18}
      />
      <div className="hidden lg:flex gap-2">
        {[carouselData[currentSlider], carouselData[(currentSlider + 1) % carouselData.length]].map(
          (data, index) => (
            <ReviewCard key={index} {...data} />
          )
        )}
      </div>
      <div className="flex gap-1 lg:hidden">
        <ReviewCard {...carouselData[currentSlider]} />
      </div>
      <div className=" w-[50vw]">
        <FaChevronRight
          onClick={nextSlide}
          style={{ color: "grey", flex: "0 0 auto", cursor: "pointer" }}
          size={18}
        />
      </div>
    </div>
  );
};

export default Carousel;

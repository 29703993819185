// import facebook from "../../assets/JoinNow/facebook.svg";
import { toastrSuccess, toastrError } from "./../../lib/toastr";
import LoadingOverlay from "react-loading-overlay-ts";
import { loginUser } from "../LoginForm/LoginForm.api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { AxiosError } from "axios";
import { AuthData } from "context/auth-context";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { intialState } from "./JoinNowForm.interface";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { getData } from "./getdata";
// import { LoginSocialFacebook } from "reactjs-social-login";
import { useAuth } from "context/auth-context";
import { Modal } from "antd";
import { registerUser, saveUserDetail, verifyUser } from "./JoinNowForm.api";

export const JoinNowWithGoogle = () => {
  const [Email, setEmail]: any = useState("");
  const [setVerficationName]: any = useState("");
  const [formValue, setFormValue] = useState(intialState);
  const [firstname, setFirstname] = useState("");
  const [lastName, setlastName] = useState("");
  const [sucessResponce, setSucessResponce] = useState();
  const { setAuth } = useAuth();
  const { info } = Modal;
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [ipAddress, setIpAddressName] = useState();
  const location = useLocation();
  const handleSuccess = (credentialResponse: any) => {
    if (credentialResponse) {
      setSucessResponce(credentialResponse);
      let userEmail: any;
      if (credentialResponse && credentialResponse?.credential) {
        userEmail = jwtDecode(credentialResponse?.credential);
        console.log(userEmail);
        if (userEmail) {
          setlastName(userEmail?.family_name);
          setEmail(userEmail?.email);
          setFirstname(userEmail?.given_name);
        }
        userEmail = userEmail?.email;

        userEmail = userEmail?.family_name;
      } else if (credentialResponse && credentialResponse?.email) {
        userEmail = credentialResponse?.email;
      }

      if (userEmail && credentialResponse?.credential) {
        let verificationGoogle: any;
        verificationGoogle = jwtDecode(credentialResponse?.credential);
        verificationGoogle = verificationGoogle?.email_verified;
        verificationGoogle ? setVerficationName("Google") : "";
      }
    } else {
      toastrError("Invalid credentialResponse object");
    }
  };

  const virtualWalletConfirmation: any = (location: any) => {
    info({
      title: "Alert",
      icon: <ExclamationCircleFilled />,
      content: "get errr with that",
      onOk() {
        navigate(location.state || "/dashboard", { state: null });
      },
    });
  };

  const initialState = {
    email: "",
    password: "",
    errors: {
      email: "",
      password: "",
    },
  };

  type formValue = typeof initialState;
  type LoginPayload = Omit<formValue, "errors">;
  const handleError = () => {
    toastrError("Something went wrong!!");
  };

  const authMutation = useMutation(
    (payload: LoginPayload) => {
      return loginUser(payload.email, payload.password);
    },
    {
      onSuccess: (response: AuthData) => {
        if (response.isFlagVirtualWallet) {
          toastrSuccess("login sucesssfuly");
          virtualWalletConfirmation(location);
          setAuth(response);
          navigate("/dashboard");
        } else {
          toastrSuccess("login sucesssfuly");
          setAuth(response);
          navigate(location.state || "/dashboard", { state: null });
        }
      },
      onError: (err: AxiosError) => {
        toastrError(err);
      },
    }
  );

  // login user function
  function Loginuser() {
    authMutation.mutate({ email: Email, password: password });
  }
  // varify user
  const verifyUserMutation = useMutation((cred: { email: string }) => verifyUser(cred), {});
  // save user details
  const saveuserdetails = useMutation(() =>
    saveUserDetail(formValue.currentUrl, formValue.country, ipAddress)
  );

  // register user
  const resisteruser = useMutation(
    () =>
      registerUser({
        password: password,
        firstName: firstname,
        lastName: lastName,
        email: Email,
        dateOfBirth: "",
        gender: "",
        active: formValue.active,
        language: formValue.language,
        timeZone: formValue.timeZone,
        currentUrl: formValue.currentUrl,
        sid: "",
        country: formValue.country,
        state: formValue.state,
        geo: "",
        clickid: "",
        referalId: "",
        socialID: "",
      }),
    {
      onSuccess: (data) => {
        if (data.success) {
          toastrSuccess("sucesssfully signup");
          Loginuser();
        } else {
          toastrError("you got err  ");
        }
      },
      onError: () => {
        toastrError("you are alredy signup please login");
      },
    }
  );

  // loginwith google
  useEffect(() => {
    getData(setIpAddressName, setPassword, setFormValue, formValue, password, Email, firstname);
    if (sucessResponce) {
      handleSuccess(sucessResponce);

      verifyUserMutation.mutate({ email: Email });
      resisteruser.mutate();
      saveuserdetails.mutate();
    }
  }, [sucessResponce]);

  return (
    <>
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={authMutation.isLoading}
        spinner
        text="Loading..."
      ></LoadingOverlay>
      <div className=" w-[50%]">
        <GoogleLogin
          onSuccess={(credential) => {
            handleSuccess(credential);
          }}
          onError={() => {
            handleError();
          }}
          size="medium"
          text="signup_with"
        ></GoogleLogin>
      </div>
    </>
  );
};

import girl from "../../assets/UnsubscribeIcon/girl.svg";
import cup from "../../assets/UnsubscribeIcon/cup.svg";
import facebook from "../../assets/UnsubscribeIcon/facebook.svg";
import game from "../../assets/UnsubscribeIcon/game.svg";
import instagram from "../../assets/UnsubscribeIcon/instagram.svg";
import linkedin from "../../assets/UnsubscribeIcon/linkedin.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { unsubscribeUser } from "./UnsubscribeForm.api";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import { Link } from "react-router-dom";
import { useSubscribe } from "context/Subscribe-context";
import { useTranslation } from "react-i18next";

export const UnsubscribeForm = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { setisSubscribe } = useSubscribe();

  const mutation = useMutation(() => unsubscribeUser(auth.email), {
    onSuccess() {
      toastrSuccess(t("Unsubscribe.toastsuccess"));
    },
    onError() {
      toastrError(t("Unsubscribe.toasterror"));
    },
  });

  const handleClick = async (e) => {
    e.preventDefault();
    setisSubscribe(0);
    mutation.mutate();
  };

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={mutation.isLoading}
      spinner
      text={t("page.Loading")}
    >
      <div className="lg:w-[98vw] flex flex-col items-center justify-center pb-20">
        <div className=" lg:w-[40%] md:w-[70%] w-screen md:fixed md:top-[6rem] md:left-[7rem] lg:ml-[14rem] lg:sticky mt-24 rounded-3xl shadow-sm">
          <div className="font-poppin">
            {/* Top Part */}
            <div className="md:flex-1 text-center bg-[#056E9C24] pt-5 pb-6 rounded-t-3xl">
              <div className="flex justify-center items-center mb-4 text-center">
                <img src={girl} alt=""></img>
              </div>
              <div className="text-[18px] font-semibold mt-2 mb-4 font-poppins">
                <p>
                  {t("Unsubscribe.description")} <br />
                  {t("Unsubscribe.descriptionline2")}
                </p>
              </div>
            </div>
            {/* Bottom Part */}
            <div className=" bg-[#FFFFFF] rounded-b-3xl pb-10">
              <div className="flex-1 text-center sm:pb-7 pt-6">
                <div>
                  <h2 className=" font-semibold text-[22px] font-poppins">
                    {t("Unsubscribe.heading")}
                  </h2>
                </div>
                <div className=" font-poppins text-[18px] mt-1">
                  <p className=""> {t("Unsubscribe.paragraph.line1")}</p>
                  <p>a{t("Unsubscribe.paragraph.line2")}</p>
                </div>
                <div className=" flex justify-center items-center mt-8 mb-7">
                  <a href="https://www.facebook.com/zampliasurveys">
                    <img src={facebook} alt="facebook"></img>
                  </a>
                  <a>
                    <img className="ml-2" src={instagram} alt="instagram"></img>
                  </a>
                  <a>
                    <img className="ml-2" src={game} alt="game"></img>
                  </a>
                  <a href="https://www.reddit.com/r/zampliasurveys/">
                    <img className="ml-2" src={cup} alt="redit"></img>
                  </a>
                  <a href="https://www.linkedin.com/company/zamplia/">
                    <img className="ml-2" src={linkedin} alt="linkedin"></img>
                  </a>
                </div>
                <div className="text-center">
                  <Link to={"/Dashboard"}>
                    <button className=" font-poppins text-[18px] text-white w-[150px] rounded-3xl p-3 bg-[#056E9C]">
                      {t("Unsubscribe.btntext1")}
                    </button>
                  </Link>

                  <button
                    className=" font-poppins text-[18px] text-wrap rounded-3xl p-3 ml-4 mt-2 bg-[#EAEAEA]"
                    onClick={handleClick}
                  >
                    {t("Unsubscribe.btntext2")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

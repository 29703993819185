type IntialState = {
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  gender: string;
  referalId: string;
  active: boolean;
  language: string;
  timeZone: string;
  currentUrl: string;
  sid: string;
  clickid: string;
  geo: string;
  socialID: string;
  country: string;
  state: string;
  confirmPassword: string;
  errors: {
    fname?: string;
    lname?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    gender?: string;
    dob?: string;
    referral?: string;
    con1?: string;
    con2?: string;
  };
};

export const intialState: IntialState = {
  password: "",
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  gender: "",
  referalId: "",
  active: true,
  language: "",
  timeZone: "",
  currentUrl: "",
  sid: "",
  clickid: "",
  geo: "",
  socialID: "",
  country: "",
  state: "",
  confirmPassword: "",
  errors: {
    fname: "",
    lname: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
    dob: "",
    referral: "",
    con1: "",
    con2: "",
  },
};

import React, { useState } from "react";
import { updateGuestProfileApi } from "./../JoinNowForm/JoinNowForm.api";
import axios from "axios";
import { Modal } from "antd";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toastrSuccess, toastrError } from "./../../lib/toastr";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { IP_REGISTRY_KEY } from "../../config/constants";

const intialState = {
  uid: "",
  password: "",
  firstName: "",
  lastName: "",
  email: "",
  language: "",
  timeZone: "",
  currentUrl: "",
  country: "",
  state: "",
  errors: {
    fname: "",
    lname: "",
    email: "",
    password: "",
    con1: "",
    con2: "",
  },
};

type formVals = typeof intialState;
type JoinNowPayload = Omit<formVals, "errors">;

export const GuestUserJoinNowModal = (props: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const toggle = () => {
    setOpen(!open);
  };
  const [formValue, setFormValue] = useState(intialState);
  const [con1, setCon1] = useState(false);
  const [con2, setCon2] = useState(false);

  const getData = async () => {
     const res = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_KEY}`);
    if (res) {
      setFormValue({
        ...formValue,
        language: res.data.location.language.name,
        timeZone: res.data.time_zone.id,
        country: res.data.location.country.name,
        state: res.data.location.region.name,
        currentUrl: window.location.href,
        uid: props.uid,
      });
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const updateGuestUserProfile = useMutation(
    (payload: JoinNowPayload) => {
      return updateGuestProfileApi({
        password: payload.password,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        language: payload.language,
        timeZone: payload.timeZone,
        currentUrl: payload.currentUrl,
        country: payload.country,
        state: payload.state,
        uid: payload.uid,
      });
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setFormValue(intialState);
          setCon1(false);
          setCon2(false);
          toastrSuccess(response.message);
          navigate("/login");
        } else {
          toastrError(response.message);
        }
      },
      onError: (err: any) => {
        const repsonse = err.response;
        if (repsonse.data && repsonse.data.message) {
          toastrError(repsonse.data.message);
        } else {
          toastrError(t("form.join.password"));
        }
      },
    }
  );

  const validation = (formValues: formVals) => {
    setFormValue((old) => {
      return {
        ...old,
        errors: {
          fname: "",
          lname: "",
          email: "",
          password: "",
          con1: "",
          con2: "",
        },
      };
    });
    const valid = true;

    // email validation patterns
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // email validation
    if (!(formValues.email && regexEmail.test(formValues.email))) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, email: t("form.join.address") } };
      });
      return false;
    }

    // first name validation
    if (formValues.firstName.length < 1) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, fname: t("form.join.First") } };
      });
      return false;
    }

    // last name validation
    if (formValues.lastName.length < 1) {
      setFormValue((old) => {
        return { ...old, errors: { ...old.errors, lname: t("form.join.required") } };
      });
      return false;
    }

    // password validation
    if (!formValues.password && formValues.password.length < 6) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, password: t("form.join.passwordS") },
        };
      });
      return false;
    }

    if (!con1) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, con1: t("form.join.conditions") },
        };
      });
      return false;
    }

    if (!con2) {
      setFormValue((old) => {
        return {
          ...old,
          errors: { ...old.errors, con2: t("form.join.accepts") },
        };
      });
      return false;
    }
    return valid;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const valid = validation(formValue);

    if (valid == false) {
      return;
    }
    updateGuestUserProfile.mutate({
      email: formValue.email,
      password: formValue.password,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      language: formValue.language,
      timeZone: formValue.timeZone,
      currentUrl: formValue.currentUrl,
      country: formValue.country,
      state: formValue.state,
      uid: formValue.uid,
    });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <Modal
      title="Fill Your Details"
      open={props.show}
      footer={false}
      onCancel={props.hideGuestUserPopup}
      className="guest-join-popup"
      maskClosable={false}
    >
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={updateGuestUserProfile.isLoading}
        spinner
        text="Loading..."
      >
        <form className="max-w-xl mx-auto" onSubmit={handleSubmit}>
          <div className="overflow-hidden">
            <div className="px-4 py-5 bg-white sm:p-3">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <label
                    data-testid="fname"
                    htmlFor="first-name"
                    aria-label="first-name"
                    className="inline-block after:content-['*'] after:ml-0.5 after:text-red-500 text-sm font-medium text-gray-700"
                  >
                    {t("form.join.FirstName")}
                  </label>
                  <input
                    pattern="[a-z,A-Z]{1,15}"
                    title="Username should only contain uppercase lowercase letters. e.g. john"
                    type="text"
                    name="firstName"
                    value={formValue.firstName}
                    onChange={onInputChange}
                    id="first-name"
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  {formValue.errors.fname ? (
                    <p className="text-red-700" id="">
                      {formValue.errors.fname}
                    </p>
                  ) : null}
                </div>

                <div className="col-span-6">
                  <label
                    className="inline-block after:content-['*'] after:ml-0.5 after:text-red-500 text-sm font-medium text-gray-700"
                    htmlFor="last-name"
                    aria-label="last-name"
                  >
                    {t("form.join.LastName")}
                  </label>
                  <input
                    pattern="[a-z,A-Z]{1,15}"
                    title="Username should only contain uppercase lowercase letters. e.g. john"
                    type="text"
                    name="lastName"
                    value={formValue.lastName}
                    onChange={onInputChange}
                    id="last-name"
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  {formValue.errors.lname ? (
                    <p className="text-red-700" id="">
                      {formValue.errors.lname}
                    </p>
                  ) : null}
                </div>

                <div className="col-span-6">
                  <label
                    className="inline-block after:content-['*'] after:ml-0.5 after:text-red-500 text-sm font-medium text-gray-700"
                    htmlFor="user-email"
                    aria-label="user-email"
                  >
                    {t("form.join.UserEmail")}
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="user-email"
                    value={formValue.email}
                    onChange={onInputChange}
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  {formValue.errors.email ? (
                    <p className="text-red-700" id="emai_err_msg">
                      {formValue.errors.email}
                    </p>
                  ) : null}
                </div>

                <div className="col-span-6">
                  <label
                    className="inline-block after:content-['*'] after:ml-0.5 after:text-red-500 text-sm font-medium text-gray-700"
                    htmlFor="password"
                    aria-label="password"
                  >
                    {t("form.join.Password")}
                  </label>
                  <div className="text-xl relative  text-[#10739f]">
                    {open === false ? (
                      <AiFillEyeInvisible
                        className="absolute block mt-3 text-center right-5"
                        onClick={toggle}
                      />
                    ) : (
                      <AiFillEye
                        className="absolute block mt-3 text-center right-5"
                        onClick={toggle}
                      />
                    )}
                  </div>
                  <input
                    type={open === true ? "text" : "password"}
                    name="password"
                    id="password"
                    value={formValue.password}
                    onChange={onInputChange}
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  {formValue.errors.password ? (
                    <p className="text-red-700" id="">
                      {formValue.errors.password}
                    </p>
                  ) : null}
                </div>
                <div className="col-span-6">
                  <fieldset aria-label="terms">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        name="terms"
                        type="checkbox"
                        checked={con1}
                        onChange={() => setCon1((prev) => !prev)}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />

                      <div className="ml-3 text-sm">
                        <p className="text-gray-500">
                          {t("form.join.accept")}{" "}
                          <a
                            className="font-bold text-blue-500 hover:text-blue-700 after:content-['*'] after:ml-0.5 after:text-red-500 "
                            href={"/terms-and-conditions"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("form.join.TermsandConditions")}
                          </a>
                        </p>
                      </div>
                    </div>
                  </fieldset>
                  {formValue.errors.con1 ? (
                    <p className="text-red-700" id="">
                      {formValue.errors.con1}
                    </p>
                  ) : null}
                </div>

                <div className="col-span-6">
                  <fieldset aria-label="privacy-policy">
                    <div className="flex items-center h-5">
                      <input
                        id="privacy-policy"
                        name="privacy-policy"
                        type="checkbox"
                        checked={con2}
                        onChange={() => setCon2((prev) => !prev)}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <div className="ml-3 text-sm">
                        <p className="text-gray-500">
                          {t("form.join.accept")}{" "}
                          <a
                            className="font-bold text-blue-500 hover:text-blue-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                            href={"/privacy-policy"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("form.join.PrivacyPolicy")}
                          </a>
                        </p>
                      </div>
                    </div>
                  </fieldset>
                  {formValue.errors.con2 ? (
                    <p className="text-red-700" id="">
                      {formValue.errors.con2}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="px-4 py-3 text-left sm:px-3">
              <button data-testid="joinNow_btn" type="submit" className="btn-2">
                {t("guestUser.Update.modal")}
              </button>
            </div>
          </div>
        </form>
      </LoadingOverlay>
    </Modal>
  );
};

import { axiosClient } from "../../api/api";

export const checkRedeemServiceEnable = async () => {
  return axiosClient
    .get("/api/survey/checkReedemNowServiceStatus", {
      params: { service: "isZampliaSurveysClaimRewardEnable" },
    })
    .then((response) => response.data);
};

export const checkVirtualRedeemServiceEnable = async () => {
  return axiosClient
    .get("/api/survey/checkReedemNowServiceStatus", {
      params: { service: "isZampliaSurveysVirtualClaimRewardEnable" },
    })
    .then((response) => response.data);
};

export const checkReferralRedeemServiceEnable = async () => {
  return axiosClient
    .get("/api/survey/checkReedemNowServiceStatus", {
      params: { service: "isZampliaSurveysReferralClaimRewardEnable" },
    })
    .then((response) => response.data);
};

export const getNeoBrands = async () => {
  return axiosClient
    .get("/api/survey/getNeoCurrencyBrandsOrBalance", {
      params: { code: "3MJsEaXjL-pRV3_t9Zy2vIl3dJhkQl2CRzu-FVNA1WZ5AzFu6UKBqQ==" },
    })
    .then((response) => response.data);
};

type formState = {
  neoBrands: string;
  points: number | string;
  email: string;
  mobile: any;
  panelistId: number;
  studyData: string;
  service: string;
  requestType: string;
  currency: string;
};

export const claimNeoRewardDetails = async (FormState: formState) => {
  const {
    neoBrands,
    points,
    email,
    mobile,
    panelistId,
    studyData,
    service,
    requestType,
    currency,
  } = FormState;
  return axiosClient
    .post("/api/survey/claimLoopSurveyNeoReward", {
      neoBrands,
      points,
      email,
      mobile,
      panelistId,
      appName: "zampliasurveys",
      ...(requestType === "Study" && { studyData }),
      service,
      requestType,
      currency,
    })
    .then((response) => response.data);
};

export const getPanelListCompletedData = async (
  panelListId: number,
  page: number,
  fromDate: any,
  type: string
) => {
  return axiosClient
    .get("/api/survey/getPanelListCompletedSurveys", {
      params: {
        panelListId,
        page,
        fromDate,
        type,
      },
    })
    .then((response) => response.data);
};

export const addStudyClaimRequest = async ({ panelListId, studyId, points, p_id }) => {
  return axiosClient
    .get("/api/survey/addLoopSurveyStudyClaimRequest", {
      params: {
        panelListId,
        studyId,
        points,
        p_id,
      },
    })
    .then((response) => response.data);
};

export const ResendOtpForClaim = async (type: string, id: string) => {
  return axiosClient
    .post("/api/survey/resendOtpForClaimLoopSurveyReward", {
      type,
      id,
      appName: "zampliasurveys",
    })
    .then((response) => response.data);
};

export const VerifyZampliaSurveys = async (
  emailOtp: number,
  smsOtp: number,
  id: number,
  panelListId: number
) => {
  return axiosClient
    .post("/api/survey/resendOtpForClaimLoopSurveyReward", {
      params: { emailOtp, smsOtp, id, cashout: "zampliasurveys cashout", panelListId },
    })
    .then((response) => response.data);
};

export const verifyZampliaSurveys = async (
  emailOtp: number,
  smsOtp: number,
  id: string,
  panelListId: number,
  points: any,
  cashout: any,
  service: string
) => {
  return axiosClient
    .post("/api/survey/verifyZampliaSurveysClaimRequestOtp", {
      emailOtp,
      smsOtp,
      id,
      cashout,
      panelListId,
      points,
      service,
    })
    .then((response) => response.data);
};

export const referralBalance = async (panelListId: number) => {
  return axiosClient
    .get("/api/survey/referralWallet", {
      params: { panelListId },
    })
    .then((response) => response.data);
};

export const bonusStatusDetailsApi = async (panelListId: number) => {
  return axiosClient
    .get("/api/user/bonusPointDetails", {
      params: { panelListId },
    })
    .then((response) => response.data);
};

export const AfterVerificatiobProcess = async (panelistId: number) => {
  return axiosClient
    .post("/api/user/updateBonusStatus", {
      panelistId,
    })
    .then((response) => response.data);
};

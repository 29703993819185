import { axiosClient } from "../../api/api";

export const getsurveysPointApi = async (sessionIdParam: string, rating?:number) => {
  return axiosClient
    .post("/api/survey/getSurvey_Complete_Point", {
      session_id : sessionIdParam,
      rating:rating
    })
    .then((response) => response.data);
};

import React, { useEffect, useState } from "react";
import { WalletCard } from "./WalletCard";
import { To } from "react-router-dom";
import groupsvg from "../../assets/dashboard/Group.svg";
import groupsvg2 from "../../assets/dashboard/group2.svg";
import groupsvg3 from "../../assets/dashboard/Group3.svg";
import Points from "./Points";
import NavCard from "./NavCard";
import { useAuth } from "context/auth-context";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { useTranslation } from "react-i18next";
import {
  GetBonusPointDetailsApi,
  ReferalWalletBalance,
  StreakWalletBalanceApi,
  getPointBalanceStudiesWiseApi
} from "../Dashboard/Dasboard.api";
// import CalimStudy from "./ClaimStudy/ClaimStudy";
import BonusPopUp from "../../components/BonusPop copy";
import { Shistory } from "./Shistory";

function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigationto = (url: To) => {
    navigate(url);
  };
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const selectedPoints = localStorage.getItem("selectedPoints");

    if (selectedPoints === "true") {
      setShowModal(false);
    }
  }, []);

  const { auth } = useAuth();
  const GetBonusPointDetails = useQuery(
    ["getBonusPointDetails", auth.panelistId],
    () => GetBonusPointDetailsApi(auth.panelistId), 
    {
      refetchOnWindowFocus: false, 
    }
  );

  const getPointsBalance = useQuery(
    ["pointBal", auth.panelistId],
    () => getPointBalanceStudiesWiseApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );
  
  const getReferalWalletBalance = useQuery(
    ["referalBal", auth.panelistId],
    () => ReferalWalletBalance(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getStreakWalletBalance = useQuery(
    ["streakbalance", auth.panelistId],
    () => StreakWalletBalanceApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,   
    }
  );               

  const pointBalance =
    getPointsBalance && getPointsBalance?.data ? getPointsBalance?.data?.data : 0;
  const referalBal =
    getReferalWalletBalance && getReferalWalletBalance?.data
      ? getReferalWalletBalance?.data?.Data
      : 0;
  const streakBalance =
    getStreakWalletBalance && getStreakWalletBalance?.data
      ? getStreakWalletBalance?.data?.streakCount
      : 0;

  const BonusData =
    GetBonusPointDetails &&
    GetBonusPointDetails?.data &&
    GetBonusPointDetails?.data?.data &&
    GetBonusPointDetails?.data?.data[0] &&
    GetBonusPointDetails?.data?.data[0]?.userId;
  const BonusUserPoint =
    GetBonusPointDetails &&
    GetBonusPointDetails?.data &&
    GetBonusPointDetails?.data?.SelectBounusData &&
    GetBonusPointDetails?.data?.SelectBounusData;

  const menus = [
    {
      icon: <Points />,
      name: `${t("reward.eligibility.dashboard")}`,
    },
    {
      name: `${t("dashboard.reward.verification")}`,
      icon: <Points />,
    },
    {
      name: `${t("dashboard.reward.distribution")}`,
      icon: <Points />,
    },
    {
      name: `${t("dashboard.reward.support")}`,
      icon: <Points />,
    },
  ];

  return (
    <>
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={GetBonusPointDetails.isLoading}
        spinner
        text={t("page.Loading")}
      >
        <div>
          <div className="flex flex-row items-center justify-center px-4 scroll-smooth lg:w-[98vw]">
            {BonusData == 0 && BonusUserPoint == 1 && showModal ? <BonusPopUp /> : null}
            <div className="mt-24 lg:ml-[16rem] lg:h-[700px] md:[700] w-full h-[700px]  ">
              <NavCard />

              <div className="flex flex-col items-center justify-center gap-4 mt-4 mb-2 lg:flex-row md:flex-col">
                <div className="bg-white px-4 w-full md:ml-0 ml-0 rounded-[21px]">
                  <div className="flex flex-col items-center justify-center lg:gap-2 gap-5 mt-8 mb-6 lg:flex-row md:flex-col  md:h-[670px] h-[630px] lg:h-[240px]">
                    <WalletCard
                      customClass="bg-[#EAF6FE] "
                      pointClass=" bg-[#92C8E0] border-spacing-[7px]"
                      groupsvg={groupsvg}
                      selectPointClass=" hover:bg-sky-700 hover:bg-opacity-5"
                      circleClass="bg-[#056E9C]"
                      points={+pointBalance ? +pointBalance : 0}
                      svgClass="ml-2 mt-2 "
                      pointBalance={t("dashboard.point.Balance")}
                      BalanceClass
                      pointBalanceClass=""
                      redeem={t("dashboard.redeemNow")}
                      onselect={() => navigationto("/survey-redeem")}
                    />

                    <WalletCard
                      customClass="bg-[#FF9C0014] "
                      pointClass=" bg-[#FFD188] border-spacing-[7px]  "
                      groupsvg={groupsvg2}
                      selectPointClass=" hover:bg-sky-700 hover:bg-opacity-5"
                      circleClass="bg-[#FF9C00]"
                      points={+referalBal ? +referalBal : 0}
                      pointBalance={t("dashboard.referral.Balance")}
                      BalanceClass
                      svgClass="ml-2 mt-2"
                      pointBalanceClass=""
                      redeem={t("dashboard.redeemNow")}
                      onselect={() => navigationto("/redeem/form/referral")}
                    />

                    <WalletCard
                      customClass="bg-[#54B9481C] "
                      pointClass=" bg-[#90EA85] border-spacing-[7px] border-[#92C8E0] "
                      groupsvg={groupsvg3}
                      selectPointClass=" hover:bg-sky-700 hover:bg-opacity-5"
                      circleClass="bg-[#54B948]"
                      points={+streakBalance ? +streakBalance : 0}
                      pointBalance={t("dashboard.streak.Balance")}
                      svgClass="ml-[13px] mt-[12px] "
                      BalanceClass
                      pointBalanceClass=""
                      redeem={t("dashboard.redeemNow")}
                      onselect={() => navigationto("/redeem/form/streak")}
                    />
                  </div>
                </div>
                <div className="lg:h-[300px] w-full flex items-center md:h-[312] ml-1 min-h-[312px] h-auto top-[296px] text-sm rounded-[21px] bg-white font-poppins ">
                  <div className=" sm:pl-4">
                    <div className="mt-3 ml-6 text-base font-medium font-poppins">
                      {t("dashboard.reward.Text")}
                    </div>
                    <div className="items-center justify-center mt-3 ml-6 font-normal font-poppins">
                      {menus.map((menuItem, index) => (
                        <div key={index} className="mb-4">
                          <div className="flex flex-row gap-4 ">
                            <div className="mt-[2px]">{menuItem.icon}</div>
                            <div>
                              <span className="font-poppins text-[#000000]">
                                {menuItem.name.split(": ")[0]}
                              </span>
                              :{" "}
                              <span className="font-poppins text-[#3B3B3B]">
                                {menuItem.name.split(": ")[1]}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="ml-[64px] mt-6 mb-[10px] lg:mt-3 font-poppins font-medium ">
                      {t("dashboard.reward.Thanks")}
                    </div>
                  </div>
                </div>
              </div>
              {/* <CalimStudy /> */}
              <div className="pb-20 lg:pb-10">
                <Shistory />
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}
export default Dashboard;


export const validatePassword = (
  password,
  newPassword,
  confirmPassword,
  userPassword,
  hashPassword,
  setError,
  t
) => {
  const trimmedPassword = password.trim();
  const trimmedNewPassword = newPassword.trim();
  const trimmedConfirmPassword = confirmPassword.trim();
  const validregex = /^\S+(?:\s*\S+)*$/;
  if (trimmedPassword === "") {
    setError(t("changePassword.error.empty"));
    return false;
  }
  if (hashPassword !== userPassword) {
    setError(t("changePassword.error.cnotmatch"));
    return false;
  }
  if (confirmPassword.includes(" ")) {
    setError(t("changePassword.error.space2"));
    return false;
  }
  if (newPassword.includes(" ")) {
    setError(t("changePassword.error.space2"));
    return false;
  }

  if (trimmedPassword === "") {
    setError(t("changePassword.error.Fspace"));
    return false;
  }
  if (
    trimmedPassword.includes(" ") ||
    trimmedNewPassword.includes(" ") ||
    trimmedConfirmPassword.includes(" ")
  ) {
    setError(t("changePassword.error.space"));
    return false;
  }

  if (password === newPassword) {
    setError(t("changePassword.error.different"));
    return false;
  }

  if (trimmedPassword == "" && trimmedNewPassword == "" && trimmedConfirmPassword == "") {
    setError(t("changePassword.error.space"));
    return false;
  }

  if (trimmedNewPassword === "") {
    setError(t("changePassword.error.entern"));
    return false;
  }

  if (trimmedConfirmPassword === "") {
    setError(t("changePassword.error.enterc"));
    return false;
  }

  if (trimmedNewPassword.length < 6) {
    setError(t("changePassword.error.min6"));
    return false;
  }

  if (trimmedNewPassword !== trimmedConfirmPassword) {
    setError(t("changePassword.error.notmatch"));
    return false;
  }

  if (trimmedNewPassword.length > 16) {
    setError(t("changePassword.error.max16"));
    return false;
  }

  if (!validregex.test(trimmedNewPassword)) {
    setError(t("changePassword.error.blank"));
    return false;
  }

  return true;
};

import React, { useCallback, useRef, useState } from "react";
import { appSupportedLanguages as languages } from "config/i18n/supported_language";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useOutsideClick } from "hooks/useOutsideClick";

export const ChangeLanguage = () => {
  const { i18n } = useTranslation();

  const menuRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const langChangeHandler = useCallback((code: string) => {
    i18n.changeLanguage(code);
    setVisible(false);
  }, []);

  const toggleDropDown = useCallback(() => {
    setVisible(function (prevState) {
      return !prevState;
    });
  }, []);

  useOutsideClick(menuRef.current!, () => {
    // do something here
    setVisible(false);
  });

  {
    /**Fix the CSS */
  }
  return (
    <div
      className="relative z-10 flex lg:px-5 font-poppins cursor-pointer"
      ref={menuRef}
      onClick={toggleDropDown}
    >
      <span data-testid="globe-btn" className="flex flex-col w-25 ">
        <FontAwesomeIcon icon={faGlobe} className="h-5 text-5xl text-color-1" />
        <FontAwesomeIcon icon={faAngleDown} className="h-5 text-5xl text-color-1" />{" "}
        {/** Add a globe icon with dropdown symbol */}
      </span>
      {visible ? (
        <ul
          style={{ maxHeight: "400px", overflowY: "scroll" }}
          data-testid="dropDown_body"
          onClick={(e) => e.stopPropagation()}
          className="p-5 lg:w-48 w-[50vw] md:w-[12rem] absolute top-12 translate-x-[-50%] bg-white  border md:-left-[1.6rem] lg:left-[0.7rem] -left-[3.4rem] text-left rounded-lg shadow-lg"
        >
          {languages.map(({ code, title, country }) => {
            return (
              <li
                data-testid="listItem"
                className={`mt-3 cursor-pointer language-list-item ${
                  code === i18n.language ? "disabled" : ""
                }`}
                key={code}
                onClick={(e) => {
                  e.stopPropagation();
                  langChangeHandler(code);
                }}
              >
                <span className={`fi fi-${country} mr-3`} />
                {title}
              </li>
            );
          })}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

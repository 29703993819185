import { useState } from "react";
import PanelistStudywiseTable from "../../components/PanelistStudywiseTable/PanelistStudywiseTable";
import ClaimListCard from "../../components/ClaimListCard/ClaimListCard";
import { getUnclaimedStudies } from "./UnclaimedPanelistStudies.api";
import { useAuth } from "context/auth-context";
import { useQuery } from "@tanstack/react-query";

import LoadingOverlay from "react-loading-overlay-ts";

const UnclaimedPanelistStudies = () => {
  const [claimList, setClaimList] = useState([]);
  const { auth } = useAuth();

  const { data, isLoading, refetch, isFetching } = useQuery(
    ["unclaimedStudies"],
    () => getUnclaimedStudies(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={isLoading || isFetching}
      spinner
      text="Loading..."
    >
      <div className="flex flex-col lg:flex-row mt-5 lg:space-x-2 mb-[84px] sm:mb-0">
        <div className="lg:w-full w-[98vw] rounded-lg lg:h-[75vh] h-[52vh] overflow-x-auto overflow-y-auto bg-white ">
          <PanelistStudywiseTable
            studiesData={data}
            setClaimList={setClaimList}
            claimList={claimList}
            isLoading={isLoading}
          />
        </div>
        <div className="mt-4 lg:mt-0">
          <ClaimListCard claimList={claimList} refreshList={refetch} setClaimList={setClaimList} />
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default UnclaimedPanelistStudies;

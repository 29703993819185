type tokenRes = {
  name: string;
  value: string;
  days: number;
  refresh_token: string;
};

export const setLoginCookie = (token: tokenRes) => {
  const { name, value, days } = token;
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const removeLoginCookie = (name: string) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

import { ChangeLanguage } from "components/core/ChangeLanguage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/MainLogo.png";
import { useTranslation } from "react-i18next";
import { NavSmallScreen } from "./NavSmallScreen";
import { useAuth } from "context/auth-context";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { UserOutlined, DownOutlined, BellOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import { getNotificationDetails } from "components/Notifications/Notifications.api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IP_REGISTRY_KEY } from "../../config/constants";

// type ToggleMobileMenu = () => void;

export const Header = () => {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const { auth } = useAuth();
  const [currentPage] = useState<number>(1);
  const [langCode, setLangCode] = useState("");
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  const getNotificationsData = useQuery(
    ["getNotifications", currentPage, langCode],
    () => {
      if (langCode) {
        if (auth) {
          return getNotificationDetails(currentPage, langCode, auth?.panelistId);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const getData = async () => {
    const res = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_KEY}`);
    if (res.data.currency.code == "USD") {
      setLangCode("En-US");
    } else if (res.data.currency.code == "CAD") {
      setLangCode("English-CA");
    } else if (res.data.currency.code == "GBP") {
      setLangCode("En-US");
    } else if (res.data.currency.code == "INR") {
      setLangCode("English-IN");
    } else {
      setLangCode(res.data.location.country.name + "-" + res.data.location.country.code);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const mobileMenuToggle: ToggleMobileMenu = () => setIsNavOpen((prev) => !prev);
  const mobileMenuToggle = () => {
    setIsNavOpen(!isNavOpen);
    console.log("the navopen menu value is ", isNavOpen);
  };
  const items = [
    {
      key: "profile_1",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/my-surveys"}>{t("home.headers.survey")}</Link>,
    },
    {
      key: "profile_2",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/my-profile"}>{t("home.headers.profile")}</Link>,
    },
    {
      key: "profile_3",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/my-referral"}>{t("home.headers.referral")}</Link>,
    },
    {
      key: "profile_4",
      className: `header-ant-dropdown-menu ${!auth?.isVirtualWalletOpen ? "hidden_menu" : ""}`,
      label: <Link to={"/virtual-wallet"}>{t("home.headers.virtual")}</Link>,
    },
    {
      key: "profile_5",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/history"}>{t("home.headers.history")}</Link>,
    },
    {
      key: "profile_6",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/unsubscribe"}>{t("home.headers.unsubscribe")}</Link>,
    },
    {
      key: "profile_7",
      className: "header-ant-dropdown-menu",
      label: (
        <Link
          to={"/logout"}
          // onClick={(event) => {
          //   event.preventDefault();
          //   // navigate(location.state || "/logout", { state: null });
          // }}
        >
          {t("home.headers.logout")}
        </Link>
      ),
    },
  ];
  // const modalDisplayed = localStorage.getItem("modalDisplayed");

  return (
    <div className="fixed z-50 top-0 w-full   mx-auto h-20 bg-white flex lg:justify-start lg:items-center py-2 px-0 md:justify-start  border-gray-100 shadow-sm border-b-2 lg:space-x-10  ">
      <div className="flex  md:w-[20%]  sm:flex-none justify-start lg:px-2 mx-8 lg:mx-[7rem]  lg:flex-auto   md:-mr-8 md:-ml-1 lg:ml-20  lg:w-[30%] lg:mr-0">
        <Link to={"/"}>
          {/* <span data-testid="companyName" className="sr-only">
              {t("home.header.Company")}
            </span> */}
          <img
            className="sm:w-[235px] md:w-[200px] lg:w-[235px] sm:h-[65px]"
            src={Logo}
            alt="mainLogo"
          />
        </Link>
      </div>

      <section className="flex MOBILE-MENU sm:hidden">
        <button
          data-testid="hamburgerBtn"
          className="px-5 space-y-2 HAMBURGER-ICON "
          onClick={mobileMenuToggle}
        >
          <span className=""></span>
          <span className=""></span>
          <span className=""></span>
        </button>
        {isNavOpen ? <NavSmallScreen toggle={mobileMenuToggle} /> : null}
      </section>

      <div className=" md:w-[80%] md:flex lg:flex lg:items-center   md:justify-center md:items-center  items-center justify-end hidden nav-links   lg:justify-end ">
        {/* <a href="https://zampliasurveys.com/" className="links ">
            OLD VERSION
          </a> */}
        <HashLink
          data-testid="home_link"
          smooth
          to="/"
          className="links font-['Poppins']"
          onClick={scrollToTop}
        >
          {t("home.headers.Home")}
        </HashLink>
        <HashLink data-testid="about_link" smooth to="/#about" className="links font-['Poppins']">
          {t("home.headers.About")}
        </HashLink>
        <HashLink data-testid="faq_link" smooth to="/#faq" className="links font-['Poppins']">
          {t("home.headers.FAQ")}
        </HashLink>
        <HashLink
          data-testid="contact_link"
          smooth
          to="/#contact"
          className="links font-['Poppins']"
        >
          {t("home.headers.Contact")}
        </HashLink>

        {auth ? (
          <>
            <HashLink data-testid="query_link" smooth to="/raise-query" className="links">
              {t("home.headers.Query")}
            </HashLink>
            <HashLink smooth to="/alert" className="links">
              {" "}
              <p>
                <BellOutlined />
                <sup className="text-red-900">
                  {getNotificationsData?.data?.totalUsers == undefined
                    ? "0"
                    : getNotificationsData.data?.totalUsers}
                </sup>
              </p>
            </HashLink>

            <Dropdown
              className="links"
              menu={{
                items,
              }}
              // trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Avatar icon={<UserOutlined />} /> Hi!{auth.name ? auth.name.split(" ")[0] : ""}{" "}
                <DownOutlined />
              </a>
            </Dropdown>
          </>
        ) : (
          <>
            <Link data-testid="login_link" to={"/login"} className="links ">
              {t("home.headers.Login")}
            </Link>
            <Link data-testid="join-now-btn" to={"/join-now"}>
              <div className="nav-btn ">{t("btn.join.now")}</div>
            </Link>
          </>
        )}
        <div
          className=" ml-2  md:-mr-4 md:ml-5 lg:px-3 lg:ml-0
        "
        >
          <ChangeLanguage />
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef } from "react";
import { ReactComponent as AccountClose } from "../../assets/SideBarIcons/AccountClose.svg";
import { useState } from "react";
import ResetPassword from "./ResetPassword";
import { changeUserPassword, decryptPassword } from "./Account.api";
import { forgotUser } from "../ForgotForm/ForgotForm.api";
import { toastrError, toastrSuccess } from "lib/toastr";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { validatePassword } from "./PasswordValidation";
import { getPassword } from "components/LoginForm/LoginForm.api";

interface AccountToggleProps {
  isPopupOpen1;
}

const ChangePassword: React.FC<AccountToggleProps> = ({ isPopupOpen1 }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { auth } = useAuth();
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [hashPassword, setHashPassword] = useState("");
  const [userPassword, setUSerPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [issubmit, setissubmit] = useState(false);
  const modelRef = useRef();

  const mutation = useMutation(() => changeUserPassword(auth?.panelistId, password, newPassword), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrError(response.message);
      } else {
        toastrSuccess(t("changePassword.success"));
      }
    },
    onError: () => {
      toastrError(t("changePassword.terminate"));
    },
  });
  console.log(error, "amit");
  const mutationDecrypt = useMutation(() => decryptPassword(password), {
    onSuccess: (response: any) => {
      if (response.success) {
        setHashPassword(response.hashedPassword);
      }
    },
  });

  // getUserPassword
  const getpassword = useMutation(() => getPassword(auth?.email), {
    onSuccess: (response) => {
      if (response) {
        setUSerPassword(response.result[0].user_pass);
      }
    },
  });

  useEffect(() => {
    getpassword.mutate();
  }, []);

  useEffect(() => {
    if (password) {
      mutationDecrypt.mutate();
    }
  }, [password]);

  const handleReset = (e) => {
    e.preventDefault();
    setissubmit(true);
    if (
      !validatePassword(
        password,
        newPassword,
        confirmPassword,
        userPassword,
        hashPassword,
        setError,
        t
      )
    ) {
      return;
    }

    mutation.mutate();
    setError("");
    isPopupOpen1();
  };

  const ResetPasswordMutaion = useMutation(() => forgotUser(auth.email), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(t("forgotPassword.success"));
      } else {
        toastrError(response.message);
      }
    },
    onError: () => {
      toastrError(t("page.unsubscribe.Encountered"));
    },
  });

  const togglepopup = () => {
    setIsPopupOpen(!isPopupOpen);
    ResetPasswordMutaion.mutate();
  };

  const CloseModel = (e) => {
    if (modelRef.current == e.target) {
      isPopupOpen1();
    }
  };

  useEffect(() => {
    if (issubmit) {
      validatePassword(
        password,
        newPassword,
        confirmPassword,
        userPassword,
        hashPassword,
        setError,
        t
      );
    }
  }, [t]);

  return (
    <>
      {isPopupOpen1 && (
        <div
          className="fixed inset-0 lg:top-[8vh] lg:left-[10vw] w-full h-full flex justify-center items-center bg-black bg-opacity-50 "
          ref={modelRef}
          onClick={CloseModel}
        >
          <div className="bg-white p-4 rounded-3xl shadow-md w-[300px] md:w-[450px] md:px-14 relative">
            <h1 className="text-center text-[16px] text-[#757575] font-medium font-poppins">
              {t("changePassword.head")}
            </h1>
            <div className="absolute right-6 top-5 md:top-6 cursor-pointer">
              <AccountClose onClick={isPopupOpen1} />
            </div>
            <div className=" mt-2 text-sm space-y-2 font-poppins font-normal text-[#413C3C]">
              <div>
                <label> {t("changePassword.current_pass")}</label>
                <input
                  type="text"
                  className="w-full h-[50px] mt-2 pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <label>{t("changePassword.setnew_pass")}</label>
                <input
                  type="text"
                  className="w-full h-[50px] mt-2 pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="h-16">
                <div>
                  <label>{t("changePassword.confnew_pass")}</label>
                  <input
                    type="text"
                    className="w-full h-[50px] mt-2 pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {error && <p className="text-red-500 text-xs pt-2 ">{error}</p>}
              </div>
              <div>
                <button
                  className="w-full h-[50px] mt-12 pl-3 text-[#FFFFFF] bg-[#056E9C] rounded-2xl"
                  onClick={handleReset}
                >
                  {t("changePassword.button")}
                </button>
              </div>
              <div className="text-center">
                <p className="text-[9px] md:text-xs font-semibold md:font-normal">
                  {t("changePassword.notRemember")}
                  <span
                    className="text-[#C20122] font-semibold cursor-pointer ml-1"
                    onClick={togglepopup}
                  >
                    {t("changePassword.forgotbutton")}.
                  </span>
                </p>
                {isPopupOpen && (
                  <div>
                    <ResetPassword
                      isPopupOpen={() => setIsPopupOpen(false)}
                      isPopupOpen1={isPopupOpen1}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePassword;

import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import Surveystreak1 from "../../../assets/SideBarIcons/Surveystreakicons/Surveystreak1.svg";
import Gift from "../../../assets/SideBarIcons/Surveystreakicons/Gift.svg";
import trophy from "../../../assets/SideBarIcons/Surveystreakicons/Trophy.svg";
import greenfire from "../../../assets/SideBarIcons/Surveystreakicons/greenFire.svg";
import { getStreakCountApi } from "./Surveystreak.api";
import { weeks } from "./Surveystreak.interface";
import { toastrSuccess } from "lib/toastr";

function Surveystreak() {
  const { auth } = useAuth();
  const queryClient = useQueryClient();

  const getSurveyvirtualwallet = useQuery(
    ["streakdata"],
    () => getStreakCountApi(auth?.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleCompletionCheck = () => {
    if (getSurveyvirtualwallet?.data?.completeCount === 7) {
      toastrSuccess("7 Days Rewards Send Sucessfully");
      queryClient.invalidateQueries(["streakdata"]);
    }
  };

  useEffect(() => {
    handleCompletionCheck();
  }, [getSurveyvirtualwallet.data]);

  const sliceWeeksByStartDay = (startOfStreakDay: string) => {
    const startDayIndex = weeks.findIndex(
      (day) => day.days.substring(0, 3) === startOfStreakDay?.substring(0, 3)
    );
    if (startDayIndex !== -1) {
      return weeks.slice(startDayIndex).concat(weeks.slice(0, startDayIndex));
    } else {
      return weeks;
    }
  };

  const slicedWeeks = sliceWeeksByStartDay(getSurveyvirtualwallet?.data?.streakStartDay);

  return (
    <div className="flex  flex-col justify-center items-center right-0 md:right-2 lg:right-[2rem] top-[4rem] md:top-[5rem] fixed z-50">
      <div className="bg-[#FCFCFC] shadow-[#0000001A] shadow-xl rounded-3xl p-4">
        <div className=" w-full">
          <div className=" flex justify-between">
            <div className=" flex space-x-2 items-center ">
              <div className="md:w-[50px] md:h-[50px] w-[32px] h-[33px] rounded-[11px] ">
                <img src={Surveystreak1} alt="Logo" />
              </div>
              <div className="md:text-sm text-xs font-semibold text-black font-poppins">
                Survey Streak
              </div>
            </div>
            <div className=" flex space-x-2 items-center">
              <div className="flex items-center justify-center bg-[#FF9C0017] md:w-[50px] md:h-[50px] w-[32px] h-[33px] rounded-full">
                <img src={Gift} alt="" />
              </div>
              <div className="md:text-sm text-xs font-medium text-orange-400 font-poppins">
                Gifts upto 1000 pts
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center mt-6">
          <div className="">
            <p className="font-poppins md:text-sm text-xs font-medium text-[#606060] pr-2">
              Daily Goal
            </p>
          </div>
          <div className="flex flex-col items-center justify-center ">
            <div className="flex flex-row md:space-x-1 space-x-2 cursor-pointer">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className={`lg:w-[47.48px] lg:h-[47px] md:w-[47.48px] md:h-[47px] w-[25px] h-[29px] rounded-[10px] ${
                    index < (getSurveyvirtualwallet?.data?.completeCount || 0)
                      ? "bg-[#00A411]"
                      : "bg-[#FF9C0017]"
                  }`}
                ></div>
              ))}
              <div
                className={`lg:w-[47.48px] lg:h-[47px] md:w-[47.48px] md:h-[47px] w-[25px] h-[25px] rounded-[10px] flex items-center justify-center bg-[#54B9483B] ${
                  getSurveyvirtualwallet?.data?.completeCount === 7 ? "" : "opacity-50"
                }`}
              >
                <img src={trophy} alt="" />
              </div>
            </div>
            <div>
              {getSurveyvirtualwallet &&
              getSurveyvirtualwallet?.data &&
              getSurveyvirtualwallet?.data?.completeCount !== undefined &&
              getSurveyvirtualwallet?.data?.completeCount !== null &&
              getSurveyvirtualwallet?.data?.completeCount > 0 ? (
                <div className="flex items-center justify-center text-xs md:text-sm font-medium font-poppins md:space-x-[26px] space-x-2">
                  {slicedWeeks?.map((item, index) => (
                    <div className="flex flex-row items-center justify-center text-sm" key={index}>
                      {item.days}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center text-xs md:text-sm font-medium font-poppins md:space-x-[26px] space-x-2">
                  {new Array(7).fill(null).map((_, index) => {
                    const day = new Date();
                    day.setDate(day.getDate() + index);
                    return (
                      <div
                        className="flex flex-row items-center justify-center text-sm"
                        key={index}
                      >
                        {day.toLocaleDateString("en-US", { weekday: "short" })}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-8 ">
          <div className="flex items-center justify-center space-x-4 ">
            <div className=" md:w-[50px] md:h-[50px] w-[32px] h-[33px] bg-[#54B94824] flex items-center justify-center  rounded-[11px]">
              <img src={greenfire} alt="Logo" />
            </div>
            <div className="flex flex-col text-xs font-medium md:text-sm font-poppins">
              <p>Current</p>
              <p>Streak</p>
            </div>

            <div className="flex  items-center justify-center font-poppins">
              <div className="font-semibold pl-4 text-[#000000] md:text-sm text-xs cursor-pointer">
                {getSurveyvirtualwallet &&
                getSurveyvirtualwallet?.data &&
                getSurveyvirtualwallet?.data?.completeCount !== undefined &&
                getSurveyvirtualwallet?.data?.completeCount !== null &&
                getSurveyvirtualwallet?.data?.completeCount > 0
                  ? `${getSurveyvirtualwallet?.data?.completeCount} Day${
                      getSurveyvirtualwallet?.data?.completeCount !== 1 ? "s" : ""
                    }`
                  : `${0} Day`}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center pl-1 space-x-4">
            <div className="md:w-[50px] md:h-[50px] w-[34px] h-[32px] rounded-[11px]">
              <img src={Surveystreak1} alt="Logo" />
            </div>
            <div className=" text-xs font-medium md:text-sm font-poppins">
              <p>Longest</p>
              <p>Streak</p>
            </div>
            <div className="cursor-pointer flex items-center justify-center  font-poppins font-semibold text-[#000000] md:text-sm text-xs pl-4">
              {getSurveyvirtualwallet &&
              getSurveyvirtualwallet?.data &&
              getSurveyvirtualwallet?.data?.longestStreak !== undefined &&
              getSurveyvirtualwallet?.data?.longestStreak > 0
                ? `${getSurveyvirtualwallet?.data?.longestStreak} Day${
                    getSurveyvirtualwallet?.data?.longestStreak !== 1 ? "s" : ""
                  }`
                : `${0} Day`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Surveystreak;

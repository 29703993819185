import { useState, useEffect } from "react";
import { claimNeoRewardDetails, getNeoBrands } from "./MyReferralPage.api";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import axios from "axios";
import { useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { Modal } from "antd";
import PhoneInput from "react-phone-number-input";
import { useBonusPoints } from "hooks/useBonusPointsDetails";
import { IP_REGISTRY_KEY } from "../../config/constants";
import { bonusDetailsType } from "types/useCustomHookTypes";
import { useWalletDetails } from "hooks/useWalletDetails";
import Giftcard from "../../assets/SideBarIcons/Giftcard.png";

const skipPointsValidationPanelList = ["509626", "517676", "521553", "513736", "521551", "513742"];
const intialState = {
  neoBrands: "",
  points: +"",
  email: "",
  mobile: "",
  studyData: "",
  service: "",
  requestType: "",
  studyDefaultPoints: +"",
  errors: {
    neoBrands: "",
    points: "",
    email: "",
    mobile: "",
  },
};
type ClaimDataType = typeof intialState;
type FormState = typeof intialState;
export const NeoBrandsData = () => {
  const { auth } = useAuth();
  const walletDetails: Record<string, number | undefined> = useWalletDetails();
  const bonusDetails: bonusDetailsType | null = useBonusPoints();
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(true);
  const [country, setCountry] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [countryCode, setCountryCode] = useState<any>("US");
  const [formState, setFormState] = useState<FormState>(intialState);
  const location = useLocation();
  const { redeem_point } = location.state || {};
  const getNeoBrandsData = useQuery(["NeoBrands", country], () => getNeoBrands(), {
    refetchOnWindowFocus: false,
    enabled: !!country,
  });

  const PointDisplay = redeem_point;

  const postQuery = useMutation(
    ["claimNeoReward"],
    () =>
      claimNeoRewardDetails({
        neoBrands: formState.neoBrands,
        points: +PointDisplay ? +PointDisplay : formState.points,
        email: formState.email,
        mobile: formState.mobile.substring(1),
        panelistId: auth.panelistId,
        studyData: formState.studyData,
        service: formState.service,
        requestType:  formState.requestType,
        currency: country,
      }),
    {
      onSuccess(response: any) {
        if (response.success) {
          Modal.success({
            title: "Success!",
            content: response.message,
            onOk() {
              navigate("/otp", {
                state: {
                  requestId: response.id,
                  service: formState.requestType,
                  points: formState.points,
                },
              });
            },
          });
        } else {
          Modal.error({
            title: "Encountered an error!",
            content: response.message,
          });
        }
      },
      onError(error: any) {
        if (error && error.response?.data) {
          Modal.error({
            title: "Encountered an error!",
            content: error.response.data.message,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (location.pathname.includes("/referral")) {
      setFormState({
        ...formState,
        ["service"]: "isZampliaSurveysReferralClaimRewardEnable",
        ["requestType"]: "Referral Wallet",
      });
    } else if (location.pathname.includes("/streak")) {
      setFormState({
        ...formState,
        ["service"]: "isZampliaSurveysStreakClaimRewardEnable",
        ["requestType"]: "Streak Wallet",
      });
    } else if (location.pathname.includes("/study") ) {
       setFormState({
        ...formState,
         ["service"]: "isZampliaSurveysClaimRewardEnable",
        ["requestType"]: "Study",
      });
    } else if (location.pathname.includes("/bonus")) {
      if (bonusDetails) {
        setFormState({
          ...formState,
          ["points"]: bonusDetails?.data?.isActive ? bonusDetails?.data?.points : 0,
          ["service"]: "isZampliaSurveysBonusClaimRewardEnable",
          ["requestType"]: "Bonus",
        });
      }
      if (walletDetails && bonusDetails) {
        setShowButton(
          walletDetails?.TotalWalletBalance >= Number(bonusDetails?.data?.target_points)
        );
      }
    } else {
      navigate(0);
    }
  }, [location, bonusDetails]);

  useEffect(() => {
    if (+PointDisplay) {
      setFormState({
        ...formState,
        ["points"]: +PointDisplay,
        ["service"]: "isZampliaSurveysClaimRewardEnable",
        ["requestType"]: "Study",
      });
    }
  },[location, +PointDisplay]);

  const validation = (formValues: FormState) => {
    setFormState((old: ClaimDataType) => {
      return {
        ...old,
        errors: { ...old.errors, neoBrands: "", points: "", email: "", mobile: "" },
      };
    });

    if (!formValues.neoBrands || formValues.neoBrands === "Select") {
      setFormState((old) => {
        return {
          ...old,
          errors: { ...old.errors, neoBrands: t("page.referral.field") },
        };
      });
      return false;
    }

    if ( 
      (!skipPointsValidationPanelList.includes(auth.panelistId.toString()) 
      && ((formValues.points <= (location.pathname.includes("/bonus") ? 149 : 299))
      || formValues.points >= (location.pathname.includes("/bonus") ? 501 : 3001)))
    ) {
      setFormState((old: ClaimDataType) => {
        return {
          ...old,
          errors: { ...old.errors, points: t("page.referral.points") },
        };
      });
      return false;
    }

    // email validation patterns
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!(formValues.email && regexEmail.test(formValues.email))) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, email: t("page.referral.valid") } };
      });
      return false;
    }
    if (!formValues.mobile) {
      setFormState((old: ClaimDataType) => {
        return { ...old, errors: { ...old.errors, mobile: t("page.referral.required") } };
      });
      return false;
    }
    return true;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLSelectElement> = (e) => {
    e.preventDefault();
    const valid = validation(formState);
    if (valid) {
      postQuery.mutate();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const setMobile = (e) => {
    setFormState({ ...formState, ["mobile"]: e });
  };
  const getData = async () => {
    const res = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_KEY}`);
    if (res.data.currency.code === "USD") {
      setCountry("USD");
    } else if (res.data.currency.code === "CAD") {
      setCountry("CAD");
    } else if (res.data.currency.code === "AUD") {
      setCountry("AUD");
    } else if (res.data.currency.code === "INR") {
      setCountry("INR");
    } else {
      setCountry(res.data.currency.code);
    }
    if (res.data?.location?.country) {
      setCountryCode(res.data.location.country.code);
    }
  };

  useEffect(() => {
    getData();
  }, []);

   useEffect(() => {
    const input = document.getElementById("points");
    const disableScroll = (e) => {
      e.preventDefault();
    };

    input.addEventListener("wheel", disableScroll);
    return () => {
      input.removeEventListener("wheel", disableScroll);
    };
  }, []);
  

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
  };


  return (
    <>
      <div className="w-full">
        <LoadingOverlay
          styles={{
            overlay: (base) => ({
              ...base,
              position: "fixed",
            }),
          }}
          active={getNeoBrandsData.isLoading || postQuery.isLoading}
          spinner
          text="Loading..."
        >
          <div className=" flex justify-center items-center w-full mt-[6rem] fixed lg:ml-[7.3rem] md:ml-0 ml-0 ">
            <div className="flex-col justify-center font-poppins bg-white w-[600px] rounded-3xl">
              <div className="flex justify-center items-center h-[4rem]">
                <p className="text-[#6B6B6B] text-xl font-medium">Claim Your Reward</p>
              </div>
              <div className="flex flex-col lg:w-[80%] w-[90%]  lg:h-[73vh] md:h-[65vh] h-[60vh] overflow-y-auto lg:ml-14 ml-5 mb-2">
                <div className="flex items-center mb-5 mt-9">
                  <div className="w-10 h-10 rounded-full bg-[#056E9C17] flex  items-center justify-center">
                    <img src={Giftcard} alt="icon" />
                  </div>
                  <p className=" ml-3  text-[#6B6B6B] font-medium">Gift Cards</p>
                </div>

                <form
                  method="post"
                  id="claimRewardFirstStep"
                  className="mx-5 mb-5 text-sm"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group outline-cyan-500">
                    <div className="flex flex-col mt-2">
                      {getNeoBrandsData.data?.brands?.length > 0 &&
                        getNeoBrandsData.data?.brands?.map((currentItem, i: string, a: string) => {
                          if (a[i].currency_name === country) {
                            return (
                              <label
                                key={i}
                                className={`flex items-center pl-5  w-full h-16  bg-[#F3F6F9AD] rounded-2xl justify-between  ${
                                  showAll || parseInt(i.toString()) < 5 ? "block" : "hidden"
                                }`}
                              >
                                {getNeoBrandsData.data?.brands[i].name}
                                <input
                                  type="radio"
                                  name="neoBrands"
                                  value={getNeoBrandsData.data?.brands[i].id}
                                  onChange={handleChange}
                                  className="w-5 h-5 mr-3 text-blue-600 required focus:ring-blue-500"
                                />
                              </label>
                            );
                          }
                          return null;
                        })}
                    </div>
                    {!showAll && getNeoBrandsData.data?.brands?.length > 3 && (
                      <button
                        onClick={handleShowMore}
                        className="mt-2 text-lg font-bold cursor-pointer text-sky-800"
                      >
                        Show More
                      </button>
                    )}
                    {showAll && (
                      <button
                        onClick={handleShowLess}
                        className="mt-2 text-lg font-bold cursor-pointer text-sky-800"
                      >
                        Show Less
                      </button>
                    )}
                    {formState.errors.neoBrands ? (
                      <p className="text-red-700" id="emai_err_msg">
                        {formState.errors.neoBrands}
                      </p>
                    ) : null}
                  </div>

                  <div className="flex-col relative  text-[#6B6B6B] font-medium">
                    <div className="from-group">
                      <label
                        className="after:content-['*'] after:ml-0.5 after:text-red-500"
                        htmlFor="points"
                        aria-label="points"
                      >
                        {" "}
                        {t("page.referral.Points1")}
                      </label>
                      {formState.requestType === "Study" ?  <div>
                        <input
                          type="number"
                          min="0"
                          className={`w-full h-16 mt-2 pl-2 bg-[#F3F6F9AD] rounded-2xl ${
                            location.pathname.includes("/bonus") ? "disabled:text-gray-500" : ""
                          } appearance-none`}
                          id="points"
                          name="points"
                          disabled={location.pathname.includes("/bonus")}
                          aria-describedby=""
                          value = {formState.requestType === "Study" && +PointDisplay ? +PointDisplay  : ""}
                          onChange={handleChange}
                          onWheel={(e) => e.preventDefault()}
                        />
                        <span id="points_error" className="text-danger"></span>
                      </div> : <div>
                        <input
                          type="number"
                          min="0"
                          className={`w-full h-16 mt-2 pl-2 bg-[#F3F6F9AD] rounded-2xl ${
                            location.pathname.includes("/bonus") ? "disabled:text-gray-500" : ""
                          } appearance-none`}
                          id="points"
                          name="points"
                          disabled={location.pathname.includes("/bonus")}
                          aria-describedby=""
                          value = {formState.points}
                          onChange={handleChange}
                          onWheel={(e) => e.preventDefault()}
                        />
                        <span id="points_error" className="text-danger"></span>
                      </div>}
                     
                      {(formState.errors.points ? (
                        <p className="text-red-700" id="emai_err_msg">
                          {formState.errors.points}
                        </p>
                      ): null)}
                    </div>
                    <div className="mt-3 form-group">
                      <label
                        className="after:content-['*'] after:ml-0.5 after:text-red-500"
                        htmlFor="email"
                        aria-label="email"
                      >
                        {" "}
                        {t("page.referral.Email")}
                      </label>

                      <div>
                        <input
                          type="email"
                          className="w-full h-16 pl-2 mt-2  bg-[#F3F6F9AD] rounded-2xl"
                          id="email"
                          name="email"
                          value={formState.email}
                          onChange={handleChange}
                        />
                      </div>
                      {formState.errors.email ? (
                        <p className="text-red-700" id="emai_err_msg">
                          {formState.errors.email}
                        </p>
                      ) : null}
                    </div>

                    <div className="mt-3 mb-36 form-group">
                      <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                        {" "}
                        {t("page.referral.Mobile")}
                      </label>
                      <PhoneInput
                        className="w-full mt-2 pl-2 h-16 bg-[#F3F6F9AD] border-none rounded-2xl px-5   focus:border-none"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={countryCode}
                        value={formState.mobile}
                        onChange={setMobile}
                        countries={["US", "CA", "IN"]}
                      />
                      {formState.errors.mobile ? (
                        <p className="text-red-700" id="emai_err_msg">
                          {formState.errors.mobile}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className=" form-group">
                    <button
                      type="submit"
                      disabled={!showButton}
                      className="disabled: w-full h-16 bottom-0 bg-[#0b3d6f] rounded-2xl text-white"
                      title={!showButton ? "You don't have enough points" : ""}
                    >
                      {t("page.referral.Continue")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </>
  );
};

import React from "react";
import { ForgotForm } from "../../components/ForgotForm";

export function Forgot() {
  return (
    <div>
      <ForgotForm />
    </div>
  );
}

import React, { useRef, useState } from "react";
import { ReactComponent as AccountClose } from "../../assets/SideBarIcons/AccountClose.svg";
import { UpdateGender} from "./Account.api";
import { useAuth } from "context/auth-context";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toastrError, toastrSuccess } from "lib/toastr";
import { useTranslation } from "react-i18next";

interface AccountToggleProps {
  gender: string;
  isPopupOpenGender;
}
const GenderToggle: React.FC<AccountToggleProps> = ({ gender, isPopupOpenGender }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const modelRef = useRef();
  const [userGender, setUserGender] = useState(gender);
  const queryClient = useQueryClient();
  const mutation = useMutation(() => UpdateGender(userGender,auth?.panelistId), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(t("genderTogel.success"));
        queryClient.invalidateQueries(["getAccountDetailsapi"]);
      } else {
        toastrError(t("genderTogel.failed"));

      }
    },
    onError: () => {
      toastrError(t("genderTogel.terminate"));
    },
  });

  const handleSubmit = () => {
    mutation.mutate();
    isPopupOpenGender();
  };

  const CloseModal = (e) => {
    if (modelRef.current === e.target) {
      isPopupOpenGender();
    }
  };

return (
  <>
    {isPopupOpenGender && (
      <div
        className="fixed inset-0 w-full lg:top-[8vh] lg:left-[10vw] h-full flex justify-center items-center bg-black bg-opacity-50"
        ref={modelRef}
        onClick={CloseModal}
      >
        <div className="bg-white p-8 rounded-3xl shadow-md w-[268px] h-[350px] md:w-[420px] md:h-[350px] md:px-14 relative">
          <h1 className="text-center text-[16px] text-[#757575] font-medium font-poppins">
            {t("genderTogel.head")}
          </h1>
          <div className="absolute right-4 md:right-6 top-9 cursor-pointer">
            <AccountClose onClick={isPopupOpenGender} />
          </div>

          <div className="mt-7 md:mt-12 text-sm space-y-5 font-poppins font-normal text-[#413C3C]">
            <div>
              <label>{t("genderTogel.lebel.select")}</label>
              <select
                onChange={(e) => setUserGender(e.target.value)}
                className="w-full h-[40px] md:h-[50px] mt-2 pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50"
              >
                <option value="" disabled selected hidden>
                  {t("genderTogel.option.select")}
                </option>
                <option value="male">{t("genderTogel.option.male")}</option>
                <option value="female">{t("genderTogel.option.female")}</option>
                <option value="other">{t("genderTogel.option.other")}</option>
              </select>
            </div>
            <div>
              <button
                className="w-full h-[40px] md:h-[50px] mt-2 md:mt-7 pl-3 text-[#FFFFFF] bg-[#056E9C] rounded-2xl"
                onClick={handleSubmit}
              >
                {t("genderTogel.button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

};

export default GenderToggle;

import { useQuery } from "@tanstack/react-query";
import { convertQueryParamsStringToQueryParamsObject } from "../lib/url-util";
import {
  getQuestionsByProfile,
  getCurrentProfileAnswers,
  getCategoryListByProfile,
} from "../components/Myprofilepage/Myprofilepage.api";
import {
  AnswerApiResponse,
  CategoryListApiResponse,
  QuestionApiResponse,
} from "../types/question-answers";

export type CategoryQuestionsType = {
  questionOptionsList: QuestionApiResponse[];
};

export function useQuestions(panelistId: number) {
  const lang_code = window.localStorage.getItem("zamplia_user_lang_code")
    ? localStorage.getItem("zamplia_user_lang_code")
    : "En-US";
  const { data, ...rest } = useQuery<QuestionApiResponse>(
    ["questions"],
    async () => {
      return getQuestionsByProfile(
        panelistId,
        +convertQueryParamsStringToQueryParamsObject(window.location.href),
        lang_code
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { questions: data, ...rest };
}

export function useAnswers(categoryId, userId) {
  const { data, ...rest } = useQuery<AnswerApiResponse>(
    ["answers"],
    async () => {
      return getCurrentProfileAnswers(categoryId, userId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { answers: data, ...rest };
}

export function useCategoryList(panelistId: number) {
  const lang_code = window.localStorage.getItem("zamplia_user_lang_code")
    ? localStorage.getItem("zamplia_user_lang_code")
    : "En-US";
  const { data, ...rest } = useQuery<CategoryListApiResponse>(
    ["categoryList"],
    async () => {
      return getCategoryListByProfile(panelistId, lang_code);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { categoryList: data, ...rest };
}

import MySurveysTable, { ThElementsType } from "components/MySurveys/MySurveysTable";
import { useState } from "react";
import Pagination from "../Pagination/Pagination";
import { dataHandler } from "./dataHandler";

const ThColumns: ThElementsType = {
  scope: "col",
  classes: "table-1-th text-center",
  dataKeys: [
    { id: 1, column: "Study Name", label: "sid" },
    { id: 2, column: "Points", label: "points" },
    { id: 3, column: "Pid", label: "p_id" },
    { id: 4, column: "Date", label: "createdAt" },
    { id: 7, column: "Action", label: "actionFunction" },
  ],
};
const PanelistStudywiseTable = ({ studiesData, setClaimList, claimList, isLoading }: any) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // add to claim points list
  const claimAction = (survey) => {
    const isInClaimList = claimList.length
      ? claimList.some((data: any) => data._id === survey._id)
      : false;

    if (isInClaimList) {
      setClaimList((prevState) => prevState.filter((e) => e._id !== survey._id));
    } else {
      setClaimList((prevState) => [...prevState, survey]);
    }
  };
  //prepare data to enter
  const surveysSet = dataHandler({
    data: studiesData?.data,
    indexOfFirstRecord,
    indexOfLastRecord,
    actionFunction: claimAction,
    claimList,
  });

  if (isLoading) {
    return (
      <div className="w-full flex justify-center mx-auto">
        <div className="overflow-x-auto md:scale-90 sm:-mx-1 lg:-mx-0 mt-36 ">Loading...</div>
      </div>
    );
  }
  return (
    <div className="w-full">
      <div className="scale-100  md:scale-90">
        <MySurveysTable
          thElements={ThColumns}
          tdElements={surveysSet}
          searchQuery="searchQuery"
          tdDataStyle="table-1-td-col-2 text-center "
          tdRowStyle=""
          tableStyle="text-2xl"
        />
      </div>
      <div className="flex justify-center w-full">
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={Math.ceil(studiesData?.data?.length /10)}
        />
      </div>
    </div>
  );
};

export default PanelistStudywiseTable;

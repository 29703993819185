import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { RaiseQuery } from "./RaiseQueryForm.api";
import { toastrError, toastrSuccess } from "../../lib/toastr";
import { useAuth } from "context/auth-context";
import support from "../../assets/support.svg";
import upload from "../../assets/upload.svg";

export function RaiseQueryForm() {
  const { t} = useTranslation();
  const { auth } = useAuth();
  const initialState = {
    name: auth.name,
    email: auth.email,
    query: "",
    errors: {
      name: "",
      email: "",
      query: "",
      image: "",
    },
    image_upload: [],
  };
  type FormState = typeof initialState;
  type RaiseQueryPayload = Omit<FormState, "errors">;

  const [formState, setFormState] = useState<FormState>(initialState);
  const postQuery = useMutation(
    (payload: RaiseQueryPayload) => {
      return RaiseQuery({
        userId: auth.id,
        name: payload.name,
        email: payload.email,
        query: payload.query,
        image_upload: payload.image_upload,
      });
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          toastrSuccess(t("help.successmsg"));
          setFormState(initialState);
        } else {
          toastrError(response.message);
        }
      },
      onError: (err: any) => {
        const response: any = err.response;
        toastrError(response?.data.message);
      },
    }
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imagesUploaded, setImagesUploaded] = useState<string[]>([]);
  const [imageError, setImageError] = useState("");
  const [imageLimitError, setImageLimitError] = useState("");
  const [imageDuplicate, setImageDuplicate] = useState("");
  const [issubmit, setissubmit] = useState(false);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const remainingSlots = 4 - imagesUploaded.length;
    const filesToUpload = files.slice(0, remainingSlots);
    const totalImages = imagesUploaded.length + files.length;
    const uploadedImageNames = filesToUpload.map((file) => file.name);
    const duplicates = uploadedImageNames.filter((name) => imagesUploaded.includes(name));
    if (duplicates.length > 0) {
      return setImageDuplicate(t("help.DuplicateImage") + `${duplicates.join(", ")}`);
    } else {
      setImageDuplicate("");
    }
    if (totalImages > 4) {
      return setImageLimitError(t("help.ImageLimitError"));
    } else {
      setImageLimitError("");

      const imageFiles = filesToUpload.filter((file) => file.type.startsWith("image/"));
      if (imageFiles.length !== filesToUpload.length) {
        setImageError(t("help.ImageError"));
        return;
      }
      setImageError("");
      const uploadedImages = filesToUpload.map((file) => (file as File).name);
      setImagesUploaded([...imagesUploaded, ...uploadedImages]);
      setFormState((prevState) => ({
        ...prevState,
        image_upload: [...prevState.image_upload, ...filesToUpload],
      }));
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...imagesUploaded];
    newImages.splice(index, 1);
    setImagesUploaded(newImages);

    setFormState((prevState) => ({
      ...prevState,
      image_upload: prevState.image_upload.filter((_, i) => i !== index),
    }));
    if (imageDuplicate) {
      setImageDuplicate("");
    } else if (imageLimitError) {
      setImageLimitError("");
    }
  };

  const validateForm = (formValues: FormState) => {
    const errors = { name: "", email: "", query: "", image: "" };

    if (!formValues.query.length) {
      errors.query = t("home.raiseQuery.validQuery");
    } else if (!formValues.query.trim()) {
      errors.query = t("help.raise.blankSpacesNotAllowed");
    }

    setFormState((oldState) => ({
      ...oldState,
      errors,
    }));

    return !errors.query;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setissubmit(true);
    const isValid = validateForm(formState);
    if (isValid) {
      postQuery.mutate(formState);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });

    if (name === "query") {
      validateForm({ ...formState, [name]: value });
    }
  };

  useEffect(() => {
    if (issubmit) {
      validateForm(formState);
    } 
  }, [t]);

  useEffect(()=>{
    if (imageError) {
      setImageError(t("help.ImageError"));
    } else if (imageLimitError) {
      setImageLimitError(t("help.ImageLimitError"));
    } else if (imageDuplicate) {
      setImageDuplicate(t("help.DuplicateImage"));
    }
  },[t]);

  useEffect(() => {
    if (postQuery.isSuccess) {
      setImagesUploaded([]);
    }
  }, [postQuery.isSuccess]);

  return (
    <div className="w-full flex items-center justify-center lg:w-[98vw] px-2 lg:px-0 ">
      <div className=" w-full bg-[#FCFDFF] mt-24 lg:ml-[20rem] rounded-3xl shadow-sm text-[#6B6B6B] lg:w-[50%] h-full">
        <div className="font-poppins py-8 px-12 2xl:py-20">
          <div className="flex flex-col justify-center items-center space-y-2">
            <div>
              <img src={support} alt="" />
            </div>
            <div>
              <p className="text-xl font-semibold">{t("help.Support")}</p>
            </div>
            <div className="text-center">
              <p>{t("help.query")}</p>
            </div>
          </div>
          <form className="mt-10 flex flex-col space-y-6 mb-12 sm:mb-0" onSubmit={handleSubmit}>
            <div className="space-y-1">
              <label htmlFor="Name" aria-label="Name">
                {t("help.Name")}
              </label>
              <div className="">
                <input
                  type="text"
                  name="name"
                  pattern="[a-zA-Z\s]{1,50}"
                  id="name"
                  value={formState.name}
                  disabled={true}
                  className="w-full h-[50px] rounded-xl px-4 text-xs lg:text-sm bg-[#F1F6FA] opacity-[68%]"
                />
                {formState.errors.name ? (
                  <p className="text-red-700" id="emai_err_msg">
                    {formState.errors.name}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="space-y-1">
              <label htmlFor="Email" aria-label="Email">
                {t("help.Email")}
              </label>
              <div className="">
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={formState.email}
                  disabled={true}
                  className="w-full h-[50px] rounded-xl px-4 text-xs lg:text-sm bg-[#F1F6FA] opacity-[68%]"
                />
                {formState.errors.email ? (
                  <p className="text-red-700" id="emai_err_msg">
                    {formState.errors.email}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="space-y-1">
              <label className="after:content-['*'] after:ml-0.5 after:text-[#FF0000] ">
                {t("help.WriteQuery")}
              </label>
              <div className="">
                <textarea
                  name="query"
                  id="query"
                  value={formState.query}
                  onChange={handleChange}
                  className="w-full h-[150px] p-1 rounded-xl px-4 py-4 text-xs lg:text-sm bg-[#F1F6FA] opacity-[68%] "
                  placeholder={t("help.placeholder.query")}
                />
                {formState.errors.query ? (
                  <p className="text-red-700" id="emai_err_msg">
                    {formState.errors.query}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="space-y-1">
              <label className="">{t("help.UploadScreenshot")}</label>
              <div className="flex flex-col justify-center items-center w-full lg:h-[185px] md:h-[180px] h-[245px] rounded-xl px-4 py-2 text-xs lg:text-base  bg-[#F1F6FA] opacity-[68%]">
                <label htmlFor="fileInput" className="cursor-pointer">
                  <div className="flex">
                    <span className="text-[#699BF7] font-medium ">{t("help.uploadhere")}</span>
                    <span className=" ">
                      <img src={upload} alt="Upload Icon" />
                    </span>
                  </div>
                </label>
                <div className="flex lg:flex-row lg:flex-wrap lg:w-[435px] md:flex-row md:flex-wrap md:w-[510px] md:pl-[2.5rem] flex-col lg:items-start lg:justify-start md:items-start md:justify-start items-center justify-center">
                  {imageError && (
                    <span className=" text-red-700 lg:pl-[5rem] md:pl-[8.3rem]">{imageError}</span>
                  )}
                  {imagesUploaded.map((image, index) => (
                    <div key={index} className="m-1">
                      <span className="bg-gray-200 rounded-full px-2 py-1 text-sm text-gray-700 mr-1">
                        {image}
                      </span>
                      <button
                        type="button"
                        className="bg-red-500 text-white text-sm px-1 w-[1.60rem] h-[1.60rem] rounded-full"
                        onClick={() => handleRemoveImage(index)}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
                <input
                  type="file"
                  id="fileInput"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  multiple
                  accept="image/*"
                />
              </div>
              {imageLimitError && (
                <span className="text-red-700 lg:text-base md:text-base text-xs font-poppins">
                  {imageLimitError}
                </span>
              )}
              {imageDuplicate && (
                <span className="text-red-700 lg:text-base md:text-base text-xs font-poppins">
                  {imageDuplicate}
                </span>
              )}
            </div>

            <div className="text-center" data-testid="button">
              <button
                type="submit"
                className="w-[70%] h-[50px] text-white rounded-3xl bg-[#056E9C]"
                disabled={postQuery.isLoading}
              >
                {postQuery.isLoading ? t("page.Loading") : t("help.Submit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

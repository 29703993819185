import { axiosClient } from "../../api/api";
import { forgotPassLogging } from "./../UpdatePassword/UpdatePassword.api";

export const forgotUser = async (emailId: string) => {
  const requestObject = {
    emailId,
    app: "zampliasurveys",
  };
  return axiosClient
    .post("/api/survey/loopSurveyForgotPassword", requestObject)
    .then((response) => {
      forgotPassLogging(
        JSON.stringify(requestObject),
        JSON.stringify(response),
        "forgot_password_initiated"
      );
      return response.data;
    })
    .catch((error) => {
      forgotPassLogging(
        JSON.stringify(requestObject),
        JSON.stringify(error),
        "forgot_password_initiated_error"
      );
      throw error;
    });
};

import { NavLink,useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as Dashboard } from "../../../assets/SideBarIcons/Dashboard.svg";
import {ReactComponent as Profile} from "../../../assets/SideBarIcons/Profile.svg";
import {ReactComponent as Surveys} from "../../../assets/SideBarIcons/Survey.svg";
import {ReactComponent as Account} from "../../../assets/SideBarIcons/Account1.svg";
import {ReactComponent as More} from "../../../assets/SideBarIcons/More.svg";

const MobileMenu = () => {
  const mobileMenus = [
    {
      name: "Dashboard",
      NavLink: "/Dashboard",
      icon: <Dashboard />,
    },
    {
      name: "Profile",
      NavLink: "/my-profile",
      icon: <Profile />,
    },
    {
      name: "Surveys",
      icon: <Surveys />,
      NavLink:"/my-surveys",
      subMenus: [
        { name: "Best match", NavLink: "/my-surveys" },
        { name: "Short Points", NavLink: "/short-survey" },
        { name: "Most Points", NavLink: "/most-point"},
      ],
    },
    {
      name: "Account",
      icon: <Account />,
      NavLink: "/accountsection",
    },
    {
      name: "More",
      NavLink: "/more",
      icon: <More />,
      subMenus: [
        { name: "Refer a Friend", NavLink: "/my-referral" },
        { name: "Surveys History", NavLink: "/history" },
        { name: "Unsubscribe", NavLink: "/unsubscribe" },
        { name: "Help", NavLink: "/raise-query" },
        { name: "Logout", NavLink: "/logout" },
      ],
    },
  ];
  const location = useLocation();
  const isActive = (NavLink) => location.pathname === NavLink;

  return (
    <div className="block md:block lg:hidden fixed bottom-0 left-0 w-full h-[4.25rem] bg-white text-black border-t-2 border-gray-200 z-50 font-poppins">
      <ul className="flex justify-around items-center md:gap-[4rem] md:py-2 py-2">
        {mobileMenus?.map((menu, index) => (
          <li key={index}>
            <NavLink
              to={menu.NavLink}
              className={`text-sm font-normal text-center flex flex-col items-center hover:text-[#056E9C]
              ${
                        isActive(menu.NavLink)
                          ? "text-sky-700"
                          : ""
                      } `}
            >
              {menu.icon}
              <span>{menu.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

MobileMenu.propTypes = {
  openMenu: PropTypes.number,
  toggleSubMenu: PropTypes.func.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
};

export default MobileMenu;
import { useEffect } from "react";

export function useOutsideClick(ref: HTMLDivElement, onClickOut: () => void) {
  useEffect(() => {
    // eslint-disable-next-line
    const onClick = ({ target }: any) => {
      ref !== target && !ref?.contains(target) && onClickOut();
    };
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [ref, onClickOut]);
}

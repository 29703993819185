import React, { useEffect, useState } from "react";
import compelete from "../../assets/completeSurvey/completeSurvey.svg";
import smileEmoji from "../../assets/completeSurvey/completeEmoji.svg";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getsurveysPointApi } from "./surveycomplete_api";

function SuccessPopup() {
  const [data, setData] = useState({ rating: 0 });
  const [surveyPoints, setSurveyPoints] = useState<number>(0);
  const rating = data.rating;

  const getPoints = async () => {
      const params = new URLSearchParams(window.location.search);
      const sessionIdParam = params.get("session_id");

      if (sessionIdParam !== null) {
        const response = await getsurveysPointApi(sessionIdParam, rating);
        if (response && response.data && response.data[0]?.points !== undefined) {
          setSurveyPoints(response.data[0].points);
        }
      }
  };
  useEffect(() => {
    getPoints();
  }, []);
  return (
    <div>
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full">
        <div className="absolute z-40 w-full h-full bg-black opacity-30 lg:py-4"></div>
        <div className="h-[30rem] w-[19rem] md:w-[25rem] md:h-[38rem] lg:h-[40rem] lg:w-[28rem] lg:top-96 md:top-1/2 top-80 bg-white opacity-100 rounded-[35px] absolute flex flex-col items-center justify-center   left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 lg:px-6  md:px-4  px-4 ">
          <div className="flex flex-col items-center">
            <div>
              <img src={compelete} alt="" className="lg:w-44 md:w-44 w-36" />
            </div>
            <div className="flex flex-col items-center lg:gap-10 md:gap-10 gap-6">
              <div className="flex flex-col lg:gap-7 md:gap-7 gap-4 items-center">
                <div className="flex flex-col items-center gap-4">
                  <div className="flex gap-2 ">
                    <span className="font-poppins font-medium  lg:text-2xl md:text-2xl text-xl text-[#00A411]">
                      Congratulation!
                    </span>
                    <img src={smileEmoji} alt="" />
                  </div>
                  <div className="flex flex-col items-center lg:text-base md:text-base text-xs text-[#434343]">
                    <span>Though you&re leaving, our digital door </span>
                    <span>is always open</span>
                  </div>
                </div>
                <div className="flex flex-col gap-1 font-medium  font-poppins items-center text-[#056E9C]">
                  <span className="lg:text-base md:text-base text-sm">Your Reward</span>
                  <span className="lg:text-2xl md:text-2xl text-base">{surveyPoints} Points</span>
                </div>
              </div>
              <div className="flex flex-col lg:gap-5 md:gap-5 gap-3 items-center">
                <div className="font-poppins flex flex-col lg:gap-2 md:gap-2 gap-1 items-center font-normal lg:text-base md:text-base text-sm  text-[#434343]">
                  <span>Please rate your experience</span>
                  <div className="flex items-center gap-3 ">
                    {[1, 2, 3, 4, 5].map((num) => (
                      <FaStar
                        key={num}
                        className={`cursor-pointer lg:text-3xl md:text-3xl text-2xl ${
                          data.rating >= num ? "text-yellow-400" : "text-gray-300"
                        }`}
                        onClick={() => setData({ ...data, rating: num })}
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <Link to="/my-surveys">
                    <button
                      className={`w-52  h-8 lg:w-72 lg:h-14 md:w-64 md:h-12 bg-[#056E9C] lg:text-base md:text-sm text-xs text-white rounded-3xl lg:mt-8 md:mt-6 mt-4`}
                      onClick={getPoints}
                    >
                      My Surveys
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPopup;

import { useEffect, useState } from "react";
import remove from "../../assets/modal/remove.svg";
import card1 from "../../assets/modal/Frame.png";
import card2 from "../../assets/modal/Checklist-bro 1.png";
import card3 from "../../assets/modal/Gift card-bro 1.png";
import { useTranslation } from "react-i18next";

type ModalIndex = 1 | 2 | 3;

const SubscribeModal = () => {
  const { t } = useTranslation();
  const [modalDisplayIndex, setModalDisplayIndex] = useState<ModalIndex | null>(1);
  const [showModal, setShowModal] = useState(false);
  const modalDisplayed = localStorage.getItem("modalDisplayed");

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("auth_ls");

    if (!isLoggedIn) {
      setShowModal(true);
    }
  }, [modalDisplayed]);

  const handleChangeModalDisplay = (i: ModalIndex): void => {
    if (i === 1) {
      setModalDisplayIndex(2);
    } else if (i === 2) {
      setModalDisplayIndex(3);
    } else {
      setModalDisplayIndex(null);
      setShowModal(false);
      localStorage.setItem("modalDisplayed", "true");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem("modalDisplayed", "true");
  };

  return (
    <>
      {showModal && !modalDisplayed ? (
        <div className="fixed left-0 w-full h-full flex items-center  justify-center z-50 md:-mt-6  -mt-3 ">
          <div className=" lg:w-full lg:h-[96%] md:w-full md:h-[97%] w-full h-full bg-black opacity-30"></div>
          <div className="absolute top-[35%] sm:top-[30%] lg:top-[45%] h-[28rem] w-[20rem] lg:h-[35rem] sm:h-[35rem] sm:w-[28rem] bg-white opacity-100 rounded-[15px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
            <div
              className="text-xl absolute font-poppins top-[18px] right-[20px] cursor-pointer "
              onClick={closeModal}
            >
              <img src={remove} alt="remove icon" />
            </div>
            {modalDisplayIndex === 1 ? (
              <div className={`flex flex-col justify-center items-center`}>
                <div className="h-[12rem] md:h-[14rem] lg:[50vh] w-auto mt-2 lg:mt-5">
                  <img src={card1} alt="" className="object-contain object-center w-full h-full" />
                </div>
                <h2 className="text-[15px] md:text-[20px] lg:text-[24px] w-[13rem] md:w-[18rem] lg:w-[21rem] text-center font-bold font-['poppins'] mb-1 mt-3 md:mt-5">
                  {t("home.subscribeModel.welcomtoZamplia")}
                </h2>
                <p className="text-center text-[13px] md:text-[15px] lg:text-[16px] font-normal w-[13rem] md:w-[18rem] lg:w-[21rem] font-['poppins'] my-2 lg:my-4 ">
                  {t("home.subscribeModel.unleasethepowerOfZamplia")}
                </p>
                <div className="flex items-start justify-center gap-3 my-2 lg:my-4">
                  <div className="h-2 w-5 rounded-lg bg-[#056E9C]"></div>
                  <div className="h-2 w-5 rounded-lg bg-[#F0F0F0]"></div>
                  <div className="h-2 w-5 rounded-lg bg-[#F0F0F0]"></div>
                </div>
                <button
                  className="mt-2 flex w-[260px] items-center justify-center bg-gradient-to-r from-[#49A4D2] to-[#217CAA] outline-none border-transparent rounded-[14px] font-['poppins'] text-white text-[16px] lg:text-[22px] py-2 hover:from-[#1d5876] hover:to-[#217CAA]"
                  onClick={() => handleChangeModalDisplay(1)}
                >
                  {t("home.subscribeModel.Next")}
                </button>
              </div>
            ) : null}
            {modalDisplayIndex === 2 ? (
              <div className="flex flex-col items-center justify-center ">
                <div className="h-[12rem] md:h-[16rem] lg:h-[18rem] w-auto mt-1 md:mt-0 lg:mt-3">
                  <img src={card2} alt="" className="object-contain w-full h-full" />
                </div>
                <h2 className="text-[15px] md:text-[20px] w-[13rem] md:w-[18rem] lg:w-[20rem] text-center font-bold font-['poppins'] mb-1 mt-0 ">
                  {t("home.subscribeModel.createYourFirstServey")}
                </h2>
                <p className="text-center text-[13px]  md:text-[15px] font-normal w-[13rem] md:w-[18rem] lg:w-[21rem] font-['poppins'] my-2 lg:my-4 lg:mx-2">
                  {t("home.subscribeModel.ReadyToDive")}
                </p>
                <div className="flex items-start justify-center gap-3 my-2 ">
                  <div className="h-2 w-5 rounded-lg bg-[#F0F0F0]"></div>
                  <div className="h-2 w-5 rounded-lg bg-[#056E9C]"></div>
                  <div className="h-2 w-5 rounded-lg bg-[#F0F0F0]"></div>
                </div>
                <button
                  className="mt-3 flex w-[260px] items-center justify-center bg-gradient-to-r from-[#49A4D2] to-[#217CAA] outline-none border-transparent rounded-[14px] font-['poppins'] text-white text-[16px] py-2 lg:text-[22px] hover:from-[#1d5876] hover:to-[#217CAA]"
                  onClick={() => handleChangeModalDisplay(2)}
                >
                  {t("home.subscribeModel.Next")}
                </button>
              </div>
            ) : null}
            {modalDisplayIndex === 3 ? (
              <div className="flex flex-col items-center justify-center">
                <div className="h-[12rem] md:h-[16rem] lg:h-[20rem] w-auto mt-1 md:mt-0 lg:mt-2">
                  <img src={card3} alt="" className="object-contain object-center w-full h-full" />
                </div>
                <h2 className="text-[15px] md:text-[20px] w-[13rem] md:w-[18rem] lg:w-[20rem] text-center font-bold font-['poppins'] mb-1 mt-0">
                  {t("home.subscribeModel.Effortless")}
                </h2>
                <p className="text-center text-[13px]  md:text-[15px] font-normal w-[13rem] md:w-[18rem] lg:w-[21rem] font-['poppins'] my-2 lg:mt-1">
                  {t("home.subscribeModel.RedeemYourPoints")}
                </p>
                <div className="flex items-start justify-center gap-3 my-4">
                  <div className="h-2 w-5 rounded-lg bg-[#F0F0F0]"></div>
                  <div className="h-2 w-5 rounded-lg bg-[#F0F0F0]"></div>
                  <div className="h-2 w-5 rounded-lg bg-[#056E9C]"></div>
                </div>
                <button
                  className="mt-3 flex w-[260px] items-center justify-center bg-gradient-to-r from-[#49A4D2] to-[#217CAA] outline-none border-transparent rounded-[14px] font-['poppins'] text-white text-[16px] py-2 lg:text-[22px] hover:from-[#1d5876] hover:to-[#217CAA]"
                  onClick={() => handleChangeModalDisplay(3)}
                >
                  {t("home.subscribeModel.finsih")}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SubscribeModal;

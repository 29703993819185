import { axiosClient } from "../../api/api";

type formState = {
  name: string;
  email: string;
  question: string;
  captchaToken: string;
};

export const Contact = async (FormState: formState) => {
  const { name, email, question, captchaToken } = FormState;
  return axiosClient
    .post("/api/survey/contact", {
      name,
      email,
      question,
      captchaToken,
    })
    .then((response) => response.data);
};

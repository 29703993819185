import React from "react";
import { UnsubscribeForm } from "../../components/UnsubscribeForm";

export function Unsubscribe() {
  return (
    <div>
      <UnsubscribeForm />
    </div>
  );
}

import React from "react";

const SvgRightQaute = () => {
  return (
    <svg
      width="60"
      style={{ display: "inline", paddingTop: "10px", marginLeft: "19px" }}
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 15C13.6739 15 12.4021 15.5268 11.4645 16.4645C10.5268 17.4021 10 18.6739 10 20V27.5C10 30.25 12.25 32.5 15 32.5H22.5C22.5 34.4891 21.7098 36.3968 20.3033 37.8033C18.8968 39.2098 16.9891 40 15 40H12.5C11.837 40 11.2011 40.2634 10.7322 40.7322C10.2634 41.2011 10 41.837 10 42.5C10 43.163 10.2634 43.7989 10.7322 44.2678C11.2011 44.7366 11.837 45 12.5 45H15C18.3152 45 21.4946 43.683 23.8388 41.3388C26.183 38.9946 27.5 35.8152 27.5 32.5V20C27.5 18.6739 26.9732 17.4021 26.0355 16.4645C25.0979 15.5268 23.8261 15 22.5 15H15ZM37.5 15C36.1739 15 34.9021 15.5268 33.9645 16.4645C33.0268 17.4021 32.5 18.6739 32.5 20V27.5C32.5 30.25 34.75 32.5 37.5 32.5H45C45 34.4891 44.2098 36.3968 42.8033 37.8033C41.3968 39.2098 39.4891 40 37.5 40H35C34.337 40 33.7011 40.2634 33.2322 40.7322C32.7634 41.2011 32.5 41.837 32.5 42.5C32.5 43.163 32.7634 43.7989 33.2322 44.2678C33.7011 44.7366 34.337 45 35 45H37.5C40.8152 45 43.9946 43.683 46.3388 41.3388C48.683 38.9946 50 35.8152 50 32.5V20C50 18.6739 49.4732 17.4021 48.5355 16.4645C47.5979 15.5268 46.3261 15 45 15H37.5Z"
        fill="url(#paint0_linear_517_308)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_517_308"
          x1="30"
          y1="5"
          x2="-1.98682e-06"
          y2="45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#056E9C" />
          <stop offset="1" stopColor="#7BB8D2" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgRightQaute;

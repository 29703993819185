import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { History } from "components/History/History";
import { ExportToCsv } from "export-to-csv";
import { useMutation } from "@tanstack/react-query";
import { exportHistory } from "../../pages/HistoryPage/HistoryPage.api";
import { useAuth } from "context/auth-context";
import { toast } from "react-toastify";
import downld from "../../assets/Download-icon.svg";

export const Shistory = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const mutation = useMutation(() => exportHistory(auth?.panelistId));

  useEffect(() => {
    mutation.mutate();
  }, []);
  const handleClick = async (mutation) => {
    if (mutation?.status === "success" && mutation?.data?.success) {
      const responseData = mutation?.data?.data;
      if (Array.isArray(responseData) && responseData?.length > 0) {
        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: true,
          title: "My Awesome CSV",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        const csvData = responseData.map((e) => ({
          surveyName: e.surveyName,
          points: e.points,
          createdAt: e.createdAt,
          status:
            e.surveyStatus === 1 || e.surveyStatus === 2 || e.surveyStatus === 3
              ? e.surveyStatusName
              : !e.surveyStatus || e.surveyStatus === 0
              ? "Not Completed"
              : "Terminated",
        }));
        csvExporter.generateCsv(csvData);
      } else {
        toast.error(t("surveyHistory.download.errorMessage"));
      }
    }
  };
  return (
    <div className="flex items-center justify-center">
      <div className="w-full h-full ">
        <div className="w-full bg-[#FFFFFF] mt-4 rounded-3xl shadow-sm font-poppins py-8">
          <div className="flex justify-between px-3 md:px-10 lg:px-8">
            <div className="pt-2 md:pt-3">
              <p className="text-[#505050] text-xl font-medium lg:ml-4">
                {t("histortpage.surveyHistory")}
              </p>
            </div>
            <button
              data-testid="button"
              onClick={() => handleClick(mutation)}
              className="bg-[#056E9C] h-fit md:px-8 md:py-3 px-5 py-2 text-white rounded-3xl flex"
            >
              <p className="mr-2">{t("page.history.Export")}</p>
              <img className="mr-[10px]" src={downld} alt="download" />
            </button>
          </div>
          <div
            data-testid="survey"
            className="mt-6 font-poppins text-[#474747] w-full flex flex-col md:px-10"
          >
            <History />
          </div>
        </div>
      </div>
    </div>
  );
};

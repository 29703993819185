import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

type PaginationPropType = {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  currentPage: number;
};

export const Pagination = ({ setCurrentPage, totalPages, currentPage }: PaginationPropType) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  let maxVisiblePages = totalPages > 10 ? 5 : 5;
  if (isMobile) {
    maxVisiblePages = totalPages > 10 ? 2 : 2;
  }
  const visiblePages = Array.from(
    { length: Math.min(totalPages, maxVisiblePages) },
    (_, i) => i + Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1)
  ).filter((page) => page <= totalPages);

  return (
    <div className="flex justify-center space-x-1 lg:m-5 m-[0.25rem] font-poppins">
      <button
        disabled={totalPages < maxVisiblePages}
        onClick={() => setCurrentPage(1)}
        className={`px-3 py-1  rounded-full text-[#10739f] font-["Poppins"] ${
          currentPage < 4
            ? "hidden md:bg-gray-400 lg:bg-gray-400 md:text-white lg:text-white text-[#10739f]"
            : "md:bg-gray-200 lg:bg-gray-200"
        }`}
      >
        1
      </button>
      <button
        disabled={currentPage <= 1}
        onClick={() => setCurrentPage(currentPage - 1)}
        className=" px-3 py-1 font-['Poppins'] rounded-full disabled:opacity-50 flex items-center"
      >
        <FaAngleLeft />
      </button>

      {currentPage > Math.floor(maxVisiblePages / 2) + 1 && (
        <span className="px-3 py-1 rounded-md md:bg-gray-200 lg:bg-gray-200 font-['Poppins']">
          ...
        </span>
      )}
      {visiblePages.map((page) => (
        <button
          key={page}
          onClick={() => setCurrentPage(page)}
          className={`px-3 py-1 rounded-full ${
            page === currentPage
              ? " bg-[#10739f] text-white"
              : " bg-white border-[#10739f] text-[#10739f]"
          }`}
        >
          {page}
        </button>
      ))}
      {currentPage < totalPages - Math.floor(maxVisiblePages / 2) && (
        <span className="px-3 py-1 md:rounded-md lg:rounded-md md:bg-gray-200 lg:bg-gray-200 font-['Poppins']">
          ...
        </span>
      )}

      <button
        disabled={currentPage >= totalPages}
        onClick={() => setCurrentPage(currentPage + 1)}
        className="px-3 py-1 rounded-full text-[#10739f] disabled:opacity-50 flex items-center font-['Poppins']"
      >
        <FaAngleRight className="" />
      </button>
      {totalPages > 1 && (
        <button
          disabled={totalPages < maxVisiblePages}
          onClick={() => setCurrentPage(totalPages)}
          className={`px-3 py-1 rounded-full text-[#10739f] font-["Poppins"] ${
            currentPage > totalPages - 2
              ? "hidden bg-bg-gray-400 text-white  font-['Poppins']"
              : "bg-gray-200 font-['Poppins']"
          }`}
        >
          {totalPages}
        </button>
      )}
    </div>
  );
};

export default Pagination;

import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth-context";
import {
  AfterVerificatiobProcess,
  ResendOtpForClaim,
  verifyZampliaSurveys,
} from "./MyReferralPage.api";
import { useLocation, useNavigate, NavigateFunction } from "react-router-dom";
// import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay-ts";
import { Modal, Button } from "antd";

const reqDataIntialState = {
  type: "",
  id: "",
};

const reqIntialState = {
  emailOtp: +"",
  smsOtp: +"",
  id: "",
  panelListId: +"",
  points: "",
  cashout: "",
  service: "",
  errors: {
    email: "",
    mobile: "",
  },
};
type reqDataType = typeof reqDataIntialState;
type FormState = typeof reqIntialState;
type ClaimDataType = typeof reqIntialState;

export const OtpRewarddata = () => {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [data, setData] = useState<reqDataType>(reqDataIntialState);
  const [formState, setFormState] = useState<FormState>(reqIntialState);
  const [updateActiveStatus, setUpdateActiveStatus] = useState(false);

  // useMutation for hitting api to debit points in claim history table when bonus is claimed
  const runAfterVerificationQuery = useMutation(
    ["afterVerification"],
    () => {
      return AfterVerificatiobProcess(auth.panelistId);
    },
    {
      onSuccess: (data: { success: boolean; message: string }) => {
        if (data.success) {
          navigate("/my-referral", { replace: true });
        }
      },
      onError: (error: Error) => {
        // Log the error or display a user-friendly message
        alert("An error occurred: " + error.message);
      },
    }
  );

  const VerifyOtp = useMutation(
    ["verifyotp"],
    () =>
      verifyZampliaSurveys(
        formState.emailOtp,
        formState.smsOtp,
        formState.id,
        formState.panelListId,
        formState.points,
        formState.cashout,
        formState.service
      ),
    {
      onSuccess(response: any) {
        setData(reqDataIntialState);
        if (response.success && location.state.service !== "Bonus") {
          Modal.success({
            title: "Success!",
            content: response.message,
            onOk() {
              navigate("/otp", {});
              if (location.state.service === "Referral Wallet") {
                navigate("/my-referral");
              } else if (location.state.service === "Streak Wallet") {
                navigate("/Dashboard");
              } else if (location.state.service === "Study") {
                navigate("/Dashboard");
              } else {
                navigate("/");
              }
            },
          });
        } else if (response.success && location.state.service === "Bonus") {
          Modal.success({
            title: "Success!",
            content: response.message,
            onOk() {
              setUpdateActiveStatus(true);
            },
          });
        } else {
          setData(reqDataIntialState);
          Modal.error({
            title: "Encountered an error!",
            content: response.message,
          });
        }
      },
      onError(error: any) {
        if (error && error.response?.data) {
          Modal.error({
            title: "Encountered an error!",
            content: error.response.data.message,
          });
        }
      },
    }
  );
  const ResendOtp = useMutation(["otp"], () => ResendOtpForClaim(data.type, data.id), {
    onSuccess(response: any) {
      setData(reqDataIntialState);
      if (response.success) {
        Modal.success({
          title: "Success!",
          content: response.message,
        });
      } else {
        Modal.error({
          title: "Encountered an error!",
          content: "Something went wrong",
        });
      }
    },
    onError(error: any) {
      setData(reqDataIntialState);
      if (error && error.response?.data) {
        Modal.error({
          title: "Encountered an error!",
          content: error.response.data.message,
        });
      }
    },
  });

  function mobileOtp() {
    setData({
      type: "mobile",
      id: location.state.requestId,
    });
  }

  function emailOtp() {
    setData({
      type: "email",
      id: location.state.requestId,
    });
  }

  //to check request id is exist or not
  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (data.type && data.type !== "") {
      ResendOtp.mutate();
    }

    if (updateActiveStatus) {
      runAfterVerificationQuery.mutate();
    }
  }, [data, updateActiveStatus]);

  const validation = (formValues: FormState) => {
    setFormState((old: ClaimDataType) => {
      return {
        ...old,
        errors: { ...old.errors, email: "", mobile: "" },
      };
    });

    if (!formValues.emailOtp) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, email: t("page.referral.mandatory") } };
      });
      return false;
    }

    if (!formValues.smsOtp) {
      setFormState((old: ClaimDataType) => {
        return { ...old, errors: { ...old.errors, mobile: t("page.referral.fill") } };
      });
      return false;
    }
    return true;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLSelectElement> = (e) => {
    e.preventDefault();
    const valid = validation(formState);
    if (valid) {
      VerifyOtp.mutate();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
      id: location.state.requestId,
      panelListId: auth.panelistId,
      service: location.state.service,
      cashout:
        location.state.service === "Referral Wallet"
          ? "zampliasurveys referral cashout"
          : "zampliasurveys cashout",
      points: location.state.points,
    });
  };

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={VerifyOtp.isLoading || ResendOtp.isLoading}
      spinner
      text="Loading..."
    >
      <div className="flex flex-row justify-center w-full p-6 font-sans pt-28 lg:ml-[25rem] md:ml-0 ml-0">
        <div className="flex flex-col w-full m-5 overflow-hidden bg-white rounded-lg shadow-lg sm:w-80">
          <p className="mx-5 mt-10 mb-5 text-xl font-bold border-b-4 border-sky-900">
            {" "}
            {t("page.referral.Reward")}
          </p>
          <form
            method="post"
            id="claimRewardFirstStep"
            className="mx-5 mb-5 text-sm"
            onSubmit={handleSubmit}
          >
            <div className="max-w-xl mx-auto">
              <div className="mt-3 form-group">
                <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                  {" "}
                  {t("page.referral.emailotp")}
                </label>
                <input
                  type="number"
                  className="w-full mt-3 rounded-sm form-control outline outline-offset-2 outline-1 outline-black-100 md:outline"
                  id="emailOtp"
                  name="emailOtp"
                  value={formState.emailOtp}
                  onChange={handleChange}
                />
              </div>
              {formState.errors.email ? (
                <p className="text-red-700" id="emai_err_msg">
                  {formState.errors.email}
                </p>
              ) : null}
              <p className="w-full mt-2 cursor-pointer lg:w-1/3 md:w-2/3 text-sky-600">
                <a data-testid="forgetpasswordlink" onClick={() => emailOtp()}>
                  {t("form.login.otp")}
                </a>
              </p>
              <div className="mt-3 form-group">
                <label className="after:content-['*'] after:ml-0.5 after:text-red-500">
                  {" "}
                  {t("page.referral.Mobileotp")}
                </label>
                <input
                  type="number"
                  className="w-full mt-2 rounded-sm form-control outline outline-offset-2 outline-1 outline-black-100 md:outline"
                  id="smsOtp"
                  name="smsOtp"
                  value={formState.smsOtp}
                  onChange={handleChange}
                />
              </div>
              {formState.errors.mobile ? (
                <p className="text-red-700" id="emai_err_msg">
                  {formState.errors.mobile}
                </p>
              ) : null}
              <p className="w-full mt-2 cursor-pointer lg:w-1/3 md:w-2/3 text-sky-600 hover: text-sky-900">
                <a onClick={() => mobileOtp()}>{t("form.login.otp")}</a>
              </p>
              <div className="mt-10 form-group">
                <Button htmlType="submit" type="primary">
                  {t("page.referral.claim")}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};
// OtpRewarddata.propTypes = {
//   id: PropTypes.string,
// };

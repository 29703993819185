import { useAuth } from "context/auth-context";
import { removeLoginCookie } from "../../components/LoginForm/LoginFormDao";
import { useNavigate, Link } from "react-router-dom";
import loginImg from "../../assets/img-logout.jpg";
import { useTranslation } from "react-i18next";

export const Logout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleLogout = () => {
    removeLoginCookie("setLoginCookie");
    localStorage.removeItem("selectedPoints");
    setAuth(null);
    navigate("/");
  };

  return (
    <div className="h-[100vh] overflow-hidden">
      <div className="mx-auto md:mt-10 rounded flex flex-col items-center gap-2">
        <div className="w-[300px] md:w-[360px] lg:w-[400px] rounded-3xl  bg-white mt-20 border border-gray-200">
          <img
            src={loginImg}
            alt="Logout"
            className="mt-3 lg:animate-bounce-logout h-[120px] lg:mt-5  md:h-[190px] lg:h-[210px] rounded-md object-contain w-full"
          />
          <form>
            <div className="mb-4 mt-2 text-center">
              <h1 className="font-serif text-2xl text-[#067ba9]">{t("logout.ComeBack")}</h1>
              <p className="text-gray-500 text-sm">{t("logout.para")}</p>
            </div>
            <div className="flex flex-row gap-2 pb-7 justify-center">
              <button
                onClick={() => navigate("/dashboard")}
                className="border px-7 py- border-gray-200  lg:px-12 lg:py-4 rounded-md"
              >
                {t("logout.btntext1")}
              </button>
              <Link
                to="/"
                onClick={handleLogout}
                className="border px-7 rounded-md border-gray lg:px-12 pt-4 pb-4 bg-[#067ba9] hover:bg-[#067ba9d2] hover:text-sky-100 text-white"
              >
                {t("logout.btntext2")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import { ReactComponent as ChatIcon } from "../../../assets/SideBarIcons/Chatbot.svg";
import { ReactComponent as Chatsend } from "../../../assets/SideBarIcons/Chatsend.svg";
import PropTypes from "prop-types";

import { useAuth } from "context/auth-context";

const ChatInterface = ({ updateMessages, messages, setShowChat }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const chatAreaRef = useRef(null);
  const { auth } = useAuth();
  useEffect(() => {
    const chatArea = chatAreaRef.current;
    chatArea.scrollTop = chatArea.scrollHeight;
  }, [messages]);

  const sendMessage = async () => {
    try {
      setLoading(true);
      const trimmedInputMessage = inputMessage.trim();
      if (!trimmedInputMessage) {
        return;
      }
      const userInputMessage = { user: "user", text: trimmedInputMessage };
      updateMessages((prevMessages) => [...prevMessages, userInputMessage]);

      const response = await fetch(
        "https://4e77-20-104-50-32.ngrok-free.app/webhooks/rest/webhook",
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sender: auth ? auth.panelistId.toString() : "Nopanalist",
            message: trimmedInputMessage,
          }),
        }
      );
      const responseData = await response.json();

      updateMessages((prevMessages) => [...prevMessages, ...responseData]);
      setInputMessage("");
    } catch (error) {
      // console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <>
      <div className="fixed lg:z-40 md:z-40 mobilez lg:left-[71vw] md:left-[12rem] top-1 lg:top-[5.1rem] md:top-[5.5rem]">
        <div className="h-[98vh] w-[100vw] md:w-[30rem] md:h-[40rem] lg:h-[76vh] lg:w-[26vw] shadow-2xl bg-[#f6fafd] opacity-100 rounded-3xl absolute flex flex-col items-center">
          <nav className="w-[100vw] lg:w-[26vw] md:w-[30rem] h-[6rem] bg-[#2194c6] rounded-lg flex items-center justify-between px-4 rounded-t-3xl">
            <div className="flex items-center justify-center gap-4 font-poppins">
              <button
                className=" text-white absolute right-4 top-[0.8rem] text-4xl focus:outline-none"
                onClick={() => setShowChat()}
              >
                x
              </button>
              <span className="bg-white rounded-full lg:w-[4.3rem] lg:h-[4rem] md:w-[4.3rem] md:h-[4rem] w-[3.2rem] h-[3rem] items-center justify-center flex">
                <ChatIcon />
              </span>
              <div className="flex flex-col">
                <span className="text-lg font-medium text-white font-poppins lg:text-lg md:text-lg">
                  {" "}
                  Vera Virtual Assistant{" "}
                </span>
                <div className="h-8">
                  <span className="inline-block w-3 h-3 text-sm bg-green-500 rounded-full"></span>
                  <span className="text-sm font-normal text-white font-poppins"> Online</span>
                </div>
              </div>
            </div>
          </nav>
          <div className="w-full overflow-y-auto">
            <div
              ref={chatAreaRef}
              className="flex-1 px-2 py-4 lg:max-h-[52vh] md:max-h-[28rem] max-h-[74.5vh] overflow-y-auto custom-scrollbar"
            >
              <div className="px-1 lg:px-3 md:px-3 ">
                <div className="mb-4">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`mb-2 p-2 whitespace-pre-wrap text-sm font-poppins ${
                        msg.user === "user"
                          ? "bg-[#d2e5ee] lg:w-[12rem] lg:h-[3rem] md:w-[20vw] w-[45vw] rounded-full flex items-center pl-[1.2rem]"
                          : ""
                      }`}
                    >
                      {msg.user === "user" ? (
                        msg.text
                      ) : (
                        <div className="flex gap-1 lg:gap-5 md:gap-5">
                          <div className="lg:w-[2.5rem] lg:h-[2rem] md:w-[5rem] md:h-[2rem] w-[6rem] h-[1.8rem] flex items-center justify-center bg-white rounded-full">
                            <ChatIcon className="w-6 h-6" />
                          </div>
                          <span className="bg-[#ffffff] rounded-xl text-sm lg:w-[48rem] md:w-[48rem] w-[50rem] flex items-center pl-[0.70rem] pt-1 pb-2">
                            {msg.text}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {loading && (
                  <div className="flex items-start justify-start w-24 h-5 p-5">
                    <div className="flex space-x-2 animate-pulse">
                      <div className="w-1 h-1 bg-black rounded-full"></div>
                      <div className="w-1 h-1 bg-black rounded-full"></div>
                      <div className="w-1 h-1 bg-black rounded-full"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 z-50 mb-2 lg:mb-4 md:mb-4 ">
            <div className="relative">
              <input
                className="lg:w-[20vw] md:w-[25rem] w-[20rem] px-4 py-4 pr-12 rounded-full border bg-[#ffffff]  border-gray-300 font-poppins focus:outline-none"
                type="text"
                placeholder="Write a Question"
                value={inputMessage}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <div className="absolute top-0 right-0 flex items-center h-full pr-3">
                <Chatsend
                  className="w-[2rem] h-[2rem] lg:text-lg text-white cursor-pointer"
                  onClick={sendMessage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
ChatInterface.propTypes = {
  updateMessages: PropTypes.func.isRequired,
  setShowChat: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
};
export default ChatInterface;

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = (props: { val: number }) => {
  const { val } = props;
  return (
    <div className="  ">
      <CircularProgressbar
        value={val}
        text={`${val}%`}
        strokeWidth={20}
        styles={{
          root: {},
          path: {
            stroke: `rgba(20, 184, 36, ${val / 100})`,
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 0.5s ease 0s",
            transform: "rotate(0.25turn)",
            transformOrigin: "center center",
          },
          trail: {
            strokeLinecap: "butt",
            transform: "rotate(0.25turn)",
            transformOrigin: "center center",
            stroke: "#A3BCA014",
          },
          text: {
            fill: "#404040",
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "10px",
          },
          background: {
            fill: "#3e98c7",
          },
        }}
      />
    </div>
  );
};

export default ProgressBar;

import React, { FC } from "react";

export interface ICard {
  Redeem: any;
  SelectPoints: any;
  points?: any;
  customClass?: any;
  payment?: any;
  pointClass?: any;
  paymentClass?: any;
  RedeemClass?: any;
  FromPoints?: any;
  selectPointClass?: any;
  payClass?: any;
  onMouseEnter: () => void; 
  onMouseLeave: () => void;
  onClickSelectPoints: () => void;
  disabled: boolean
}

export const BonusCard: FC<ICard> = ({
  Redeem,
  SelectPoints,
  points,
  payment,
  customClass,
  pointClass,
  paymentClass,
  RedeemClass,
  FromPoints,
  selectPointClass,
  payClass,
  onClickSelectPoints,
  disabled,
  onMouseEnter,
    onMouseLeave
}) => {
  return (
    <div   onMouseEnter={onMouseEnter}
  onMouseLeave={onMouseLeave}
      className={` transition duration-200 ease-in-out transform hover:scale-105  w-[125px] h-[180px] lg:w-[213.56px]  lg:h-[301.79px] top-[500.61px] md:h-[260px] md:w-[180px] left-[523.28px] font-medium flex items-center justify-center   max-w-sm p-4  rounded-[22px] sm:p-6 ${customClass}` }
    >
      <div className="items-center justify-center ">
        <div
          className={`w-[95px] h-[20px] md:w-[110px] md:h-[25px] lg:w-[125px] lg:h-[35px] ${paymentClass} flex item-center justify-center   items-center text-center lg:text-sm md:-sm text-xs  rounded-3xl ] lg:ml-3 md:ml-2 lg:mb-5 md:mb-5 mb-2 font-poppins `}
        >
          <div className={` ${payClass}`}>{payment}</div>
        </div>
        <div className="flex flex-col items-center  ml-[6px]">
          <div
            className={` w-[60px] h-[60px] md:w-[84px] md:h-[84px] lg:w-[96px] lg:h-[96px]  ${pointClass} text-center font-poppins rounded-full flex items-center justify-center lg:text-4xl md:text-4xl text-2xl `}
          >
            <div className="mb-4 font-semibold font-poppins">{points}</div>
            <div className="absolute mt-6 text-sm font-normal text-center lg:mt-10 md:mt-10 ">
              Points
            </div>
          </div>
        </div>
        <div
          className={` text-xs md:text-xs lg:text-[15px] mb-4 font-poppins w-5 items-center justify-center font-medium lg:mt-3 md:mt-3  ${RedeemClass}`}
        >
          <div className="">
            <div className="ml-6 lg:ml-11 md:ml-9">{Redeem}</div>
            <div className="absolute lg:ml-4 md:ml-4 ">{FromPoints}</div>
          </div>
        </div>
        <div
          className={`w-[95px] h-[24px] md:w-[120px] md:h-[30px] lg:w-[125px] lg:h-[35px]  ${selectPointClass}    items-center text-center   rounded-3xl justify-center lg:mb-4 lg:mt-11 lg:ml-3 md:mt-8 mt-7 border-spacing-[0.82px]  cursor-pointer `}
        >
          <button
            onClick={onClickSelectPoints}
             disabled={disabled} 
            className="flex items-center justify-center mt-1 ml-2 text-xs text-center cursor-pointer lg:mt-1 md:mt-1 lg:ml-3 md:ml-2 font-poppins md:font-medium lg:text-base md:text-base lg:font-medium"
          >
            {SelectPoints}
          </button>
        </div>
      </div>
    </div>
  );
};


import { useState } from 'react';
import { ReactComponent as ChatIcon } from '../../../assets/SideBarIcons/chatbot1.svg';
import { IoIosArrowDown } from "react-icons/io";
import ChatInterface from './ChatInterface';

function ChatToggle() {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);


  const toggleChat = (event) => {
    event.preventDefault();
    setShowChat(prevState => !prevState);
  };

  const updateMessages = (newMessages) => {
    setMessages(newMessages);
  };

  return (
    <>
      <div className=''>
        {showChat && (
          <ChatInterface messages={messages} updateMessages={updateMessages} setShowChat={setShowChat} />
        )}
        <div className="fixed z-50 lg:bottom-[0.75rem] md:bottom-[6rem] bottom-[5.8rem] lg:right-[2.2rem] md:right-5 right-2">
          <div className="lg:w-[4.1vw] lg:h-[8.6vh] md:w-[5rem] md:h-[5rem] w-[3rem] h-[3rem] bg-[#5a38ae] rounded-full flex items-center justify-center cursor-pointer" onClick={toggleChat}>
            {showChat ?
              <IoIosArrowDown className="text-white w-[4vw] h-[4vh] rounded-full" />
              : <ChatIcon />}
          </div>
        </div>
      </div>
    </>
  );
}
export default ChatToggle;

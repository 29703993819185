import React, { useEffect, useState } from "react";
import loginImg from "../../assets/Login/LoginImg.svg";
// import goggle from "../../assets/Login/google.svg";
// import facebook from "../../assets/Login/facebook.svg";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { loginUser } from "./LoginForm.api";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { AuthData, useAuth } from "context/auth-context";
import { Modal } from "antd";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import LoadingOverlay from "react-loading-overlay-ts";
import { Link } from "react-router-dom";
import { LoginWithGoogle } from "./LoginwithGoogle";
const { info } = Modal;

const initialState = {
  email: "",
  password: "",
  errors: {
    email: "",
    password: "",
  },
};

type FormState = typeof initialState;
type LoginPayload = Omit<FormState, "errors">;
type ErrorBody = { status: number; message: string };
export const LoginForm = () => {
  const { setAuth } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const [formState, setFormState] = useState<FormState>(initialState);

  const virtualWalletConfirmation: any = (location: any) => {
    info({
      title: "Alert",
      icon: <ExclamationCircleFilled />,
      content: t("virtualWallet_confirm.message"),
      onOk() {
        navigate(location.state || "/dashboard", { state: null });
      },
    });
  };

  const authMutation = useMutation(
    (payload: LoginPayload) => {
      return loginUser(payload.email, payload.password);
    },
    {
      onSuccess: (response: AuthData) => {
        if (response.isFlagVirtualWallet) {
          toastrSuccess(t("form.login.successful"));
          virtualWalletConfirmation(location);
          setAuth(response);
        } else {
          toastrSuccess(t("form.login.successful"));
          setAuth(response);
          navigate(location.state || "/dashboard", { state: null });
        }
      },
      onError: (err: AxiosError) => {
        const repsonse = err.response as AxiosResponse<ErrorBody, Record<string, unknown>>;
        toastrError(repsonse?.data.message);
      },
    }
  );

  const validation = (formValues: FormState) => {
    setFormState((old) => {
      return { ...old, errors: { ...old.errors, email: "", password: "" } };
    });
    // email validation patterns
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!(formValues.email && regexEmail.test(formValues.email))) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, email: t("form.login.emails") } };
      });
      return false;
    }

    //password validation
    if (!(formValues.password.length && formValues.password.length > 4)) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, password: t("form.login.Password") } };
      });
      return false;
    }
    return true;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const valid = validation(formState);
    if (valid) {
      authMutation.mutate({ email: formState.email, password: formState.password });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value.trim() });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={authMutation.isLoading}
        spinner
        text="Loading..."
      >
        <div className=" flex justify-center items-center ">
          <div className=" bg-white mt-[6rem] rounded-2xl flex w-[350px] h-[550px]  md:w-[800px] md:h-[600px] lg:w-[1000px] font-inter  ">
            {/* first component */}
            <div className="  ">
              <div className=" hidden  md:block  lg:block md:ml-10  py-6">
                <div className="text-[rgb(5,110,156)] font-poppins lg:text-2xl md:text-base font-semibold  ">
                  <div className="lg:h-[2rem] lg:w-[29rem]  md:h-[1.50rem] md:w-[15.50rem] flex items-center mt-6 ">
                    Start earning cash rewards for
                  </div>
                  <div className="lg:h-[2rem] lg:w-[29rem]   md:h-[1.50rem] md:w-[15rem] flex items-center ">
                    {" "}
                    your opinions!{" "}
                  </div>
                </div>
                <div className=" flex flex-col gap-3 lg:mt-8 md:mt-4">
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-2 md:w-2 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[10px] md:h-[8px] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Get Paid with a Visa Card!</div>
                  </div>
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-2 md:w-2 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[10px] md:h-[8px] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Take Surveys Anytime You&apos;d Like</div>
                  </div>
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-2 md:w-2 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[10px] md:h-[8px] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Contribute to New Products & Services</div>
                  </div>
                  <div className="flex items-center gap-2 font-poppins font-normal lg:text-base md:text-xs">
                    <div className="rounded-full lg:h-5 lg:w-5 md:h-2 md:w-2 bg-[#056E9C24] flex items-center justify-center">
                      <div className="lg:w-[0.88rem] lg:h-[0.88rem]  md:w-[10px] md:h-[8px] rounded-full bg-[#056E9C]"></div>
                    </div>
                    <div>Your Answers Are Confidential!</div>
                  </div>
                </div>
                <div className="lg:mt-10 md:mt-16 lg:w-[27rem] md:w-[19rem]">
                  <img src={loginImg} alt="loginImg" className="  lg:w-[400px] h-[250px]" />
                </div>
              </div>
            </div>
            {/* secound componnet */}

            <div className="  ml-3 mt-8 flex flex-col md:ml-20  ">
              <div className="flex  flex-col space-y-3 mb-8  ">
                <p className=" text-[#777575] font-medium ">Welcome Back,</p>
                <h1 className=" text-[#666666] font-semibold  text-3xl ">Login!</h1>
              </div>

              <div className=" flex flex-col  px-1 ">
                <form className="max-w-xl mx-auto" onSubmit={handleSubmit}>
                  <div className="overflow-hidden sm:rounded-sm">
                    <div data-testid="email" className=" col-span-30">
                      <label
                        htmlFor="email"
                        aria-label="email"
                        className="inline-block after:content-['*'] after:ml-0.5 after:text-red-500  text-sm font-medium text-[#777575]"
                      >
                        {t("form.login.email")}
                      </label>
                      <input
                        type="text"
                        value={formState.email}
                        onChange={handleChange}
                        placeholder="zampliasurveys@gmail.com"
                        name="email"
                        id="email"
                        className="  xs-w-[90%]  w-full p-3 mt-1 border border-gray-300   rounded-xl  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm  "
                      />
                      {formState.errors.email ? (
                        <p className="text-red-700" id="emai_err_msg">
                          {formState.errors.email}
                        </p>
                      ) : null}
                    </div>
                    <div data-testid="password" className="mt-4 col-span-30">
                      <label
                        htmlFor="password"
                        aria-label="password"
                        className="inline-block after:content-['*'] after:ml-0.5 after:text-red-500  text-sm font-medium text-[#777575]"
                      >
                        {t("form.login.password")}
                      </label>
                      <div className=" text-2xl relative text-[#10739f]">
                        {open === false ? (
                          <AiFillEyeInvisible
                            className="absolute block mt-5 text-center right-5"
                            onClick={toggle}
                          />
                        ) : (
                          <AiFillEye
                            className="absolute block mt-5 text-center right-5"
                            onClick={toggle}
                          />
                        )}
                      </div>
                      <input
                        type={open === true ? "text" : "password"}
                        name="password"
                        id="password"
                        min={4}
                        value={formState.password}
                        placeholder="please enter password"
                        onChange={handleChange}
                        className="w-full p-3 mt-2 border border-gray-300 rounded-xl  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {formState.errors.password ? (
                        <p className="text-red-700" id="pass_err_msg">
                          {formState.errors.password}
                        </p>
                      ) : null}
                    </div>
                    <p className="after:content-['?'] after:ml-0.5 after:text-sky-600 w-fulltext-sky-600 mt-2 text-end mb-3 text-[#056E9C] font-medium">
                      <a data-testid="forgetpasswordlink" href="/forgot">
                        {t("form.login.forget")}
                      </a>
                    </p>

                    <label
                      htmlFor="submit-btn"
                      className="flex justify-center items-center w-[330px] h-[56px] text-white bg-[#056E9C] rounded-[13px] cursor-pointer"
                    >
                      <button id="submit-btn" type="submit" className="font-poppins">
                        {t("form.login.LogIn")}
                      </button>
                    </label>
                  </div>
                </form>

                <div className="  mb-6">
                  <LoginWithGoogle />
                </div>

                {/* Checkbox */}

                {/* <div className=" flex space-x-2  ">
                  <div className="flex h-6 ">
                    <input
                      id="offers"
                      name="offers"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <p className=" text-xs">Remember for 30 days</p>
                  <div className=" flex justify-evenly">
                    <p className=" text-end text-xs  ml-9 text-[#056E9C] ">Forgot Password?</p>
                  </div>
                </div> */}

                {/* signup with login facebook */}

                {/* <div className="flex justify-center space-x-4 ">
                  <div className=" flex   w-28 h-10 border-2 border-zinc-100 border-solid justify-center items-center rounded-lg">
                    <img src={goggle} alt="google" className=" w-8 h-6" />
                    <span>Google</span>
                  </div>
                  <div className=" flex  w-28 h-10 border-2 border-zinc-100 border-solid items-center rounded-lg">
                    <img src={facebook} alt="facebook" className=" w-8 h-6" />
                    <span>Facebook</span>
                  </div>
                </div> */}
                <div className=" flex justify-start items-center mb-3 md:mt-5">
                  <p>
                    {" "}
                    Do not have account?{" "}
                    <span className=" text-[#056E9C] font-semibold">
                      <Link to={"/join-now"}>Sign Up</Link>{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getReferral, getRedeemRequestHistoryData } from "./MyReferral.api";
import { useAuth } from "context/auth-context";
import { WalletCard } from "components/Dashboard/WalletCard";
import NavCard from "components/Dashboard/NavCard";
import groupsvg from "../../assets/dashboard/Group.svg";
import groupsvg2 from "../../assets/dashboard/group2.svg";
import alertSvg from "../../assets/MyReferral/alert.svg";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay-ts";
import { ReferalWalletBalance, getPointBalanceStudiesWiseApi } from "../Dashboard/Dasboard.api";
import { To, useNavigate } from "react-router-dom";
import { MyReferralTable } from "./MyReferralTable";

export const MyReferral = () => {
  const { auth } = useAuth();
  const host = window.location.protocol + "//" + window.location.host;
  const divRef = React.useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const [currentPage] = useState<number>(1);
  const getReferralData = useQuery(["referral"], () => getReferral(auth.panelistId, currentPage), {
    refetchOnWindowFocus: false,
  });
  const navigate = useNavigate();
  const navigationto = (url: To) => {
    navigate(url);
  };
  const getPointsBalance = useQuery(
    ["pointBal", auth.panelistId],
    () => getPointBalanceStudiesWiseApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getReferalWalletBalance = useQuery(
    ["referalBal", auth.panelistId],
    () => ReferalWalletBalance(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const pointBalance =
    getPointsBalance && getPointsBalance?.data ? getPointsBalance?.data?.data : 0;
  const referalBal =
    getReferalWalletBalance && getReferalWalletBalance?.data
      ? getReferalWalletBalance?.data?.Data
      : 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function copyFunction(e: any) {
    if (window.navigator.clipboard) {
      const copyText = divRef.current;
      navigator.clipboard.writeText(copyText.value).then(() => {
        alert(`Copied the text : ${host}/join-now?referralBy=` + auth.email);
      });
    }
  }
  const getReferralvirtualwallet = useQuery(
    ["virtual"],
    () => getRedeemRequestHistoryData(auth.panelistId, currentPage),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    getReferralData.refetch();
  }, [currentPage]);

  useEffect(() => {
    getReferralvirtualwallet.refetch();
  }, [currentPage]);

  return (
    <>
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={getReferralData.isLoading}
        spinner
        text="Loading..."
      >
        <div className="flex flex-row items-center justify-center px-4 scroll-smooth lg:w-[98vw]">
          <div className="mt-24 lg:pl-[16rem] w-full ">
            <div className="">
              <NavCard />
            </div>
            <div className="flex flex-col w-full mt-4 lg:flex-row">
              {/* Wallet box */}
              <div className="bg-white lg:w-[50%] items-center justify-center w-full lg:h-[320px] rounded-[21px]">
                <div className="flex flex-col items-center justify-center gap-4 2xl:gap-8 mt-4 bg-white lg:flex-row md:flex-col lg:h-[280px] md:h-[550px] h-[500px]">
                  <WalletCard
                    customClass="bg-[#EAF6FE] "
                    pointClass=" bg-[#92C8E0] border-spacing-[7px]"
                    groupsvg={groupsvg}
                    selectPointClass=" hover:bg-sky-700 hover:bg-opacity-5"
                    circleClass="bg-[#056E9C]"
                    points={+pointBalance ? +pointBalance : 0}
                    svgClass="ml-2 mt-2 "
                    pointBalance={t("dashboard.point.Balance")}
                    BalanceClass
                    pointBalanceClass=""
                    redeem={t("referFriend.Redeem Now")}
                    onselect={() => navigationto("/survey-redeem")}
                  />
                  <WalletCard
                    customClass="bg-[#FF9C0014] "
                    pointClass=" bg-[#FFD188] border-spacing-[7px]  "
                    groupsvg={groupsvg2}
                    selectPointClass=" hover:bg-sky-700 hover:bg-opacity-5"
                    circleClass="bg-[#FF9C00]"
                    points={+referalBal ? +referalBal : 0}
                    pointBalance={t("dashboard.referral.Balance")}
                    BalanceClass
                    svgClass="ml-2 mt-2"
                    pointBalanceClass=""
                    redeem={t("referFriend.Redeem Now")}
                    onselect={() => navigationto("/redeem/form/referral")}
                  />
                </div>
              </div>
              {/*Referral link box */}
              <div className="flex flex-col lg:w-[50%] lg:ml-4 sm:pb-0 pt-2  justify-center items-center lg:items-start bg-white lg:flex-row md:flex-col h-[420px] md:h-[350px] mt-4 lg:mt-0 lg:h-[321px] rounded-[21px]">
                <div className="px-4 pt-6">
                  <div className="mb-4 text-lg font-poppins ">
                    <p>{t("referFriend.Referral Link")}</p>
                  </div>
                  <div className="text-sm font-poppins ">
                    <p>{t("referFriend.Spread the word and earn rewards")}</p>
                  </div>
                  <div className="font-poppins mt-1 text-sm text-[#737373] ">
                    <p>
                      {t("referFriend.Get")}
                      <span className="text-black ">
                        50 {t("referFriend.Points")}
                        {t("referFriend.for every friend you refer to Zamplia Surveys")}
                      </span>
                    </p>
                  </div>
                  <div className="mt-5 font-poppins flex justify-between ">
                    <input
                      data-testid="copyFun"
                      type="text"
                      id="myInput"
                      ref={divRef}
                      name="myInput"
                      value={`${host}/join-now?referralBy=` + auth.email}
                      className="bg-[#F9F9F9] w-[80%] text-sm text-[#737373] md:w-[70%] lg:w-[86%] py-4 px-3 rounded-xl"
                    />
                    <button
                      className="bg-[#00A411] md:px-8  px-2 rounded-xl lg:py-3 ml-2"
                      onClick={(e: any) => copyFunction(e)}
                    >
                      {t("referFriend.copy")}
                    </button>
                  </div>
                  <div className="flex bg-[#00A41105] lg:mt-4 mt-4 h-fit w-fit text-justify ">
                    <img src={alertSvg} alt="" className="w-[32px] h-[32px] ml-1"></img>
                    <p className="ml-2 text-sm font-poppins ">
                      {t("referFriend.Share your unique referral")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <MyReferralTable />
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

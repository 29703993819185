import React, { useState, useEffect } from "react";
import { useAuth } from "context/auth-context";
import { useAnswers, useQuestions, useCategoryList } from "hooks/question-answers-hook";
import { useMutation } from "@tanstack/react-query";
import { saveAnswers } from "./../Myprofilepage/Myprofilepage.api";
import { TextBox, Multiselect, Dropdown, Radio } from "./Inputs";
import $ from "jquery";

import { toast } from "react-toastify";

function MyProfileQuestions() {
  const [questionOpionsList, setQuestionOpionsList] = useState([]);
  const [currentCategory] = useState(window.location.href.split("=")[1]);
  const [currentCategoryName, setCurrentCategoryName] = useState("");
  const { auth } = useAuth();
  const { answers } = useAnswers(window.location.href.split("=")[1], auth?.panelistId);
  const { categoryList } = useCategoryList(auth?.panelistId);
  const { questions } = useQuestions(auth?.panelistId);

  function getNextCategoryQuestion(categoryId) {
    if (categoryList && categoryList.categoryList.length > 0) {
      let getAllCategories = categoryList.categoryList;
      let getCurrentCatIndex = getAllCategories.findIndex(
        (d) => Number(d.id) === Number(categoryId)
      );
      let nextCatId = getAllCategories[getCurrentCatIndex + 1];
      if (typeof nextCatId === "object") {
        $("#submit_btn").attr("next-category", nextCatId.id);
        $("#submit_btn").attr("value", "Save & Next");
      } else {
        $("#submit_btn").attr("value", "Submit");
      }
    }
  }
  //post your answers
  const postQuestionAnswers = useMutation(
    (payload) => {
      return saveAnswers(payload);
    },
    {
      onSuccess: () => {
        toast.success("Data saved successfully");
        let nextPageCatId = $("#submit_btn").attr("next-category");
        if (nextPageCatId) {
          //   const anchor = document.createElement("a");
          // anchor.setAttribute("href", `/my-profile-category-question?category=${nextCateogry}`);
          // anchor.click();
          window.location.href = `/my-profile-category-question/?category=${nextPageCatId}`;
        } else {
          window.location.href = `/my-profile`;
        }
      },
      onError: () => {
        toast.error("Something went wrong! Please try again later.");
      },
    }
  );

  //on Submit function
  const submitHandler = (event) => {
    event.preventDefault();
    let isNoneAboveError = false;
    let isNoneAboveErrorOptions = "";

    const $input = $("#saveAnswer");
    let data = $($input).serializeArray();
    let allInputTextIds = [];
    $($input)
      .find("input[type=text]")
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .each(function (d) {
        let input = $(this);
        allInputTextIds.push(input.attr("id"));
      });
    let newArrData = data.reduce((acc, curr) => {
      if (acc.some((obj) => obj.name === curr.name)) {
        acc.forEach((obj) => {
          if (obj.name === curr.name) {
            obj.value = obj.value + "," + curr.value;
          }
        });
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    newArrData = newArrData.map((d) => {
      let qData = questionOpionsList.filter((dd) => Number(d.name) === dd.id);
      // when question type multi selected
      if (Number(qData[0].questionType) === 2) {
        if (d.value && d.value !== "") {
          const selectedAnswers = d.value
            .toString()
            .split(",")
            .map((d) => Number(d));
          const selectedMutiOptions = qData[0].optionData.filter((d) =>
            selectedAnswers.includes(d.id)
          );
          let checkNoneIsExist = selectedMutiOptions.filter(
            (d) => d.optionText == "None of the above"
          );
          if (checkNoneIsExist.length > 0 && selectedMutiOptions.length > 1) {
            isNoneAboveError = true;
            isNoneAboveErrorOptions = checkNoneIsExist[0].optionText;
          }
        }
      }

      const lang_code =
        localStorage?.getItem("zamplia_user_lang_code") == "en"
          ? "En-US"
          : localStorage.getItem("zamplia_user_lang_code");

      return {
        user_id: auth.panelistId,
        category_id: window.location.href.split("=")[1],
        questionId: d.name,
        answerId: allInputTextIds.includes(d.name) ? null : d.value,
        answerValues: allInputTextIds.includes(d.name) ? d.value : null,
        lang_code: lang_code.length > 0 ? qData[0].lang_code : "En-US",
      };
    });
    newArrData = newArrData.filter((d) => d.answerId || d.answerValues);
    if (isNoneAboveError) {
      alert(
        `If you are selecting: ${isNoneAboveErrorOptions}. So you can't choose other options for this question`
      );
    } else {
      if (newArrData.length > 0) {
        postQuestionAnswers.mutate(newArrData);
      }
    }
  };

  function bindInputFields(questionId, fieldType, options, mKey, qLevel) {
    switch (Number(fieldType)) {
      case 1:
        return TextBox(questionId, mKey, qLevel);
      case 2:
        return Multiselect(questionId, options, mKey, qLevel);
      case 3:
        return Dropdown(questionId, options, mKey, qLevel);
      case 4:
        return Radio(questionId, options, mKey, qLevel);
      default:
        return TextBox(questionId, options, mKey, qLevel);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function bindQuestionField(mapKey, ques, isChildQuestion = false, parentQuestionId = 0) {
    //it will show only for first level of question
    let isHeadingSame = false;
    // if (!isChildQuestion) {
    //     let qKey = mapKey - 2;
    //     let firstLevelQuestionOptionsList = questionOpionsList.filter((d) => d.questionLevel === 1);
    //     let lasQuestion = firstLevelQuestionOptionsList[qKey];
    //     if (typeof lasQuestion === 'object') {
    //         if (lasQuestion.questionHeading == ques.questionHeading) {
    //             isHeadingSame = true
    //         }
    //     }
    // }
    return `<div class="col-md-12 bq-counter bq_detect_${mapKey}_${
      ques.questionLevel
    }" id="question_${ques.id}" data-detect="bq-detect_${ques.questionLevel}_${ques.id}">
        <div class="form-group">
            ${
              !isChildQuestion &&
              !isHeadingSame &&
              ques.questionHeading &&
              ques.questionHeading !== ""
                ? '<h3 class="ques-heading">' + ques.questionHeading + "</h3>"
                : ""
            }
            <label class="inline-block after:content-[''] after:ml-0.5 after:text-red-500 block text-sm font-medium text-gray-700">${
              !isChildQuestion ? mapKey + "." : ""
            } ${ques.questionTitle}</label>
            ${bindInputFields(
              ques.id,
              ques.questionType,
              ques.optionData,
              mapKey,
              ques.questionLevel
            )}
        </div>
    </div>`;
  }

  function getQuestionsAndOptionsByCategory() {
    let data = "";
    if (questions && questions.questionOptionsList && questions.questionOptionsList.length > 0) {
      $("#questions_save_btn_wrapped").show();
      let questionOpionsListData = questions.questionOptionsList;
      setQuestionOpionsList(questionOpionsListData);
      questionOpionsListData = questionOpionsListData.sort((a, b) => a.orderNo - b.orderNo);
      let firstLevelQuestionOptionsList = questionOpionsListData.filter(
        (d) => d.questionLevel === 1
      );
      data = firstLevelQuestionOptionsList?.map((d, key) => {
        return bindQuestionField(key + 1, d);
      });
      $("#questions_wrapper").html(data);
      $(".question_box").attr("class", "shadow-lg bg-white lg:w-1/2 p-10 pb-16 mx-auto");
      const script = document.createElement("script");
      script.innerHTML = '$(".js-example-basic-multiple").select2()';
      document.body.appendChild(script);
    } else {
      $(".question_box").attr("class", "w-1/2 mx-auto");
      data = '<div class="alert alert-danger mb-50"> Question Not Found.</div>';
      $("#questions_wrapper").html(data);
    }
  }
  const getCurrentUserProfileData = () => {
    if (answers?.success && answers.data.length > 0) {
      answers.data.map((d) => {
        if (d.questionType === 1) {
          $("#" + d.questionId).val(d.answerValues);
        } else if (d.questionType === 2) {
          let answerIds = d.answerIds;
          answerIds = answerIds.split(",");
          for (let i = 0; i < answerIds.length; i++) {
            $(`#${d.questionId} option[value='${answerIds[i]}']`).attr("selected", "selected");
          }
          const script = document.createElement("script");
          script.innerHTML = '$(".js-example-basic-multiple").select2()';
          document.body.appendChild(script);
        } else if (d.questionType === 3) {
          $("#" + d.questionId)
            .val(d.answerIds)
            .trigger("change");
        } else if (d.questionType === 4) {
          $(".qid_" + d.questionId + "[value=" + d.answerIds + "]")
            .prop("checked", true)
            .trigger("change");
        } else {
          $("#" + d.questionId).val(d.answerIds);
        }
      });
    }
  };

  function autoFillRegion() {
    if (currentCategory == 20) {
      if (questions && questions.questionOptionsList && questions.questionOptionsList.length > 0) {
        //for canada region
        let getProvinceQuestion = questions.questionOptionsList.filter((d) => d.id === 184);
        let getPostalCode = questions.questionOptionsList.filter((d) => d.id === 185);
        if (getProvinceQuestion.length > 0 && localStorage.getItem("current_user_region")) {
          let getProvinceOption = getProvinceQuestion[0].optionData.filter(
            (d) => d.optionText === localStorage.getItem("current_user_region")
          );
          if (getProvinceOption.length > 0) {
            $(
              `#${getProvinceQuestion[0]["id"]} option[value='${getProvinceOption[0]["id"]}']`
            ).attr("selected", true);
          }
        }
        if (getPostalCode.length > 0 && localStorage.getItem("current_user_postal")) {
          $("#" + getPostalCode[0]["id"]).val(localStorage.getItem("current_user_postal"));
        }

        //for usa region
        let getStateQuestion = questions.questionOptionsList.filter((d) => d.id === 89);
        let getZipCode = questions.questionOptionsList.filter((d) => d.id === 2);
        if (getStateQuestion.length > 0 && localStorage.getItem("current_user_region")) {
          let getStateOption = getStateQuestion[0].optionData.filter(
            (d) =>
              d.optionText.toLowerCase() ===
              localStorage.getItem("current_user_region").toLowerCase()
          );
          if (getStateOption.length > 0) {
            $(`#${getStateQuestion[0]["id"]} option[value='${getStateOption[0]["id"]}']`).attr(
              "selected",
              true
            );
          }
        }
        if (getZipCode.length > 0 && localStorage.getItem("current_user_postal")) {
          $("#" + getZipCode[0]["id"]).val(localStorage.getItem("current_user_postal"));
        }
      }
    }
  }

  useEffect(() => {
    if (
      questions &&
      questions.success &&
      answers &&
      answers.success &&
      categoryList &&
      categoryList.success
    ) {
      getQuestionsAndOptionsByCategory();
      let currentCat = categoryList.categoryList.filter(
        (d) => Number(d.id) === Number(currentCategory)
      );
      if (currentCat.length > 0) {
        setCurrentCategoryName(currentCat[0]["name"]);
      }
      window.setTimeout(() => {
        autoFillRegion();
        getNextCategoryQuestion(currentCategory);
      }, [100]);
      window.setTimeout(() => {
        getCurrentUserProfileData();
      }, [200]);
    }
  }, [questions, answers, categoryList]);

  $(document).on("change", ".showChildQuestion", function () {
    const questionLevel = $(this).attr("data-question-level");
    const isHierarchy = $(this).attr("data-ishierarchy");
    let hierarchyQuestionId = $(this).attr("data-hierarchy-question");
    hierarchyQuestionId = hierarchyQuestionId !== undefined ? hierarchyQuestionId.split(",") : [];
    hierarchyQuestionId = hierarchyQuestionId && hierarchyQuestionId.map((d) => Number(d));
    const questionKey = $(this).attr("data-key");
    const currentQuestionId = $(this).attr("data-current-question");
    const questionData = questionOpionsList.filter((d) => hierarchyQuestionId.includes(d.id));
    const bqCounter = document.querySelectorAll(".bq-counter");
    for (let index = 0; index < bqCounter.length; index++) {
      if (index + 1 > questionLevel) {
        $(".bq_detect_" + questionKey + "_" + (index + 1)).remove();
      }
    }
    if (Number(isHierarchy)) {
      if (questionData.length > 0) {
        for (let i = 0; i < questionData.length; i++) {
          let isChildQuestion = true;
          let parentQuestionId = currentQuestionId;
          $("#question_" + currentQuestionId).after(
            bindQuestionField(questionKey, questionData[i], isChildQuestion, parentQuestionId)
          );
        }
        const script = document.createElement("script");
        script.innerHTML = '$(".js-example-basic-multiple").select2()';
        document.body.appendChild(script);
      }
    }
  });

  //for select type options
  $(document).on("change", ".showChildQuestionSelect", function () {
    if (questions) {
      const questionLevel = $(this).find(":selected").attr("data-question-level");
      const isHierarchy = $(this).find(":selected").attr("data-ishierarchy");
      let hierarchyQuestionId = $(this).find(":selected").attr("data-hierarchy-question");
      hierarchyQuestionId = hierarchyQuestionId !== undefined ? hierarchyQuestionId.split(",") : [];
      hierarchyQuestionId = hierarchyQuestionId && hierarchyQuestionId.map((d) => Number(d));
      const questionKey = $(this).find(":selected").attr("data-key");
      const currentQuestionId = $(this).find(":selected").attr("data-current-question");
      const questionData = questions?.questionOptionsList.filter(
        (d) => Number(hierarchyQuestionId) === Number(d.id)
      );
      const bqCounter = document.querySelectorAll(".bq-counter");
      for (let index = 0; index < bqCounter.length; index++) {
        if (index + 1 > questionLevel) {
          $(".bq_detect_" + questionKey + "_" + (index + 1)).remove();
        }
      }

      if (Number(isHierarchy)) {
        if (questionData.length > 0) {
          for (let i = 0; i < questionData.length; i++) {
            let isChildQuestion = true;
            let parentQuestionId = currentQuestionId;
            $("#question_" + currentQuestionId).after(
              bindQuestionField(questionKey, questionData[i], isChildQuestion, parentQuestionId)
            );
          }
          const script = document.createElement("script");
          script.innerHTML = '$(".js-example-basic-multiple").select2()';
          document.body.appendChild(script);
        }
      }
    }
  });

  return (
    <>
       <div className="justify-center w-full min-h-screen p-6 font-sans pt-24 px-10">
        <h1 className="mb-1 text-xl font-bold text-center md:text-3xl md:mt-11">
          {currentCategoryName}
        </h1>
        <div className="question_box w-full p-5 mx-auto">
          <form id="saveAnswer" onSubmit={(e) => submitHandler(e)}>
            <div className="row" id="questions_wrapper"></div>
            <div className="row" id="questions_save_btn_wrapped" style={{ display: "none" }}>
              <br />
              <div className="col-md-12">
                <input
                  type="submit"
                  id="submit_btn"
                  className="btn-2"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default MyProfileQuestions;

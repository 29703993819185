import { axiosClient } from "api/api";

export const getAccountDetails = async (panelListId: number) => {
  return axiosClient
    .get("/api/user/getPanelistDataForAccount", {
      params: {
        panelListId,
      },
    })
    .then((response) => response.data);
};


export const UpdateUsername = async (panelistId: number, user_nicename: string) => {
  return axiosClient
    .post("/api/user/UpdatePanelistDataForAccount", {
      panelistId,
      user_nicename,
    })
    .then((response) => response.data);
};


export const deactivateAccount = async (panelListId) => {
  return axiosClient
    .post("/api/user/UserDeactivatForAccount", {
      panelListId,
    })
    .then((response) => response.data);
};


export const changeUserPassword = async (
  panelistId: number,
  password: string,
  new_password: string
) => {
  return axiosClient
    .post("/api/user/ChangePasswordPanelistDataForAccount", {
      panelistId,
      password,
      new_password,
    })
    .then((response) => response.data);
};

export const decryptPassword = async (
  password: string,
) => {
  return axiosClient
    .post("/api/user/DecryptPassword", {
      password,
    })
    .then((response) => response.data);
};

export const SubscribeAccount = async (panelListId) => {
  return axiosClient
    .post("/api/user/Subscribe", {
      panelListId,
    })
    .then((response) => response.data);
};

export const UpdateGender = async (gender: string, panelListId: number) => {
  return axiosClient
    .post("/api/user/updateGender", {
      gender,
      panelListId,
    })
    .then((response) => response.data);
};

export const UpdateDob = async (dob: string, panelListId: number) => {
  return axiosClient
    .post("/api/user/updateDob", {
      dob,
      panelListId,
    })
    .then((response) => response.data);
};

export const uploadProfileImage = async (panelistId: number, image: File) => {
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };
  try {
    const base64String = await convertToBase64(image);
    const payload = {
      panelistId,
      image: base64String
    };
    const response = await axiosClient.post("/api/user/imageUrl", payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to upload profile image');
  }
};
import React, { useRef } from "react";
import { ReactComponent as AccountClose } from "../../assets/SideBarIcons/AccountClose.svg";
import { ReactComponent as EmailSvg } from "../../assets/SideBarIcons/EmailSvg.svg";
// import { useState } from "react";
import { forgotUser } from "../ForgotForm/ForgotForm.api";
import { toastrError, toastrSuccess } from "lib/toastr";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
interface AccountToggleProps {
  isPopupOpen;
  isPopupOpen1
}

  const ResetPassword: React.FC<AccountToggleProps> = ({ isPopupOpen,isPopupOpen1 }) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const ResetPasswordMutaion = useMutation(() => forgotUser(auth.email), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(t("forgotPassword.success"));
      } else {
        toastrError(response.message);
      }
    },
    onError: () => {
      toastrError(t("page.unsubscribe.Encountered"));
    },
  });

  function handleReset(e) {
    e.preventDefault();
    ResetPasswordMutaion.mutate();
  }

  const modelRef = useRef();
  const CloseModal = (e) => {
    if (modelRef.current == e.target) {
      isPopupOpen();
     isPopupOpen1(); 
    }
  };
   const handleClose=()=>{
       isPopupOpen();
       isPopupOpen1();
  };

  return (
    <>
      {isPopupOpen && (
        <div
          className="fixed inset-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 mt-6 md:mt-0 lg:mt-10"
          ref={modelRef}
          onClick={CloseModal}
        >
          <div className="bg-white rounded-3xl shadow-md w-[450px] px-8 py-4 relative ">
            <h1 className=" text-center text-xl text-[#757575]  font-[500] font-poppins">
              {t("forgotPassword.head")}
            </h1>
            <div className=" absolute  right-6 top-6 cursor-pointer">
              <AccountClose onClick={handleClose} />
            </div>
            <div className=" flex-col justify-center items-center  ">
              <div className=" flex justify-center items-center mt-9 mb-5 ">
                <div className=" w-24 h-24  bg-[#056E9C24] flex justify-center items-center  rounded-full">
                  <EmailSvg />
                </div>
              </div>
              <div className=" flex flex-col justify-center items-center space-y-5">
                <div>
                  <p className=" text-center text-[#616161]">
                    {t("forgotPassword.p1")}
                    <span className=" text-[#056E9C] ml-0.5">{auth.email}</span>
                  </p>
                </div>
                <div>
                  <p className=" text-center text-[#868686]">{t("forgotPassword.p2")}</p>
                </div>
                <div>
                  <p className=" text-center text-[#056E9C]">{t("forgotPassword.p3")}</p>
                </div>
                <div>
                  <button className="text-center text-[#056E9C] text-[16px]" onClick={handleReset}>
                    {t("forgotPassword.button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;

import React, { useState } from "react";
import remove from "../../../assets/JoinNow/cancel.svg";
import grp from "../../../assets/Surveys/qualiGroup.svg";
import PropTypes from "prop-types";
import { useAuth } from "context/auth-context";

export const QualificationPopUp = ({ ShowPopup , attemptFunction, setSelectedSurveyData }) => {
  
   const [buttonClicked, setButtonClicked] = useState(false);
 const { auth } = useAuth();
 const panelistId = auth.panelistId;

  const handleAttempt = () => {
    if (!buttonClicked) {
      setButtonClicked(true);
      const payload = {
        affilate: null,
        apiClientId: setSelectedSurveyData.apiClientId,
        isGuestUser: null,
        panelListId: panelistId,
        points: setSelectedSurveyData.finalPoint || setSelectedSurveyData.points,
        sid: setSelectedSurveyData.s_id,
        source: null,
        studyName: setSelectedSurveyData.studyName,
        study_id: setSelectedSurveyData._id,
        v_id: null,
        vid: null,
        vendorCpi: setSelectedSurveyData.vendorCpi,
        loi: setSelectedSurveyData.loi,
      };
      attemptFunction(payload);
    }
  };
  return (
    <div>
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full">
        <div className="absolute z-40 w-full h-full bg-black opacity-30 lg:py-4" onClick={ShowPopup}></div>
        <div className="h-[30rem] w-[19rem] md:w-[25rem] md:h-[35rem] lg:h-[42rem] lg:w-[30rem] lg:top-[22rem] md:top-96 top-80 bg-white opacity-100 rounded-[35px] absolute flex flex-col items-center   left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 lg:px-6  md:px-4  px-4 ">
          <div className=" flex items-center justify-center gap-4 lg:h-16 lg:w-[30rem] md:w-[25rem] md:h-16 w-[19rem] h-14 bg-gradient-to-b from-[#8FDCFF42] to-[#1B87B742] rounded-tl-[1.9rem] rounded-tr-[1.9rem] relative">
            <span className="text-lg font-medium  font-poppins lg:text-2xl md:text-xl">Qualification</span>
            <div
              className="text-[#222020]  lg:w-14 md:w-10 w-6  absolute font-poppins lg:top-4 md:top-4  lg:right-[25px] md:right-[20px] right-5 cursor-pointer"
              onClick={ShowPopup}
            >
              <img src={remove} alt="remove icon" />
            </div>
          </div>
          <div className="flex flex-col items-center mt-2  font-poppins lg:mt-3 md:mt-2">
            <div className="flex flex-col lg:w-[20.50rem] lg:h-10 md:w-[18rem] md:h-8 w-[17rem] h-8 text-[#4E4E4E]  rounded-3xl font-poppins font-normal  items-center justify-center gap-1  lg:mt-4 md:mt-2 mt-3">
              
              <span className="text-sm font-medium lg:text-lg md:text-base font-poppins">Share your opinion </span>
              <span className="text-xs lg:text-sm md:text-xs">Your responses are completely anonymous  </span>
            </div>
            <div className="flex  lg:gap-4 md:gap-4 gap-2 lg:h-28 md:w-[23rem] md:h-24 lg:w-[26rem] h-20 w-[17rem] rounded-2xl  font-poppins lg:mt-8 md:mt-6 mt-6 bg-[#056E9C0A] px-4 py-3">
              <div className="w-12 lg:w-20 md:w-16">
              <img src={grp} alt="" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="lg:text-base md:text-sm text-xs font-poppins text-[#4E4E4E]  font-medium">Be attentive</span>
                <div className="text-[#4E4E4E] font-poppins font-normal lg:text-sm md:text-xs text-[0.50rem]">

                <span>Read questions carefully. Surveys detect if you are
answering</span>
                <span> too quickly or fail test questions and
screen you out.</span>
                </div>
              </div>
              
            </div>
            <div className="flex  lg:gap-4 md:gap-4 gap-2 lg:h-28 md:w-[23rem] md:h-24 lg:w-[26rem] h-20 w-[17rem] rounded-2xl  font-poppins lg:mt-8 md:mt-6 mt-6 bg-[#056E9C0A] px-4 py-3">
              <div className="w-12 lg:w-20 md:w-16">
              <img src={grp} alt="" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="lg:text-base md:text-sm text-xs font-poppins text-[#4E4E4E]  font-medium">Be open</span>
                <div className="text-[#4E4E4E] font-poppins font-normal lg:text-sm md:text-xs text-[0.50rem]">

                <span>Cive accurate answers. Answering I don&quott want to
share</span>
                <span> this information willalmost certainly screen you
out of a survey.</span>
                </div>
              </div>
              
            </div>
            <div className="flex  lg:gap-4 md:gap-4 gap-2 lg:h-28 md:w-[23rem] md:h-24 lg:w-[26rem] h-20 w-[17rem] rounded-2xl  font-poppins lg:mt-8 md:mt-6 mt-6 bg-[#056E9C0A] px-4 py-3">
              <div className="w-12 lg:w-20 md:w-16">
              <img src={grp} alt="" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="lg:text-base md:text-sm text-xs font-poppins text-[#4E4E4E]  font-medium">Be patient</span>
                <div className="text-[#4E4E4E] font-poppins font-normal lg:text-sm md:text-xs text-[0.50rem]">

                <span>You won&quott be able to complete every survey you start
The more</span>
                <span> surveys you take. the better we can match
you to new surveys.</span>
                </div>
              </div>
              
            </div>
            
            {/* <div className="flex gap-2 mt-2 lg:mt-6 md:mt-4">
              <input type="checkbox" className="" />
              <div className=" lg:text-sm md:text-xs text-[0.50rem]">

              <span>By taking this surveys you accept our</span>
              <span className="text-[#056E9C]"> Privacy Policy</span>
              </div>
            </div> */}
            <button className={`w-52  h-8 lg:w-72 lg:h-14 md:w-64 md:h-12 bg-[#056E9C] lg:text-base md:text-sm text-xs text-white rounded-3xl lg:mt-8 md:mt-6 mt-4 ${buttonClicked ? 'opacity-50 transition-opacity duration-500 cursor-not-allowed': ""}` }
             disabled={buttonClicked}
            onClick={handleAttempt}> 
          
              Start the Survey
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
QualificationPopUp.propTypes = {
   setSelectedSurveyData: PropTypes.object.isRequired,
  attemptFunction: PropTypes.func.isRequired,
  ShowPopup: PropTypes.func.isRequired,
};

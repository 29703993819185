export const ForgotValidation = (formValues: any, setErrors: any, t: any) => {
  setErrors({ ...formValues.errors, email: "" });
  // email validation patterns
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  if (!(formValues.emailId && regexEmail.test(formValues.emailId))) {
    setErrors({ ...formValues.errors, email: t("form.login.emails") });
    return false;
  }
  return true;
};

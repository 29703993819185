import { useQuery } from "@tanstack/react-query";
import {
  JoinNowBonusBalanceApi,
  ReferalWalletBalance,
  StreakWalletBalanceApi,
  getPointBalanceStudiesWiseApi,
} from "../components/Dashboard/Dasboard.api";
import { useAuth } from "../context/auth-context";

type WalletdetailsReturnType = Record<string, number | undefined>;

export const useWalletDetails = (): WalletdetailsReturnType => {
  const { auth } = useAuth();
  const getPointsBalance = useQuery(
    ["pointBal", auth.panelistId],
    () => getPointBalanceStudiesWiseApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getReferalWalletBalance = useQuery(
    ["referalBal", auth.panelistId],
    () => ReferalWalletBalance(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getStreakWalletBalance = useQuery(
    ["streakbalance", auth.panelistId],
    () => StreakWalletBalanceApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getJoinNowBonusBalance = useQuery(
    ["getBonusPoint", auth.panelistId],
    () => JoinNowBonusBalanceApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const pointBalance =
    getPointsBalance && getPointsBalance?.data ? getPointsBalance?.data?.Data : 0;
  const joinNowBonusBalance =
    getJoinNowBonusBalance && getJoinNowBonusBalance?.data ? getJoinNowBonusBalance?.data?.data : 0;
  const referalBal =
    getReferalWalletBalance && getReferalWalletBalance?.data
      ? getReferalWalletBalance?.data?.Data
      : 0;
  const streakBalance =
    getStreakWalletBalance && getStreakWalletBalance?.data
      ? getStreakWalletBalance?.data?.streakCount
      : 0;

  const PointBalance = pointBalance ? pointBalance : 0;
  const ReferalBalance = referalBal ? referalBal : 0;
  const StreakBalance = streakBalance ? streakBalance : 0;
  const JoinNowBonusBalance = +joinNowBonusBalance ? +joinNowBonusBalance : 0;
  const TotalWalletBalance = PointBalance + ReferalBalance + StreakBalance + JoinNowBonusBalance;

  return {
    PointBalance,
    ReferalBalance,
    StreakBalance,
    JoinNowBonusBalance,
    TotalWalletBalance,
  };
};

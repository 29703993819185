import React, { useEffect, useState } from "react";
import { MdPhotoCamera } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import usericon from "../../assets/Account/user.svg";
import { useAuth } from "context/auth-context";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAccountDetails } from "./Account.api";
import LoadingOverlay from "react-loading-overlay-ts";
// import { deactivateAccount } from "./Account.api";
//import { SubscribeAccount } from "./Account.api";
import AccountToggle from "./AccountToogle";
import ChangePassword from "./AccountForgetPassword";
import { Link } from "react-router-dom";
import { uploadProfileImage } from "./Account.api";
import { MdLogout } from "react-icons/md";
import { useSubscribe } from "context/Subscribe-context";
 import { subscribeUser, subscribeStatus } from "./Subscribe.api";
import DobToggle from "./DobToogle";
import GenderToggle from "./GenderToogle";
import { useTranslation } from "react-i18next";

export const Account = () => {
  const {t} = useTranslation();
  const intialState = {
    name: "",
    Gender: "",
    email: "",
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [isPopupOpenDob, setIsPopupOpenDob] = useState(false);
  const [isPopupOpenGender, setIsPopupOpenGender] = useState(false);
  // const [userTitle, setuserTitle] = useState<any>("");
  const [isUploading, setIsUploading] = useState(false);
  const { auth } = useAuth();
  const { setAuth } = useAuth();
 
  const queryClient = useQueryClient();

  const uploadImage = async (imageFile) => {
    try {
      setIsUploading(true);
      if (!imageFile) {
        setIsUploading(false);
        return;
      }
      const panelistId = auth.panelistId;
 
      await uploadProfileImage(panelistId, imageFile);
 
      queryClient.invalidateQueries(["getAccountDetailsapi"]);
      toastrSuccess("Profile image uploaded successfully");
    } catch (error) {
      toastrError("Failed to upload profile image");
    } finally {
      setIsUploading(false);
    }
  };
 
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        uploadImage(file);
      } else {
        toastrError("Please select an image file (JPEG, PNG)");
      }
    }
  };
  const spinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderLeftColor: '#333',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  };
 
 
  const togglepopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const togglepopup1 = () => {
    setIsPopupOpen1(!isPopupOpen1);
  };

  const toggleDobpopup = () => {
    setIsPopupOpenDob(!isPopupOpenDob);
  };

    const toggleGenderpopup = () => {
      setIsPopupOpenGender(!isPopupOpenDob);
    };
 
  const dates: string[] = [];
  const title: string[] = [];
 
  const getuserData = useQuery(
    ["getAccountDetailsapi", formValue],
    () => getAccountDetails(auth.panelistId),
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setAuth({ ...auth, profile_pic: response.data[0].profile_pic, name: response.data[0].user_nicename });
 
          queryClient.invalidateQueries(["getAccountDetails"]);
        } else {
          toastrError(response.message);
        }
      },
      onError: () => {
        toastrError("please write good email");
      },
    }
  );
  //  for joinDate
  getuserData?.data?.data?.map((item) => {
    const dat = item.user_registered;
    const dateString = dat;
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    const formattedDate = formatter.format(date);
    dates.push(formattedDate);
    title.push(item.user_nicename);
  });
  const username = title[0];
  if (getuserData && getuserData.data && Array.isArray(getuserData.data.data)) {
    // const userLogin = getuserData;
  }
 
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };
  const handleKeyDown = (e) => {
    e.preventDefault();
  };
 
  // decativate
  // const deactivaemutain = useMutation(() => deactivateAccount(auth.panelistId), {
  //   onSuccess: (response: any) => {
  //     if (response.success) {
  //         toastrError(response.message);
  //     } else {
  //      toastrSuccess(response.message);
  //     }
  //   },
  //   onError: () => {
  //     toastrError(("please write good email"));
  //   },
  // });
 
  // const Deactivate=(e)=>{
  // e.preventDefault();
  // deactivaemutain.mutate();
  // };
 
  // subcribeUser
   const { isSubscribe, setisSubscribe } = useSubscribe();
   useEffect(() => {
     const fetchInitialSubscriptionStatus = async () => {
       try {
         const response = await subscribeStatus(auth.panelistId);
         setisSubscribe(response.isSubscribed);
       } catch (error) {
         setisSubscribe(0);
       }
     };
     fetchInitialSubscriptionStatus();
   }, [auth.panelistId, setisSubscribe]);

   const mutation = useMutation(() => subscribeUser(auth.panelistId), {
     onSuccess(data) {
       if (data.newSubscribeStatus === 1) {
         setisSubscribe(data.newSubscribeStatus);
         toastrSuccess(t("account.success.subscribe"));
       }
     },
     onError() {
       toastrError(t("account.error.subscribe"));
     },
   });

  const handleSubscribe = (e) => {
        e.preventDefault();
        mutation.mutate();
  };


  //   useEffect(()=>{
  // handleSubscribe();
  //   });
 
  // validation
  // function handleFileSelect(event) {
  //   const files = event.target.files;
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //   }
  // }
 
  //  fileinputopen
 
 
  return (
    <>
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={getuserData.isLoading}
        spinner
        text="Loading..."
      >
        <div className=" flex justify-center items-center lg:pl-[16rem] lg:pb-4 lg:pt-[6rem] md:pt-24 pt-20 pb-24 lg:w-[98vw]  ">
          <div className=" bg-white w-full md:w-[90%] rounded-3xl lg:w-[60vw] flex justify-center relative ">
            {getuserData &&
              getuserData.data &&
              Array.isArray(getuserData.data.data) &&
              getuserData.data.data.length > 0 &&
              getuserData.data.data.map((item, i) => (
                <div key={i} className="lg:w-[50vw] pt-10 w-[90%]">
                  <header className="w-[100%] flex text-xs pb-8 justify-between text-[#505050] sm:text-base font-poppins font-medium  ">
                    <div className="flex justify-between relative">
                      <div className=" flex items-center">
                        {isUploading ? (
                          <div className="w-[60px] h-[60px] bg-gray-300 rounded-full sm:w-[100px] sm:h-[100px] relative flex justify-center items-center">
                            <div style={spinnerStyle}></div>
                          </div>
                        ) : item.profile_pic ? (
                          <div className="w-[60px] h-[60px] bg-gray-300 rounded-full sm:w-[100px] sm:h-[100px] relative">
                            <img
                              src={item.profile_pic}
                              alt="Uploaded Profile"
                              className="object-cover w-full h-full overflow-hidden rounded-full"
                              onLoad={() => setIsUploading(false)}
                            />
                          </div>
                        ) : (
                          <div className="w-[60px] h-[60px] bg-gray-300 rounded-full sm:w-[100px] sm:h-[100px] relative">
                            <img src={usericon} alt="Default Profile" />
                          </div>
                        )}

                        <div
                          className=" absolute top-9 left-9 sm:top-16 sm:left-16 w-6 h-6 bg-[#056E9C]  cursor-pointer   py-[6px] px-[6px]  sm:py-[9px] sm:px-[9px]  rounded-full sm:h-8 sm:w-8 "
                          onClick={(event) => {
                            event.preventDefault();
                            document.getElementById("fileInput").click();
                          }}
                        >
                          <MdPhotoCamera style={{ color: "white" }} />
                        </div>
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          className="hidden"
                          onChange={handleFileSelect}
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      <div className="flex flex-col mt-3 ml-5 -mr-2 md:mt-7 md:ml-0">
                        <p className="sm:ml-4">{item.user_nicename}</p>
                        <p className="sm:ml-4">
                          {window.innerWidth < 640
                            ? item.user_email.slice(0, 20) + "..."
                            : item.user_email}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col mt-3 md:mt-7 sm:mt-5">
                      <p className=" text-end">{t("Account.user_status")}</p>
                      <p className="">{dates[0]}</p>
                    </div>
                  </header>
                  <main className="w-[100%] flex flex-col">
                    <form className="flex flex-col  space-y-6  text-[#6B6B6B] font-poppins  font-normal  text-sm sm:text-base ">
                      <div className="space-y-1 ">
                        <label>{t("Account.Label.Gender")}</label>
                        <div className="flex items-center">
                          <input
                            type=" text"
                            className=" w-full h-[50px] pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50 "
                            value={item.gender}
                            placeholder="Set Your Gender"
                            onChange={onInputChange}
                            onKeyDown={handleKeyDown}
                          />
                          <div>
                            <MdEdit
                              className="absolute right-[8vw] md:right-[8vw] lg:right-[6vw]  transform -translate-y-1/2 cursor-pointer"
                              onClick={toggleGenderpopup}
                            />
                          </div>
                          {isPopupOpenGender && (
                            <div>
                              <GenderToggle
                                gender={item.gender}
                                isPopupOpenGender={() => setIsPopupOpenGender(false)}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="space-y-1 ">
                        <label>{t("Account.label.Dob")}</label>
                        <div className="flex items-center">
                          <input
                            type=" text"
                            className=" w-full h-[50px]  pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50 "
                            value={item.dob}
                            placeholder="Set Your DOB"
                            onChange={onInputChange}
                            onKeyDown={handleKeyDown}
                          />
                          <div>
                            <MdEdit
                              className="absolute right-[8vw] md:right-[8vw] lg:right-[6vw]  transform -translate-y-1/2 cursor-pointer"
                              onClick={toggleDobpopup}
                            />
                          </div>
                          {isPopupOpenDob && (
                            <div>
                              <DobToggle
                                dob={item.dob}
                                isPopupOpenDob={() => setIsPopupOpenDob(false)}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label>{t("Account.Label.Email")}</label>
                        <div className="flex items-center">
                          <input
                            type="text"
                            name="emailId"
                            className="w-full h-[50px] rounded-xl pl-3 text-xs lg:text-base  font-normal bg-[#F1F6FA] bg-opacity-50"
                            placeholder="Steve.male@zamplia.com"
                            onChange={onInputChange}
                            value={item.user_email}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label>{t("Account.Label.user_name")}</label>
                        <div className="flex items-center ">
                          <input
                            type="text"
                            className="w-full h-[50px] rounded-xl pl-3 bg-[#F1F6FA] bg-opacity-50"
                            placeholder="stevemale57ki"
                            onChange={onInputChange}
                            value={item.user_nicename}
                            onKeyDown={handleKeyDown}
                          />
                          <div>
                            <MdEdit
                              className="absolute right-[8vw] md:right-[8vw] lg:right-[6vw]  transform -translate-y-1/2 cursor-pointer"
                              onClick={togglepopup}
                            />
                          </div>
                        </div>
                        {isPopupOpen && (
                          <div>
                            <AccountToggle
                              username={username}
                              isPopupOpen={() => setIsPopupOpen(false)}
                            />
                          </div>
                        )}
                      </div>

                      <div className="space-y-1 ">
                        <label>{t("Account.Label.change_password")}</label>
                        <div className="flex items-center ">
                          <input
                            type="password"
                            className="w-full h-[50px] rounded-xl pl-3 bg-[#F1F6FA] bg-opacity-50"
                            placeholder="********"
                            onKeyDown={handleKeyDown}
                          />
                          <div>
                            <MdEdit
                              className="absolute right-[8vw] md:right-[8vw] lg:right-[6vw] transform -translate-y-1/2 cursor-pointer "
                              onClick={togglepopup1}
                            />
                          </div>
                        </div>
                        {isPopupOpen1 && (
                          <div>
                            <ChangePassword isPopupOpen1={() => setIsPopupOpen1(false)} />
                          </div>
                        )}
                      </div>
                    </form>
                  </main>
                  <footer className="w-[100%] font-poppins font-normal ">
                    <div className="flex justify-between items-center ">
                      <p className="cursor-pointer ">
                        {isSubscribe ? (
                          <span>
                            <Link
                              to={"/unsubscribe"}
                              className="px-4 py-2 bg-[#056E9C] rounded-full shadow mt-5 mb-6 text-white"
                            >
                              {t("Account.Button.Unsubscribe")}
                            </Link>
                          </span>
                        ) : (
                          <span
                            onClick={handleSubscribe}
                            className="px-4 py-2 bg-[#056E9C] rounded-full shadow mt-5 mb-6 text-white"
                          >
                            {t("Account.Button.Subscribe")}
                          </span>
                        )}
                      </p>
                      <p className="flex px-4 py-2 justify-center items-center w-fit gap-2 bg-[#056E9C] rounded-full shadow mt-5 mb-6 text-white">
                        <MdLogout />
                        <Link to={"/logout"} className="">
                          {t("Account.Button.Log_out")}
                        </Link>
                      </p>
                      {/* <p className="text-[#C10606]"  onClick={Deactivate}>Deactivate Account</p> */}
                    </div>
                  </footer>
                </div>
              ))}
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

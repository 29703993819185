import { axiosClient } from "../../api/api";

export const verifyUser = async (cred) => {
  const { email } = cred;
  return axiosClient
    .post("/api/survey/check_join_now_Email", {
      email,
    })
    .then((response) => response.data);
};
export const registerUser = async (cred) => {
  const {
    password,
    firstName,
    lastName,
    email,
    dateOfBirth,
    gender,
    referalId,
    active,
    language,
    timeZone,
    currentUrl,
    sid,
    socialID,
    country,
    state,
    geo,
    clickid,
  } = cred;
  return axiosClient
    .post("/api/survey/join_now", {
      password,
      firstName,
      lastName,
      email,
      dateOfBirth,
      gender,
      referalId,
      active,
      language,
      timeZone,
      currentUrl,
      sid,
      socialID,
      country,
      state,
      geo,
      clickid,
    })
    .then((response) => response.data);
};
export const updateGuestProfileApi = async (cred) => {
  const {
    uid,
    password,
    firstName,
    lastName,
    email,
    language,
    timeZone,
    currentUrl,
    country,
    state,
  } = cred;
  return axiosClient
    .post("/api/user/updateGuestUserProfile", {
      password,
      firstName,
      lastName,
      email,
      language,
      timeZone,
      currentUrl,
      country,
      state,
      uid,
    })
    .then((response) => response.data);
};

export const saveUserDetail = async (landing_url: string, ip_address: string, country: string) => {
  return axiosClient
    .get("/api/user/saveUserDetails", {
      params: {
        landing_url,
        ip_address,
        country,
      },
    })
    .then((response) => response.data);
};

export const GetDetailsOfVerificationUserApi = async (emailID: string, name: string) => {
  return axiosClient
    .post("/api/survey/getVerificationData", {
      emailID,
      name,
    })
    .then((response) => response.data);
};

export const SendOtpApi = async (email: string) => {
  return axiosClient
    .post("/api/user/send-otp", {
      email,
      app: "zampliasurveys",
    })
    .then((response) => response.data);
};

export const VerifyOtpApi = async (email: string, otp: string) => {
  return axiosClient
    .post("/api/user/verify-otp", {
      email,
      otp,
    })
    .then((response) => response.data);
};


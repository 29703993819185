import { axiosClient } from "../../api/api";

export const subscribeBonus = async (type: number, points: number , panelistId: number) => {
  return axiosClient
    .post("/api/user/subscribeBonus", {
      type,
      points,
      panelistId
    })
    .then((res) => res.data);
};

export const subscribeBonusPoints = async () => {
  return axiosClient
    .get("/api/user/subscribeBonusPoints", {})
    .then((res) => res.data);
};

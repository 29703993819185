import axios from "axios";
import { nanoid } from "nanoid";

export const getData = async (
  setIpAddressName,
  setPassword,
  setFormValue,
  formValue,
  setEmail,
  setFirstname,
  setLastName
) => {
  try {
    // Fetch IP data
    const ipResponse = await axios.get("https://api.ipregistry.co/?key=bx6vvs1qwd9w1zdm");

    // Extract necessary data from the IP response
    const ipData = ipResponse.data;
    const ipAddress = ipData.ip;
    const language = ipData.location.language.name;
    const timeZone = ipData.time_zone.id;
    const country = ipData.location.country.name;
    const state = ipData.location.region.name;

    // Generate a random password
    const password = nanoid();

    // Update state with IP data
    setIpAddressName(ipAddress);
    setPassword(password);
    setFormValue({
      ...formValue,
      language,
      timeZone,
      country,
      state,
      currentUrl: window.location.href,
      confirmPassword: password,
      email: setEmail,
      firstName: setFirstname,
      lastName: setLastName,
    });

    return {
      ipAddress,
      language,
      timeZone,
      country,
      state,
      password,
    };
  } catch (error) {
    return null;
  }
};

import { axiosClient } from "../../api/api";
import { saveDataInLogs } from "./UpdatePasswordfun";

export const forgotPassLogging = async (request: string, response: string, section: string) => {
  return axiosClient
    .post("/api/survey/ForgotPasswordLogging", {
      request,
      response,
      section,
    })
    .then((response) => response.data);
};

export const updatePassword = async (
  emailId: string,
  new_pass: string,
  confirm_password: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const requestObject = {
    emailId,
    new_pass,
    confirm_password,
  };
  return axiosClient
    .post("/api/survey/updatePassword", requestObject)
    .then((response) => {
      saveDataInLogs({ emailId, new_password: new_pass, confirm_password, result: response });
      return response.data;
    })
    .catch((error) => {
      forgotPassLogging(
        JSON.stringify(requestObject),
        JSON.stringify(error),
        "update_password_initiated_error"
      );
      throw error;
    });
};

export const getUpdatePasswordToken = async (token: string) => {
  return axiosClient
    .post("/api/survey/checkLoopForgotToken", {
      token,
      trigger: "check_token",
    })
    .then((response) => response.data);
};

import React from "react";
import { useTranslation } from "react-i18next";
import { History } from "components/History/History";
import { ExportToCsv } from "export-to-csv";
import { useMutation } from "@tanstack/react-query";
import { exportHistory } from "./HistoryPage.api";
import { useAuth } from "context/auth-context";
import { toast } from "react-toastify";
import downld from "../../assets/Download-icon.svg";
export const HistoryPage = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const mutation = useMutation(() => exportHistory(auth?.panelistId));
  

  const mutatefunction= ()=>{
    if (mutation?.status === "success" && mutation?.data?.success) {
      const responseData = mutation?.data?.data;
      if (Array.isArray(responseData) && responseData?.length > 0) {
        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: true,
          title: "My Awesome CSV",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        const csvData = responseData.map((e) => ({
          surveyName: e.surveyName,
          points: e.points,
          createdAt: e.createdAt,
          status:
            e.surveyStatus === 1 || e.surveyStatus === 2 || e.surveyStatus === 3
              ? e.surveyStatusName
              : !e.surveyStatus || e.surveyStatus === 0
              ? "Not Completed"
              : "Terminated",
        }));
        csvExporter.generateCsv(csvData);
      } else {
        toast.error(t("history.SurveyEmpty"));
      }
    }
  };
  const getExportData = () => {
    mutation.mutate();
    mutatefunction();
  };
  return (
    <div className="sm:w-[100vw] w-full flex justify-center h-full">
      <div className="lg:w-[75%] max-h-screen lg:min-h-screen md:h-[55vh] md:w-[95%] w-full bg-[#FCFDFF] lg:ml-[16rem] mt-24 rounded-3xl shadow-sm font-poppins">
        {/* content */}
        <div className="py-8 w-full">
          <div className="flex justify-between px-3 md:px-10 lg:px-8">
            <div className="md:pt-3 pt-2">
             

              <p className="text-[#505050] text-xl font-medium lg:ml-4">
                {t("histortpage.surveyHistory")}
              </p>
            </div>
            <button
              data-testid="button"
              onClick={() => getExportData()}
              className="bg-[#056E9C] md:px-8 md:py-3 px-3 py-2 text-white rounded-md sm:rounded-3xl flex"
            >
              <p className="mr-2">{t("history.Exporter")}</p>
              <img src={downld} alt="download" className="mr-3" />
            </button>
          </div>
          <div
            data-testid="survey"
            className="mt-6 font-poppins text-[#474747] w-full flex flex-col md:px-10"
          >
            <History />
          </div>
        </div>
      </div>
    </div>
  );
};

import { MyReferral } from "../../components/MyReferral/MyReferral";


export const MyReferralPage = () => {
  
  
  return (
    <div className="">
      <div className="">
        <MyReferral />
      </div>
    </div>
  );
};
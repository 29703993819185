import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AccountClose } from "../../assets/SideBarIcons/AccountClose.svg";
import { UpdateUsername } from "./Account.api";
import { useAuth } from "context/auth-context";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toastrError, toastrSuccess } from "lib/toastr";
import { useTranslation } from "react-i18next";

interface AccountToggleProps {
  username: string;
  isPopupOpen;
}

const AccountToggle: React.FC<AccountToggleProps> = ({ username, isPopupOpen }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const modelRef = useRef();
  const [user_nicename, setuser_nicename] = useState("");
  const [issubmit, setissubmit] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const mutation = useMutation(() => UpdateUsername(auth?.panelistId, user_nicename), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(t("accountTogel.success"));
        queryClient.invalidateQueries(["getAccountDetailsapi"]);
      } else {
        toastrError(t("accountTogel.failed"));
      }
    },
    onError: () => {
      toastrError(t("accountTogel.terminate"));
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setissubmit(true);

    const usernameRegex = /^[a-zA-Z\s]+$/;

    if (!user_nicename.trim()) {
      setError(t("accountTogel.error.empty"));
    } else if (user_nicename.trim() === username) {
      setError(t("accountTogel.error.same"));
    } else if (!usernameRegex.test(user_nicename.trim())) {
      setError(t("accountTogel.error.special"));
    } else {
      mutation.mutate();
      setError("");
      isPopupOpen();
    }
  };


  

  localStorage.setItem("UserName", JSON.stringify(user_nicename));
  const CloseModal = (e) => {
    if (modelRef.current === e.target) {
      isPopupOpen();
    }
  };


  useEffect(() => {
    if(issubmit){
    if (user_nicename && !user_nicename.trim()) {
      setError(t("accountTogel.error.empty"));
    } else if (user_nicename.trim() === username) {
      setError(t("accountTogel.error.same"));
    } else if (!/^[a-zA-Z\s]+$/.test(user_nicename.trim())) {
      setError(t("accountTogel.error.special"));
    } else {
      setError("");
    }
  }
  }, [user_nicename, username, t]);

  return (
    <>
      {isPopupOpen && (
        <div
          className="fixed inset-0 lg:top-[8vh] lg:left-[10vw] w-full h-full flex justify-center items-center bg-black bg-opacity-50"
          ref={modelRef}
          onClick={CloseModal}
        >
          <div className="bg-white p-8 rounded-3xl shadow-md w-[268px] h-[350px]  md:w-[420px] md:h-[420px]  md:px-14 relative">
            <h1 className=" text-center text-[16px] text-[#757575] font-medium font-poppins">
              {t("accountTogel.head")}
            </h1>
            <div className=" absolute  right-4 md:right-6 top-9 cursor-pointer ">
              <AccountClose onClick={isPopupOpen} />
            </div>

            <div className=" mt-7 md:mt-12  text-sm space-y-5 font-poppins  font-normal text-[#413C3C]">
              <div>
                <label>{t("accountTogel.current_name")}</label>
                <input
                  type="text"
                  className="  w-full h-[40px] md:h-[50px] mt-2 pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50 "
                  value={username}
                />
              </div>
              <div className=" h-16">
                <div>
                  <label>{t("accountTogel.new_name")}</label>
                  <input
                    type="text"
                    className=" w-full h-[40px] md:h-[50px] mt-2 pl-3 rounded-xl bg-[#F1F6FA] bg-opacity-50 "
                    onChange={(e) => setuser_nicename(e.target.value)}
                  />
                </div>
                {error && <p className="text-red-500 mt-1 text-[11px] pt-2 pb-2 w-full">{error}</p>}
              </div>
              <div>
                <button
                  className="w-full h-[40px] md:h-[50px] mt-2 md:mt-6 pl-3 text-[#FFFFFF] bg-[#056E9C] rounded-2xl "
                  onClick={handleSubmit}
                >
                  {t("accountTogel.button")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountToggle;
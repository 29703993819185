import survey from "../../assets/Surveys/survey.png";
import { useTranslation } from "react-i18next";

export const Survey = () => {
  const { t } = useTranslation();
  const sendToEmail = "ranjit.bodare@zamplia.com";
  return (
    // <div className="py-10 font-sans text-white mx-8">
    //   <div className="mt-8 text-cente flex items-center justify-center">
    //     <div className=" bg-[#D3EBFC] rounded-lg flex flex-col-reverse items-center max-w-6xl gap-12 px-10 mx-auto md:flex-row md:justify-center md:w-55  md:gap-6">
    //       <div className="flex items-center justify-center flex-1">
    //         <div className="relative w-11/12 h-full max-w-sm lg:w-45 sm:w-10/12 aboutImg ">
    //           <img src={survey} alt="" className="w-full mb-24" />
    //         </div>
    //       </div>
    //       <div className="my-3 text-left">
    //         <p className="text-xl sm:text-2xl w-[583px] text-neutral-700 font-semibold font-['Poppins']">
    //           {t("home.survey.title")}
    //         </p>
    //         <p className="w-11/12 mx-auto leading-7 text-justify text-gray-500">
    //           {t("home.survey.description")}
    //         </p>
    //         <a
    //           href={"mailto:" + auth?.email}
    //           data-testid="contactseelink"
    //           className="after:content-['→'] after:ml-0.5 after:text-sky-700 text-sky-700 font-semibold"
    //         >
    //           {t("home.survey.link")}
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="bg-white py-12 px-4 h-full flex justify-center items-center">
      <div className="w-full bg-[#D3EBFC] lg:w-[1200px] py-16 rounded-[15px] md:rounded-[28px] flex flex-col-reverse justify-center items-center lg:flex-row lg:px-10">
        <div className="w-auto h-[15rem]">
          <img src={survey} alt="" className="w-full h-full object-contain" />
        </div>
        <div className="flex flex-col gap-6 mb-2  md:px-2 md:ml-10  justify-center items-center
          ">
          <p className="  text-base  flex justify-center items-center sm:text-2xl lg:text-[1.7rem] w-[350px] lg:w-[400px] text-neutral-700 font-semibold font-['Poppins']">
            {t("home.survey.title")}
          </p>

          <p className="text-neutral-600  text-sm w-[250px]  sm:w-[350px]  lg:w-[425px]   font-normal font-['Poppins'] leading-[22px]">
            {t("home.survey.description")}
          </p>
          <a
            href={`mailto:${sendToEmail}`}
            data-testid="contactseelink"
            className="hover:bg-[#056D9B] bg-[#056f9cf7]  sm:w-[20rem] w-[19rem] rounded-full text-center px-1 py-2 text-white text-md font-medium font-['Poppins'] leading-[22px]"
          >
            {t("home.survey.contactUs")} &#8599;
          </a>
        </div>
      </div>
    </div>
  );
};

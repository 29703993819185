import { axiosClient } from "../../../api/api";

export const getStreakCountApi = async (panelistId: number, credit_points?:number) => {
   const requestBody = { credit_points };
  return axiosClient
    .post("/api/survey/completeStatus", {
      params: { panelistId },
      data:requestBody
    })
    .then((response) => response.data);
};

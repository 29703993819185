export const postSuccessOps = (result) => {
  let userCurrentCountry: string;
  let userLangCode: string;
  let currencyName: string;
  if (result?.data?.region) {
    localStorage.setItem("current_user_region", JSON.stringify(result.data.region));
  }
  if (result?.data?.postal) {
    localStorage.setItem("current_user_postal", JSON.stringify(result.data.postal));
  }
  if (result?.data?.currency) {
    currencyName = result?.data?.currency;
    localStorage.setItem("currencyName", JSON.stringify(currencyName));
  }

  if (result?.country?.length > 0 && userCurrentCountry !== result?.country[0]) {
    userCurrentCountry = result?.country[0];
    userLangCode = result?.country[1];
    localStorage.setItem("zamplia_user_lang_code", JSON.stringify(userLangCode));
  }
  if (result?.country?.length === 0) {
    userCurrentCountry = result?.data?.country_name;
    userLangCode = "";
    localStorage.setItem("zamplia_user_lang_code", JSON.stringify(userLangCode));
  }
};

import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { updatePassword } from "./UpdatePassword.api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUpdatePasswordToken } from "./UpdatePassword.api";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import LoadingOverlay from "react-loading-overlay-ts";

const intialState = {
  emailId: "",
  new_pass: "",
  confirm_password: "",
};

export const UpdatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [confirmPassError, setConfirmPassError] = useState("");

  const convertQueryParamsStringToQueryParamsObject = (queryParam: string) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(queryParam);
  };
  const tokenValue = convertQueryParamsStringToQueryParamsObject("token");
  const toggle = () => {
    setOpen(!open);
  };
  const toggle2 = () => {
    setOpens(!opens);
  };
  const [formValue, setFormValue] = useState(intialState);
  const { new_pass, confirm_password } = formValue;
  const mutation = useMutation(() => updatePassword(tokenValue, new_pass, confirm_password), {
    onSuccess: (response: any) => {
      if (response.success) {
        toastrSuccess(response.message);
        navigate("/login");
      } else {
        toastrError(response.message);
      }
    },
    onError: async (error: any) => {
      toastrError(error.response.data.message);
    },
  });
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    let isValid = true;

    if (formValue.new_pass.length < 6) {
      setConfirmPassError("Password must be at least 6 characters");
      isValid = false;
    } else {
      setConfirmPassError("");
    }
    if (formValue.confirm_password.length === 0) {
      setConfirmPassError("Please confirm your password");
      isValid = false;
    } else if (formValue.confirm_password.length < 6) {
      setConfirmPassError("Password must be at least 6 characters long");
      isValid = false;
    } else if (formValue.new_pass !== formValue.confirm_password) {
      setConfirmPassError("Passwords do not match");
      isValid = false;
    } else {
      setConfirmPassError("");
    }
    if (formValue.new_pass.length === 0 && formValue.confirm_password.length === 0) {
      setConfirmPassError("Fill the password");
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    mutation.mutate();
  };

  const checkToken = useQuery(["token"], () => getUpdatePasswordToken(tokenValue), {
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (checkToken && checkToken.data) {
      if (!checkToken.data.success) {
        Modal.error({
          title: "Error",
          content: "Token is expired",
          onOk() {
            navigate("/login");
          },
        });
      }
    }
  }, [checkToken]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormValue({ ...formValue, [name]: trimmedValue });

    setConfirmPassError("");
  };

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={checkToken.isLoading || mutation.isLoading}
      spinner
      text="Loading..."
    >
      <div className="w-full h-screen mb-48 font-sans pt-28">
        <div className="mx-5 my-auto md:w-4/6 lg:w-2/6 md:mx-auto lg:mx-auto">
          <form className="mx-auto lg:mt-4 md:mt-3 mt-2 min-w-xl" onSubmit={handleSubmit}>
            <div className="overflow-hidden bg-white border rounded-xl p-4">
              <div className="relative p-6 px-4 py-5">
                <h2
                  data-testid="heading"
                  className="lg:text-3xl md:text-2xl text-2xl ui-sans-serif font-semibold text-center text-gray-500"
                >
                  {t("form.updatepassword.UpdateYourPassword")}
                </h2>
                <div data-testid="new_password" className="mt-10 col-span-30">
                  <label
                    htmlFor="new_password"
                    aria-label="new_password"
                    className=" label-1 text-[#777575] after:content-['*'] after:ml-0.5 after:text-red-500 "
                  >
                    {t("form.updatepassword.NewPassword")}
                  </label>
                  <div className="text-xl absolute right-8 mt-3 text-[#10739f]">
                    {new_pass.length > 0 && (
                      <>
                        {opens === false ? (
                          <AiFillEyeInvisible onClick={toggle2} />
                        ) : (
                          <AiFillEye onClick={toggle2} />
                        )}
                      </>
                    )}
                  </div>
                  <input
                    type={opens === true ? "text" : "password"}
                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    // title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                    name="new_pass"
                    id="new_pass"
                    className="input-1 border placeholder-gray-500 placeholder-opacity-50 border-gray-300 rounded-lg p-2 mt-1"
                    value={new_pass}
                    onChange={onInputChange}
                    placeholder="Password"
                  />
                </div>
                <div data-testid="password" className="mt-5 col-span-30">
                  <label
                    htmlFor="password"
                    aria-label="password"
                    className=" label-1 text-[#777575] after:content-['*'] after:ml-0.5 after:text-red-500 "
                  >
                    {t("form.updatepassword.ConfirmPassword")}
                  </label>
                  <div className="text-xl absolute right-8 mt-3 text-[#10739f]">
                    {confirm_password.length > 0 && (
                      <>
                        {open === false ? (
                          <AiFillEyeInvisible onClick={toggle} />
                        ) : (
                          <AiFillEye onClick={toggle} />
                        )}
                      </>
                    )}
                  </div>
                  <input
                    type={open === true ? "text" : "password"}
                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    // title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                    value={confirm_password}
                    name="confirm_password"
                    id="confirm_password"
                    className="input-1 border placeholder-gray-500 placeholder-opacity-50 border-gray-300 rounded-lg p-2 mt-1"
                    onChange={onInputChange}
                    placeholder="Password"
                  />
                  {confirmPassError && (
                    <p className="text-red-700 lg:text-base md:text-sm text-xm translate-x-[0px] mt-2 ml-2 font-semibold font-poppins">
                      {confirmPassError}
                    </p>
                  )}
                </div>
              </div>
              <div data-testid="button" className="px-4 py-3 text-left ">
                <button
                  type="submit"
                  className="btn-2 w-full text-lg font-medium border rounded-xl py-2 lg:mb-7 block text-center"
                >
                  {t("form.updatepassword.Submit")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};

import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Mainlogo from "../../assets/MainLogo.png";
import { ReactComponent as Navwallet } from "../../assets/SideBarIcons/Navwallet.svg";
import { ReactComponent as Navvector } from "../../assets/SideBarIcons/Navvector.svg";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import { useAuth } from "context/auth-context";
import PropTypes from "prop-types";
import { ChangeLanguage } from "components/core/ChangeLanguage";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Polygon from "../../../src/assets/SideBarIcons/Surveystreakicons/Polygon.svg";
import {
  JoinNowBonusBalanceApi,
  ReferalWalletBalance,
  StreakWalletBalanceApi,
  getPointBalanceStudiesWiseApi,
} from "../../components/Dashboard/Dasboard.api";
import { getStreakCountApi } from "../../components/Sidebar/Surveystreak/Surveystreak.api";
import { currencySymbols } from "./CurrencySymbol";
import axios from "axios";

const Navbar = ({ toggleSurveystreak, showSurveystreak }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const items = [
    {
      key: "profile_1",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/logout"}>{t("home.headers.logout")}</Link>,
    },
  ];
  const [toCurrency, setToCurrency] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState<number | undefined>(undefined);
  const [symbol, setSymbol] = useState(" ");
  // taking currency symbol
  const getCurrencySymbol = (currencyCode) => {
    return currencySymbols[currencyCode] || currencyCode;
  };
  // taking currency code from api
  const getData = async () => {
    const res = await axios.get("https://api.ipregistry.co/?key=bx6vvs1qwd9w1zdm");
    setToCurrency(res?.data?.currency?.code);
  };
  useEffect(() => {
    getData();
    setSymbol(getCurrencySymbol(toCurrency));
  }, [toCurrency]);

  const getPointsBalance = useQuery(
    ["pointBal", auth.panelistId],
    () => getPointBalanceStudiesWiseApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getReferalWalletBalance = useQuery(
    ["referalBal", auth.panelistId],
    () => ReferalWalletBalance(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getStreakWalletBalance = useQuery(
    ["streakbalance", auth.panelistId],
    () => StreakWalletBalanceApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getJoinNowBonusBalance = useQuery(
    ["getBonusPoint", auth.panelistId],
    () => JoinNowBonusBalanceApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getSurveyvirtualwallet = useQuery(
    ["streakdata"],
    () => getStreakCountApi(auth?.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const pointBalance =
    getPointsBalance && getPointsBalance?.data ? getPointsBalance?.data?.data : 0;
  const joinNowBonusBalance =
    getJoinNowBonusBalance && getJoinNowBonusBalance?.data ? getJoinNowBonusBalance?.data?.data : 0;
  const referalBal =
    getReferalWalletBalance && getReferalWalletBalance?.data
      ? getReferalWalletBalance?.data?.Data
      : 0;
  const streakBalance =
    getStreakWalletBalance && getStreakWalletBalance?.data
      ? getStreakWalletBalance?.data?.streakCount
      : 0;

  const PointBalance = pointBalance ? pointBalance : 0;
  const ReferalBalance = referalBal ? referalBal : 0;
  const StreakBalance = streakBalance ? streakBalance : 0;
  const JoinNowBonusBalance = +joinNowBonusBalance ? +joinNowBonusBalance : 0;
  const TotalWalletBalance = PointBalance + ReferalBalance + StreakBalance + JoinNowBonusBalance;
  const totalDollar = TotalWalletBalance / 100;
  useEffect(() => {
    const calculateTotalWalletDollar = async () => {
      try {
        if (toCurrency !== undefined) {
          // Fetch exchange rate from Fixer API
          const response = await axios.get(
            "https://cdn.jsdelivr.net/gh/ismartcoding/currency-api@main/latest/data.json"
          );

          const quotes = response.data.quotes;

          if (quotes[toCurrency]) {
            const rate = quotes[toCurrency];
            setCurrentCurrency(totalDollar * rate);
          } else {
            console.error("Error: Exchange rate not found for the selected currency");
          }
        }
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    calculateTotalWalletDollar();
  }, [toCurrency, totalDollar]);

  // const name = localStorage.getItem("UserName");
  // const userName = JSON.parse(name);
  const profilePic = auth?.profile_pic;
  const username = auth?.name;
  useEffect(() => {
    // Force re-render when profile_pic changes
  }, [profilePic, username]);

  return (
    <div className="fixed z-50 top-0 w-full mx-auto h-20 bg-[#FFFFFF] md:justify-start lg:justify-start  md:space-x-10 lg:space-x-10 border-gray-100 shadow-sm border-b-2 md:px-4 lg:pr-6 lg:px-2 font-poppins">
      <div className="flex py-3 md:items-center lg:items-center">
        <NavLink to="/Dashboard">
          <img src={Mainlogo} className="sm:w-[235px] sm:h-[65px] hidden md:block lg:block " />
        </NavLink>
        <div className="flex items-center justify-around flex-grow space-x-4 md:justify-end lg:justify-end">
          <NavLink to="#">
            <div className="flex px-4 py-4 rounded-full bg-sky-600 bg-opacity-5">
              <Navwallet className="w-5 h-5 text-gray-700 md:w-6 md:h-6 lg:w-6 lg:h-6" />
              <span className="relative group">
                <span className="mx-1 text-gray-600 font-poppins">
                  {+TotalWalletBalance} Points
                </span>
                <span className="absolute z-10 hidden group-hover:block bg-cyan-900 text-white text-lg py-2 top-10 px-4 w-[8rem] text-center rounded shadow-md">
                  {`${symbol} ${currentCurrency?.toFixed(2)}`}
                </span>
              </span>
            </div>
          </NavLink>
          <NavLink to="#">
            <div
              className="flex items-center justify-center md:w-24 md:h-[52px] lg:w-24 lg:h-[52px] w-[3.2rem] h-[38px] bg-green-500 bg-opacity-10 rounded-[67px] p-2"
              onClick={toggleSurveystreak}
            >
              {showSurveystreak ? (
                <img src={Polygon} alt="PloygonImg" className="fixed top-[3rem] md:top-[4rem]" />
              ) : (
                ""
              )}
              <Navvector className="w-5 h-5 text-gray-700 md:w-6 md:h-6 lg:w-6 lg:h-6" />
              <span className="text-[#54B948] mx-1 font-['Poppins']">
                {getSurveyvirtualwallet?.data?.completeCount}
              </span>
            </div>
          </NavLink>
          {/* <NavLink to="/alert">
            <NavNotify className="md:h-12 md:w-12 lg:h-12 lg:w-12 w-9 h-12 text-gray-700 font-['Poppins']" />
          </NavLink> */}
          {/* <NavLink to="" className="flex items-center font-['Poppins']">
            <AiOutlineUser className="md:text-[38px] lg:text-[38px] text-[29px] bg-[#bdbdbd] border boreder-2 rounded-full text-white font-thin font-['Poppins']" />
            <span className="hidden md:hidden lg:block md:ml-1 lg:ml-1">{auth?.name}</span>
          </NavLink> */}

          <Dropdown
            className="ml- whitespace-nowrap text-cyan-900"
            menu={{
              items,
            }}
          >
            <a className="cursor-pointer sm:flex">
              {auth.profile_pic ? (
                <div className="w-8 h-8 rounded-full lg:w-10 lg:h-10 md:w-10 md:h-10">
                  <img
                    src={auth.profile_pic}
                    alt="Profile"
                    className="object-cover w-8 h-8 overflow-hidden rounded-full lg:w-10 lg:h-10 md:w-10 md:h-10"
                  />
                </div>
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}
              <span className="hidden mt-1 ml-2 sm:flex">
                Hi! {auth.name ? auth.name.split(" ")[0] : ""}
              </span>
            </a>
          </Dropdown>
          <div className="mt-3 lg:mt-0 md:mt-0">
            <ChangeLanguage />
          </div>
        </div>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  toggleSurveystreak: PropTypes.func.isRequired,
  showSurveystreak: PropTypes.bool,
};
export default Navbar;

import React, {  useState } from 'react';
import SpinnCircle from './SpinnCircle';
import { getStreakCountApi } from "../Surveystreak.api";

import { useAuth } from "context/auth-context";


type SetShowSpinnerType = (value: boolean) => void;
const  Spinner = ({setShowSpinner}: { setShowSpinner: SetShowSpinnerType })=> {

const [result, setResult] = useState(null);
  const [spinning, setSpinning] = useState(false);

  const { auth } = useAuth();

const creditPoints = result;
const getSurveyvirtualwallet = (auth,creditPoints:number) => {
  getStreakCountApi(auth?.panelistId,creditPoints);
};
 
const getPoint = ()=>{

  if(result){
    getSurveyvirtualwallet(auth,creditPoints);
    setShowSpinner(false);
  }
};

const angles = [90,  180, 225, 270, 315];
const handleSpinClick = () => {
  if (!spinning) {
    setSpinning(true);
    
    const spinUntilDesiredValue = () => {
      const container = document.querySelector('.container') as HTMLElement;
      const randomAngle = angles[Math.floor(Math.random() * angles.length)];
      const totalRotation = 18;
      const spinningDuration = 10; 
      container.style.transition = `transform ${spinningDuration}s ease-in-out`;
      container.style.transform = `rotate(${totalRotation * 360 + randomAngle}deg)`;
      setTimeout(() => {
        const computedStyle = window.getComputedStyle(container);
        const transform = computedStyle.getPropertyValue('transform');
        const matrix: string[] = transform.split('(')[1].split(')')[0].split(',');
        const angle = Math.round(Math.atan2(parseFloat(matrix[1]), parseFloat(matrix[0])) * (180 / Math.PI));
        let point: number;
        
        const positiveAngle = angle !== -90 ? Math.abs(angle) : angle;
         if (positiveAngle === 225 || positiveAngle === 315) {
          
          spinUntilDesiredValue();
        } else {
switch (positiveAngle) {
          case 0:
            point = 55;
            break;
          case -90:
            point = 75;
            break;
          case 180:
            point = 200;
            break;
          case 45:
            point = 350;
            break;
          case 90:
            point = 150;
            break;
          case 135:
            point = 125;
            break;
          default:
            point = null;
        }
          setResult(point);
          setSpinning(false);
        }
      }, spinningDuration*1000); 
    };
    spinUntilDesiredValue();
  }
};

  return (
    <div>
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full">
        <div className="absolute z-40 w-full h-full bg-black opacity-30 lg:py-4" ></div>
        <div className="h-[30rem] w-[19rem] md:w-[25rem] md:h-[37rem] lg:h-[39rem] lg:w-[28rem] lg:top-96 md:top-96 top-80 bg-white opacity-100 rounded-[35px] absolute flex flex-col items-center  left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 lg:px-6  md:px-4  px-4  lg:py-2 md:py-0 py-0">
          <div className='flex flex-col items-center justify-center lg:h-44 md:h-40 h-32 lg:w-[22rem] md:w-[22rem] w-[16rem]  gap-2 '>
{ !result ? 

         ( <><span className='font-poppins font-bold lg:text-2xl md:text-xl text-base text-center text-[#6B6B6B]'>Spin The Wheel!</span><div className='text-center font-poppins font-normal lg:text-sm md:text-sm text-xs text-[#2B2B2B]'>

                <span>Verification We&quotll verify responses before </span>
                <span>issuing rewards. </span>
              </div></>) 
              : 
          <>
          <span className='font-poppins font-bold lg:text-2xl md:text-xl text-base text-center text-[#6B6B6B]'>Congratulation!</span>
          <div className='text-center font-poppins font-normal lg:text-sm md:text-sm text-xs text-[#2B2B2B]'>

                <span>You won {result} Points</span>
              </div>
              </>   
}
          
          </div>
        <div className='lg:h-80 md:h-72 h-64 w-80 md:w-72 flex items-center justify-center'>

         <SpinnCircle
         result = {result}
        spinning={spinning}
        handleSpinClick={handleSpinClick}
        /> 
        </div>
        <div>
          {result &&  <button className={`w-52  h-8 lg:w-72 lg:h-14 md:w-64 md:h-12 bg-[#056E9C] lg:text-base md:text-sm text-xs text-white rounded-3xl lg:mt-8 md:mt-6 mt-4`}
          onClick={getPoint}
          >
              Collect Points
            </button>}
        </div>
        </div>
      </div>
    </div>
  );
};


export default Spinner;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import axios from "axios";
import Pagination from "../Pagination/Pagination";
import { Alert } from "antd";
import { getAttemptSurvey, getMarkAsReadActive, getNotificationDetails } from "./Notifications.api";
import { Link } from "react-router-dom";
import { toastrError, toastrSuccess } from "lib/toastr";
import { FileExclamationOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { IP_REGISTRY_KEY } from "../../config/constants";

export const Notification = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [langCode, setLangCode] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [countryChangeMessage, setCountryChangeMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const queryClient = useQueryClient();
  const session_id: string = uuidv4();
  const getNotificationsData = useQuery(
    ["getNotifications", currentPage, langCode],
    () => {
      if (langCode) {
        return getNotificationDetails(currentPage, langCode, auth.panelistId);
      } else {
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const perPage = 10;
  const startIndex = (currentPage - 1) * perPage;
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = getNotificationsData?.data?.data?.filter((item) =>
    item.study_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const mutation = useMutation(
    (studyName: any) => getMarkAsReadActive(studyName, auth?.panelistId),
    {
      onSuccess() {
        toastrSuccess("Read Successfully");
        queryClient.invalidateQueries(["getNotifications"]);
      },
      onError() {
        toastrError("Something went wrong");
      },
    }
  );

  const mutationAttempt = useMutation(
    (studyName: any) => getAttemptSurvey(studyName, auth?.panelistId),
    {
      onSuccess() {
        toastrSuccess("Fetch Successfully");
        queryClient.invalidateQueries(["getNotifications"]);
      },
      onError() {
        toastrError("Something went wrong");
      },
    }
  );

  const handleMarkAsRead = (studyName) => {
    mutation.mutate(studyName);
  };

  const handleAttempt = (studyName) => {
    mutationAttempt.mutate(studyName);
  };

  const getData = async () => {
     const res = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_KEY}`);
    if (res.data.currency.code == "USD") {
      setLangCode("En-US");
    } else if (res.data.currency.code == "CAD") {
      setLangCode("English-CA");
    } else if (res.data.currency.code == "GBP") {
      setLangCode("En-US");
    } else if (res.data.currency.code == "INR") {
      setLangCode("English-IN");
    } else {
      setLangCode(res.data.location.country.name + "-" + res.data.location.country.code);
    }
    if (res && res.data) {
      if (!auth.user_country.includes(res.data.location.country.name)) {
        setCountryChangeMessage(
          <div>
            System identified you as <b>{res.data.location.country.name}</b> country user so{" "}
            <b>{res.data.location.country.name}</b> survey will be listed for you.
          </div>
        );
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="pt-36 lg:w-[75%] md:w-full w-full lg:ml-[22rem]">
      <div className="table-1 bg-white">
        <h2 className="my-5 text-2xl text-center">Notifications</h2>
        <div className="relative mx-auto mr-1 text-right text-gray-600 survey-search">
          <input
            className="h-10 px-5 pr-16 text-sm bg-white border-2 border-gray-300 rounded-lg focus:outline-none"
            type="text"
            name="search"
            placeholder={t("page.mysurvey.surveySearchPlaceholder")}
            onChange={handleSearch}
            value={searchQuery}
          />
          <button type="submit" className="mt-5 -translate-x-6 translate-y-1">
            <svg
              className="w-4 h-4 text-gray-600 fill-current"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              width="512px"
              height="512px"
            >
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </svg>
          </button>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:mx-6 lg:mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="px-5 overflow-hidden">
                {countryChangeMessage && (
                  <Alert message={countryChangeMessage} type="info" showIcon />
                )}
                <table className="min-w-full">
                  <thead className="border-b">
                    <tr>
                      <th scope="col" className="text-center table-1-th">
                        S.NO
                      </th>
                      <th scope="col" className="text-center table-1-th">
                        STUDY NAME
                      </th>
                      <th scope="col" className="text-center table-1-th">
                        LOI
                      </th>
                      <th scope="col" className="text-center table-1-th">
                        IR
                      </th>
                      <th scope="col" className="text-center table-1-th">
                        CPI
                      </th>
                      <th scope="col" className="text-center table-1-th">
                        ACTION
                      </th>
                      <th scope="col" className="text-center table-1-th">
                        SEEN
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {" "}
                    {filteredData?.length ? (
                      filteredData?.map((item: any, key: number) => (
                        <tr key={key}>
                          <td scope="col" className="text-center table-1-th">
                            {startIndex + key + 1}
                          </td>
                          <td scope="col" className="text-center table-1-th">
                            {item.study_name}
                          </td>
                          <td scope="col" className="text-center table-1-th">
                            {item.loi}
                          </td>
                          <td scope="col" className="text-center table-1-th">
                            {item.ir}
                          </td>
                          <td scope="col" className="text-center table-1-th">
                            {item.vendor_cpi}
                          </td>

                          <th scope="col" className="text-center cursor-pointer table-1-th">
                            <Link
                              className="ml-3 font-mono inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#10739f] hover:bg-[#1c85b4] px-3 py-1.5 text-base font-medium text-white shadow-sm"
                              onClick={() => {
                                handleAttempt(item.study_name),
                                  window.open(
                                    `https://zampparticipant.zamplia.com/?vid=313&SID=${item.s_id}&UID=${auth.panelistId}&cid=${item.api_client_id}&session_id=${session_id}&isMap=1`,
                                    "_blank"
                                  );
                              }}
                              to="#"
                            >
                              Attempt
                            </Link>
                          </th>
                          <th scope="col" className="text-center cursor-pointer table-1-th">
                            <Link
                              className="ml-8 font-mono inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#10739f] hover:bg-[#1c85b4] px-3 py-1.5 text-base font-medium text-white shadow-sm"
                              to="#"
                              onClick={() => handleMarkAsRead(item.study_name)}
                            >
                              Mark As Read
                            </Link>
                          </th>
                        </tr>
                      ))
                    ) : (
                      <p className="mt-6 mb-4">
                        <FileExclamationOutlined /> No Data Found
                      </p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {searchQuery.length == 0 && getNotificationsData?.data?.data?.length > 0 && (
              <Pagination
                setCurrentPage={setCurrentPage}
                totalPages={getNotificationsData.data?.totalPages}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

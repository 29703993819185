import type { FC } from "react";
import { useState } from "react";

export interface IAccordion {
  title: any;
  description: any;
  icon?: any;
}

export const Accordion: FC<IAccordion> = ({ title, description, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="w-full bg-white rounded-[17px] mb-2">
      <div
        className="p-2 cursor-pointer flex justify-between items-center"
        onClick={toggleAccordion}
      >
        <div className="flex items-center justify-center py-2">
          {icon && (
            <span className="mr-2">
              <div className="hidden md:flex w-[40px] h-[40px] lg:w-[58px] lg:h-[60px] bg-sky-700 bg-opacity-5 rounded-full items-center justify-center">
                <img src={icon} alt="" className=" w-5 h-5 md:w-8 md:h-8" />
              </div>
            </span>
          )}
          <h2 className="text-sky-700 text-[14px] md:text-base w-full font-poppins">
            {title}
          </h2>
        </div>
        <span>
          {" "}
          <svg
            className={`w-6 h-6 ${isOpen ? "transform rotate-180" : ""}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className="p-4 md:pt-0">
          <p className="text-neutral-600 mr-8 md:ml-16 mt-0 text-[.8em] md:text-base font-poppins md:leading-[1.8rem]">
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

import React, { memo, useEffect, useState } from "react";

type dataKeysType = {
  id: number;
  column: string;
  label: string;
};

export type ThElementsType = {
  scope: string;
  classes: string;
  dataKeys: dataKeysType[];
};

const MySurveysTable = ({
  thElements,
  tdElements,
  searchQuery,
  tdDataStyle,
  tdRowStyle,
  tableStyle,
}: {
  thElements: ThElementsType;
  tdElements: any;
  searchQuery: string;
  tdDataStyle: string;
  tdRowStyle: string;
  tableStyle: string;
}) => {
  const [ThElementsList, setThElementsList] = useState([]);
  const [TbodyElements, setTbodyElements] = useState([]);

  useEffect(() => {
    if (thElements) {
      setThElementsList(
        thElements.dataKeys.map((element: dataKeysType) => {
          return (
            <th key={element.id} scope={thElements.scope} className={thElements.classes}>
              {element.column}
            </th>
          );
        })
      );
    }

    if (tdElements && tdElements?.length) {
      if (TbodyElements.length > 0) {
        setTbodyElements([]);
      }
      setTbodyElements(
        tdElements?.map((element) => {
          return (
            <tr key={element.id} className={tdRowStyle}>
              {thElements?.dataKeys.map((e) => {
                return (
                  <td key={`${element._id}-${e.label}`} className={tdDataStyle}>
                    {element[e.label]}
                  </td>
                );
              })}
            </tr>
          );
        })
      );
    }
  }, [thElements, tdElements, searchQuery]);

  return (
    <div>
      <table className={`min-w-full ${tableStyle}`}>
        <thead className="border-b">
          <tr>{ThElementsList}</tr>
        </thead>
        <tbody className="text-center ">{tdElements?.length ? TbodyElements : null}</tbody>
      </table>
      <div className="flex justify-center w-full">
        {!tdElements?.length && (
          <div className="inline-block text-center">{tdElements?.length} Data Found !!</div>
        )}
      </div>
    </div>
  );
};

export default memo(MySurveysTable);

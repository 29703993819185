import { axiosClient } from "../../api/api";
export const PointsBalance = async (panelListId: number) => {
  return axiosClient
    .get("/api/survey/getPointsBalance", {
      params: { panelListId },
    })
    .then((response) => response.data);
};
export const ReferalWalletBalance = async (panelListId: number) => {
  return axiosClient
    .get("/api/survey/referralWallet", {
      params: { panelListId },
    })
    .then((response) => response.data);
};

export const StreakWalletBalanceApi = async (panelistId: number) => {
  return axiosClient
    .post("/api/survey/completeStatus", {
      params: { panelistId },
    })
    .then((response) => response.data);
};

export const JoinNowBonusBalanceApi = async (paneListId: number) => {
  return axiosClient
    .get("/api/user/getBonusPoint", {
      params: { paneListId },
    })
    .then((response) => response.data);
};

export const GetBonusPointDetailsApi = async (paneListId: number) => {
  return axiosClient
    .get("/api/user/getBonusPointDetails", {
      params: { paneListId },
    })
    .then((response) => response.data);
};

export const getPointBalanceApi = async (panelListId: number) => {
  return axiosClient
    .get("/api/user/GetPointBal", {
      params: { panelListId },
    })
    .then((response) => response.data);
};

export const getPointBalanceStudiesWiseApi = async (panelListId: number) => {
  return axiosClient
    .get("/api/user/getPointBalanceStudiesWise", {
      params: { panelListId },
    })
    .then((response) => response.data);
};
/* eslint-disable @typescript-eslint/no-unused-vars */
function showOptions(data, mKey, questionId, qLevel, isMultiSelect) {
  data = data.sort((a, b) => a.orderNo - b.orderNo);
  let options = isMultiSelect
    ? ""
    : `<option value='' data-key='${mKey}' data-current-question='${questionId}' data-question-level='${qLevel}'>Select</option>`;
  options = options.concat(
    data.map(
      (d) =>
        `<option value='${d.id}' data-key='${mKey}' data-ishierarchy='${d.isHierarchy}' data-hierarchy-question='${d.hierarchyQuestionId}' data-current-question='${questionId}' data-question-level='${d.questionLevel}'>${d.optionText}</option>`
    )
  );
  return options;
}

function showRadioOptions(questionId, data, mKey, qLevel) {
  data = data.sort((a, b) => a.orderNo - b.orderNo);
  let radioOptions = "";
  for (let i = 0; i < data.length; i++) {
    radioOptions = radioOptions.concat(
      `<input type='radio' class='showChildQuestion qid_${questionId}' name='${questionId}' id='${questionId}' value=${data[i].id} data-key='${mKey}' data-ishierarchy='${data[i].isHierarchy}' data-hierarchy-question='${data[i].hierarchyQuestionId}' data-current-question='${questionId}' data-question-level='${data[i].questionLevel}'> ${data[i].optionText} <br/>`
    );
  }
  return radioOptions;
}

export function TextBox(questionId, mKey, qLevel) {
  return `<input type='text' name='${questionId}' id='${questionId}' class='border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'/>`;
}

export function Multiselect(questionId, options, mKey, qLevel) {
  const isMultiSelect = true;
  return `<br/><select class='form-control js-example-basic-multiple showChildQuestionSelect' style='width:100%; height: 40px;' name='${questionId}' id='${questionId}' multiple='multiple'>${showOptions(
    options,
    mKey,
    questionId,
    qLevel,
    isMultiSelect
  )}</select>
`;
}

export function Dropdown(questionId, options, mKey, qLevel) {
  const isMultiSelect = false;
  return `<select class='block w-full p-2 border border-gray-300 cursor-pointer focus:outline-none rounded showChildQuestionSelect' name='${questionId}' id='${questionId}'>${showOptions(
    options,
    mKey,
    questionId,
    qLevel,
    isMultiSelect
  )}</select>`;
}

export function Radio(questionId, options, mKey, qLevel) {
  return "<br/>" + showRadioOptions(questionId, options, mKey, qLevel);
}

import React from "react";

function Points() {
  return (
    <div>
      <div className="rounded-full w-[20px] h-[20px] top-[380px] left-[1245px] bg-[#056E9C1C] pt-1">
        <div className="w-[12px] h-[12px] top-[362px] left-[1145px] rounded-full bg-[#056E9C70] ml-1 "></div>
      </div>
    </div>
  );
}

export default Points;

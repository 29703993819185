import MySurveysTable, { ThElementsType } from "components/MySurveys/MySurveysTable";
import React, { useState } from "react";
import { approvedDataHandler } from "./dataHandler";
import { usePanelistCompleteStatusData } from "hooks/usePanelistCompleteStatusData-hook";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import LoadingOverlay from "react-loading-overlay-ts";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { ApprovedRedeemRequestApi } from "./ClaimedStudiesApproved.api";

interface ICheckBoxDataType {
  id: number | string;
  points: number;
  studyName: string;
}

const ThColumns: ThElementsType = {
  scope: "col",
  classes: "table-1-th text-center",
  dataKeys: [
    { id: 1, column: "id", label: "id" },
    { id: 2, column: "Points", label: "points" },
    { id: 3, column: "Study Name", label: "studyName" },
    { id: 4, column: "Date", label: "createdAt" },
    { id: 5, column: "Status", label: "status" },
    { id: 6, column: " ", label: "checkBoxAction" },
  ],
};

export const ClaimedStudiesApproved = () => {
  const { auth } = useAuth();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [recordsPerPage] = useState(10);
  // eslint-disable-next-line
  const [approvedStudy , setApprovedStudy] = useState(false);
  const [checkedData, setCheckedData] = useState<[] | ICheckBoxDataType[]>([]);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const navigate = useNavigate();

  const data = usePanelistCompleteStatusData({
    page: currentPage,
    fromDate: "2023-12-01+14:58:53",
    type: "approved",
  });

  const handleCheckBox = (item, checkedData: [] | ICheckBoxDataType[]) => {
    if (checkedData?.some((checkedElement: ICheckBoxDataType) => checkedElement.id === item.id)) {
      setCheckedData(
        checkedData.filter((checkedElement: ICheckBoxDataType) => checkedElement.id !== item.id)
      );
    } else {
      setCheckedData([...checkedData, { id: item.id, points: item.points, studyName: item.sid }]);
    }
  };

  const statusDisplay = () => {
    return (
      <button className="px-3 py-1 text-white text-sm bg-[#54B948] rounded-md ">Approved</button>
    );
  };

  const checkBoxes = (item, checkedData: [] | ICheckBoxDataType[]) => {
    return (
      <input
       className="w-6 h-6 cursor-pointer rounded-3xl"
        type="checkBox"
        key={item.id}
        checked={checkedData?.some(
          (checkedElement: ICheckBoxDataType) => checkedElement.id === item.id
        )}
        onClick={() => handleCheckBox(item, checkedData)}
      />
    );
  };

  const totalPoints = (checkedData) => {
    return checkedData.reduce((a, b) => a + b.points, 0);
  };

   const subscribeMutation = useMutation(
    () => {
      const checkedIds = checkedData.map((item) => item.id);
      return ApprovedRedeemRequestApi(auth?.panelistId, checkedIds);
    },
    {
      onSuccess: () => {
        setApprovedStudy(true);
      },
      onError: () => {
        setApprovedStudy(false);
      },
    }
  );

  const dataSet = approvedDataHandler({
    data,
    indexOfFirstRecord,
    indexOfLastRecord,
    status: statusDisplay(),
    checkBoxAction: checkBoxes,
    checkedData,
  });
  
  const handleRedeemClick = () => {
    if (totalPoints(checkedData) > 299 && totalPoints(checkedData) < 3001) {
      subscribeMutation.mutate();
    }
  };

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={data?.isLoading || data?.isFetching}
      spinner
      text="Loading..."
    >
      <div className="flex flex-col lg:flex-row mt-2 lg:space-x-2 mb-[84px] sm:mb-0">
        <div className="w-[98vw] lg:w-[50vw] rounded-lg lg:h-[75vh] md:h-[60vh] h-[50vh] overflow-x-auto overflow-y-auto bg-white">
          <MySurveysTable
            thElements={ThColumns}
            tdElements={dataSet}
            searchQuery="searchQuery"
            tdDataStyle="table-1-td-col-2 "
            tdRowStyle=""
            tableStyle=""
          />
          <div className="flex justify-center w-full">
            <Pagination
              totalPages={data?.data?.totalPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
        {/* claim card */}
        <div className="mt-2 lg:mt-0">
          <div className="flex flex-col md:block lg:block md:w-full lg:w-[250px] bg-white px-4 py-4 lg:text-center md:text-center rounded-lg ">
            <h1 className="hidden lg:text-2xl md:block lg:block lg:text-center md:text-center">
              Redeem Points
            </h1>
            <p className="text-xs text-center text-sky-600 lg:mt-4">
              Select studies with points totaling at least 300, up to a maximum of 3000. Start
              selecting studies to claim your reward today!
            </p>
            <div className="flex justify-center w-full">
              <button
                className={`block px-8 py-3 mt-4 rounded-xl text-white ${
                  checkedData?.length &&
                  totalPoints(checkedData) > 299 &&
                  totalPoints(checkedData) < 3001
                    ? "bg-sky-600"
                    : "bg-sky-600 bg-opacity-50 cursor-not-allowed"
                }`}
                onClick={() => {
                  if (
                    checkedData?.length &&
                    totalPoints(checkedData) > 299 &&
                    totalPoints(checkedData) < 3001
                  ) {
                    handleRedeemClick();
                    navigate("/redeem/form/study", { state: { redeem_point: totalPoints(checkedData) } }  );
                  }
                }}
                disabled={
                  !checkedData?.length ||
                  totalPoints(checkedData) <= 299 ||
                  totalPoints(checkedData) >= 3001
                }
              >
                Redeem Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

import React from "react";
import {
  checkAndupdateSurveyStatusApi,
  checkUserProfileStatusApi,
} from "./../../api/comman/SurveyRedirectApi";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ZAMPLIA_SURVEYS_VENDOR__ID } from "../../config/constants";
import LoadingOverlay from "react-loading-overlay-ts";
import { GuestUserJoinNowModal } from "components/GuestUserJoin/GuestUserJoinNowModal";
import { useAuth } from "context/auth-context";

export const Quotafull = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [searchParams] = useSearchParams();
  const [uid, setUid] = React.useState("");
  const [sid, setSid] = React.useState("");
  const [vid, setVid] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [isHmacFailed, SetHmacFailed] = React.useState(false);
  const [hmacValue, setHmacValue] = React.useState("");
  const [currentUrl, setCurrentUrl] = React.useState("");
  const [isShowGuestUserPopUP, setShowGuestUserPopUP] = React.useState<boolean>(false);
  useQuery(
    ["checkAndupdateSurveyStatus"],
    () => checkAndupdateSurveyStatusApi(auth?.panelistId, sid, vid, hmacValue, currentUrl, sessionId),
    {
      enabled: !!uid,
      refetchOnWindowFocus: false,
      onSuccess: (response: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (response && response.hasOwnProperty("isHamcFailed")) {
          if (response.isHamcFailed) {
            SetHmacFailed(true);
          }
        }
      },
    }
  );

  const getUserProfileStatus = useQuery(
    ["getUserProfileStatus"],
    () => checkUserProfileStatusApi(uid),
    {
      enabled: !!uid,
      refetchOnWindowFocus: false,
      onSuccess: (response: any) => {
        if (response.success && response.data.length > 0) {
          setShowGuestUserPopUP(true);
        }
      },
    }
  );

  React.useEffect(() => {
    const hmac = searchParams.get("hmac");
    // getData();
    const UID = searchParams.get("UID");
    const SID = searchParams.get("SID");
    const SESSION_ID = searchParams.get("SESSION_ID");
    const VID = ZAMPLIA_SURVEYS_VENDOR__ID;
    if (UID && SID && VID) {
      setUid(UID);
      setSid(SID);
      setVid(VID);
      setSessionId(SESSION_ID);
      const currentFullUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?SID=" +
        SID +
        "&UID=" +
        UID +
        "&SESSION_ID=" +
        SESSION_ID;
      setCurrentUrl(currentFullUrl);
      if (hmac) setHmacValue(hmac);
    }
  }, []);

  const hideGuestUserPopup = () => {
    setShowGuestUserPopUP(false);
  };
  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={getUserProfileStatus.isLoading}
      spinner
      text="Loading..."
    >
      <div className="pt-40 mx-auto sm:h-full lg:ml-[32rem]">
        <div className="h-full p-4 sm:mt-0">
          <form className="max-w-xl mx-auto">
            <div className="overflow-hidden sm:rounded-sm">
              <div className="px-4 py-5 sm:p-6">
                {isHmacFailed ? (
                  <h2 className="text-3xl font-medium text-center text-gray-800 text-red-500">
                    {t("page.surveysecurityterminate.internal")}
                  </h2>
                ) : (
                  <h2 className="text-3xl font-medium text-center text-gray-800 text-red-500">
                    {t("page.quotafull.SurveyHave")}
                  </h2>
                )}
              </div>
              <div data-testid="button" className="flex justify-center px-4 py-3 sm:px-6">
                <a href="/my-surveys" className="btn-2">
                  {t("page.quotafull.MySurveys")}
                </a>
              </div>
            </div>
          </form>
          {isShowGuestUserPopUP && (
            <GuestUserJoinNowModal
              show={isShowGuestUserPopUP}
              hideGuestUserPopup={hideGuestUserPopup}
              uid={uid}
            />
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export const currencySymbols = {
  USD: "$", 
  EUR: "€",
  GBP: "£",
  AUD: "A$",
  CAD: "C$", 
  JPY: "¥",
  CHF: "CHF",
  SEK: "kr", 
  NOK: "kr",
  DKK: "kr",
  NZD: "NZ$",
  SGD: "S$",
  HKD: "HK$",
  KRW: "₩",
  CNY: "¥",
  INR: "₹",
  BRL: "R$",
  RUB: "₽",
  TRY: "₺",
  MXN: "Mex$", 
  ZAR: "R", 
  SAR: "﷼", 
  AED: "د.إ", 
  QAR: "﷼", 
  THB: "฿", 
  MYR: "RM", 
  PHP: "₱", 
  IDR: "Rp", 
  VND: "₫", 
  HUF: "Ft",
  CZK: "Kč", 
  PLN: "zł",
};

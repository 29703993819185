import { useEffect } from "react";

function OldZampliaSurveys() {
  useEffect(() => {
    window.location.href = "https://zampliasurveys.com";
  });
  return <div>Redirecting...</div>;
}

export default OldZampliaSurveys;

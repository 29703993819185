import { HeroSession } from "components/Hero";
// import { About } from "components/About";
// import { Reason } from "components/Reason";
import { Step } from "components/Step";
import { FAQ } from "../components/FAQ";
import { Survey } from "components/Survey/Survey";
import { ContactForm } from "components/ContactForm";
// import { useAuth } from "context/auth-context";
import LovedAboutUs from "components/LovedAboutUs/LovedAboutUs";
import RewardCompanies from "components/RewardCompanies/RewardCompanies";
import SubscribeModal from "components/SubscribeModal";

export const Home = () => {
  // const { auth } = useAuth();

  return (
    <>
      {/* {auth?.subsCount ? "" : <SubscribeModal />} */}
      <SubscribeModal />
      <HeroSession />
      <RewardCompanies />
      {/* <Reason /> */}
      <Step />
      <LovedAboutUs />
      {/* <About /> */}
      <FAQ />
      <ContactForm />
      <Survey />
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { requestPointsClaimApi } from "./ClaimListCard.api";
import { toastrError, toastrSuccess } from "lib/toastr";

const ClaimListCard = ({
  claimList,
  refreshList,
  setClaimList,
}: {
  claimList: any;
  refreshList: any;
  setClaimList: any;
}) => {
  const [totalPoints, setTotalPoints] = useState<number>(0);
  //handle insertion
  const insertToClaimList = useMutation(
    (payload: any) => {
      return requestPointsClaimApi.insertPoints(payload);
    },
    {
      onError: (err: any) => {
        if (err) {
          toastrError("Something went wrong! Please try again later");
        }
      },
      onSuccess: (response: any) => {
        //todo: handle sucessful response
        setClaimList([]);
        refreshList();
        if (response) {
          toastrSuccess("Claim request sent successfully!");
        }
      },
    }
  );

  const handleList = () => {
    return (
      <>
        <p className="">Total Points: {totalPoints}</p>
      </>
    );
  };

  // Handle claimFunction
  function handleClaimFunction() {
    console.log("handleClaimFunction");
    insertToClaimList.mutate(claimList);
  }

  // Calculate total points whenever claimList changes
  useEffect(() => {
    let sum = 0;
    claimList.forEach((item: any) => {
      sum += Number(item.points);
    });
    setTotalPoints(sum);
  }, [claimList]);

  return (
    <div className="flex flex-col md:block lg:block md:w-full bg-white px-4 py-4 lg:text-center md:text-center rounded-lg ">
      <h1 className="lg:text-2xl hidden md:block lg:block  lg:text-center md:text-center">
        ClaimList
      </h1>
      <p className="text-sky-600 text-xs lg:mt-4">
        You need points between 300 and 3000 to claim your points
      </p>
      <div className="flex flex-row items-center justify-center md:flex-col lg:flex-col">
        <section className="lg:mt-10 md:mt-4  flex items-center">
          {claimList.length ? handleList() : <p className="my-auto">No study selected</p>}
        </section>
        <button
          disabled={totalPoints < 299 || totalPoints > 3001 || insertToClaimList.isLoading}
          className={`ml-4 md:mt-8 md:ml-0 lg:mt-10 lg:ml-0 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
            totalPoints < 299 || totalPoints > 3001 || insertToClaimList.isLoading
              ? "cursor-not-allowed opacity-50"
              : ""
          }`}
          onClick={handleClaimFunction}
        >
          {insertToClaimList.isLoading ? <span>Loading...</span> : <span>Claim</span>}
        </button>
      </div>
    </div>
  );
};

export default ClaimListCard;

import React from "react";
import { useTranslation } from "react-i18next";

function Test() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mx-auto mb-40 font-sans sm:h-full pt-28">
        <div className="h-full p-4 sm:mt-0">{t("home.hero.title")}</div>
      </div>
    </div>
  );
}

export default Test;

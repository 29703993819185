import { RedeemHistoryTable } from "./RedeemHistoryTable";
import { ReferralUserTable } from "./ReferralUserTable";
export const MyReferralTable = () => {

  return (
    <div className="flex flex-col lg:flex-row md:flex-col mt-4 font-poppins">
      {/* redeem history box */}
      <div className=" rounded-[21px] bg-white lg:w-[50%] w-full h-[500px] lg:h-[450px]">
        <RedeemHistoryTable />
      </div>
      {/* Referral Users box */}
      <div className="bg-white rounded-[21px] lg:w-[50%] lg:ml-4 w-full h-[500px] lg:h-[450px] mt-4 lg:mt-0 mb-10 sm:mb-0">
        <ReferralUserTable />
      </div>
    </div>
  );
};

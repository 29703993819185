import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getRedeemRequestHistoryData } from "./MyReferral.api";
import Pagination from "components/Pagination/Pagination";
import { Referral } from "./MyReferral.interface";
import { useAuth } from "context/auth-context";
import { dateFormatHandler, YYYY_MM_DD } from "./../../lib/date-formatter-moment";
import { Tag } from "antd";

function getStatus(
  status: string | number | boolean | React.ReactElement<string> | React.ReactFragment
) {
  let btn = status;
  switch (status) {
    case "Pending":
      btn = (
        <Tag color="blue" className=" px-5 py-0.5 rounded-2xl border-none font-poppins text-sm">
          {status}
        </Tag>
      );
      break;
    case "Reject":
      btn = (
        <Tag color="red" className=" px-5 py-0.5 rounded-2xl border-none font-poppins text-sm">
          {status}
        </Tag>
      );
      break;
    case "Approved":
    case "Completed":
      btn = (
        <Tag color="green" className=" px-5 py-0.5 rounded-2xl border-none font-poppins text-sm">
          {status}
        </Tag>
      );
      break;
    default:
      btn = status;
  }
  return btn;
}

export const RedeemHistoryTable = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [currentVirtualWalletPage, setCurrentVirtualWalletPage] = useState<number>(1);

  const getReferralvirtualwallet = useQuery(
    ["virtual", currentVirtualWalletPage],
    () => getRedeemRequestHistoryData(auth.panelistId, currentVirtualWalletPage),
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    getReferralvirtualwallet.refetch();
  }, [currentVirtualWalletPage]);

  if (getReferralvirtualwallet.isLoading) {
    return (
      <div className="text-xl text-center rounded-full animate-bounce mt-[15rem]">
        <span className="visually-hidden">{t("page.referral.Loading")}</span>
      </div>
    );
  }

  const page = 5;
  const startIndex = (currentVirtualWalletPage - 1) * page;

  return (
    <div className="flex flex-col  w-full h-[555px] lg:h-[445px]">
      <div className="mt-6 ml-4 text-lg text-center font-poppins lg:text-left">
        {t("referFriend.Redeemed Requested History")}
      </div>
      <div data-testid="referral2" className="pl-2 overflow-x-auto ">
        <table className=" text-sm mt-4 ml-[0rem] sm:ml-[4rem] md:ml-[0rem] lg:ml-0 w-full">
          <thead>
            <tr>
              <div className="flex flex-row">
                <th scope="col" className="w-3/12 self-end font-semibold text-center">
                  {t("page.referral.Ids")}
                </th>
                <th scope="col" className="w-3/12 font-semibold text-center">
                  {t("page.referral.point")}
                </th>
                <th scope="col" className="w-3/12 font-semibold text-center">
                  {t("page.referral.status")}
                </th>
                <th scope="col" className="w-3/12 font-semibold text-center">
                  {t("page.referral.Date")}
                </th>
              </div>
            </tr>
          </thead>
          <tbody className="w-full h-full">
            {getReferralvirtualwallet?.data?.requestData?.length > 0 ? (
              getReferralvirtualwallet?.data?.requestData?.map((survey: Referral, i: number) => {
                return (
                  <tr key={startIndex + i + 1} className="flex flex-row">
                    <td className="w-3/12 font-normal text-center table-1-td-col-2">
                      {survey.requestId}
                    </td>
                    <td className="w-3/12 font-normal text-center table-1-td-col-2">
                      {Number.isInteger(survey.claimPoints)
                        ? survey.claimPoints
                        : Math.floor(survey.claimPoints)}
                    </td>
                    <td
                      className="w-3/12 font-normal text-center table-1-td-col-2 cursor-pointer"
                      title={survey.status === "Reject" && `Reason:- ${survey.rejectReason}`}
                    >
                      {survey.isVerify ? (
                        getStatus(survey.status)
                      ) : (
                        <Tag
                          color="volcano"
                          className="px-3 py-0.5 border-none rounded-2xl font-poppins text-sm"
                        >
                          Not Verified
                        </Tag>
                      )}
                    </td>
                    <td className="w-3/12 font-normal text-center table-1-td-col-2">
                      {dateFormatHandler(survey.createdAt, YYYY_MM_DD)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="w-full">
                <div className="absolute lg:bottom-[12rem] lg:left-[14rem] bottom-[20rem] left-[8rem] md:left-[20rem]">
                  <p className="text-sm">{t("page.mysurvey.nodata")}</p>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-5">
        {getReferralvirtualwallet?.data?.requestData &&
        getReferralvirtualwallet?.data?.requestData?.length > 0 ? (
          <div className="flex justify-center">
            <Pagination
              setCurrentPage={setCurrentVirtualWalletPage}
              totalPages={getReferralvirtualwallet?.data?.totalPages}
              currentPage={currentVirtualWalletPage}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

import type { FC } from "react";

export interface IReviewCard {
  text1: any;
  text2: any;
  profile: any;
  rating: any;
}

const ReviewCard: FC<IReviewCard> = ({ text1, text2, profile, rating }) => {
  return (
    <div className="relative w-[19.375rem] h-[5rem] bg-opacity-5 bg-sky-700 text-black border border-opacity-40 border-slate-200 px-4  py-2 rounded-[13px] flex flex-auto items-center gap-2">
      <div className="w-6 h-6 bg-yellow-400 rounded-full absolute z-10 top-3 left-0.5 text-[12px] flex items-center justify-center">
        {rating}
      </div>
      <div className="relative flex items-center justify-start">
        {/* <img src={im} alt="" /> */}
        <img
          className="absolute w-[53px] h-[54px] rounded-full border-black right-2"
          src={profile}
        />
        <div className="pl-10 w-[53px] h-[54px] bg-sky-700 bg-opacity-10 rounded-full border-black" />
      </div>
      <div className="text-[15px] font-poppins">
        <span className="text-neutral-600 lg:text-[1em] leading-1 font-normal ">{text1} </span>
        <span className="text-sky-700 font-medium lg:text-[1em] leading-1">{text2}</span>
      </div>
    </div>
  );
};

export default ReviewCard;

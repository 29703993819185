import React from "react";
import { ZAMPLIA_SURVEYS_VENDOR_ID, ZAMPLIA_SURVEYS_VENDOR__ID } from "../../../config/constants";

const MySurveysListHandler = {
  wihoutSearch: function (surveys, indexOfFirstRecord, indexOfLastRecord, attemptFunction, auth, type) {
    let points;
    let finalPoint;
    const sortedList = this.sortSurvey(surveys,type);

    return sortedList?.slice(indexOfFirstRecord, indexOfLastRecord)?.map((survey) => {
      points = (100 / 100) * survey.vendorCpi * 100;
      finalPoint = Number.isInteger(points) ? points : Math.floor(points);
      const handleAttemptButton = () => {
        attemptFunction.mutate({
          studyName: survey.studyName,
          sid: survey.s_id,
          vid: Number(ZAMPLIA_SURVEYS_VENDOR_ID),
          panelListId: auth.panelistId,
          apiClientId: survey.apiClientId,
          source: "zamplia",
          points: finalPoint,
          v_id: Number(ZAMPLIA_SURVEYS_VENDOR__ID),
          study_id: survey._id,
          affilate: auth.affiliate,
          isGuestUser: 0,
        });
      };

      return {
        ...survey,
        points: (100 / 100) * survey.vendorCpi * 100,
        finalPoint: Number.isInteger(points) ? points : Math.floor(points),
        attemptFunction: (
          <button className="btn-2" onClick={handleAttemptButton}>
            Attempt
          </button>
        ),
      };
    });
  },

  withSearch: function (surveys, searchQuery, attemptFunction, auth) {
    let points;
    let finalPoint;
    return surveys
      ?.filter((survey) => survey.studyName.toLowerCase().includes(searchQuery.toLowerCase()))
      .map((survey) => {
        points = (100 / 100) * survey.vendorCpi * 100;
        finalPoint = Number.isInteger(points) ? points : Math.floor(points);
        const handleAttemptButton = () => {
          attemptFunction.mutate({
            studyName: survey.studyName,
            sid: survey.s_id,
            vid: Number(ZAMPLIA_SURVEYS_VENDOR_ID),
            panelListId: auth.panelistId,
            apiClientId: survey.apiClientId,
            source: "zamplia",
            points: finalPoint,
            v_id: Number(ZAMPLIA_SURVEYS_VENDOR__ID),
            study_id: survey._id,
            affilate: auth.affiliate,
            isGuestUser: 0,
          });
        };

        return {
          studyName: survey.studyName,
          createdAt: survey.createdAt,
          loi: survey.loi,
          finalPoint: Number.isInteger(points) ? points : Math.floor(points),
          attemptFunction: (
            <button className="btn-2" onClick={handleAttemptButton}>
              Attempt
            </button>
          ),
        };
      });
  },

  sortSurvey: function ( surveys,type){
    if (type === 1) {
          return surveys;
    }
    else if(type===2){
      return surveys.slice()?.sort((a, b) => {
      const pointsA = (100 / 100) * a.vendorCpi * 100;
      const pointsB = (100 / 100) * b.vendorCpi * 100;
      return pointsB - pointsA;
    });
    }
    if(type===3){
      return surveys.slice()?.sort((a, b) => {
        return a.loi - b.loi;
    });
    } 
  }
};

export default MySurveysListHandler;

import React from "react";
import { RaiseQueryForm } from "../../components/RaiseQueryForm";

export const RaiseQueryPage = () => {
  return (
    <div>
      <RaiseQueryForm />
    </div>
  );
};

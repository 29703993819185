import { useEffect, useState } from "react";
import gift from "../../assets/BonusPopUp/gift.svg";
import info from "../../assets/BonusPopUp/info.svg";
import { useQueryClient } from "@tanstack/react-query";
import { BonusCard } from "components/BonusCards/BonusCards";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import { subscribeBonus, subscribeBonusPoints } from "./Subscription.api";
import { InitialState } from "./BonusPopUp.interface";
import { useAuth } from "context/auth-context";

type SubscriptionPoint = typeof InitialState;
type SubscribePayload = Omit<SubscriptionPoint, "errors">;
type ErrorBody = { status: number; message: string };

const BonusPopUp = () => {
  const { auth } = useAuth();
  const queryClient = useQueryClient();
  const [modalDetails, setModalDetails] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [subscriptionPoint, setSubscriptionPoint] = useState<SubscriptionPoint>(InitialState);
  const [showModal, setShowModal] = useState(true);
  const [hoveredCard, setHoveredCard] = useState(null);

  const points1 = modalDetails?.data[0]?.points;
  const points2 = modalDetails?.data[1]?.points;
  const points3 = modalDetails?.data[2]?.points;

  const targetPoint1 = modalDetails?.data[0]?.target_points;
  const targetPoint2 = modalDetails?.data[1]?.target_points;
  const targetPoint3 = modalDetails?.data[2]?.target_points;

  const planName1 = modalDetails?.data[0]?.planName;
  const planName2 = modalDetails?.data[1]?.planName;
  const planName3 = modalDetails?.data[2]?.planName;

  const type1 = modalDetails?.data[0]?.type;
  const type2 = modalDetails?.data[1]?.type;
  const type3 = modalDetails?.data[2]?.type;

  const subscribeMutation = useMutation(
    (payload: SubscribePayload) => {
      return subscribeBonus(payload.type, payload.points, auth?.panelistId);
    },
    {
      onSuccess: () => {
        toastrSuccess("Request Submit");
        setShowModal(false);
        queryClient.invalidateQueries(["getBonusPoint"]);
      },
      onError: (err: AxiosError) => {
        const repsonse = err.response as AxiosResponse<ErrorBody, Record<string, unknown>>;
        toastrError(repsonse?.data.message);
      },
    }
  );

  const BonusPoints = useMutation(
    () => {
      return subscribeBonusPoints();
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setModalDetails(response);
        }
      },
      onError: (err: AxiosError) => {
        const repsonse = err.response as AxiosResponse<ErrorBody, Record<string, unknown>>;
        toastrError(repsonse?.data.message);
      },
    }
  );

  useEffect(() => {
    BonusPoints.mutate();
  }, []);

  const handleSelectPoints = (type: number, points: number) => {
    localStorage.setItem("selectedPoints", "true");
    setSubscriptionPoint({ ...subscriptionPoint, type, points });
    subscribeMutation.mutate({
      type,
      points,
      panelistId: undefined,
    });
    setIsClicked(true);
  };

  return (
    <>
      {showModal ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center w-full h-full">
          <div
            className={`flex flex-col justify-center items-center bg-white rounded-[15px] px-8 py-1 `}
          >
            <div className="hidden md:block lg:mt-0 md:mt-[2.5rem]">
              <img src={gift} alt="" />
            </div>
            <span className="text-[15px] md:text-[20px] lg:text-[15px] w-[13rem] md:w-[18rem] lg:w-[21rem] text-center md:mt-3 mt-2 font-poppins">
              Choose your
            </span>
            <h2 className="text-[15px] md:text-[20px] lg:text-[20px] w-[13rem] md:w-[18rem] lg:w-[21rem] text-center font-bold font-poppins">
              Welcome Bonus
            </h2>
            <div className="flex flex-col items-center justify-center lg:gap-5 md:gap-5 gap-2 mt-2 md:mt-4 md:flex-row">
              <BonusCard
                onMouseEnter={() => setHoveredCard(1)}
                onMouseLeave={() => setHoveredCard(null)}
                Redeem="Redeem"
                SelectPoints="Select Points"
                customClass={hoveredCard === 1 ? "bg-[#056E9C]" : "bonus-gradiant "}
                pointClass={
                  hoveredCard === 1
                    ? " bg-[#0D78A8] bg-opacity-80 text-white"
                    : "bg-sky-700 bg-opacity-5"
                }
                paymentClass={
                  hoveredCard === 1 ? "bg-[white] text-[#0D78A8]" : "bg-[#9EC6D8]  text-white"
                }
                selectPointClass={
                  hoveredCard === 1
                    ? "bg-[#0D78A8] text-[white] border border-white border-solid hover:bg-[#0D78A8] bg-opacity-40"
                    : "bg-[bonus-gradiant] text-[black] border border-[#E3E3E3] border-solid hover:bg-sky-700 hover:bg-opacity-5"
                }
                RedeemClass={hoveredCard === 1 ? "text-white" : ""}
                payClass=" lg:text-sm"
                payment={`${planName1}`}
                points={points1}
                FromPoints={`From  ${targetPoint1} Points`}
                onClickSelectPoints={() => handleSelectPoints(type1, points1)}
                disabled={isClicked}
              />
              <BonusCard
                onMouseEnter={() => setHoveredCard(2)}
                onMouseLeave={() => setHoveredCard(null)}
                Redeem="Redeem"
                SelectPoints="Select Points"
                customClass={
                  hoveredCard === 1 || hoveredCard === 3 ? "bonus-gradiant " : "bg-[#056E9C]"
                }
                pointClass={
                  hoveredCard === 1 || hoveredCard === 3
                    ? "bg-sky-700 bg-opacity-5"
                    : " bg-[#0D78A8] bg-opacity-80 text-white"
                }
                paymentClass={
                  hoveredCard === 1 || hoveredCard === 3
                    ? "bg-[#9EC6D8]  text-white"
                    : "bg-[white] text-[#0D78A8]"
                }
                selectPointClass={
                  hoveredCard === 1 || hoveredCard === 3
                    ? "bg-[bonus-gradiant] text-[black] border border-[#E3E3E3] border-solid hover:bg-sky-700 hover:bg-opacity-5"
                    : "bg-[#0D78A8] text-[white] border border-white border-solid hover:bg-[#0D78A8] bg-opacity-40"
                }
                payClass=" "
                RedeemClass={hoveredCard === 1 || hoveredCard === 3 ? "" : "text-white"}
                payment={`${planName2}`}
                points={points2}
                FromPoints={`From  ${targetPoint2} Points`}
                onClickSelectPoints={() => handleSelectPoints(type2, points2)}
                disabled={isClicked}
              />
              <BonusCard
                onMouseEnter={() => setHoveredCard(3)}
                onMouseLeave={() => setHoveredCard(null)}
                Redeem="Redeem"
                SelectPoints="Select Points"
                customClass={hoveredCard === 3 ? "bg-[#056E9C]" : "bonus-gradiant "}
                pointClass={
                  hoveredCard === 3
                    ? " bg-[#0D78A8] bg-opacity-80 text-white"
                    : "bg-sky-700 bg-opacity-5"
                }
                paymentClass={
                  hoveredCard === 3 ? "bg-[white] text-[#0D78A8]" : "bg-[#9EC6D8]  text-white"
                }
                selectPointClass={
                  hoveredCard === 3
                    ? "bg-[#0D78A8] text-[white] border border-white border-solid hover:bg-[#0D78A8] bg-opacity-40"
                    : "bg-[bonus-gradiant] text-[black] border border-[#E3E3E3] border-solid hover:bg-sky-700 hover:bg-opacity-5"
                }
                RedeemClass={hoveredCard === 3 ? "text-white" : ""}
                payClass="lg:text-sm"
                payment={`${planName3}`}
                points={points3}
                FromPoints={`From  ${targetPoint3} Points`}
                onClickSelectPoints={() => handleSelectPoints(type3, points3)}
                disabled={isClicked}
              />
            </div>
            <div className="font-poppins font-normal w-fit px-4 pt-2 mt-1 md:mt-4 lg:text-sm md:text-sm text-[0.60rem] rounded-md h-fit text-center bg-[#0D78A8] bg-opacity-[5%]">
              <div className="flex items-center">
                <img src={info} alt="" />
                <span>Withdraw coins for cash or gift cards.</span>
              </div>
              <div>100 Points = £0.78</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BonusPopUp;

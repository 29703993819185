export function dataHandler(payload) {
  const { data, indexOfFirstRecord, indexOfLastRecord, actionFunction, claimList } = payload;
  return data?.slice(indexOfFirstRecord, indexOfLastRecord).map((survey: any) => {
    const handleActionButton = () => {
      //some logic
      actionFunction(survey);
    };
    survey.points = Number(survey.vendorCpi * 100).toFixed(0);

    const isInClaimList = claimList.length
      ? claimList.some((data: any) => data._id === survey._id)
      : false;
    return {
      ...survey,
      actionFunction: (
        <button className="btn-2" onClick={() => handleActionButton()}>
          {!isInClaimList ? "Add" : "Remove"}
        </button>
      ),
    };
  });
}

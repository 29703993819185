import { Radio, RadioChangeEvent } from "antd";
import { AuthContextType } from "context/auth-context";
import React, { useEffect } from "react";
export type reqDataType = {
  panelListId: string | number;
  type: number | number;
};
type GroupButtonType = { id: number; name: string };
export const ButtonGroup = ({
  reqData,
  setReqData,
  userAuthenticationData,
  setCurrentPage,
  allButtons,
}: {
  reqData: reqDataType;
  setReqData: React.Dispatch<React.SetStateAction<reqDataType>>;
  userAuthenticationData: AuthContextType;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  allButtons: GroupButtonType[];
}) => {
  function handleTabChange(e: RadioChangeEvent) {
    //handle tab change
    setReqData({
      panelListId: userAuthenticationData.auth.panelistId,
      type: (e.target as any).value,
    });
    setCurrentPage(1);
  }

  useEffect(() => {
    console.log(reqData.type);
  }, [reqData.type]);

  return (
    <div>
      <div className="">
        <Radio.Group value={reqData.type} onChange={handleTabChange}>
          {allButtons.map((button) => (
            <Radio.Button key={button.id} value={button.id} checked={button.id === reqData.type}>
              {button.name}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSurveyData, addParticipant } from "../../components/MySurveys/MySurveys.api";
import { useAuth } from "context/auth-context";
import axios from "axios";
import Pagination from "../Pagination/Pagination";
import { AttemptDataType } from "components/MySurveys/intial-data";
import { postSuccessOps } from "components/MySurveys/utils/onSurveyFetchedSucessful";
import MySurveysListHandler from "components/MySurveys/utils/MySurveysListHandler";
import SurveyCard from "./SurveyCard";
import NavCard from "components/Dashboard/NavCard";
import SurveysSub from "./Menus/SurveysSub";
import { QualificationPopUp } from "./QualificationPopup";
import { IP_REGISTRY_KEY } from "../../config/constants";
const useSurveyQuery = function (country, langCode, userId) {
  const { data, ...rest } = useQuery(
    ["survey", country],
    () =>
      getSurveyData({
        userId,
        page: 1,
        userLangCode: langCode,
        sortColumnName: "Date",
        sortColumnOrder: "DESC",
        country,
      }),
    {
      enabled: !!country,
      refetchOnWindowFocus: false,
      onSuccess: (result) => {
        postSuccessOps(result);
      },
    }
  );
  return {
    surveys: data,
    surveyLength: data?.length,
    pages: Math.ceil(data?.length / 10),
    ...rest,
  };
};
export const SurveyList = ({type}:any) => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [country, setCountry] = useState("");
  const [langCode, setLangCode] = useState("");
  const [searchQuery] = useState(null);
  const [countryChangeMessage, setCountryChangeMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [noDataMsg, setNoDataMsg] = useState(false);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const { surveyLength, surveys, isLoading, refetch } = useSurveyQuery(
    country,
    langCode,
    auth.panelistId
  );
  const [showQualificationPopup, setShowQualificationPopUp] = useState(false);
  const [selectedSurveyData, setSelectedSurveyData] = useState(null);

  const attemptFunction = useMutation(
    (payload: AttemptDataType) => {
      return addParticipant({ ...payload });
    },
    {
      onError: (e) => alert(e),
      onSuccess: (response, variable) => {
        setShowQualificationPopUp(false);
        if (response && variable.sid) {
          const redirectUrl = `https://zampparticipant.zamplia.com/?vid=313&SID=${variable.sid}&UID=${auth.panelistId}&cid=${variable.apiClientId}&session_id=${response.session_id}&isMap=1`;
          window.open(redirectUrl);
        }
      },
    }
  );

  const getData = async () => {
     const res = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_KEY}`);
    if (res.data.currency.code === "USD") {
      setCountry("USA");
      setLangCode("En-US");
    } else if (res.data.currency.code == "CAD") {
      setCountry("Canada");
      setLangCode("English-CA");
    } else if (res.data.currency.code == "GBP") {
      setCountry("UK");
      setLangCode("En-US");
    } else if (res.data.currency.code == "INR") {
      setCountry("India");
      setLangCode("English-IN");
    } else {
      setCountry(res.data.location.country.name);
      setLangCode(res.data.location.country.name + "-" + res.data.location.country.code);
    }
    if (res && res.data) {
      if (auth?.user_country?.includes(res.data.location.country.name)) {
        setCountryChangeMessage(
          <div>
            System identified you as <b>{res.data.location.country.name}</b> country user so{" "}
            <b>{res.data.location.country.name}</b> survey will be listed for you.
          </div>
        );
      } else {
        setCountryChangeMessage(null);
      }
    }
  };
  // Effect to reset current page when type prop changes
  useEffect(() => {
    setCurrentPage(1);
  }, [type]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!isLoading && (searchQuery === "" || searchQuery === null)) {
      refetch();
    }
  }, [searchQuery, isLoading]);

  let surveysSet = [];
  if (surveys && surveys.length > 0) {
    surveysSet = MySurveysListHandler?.wihoutSearch(
      surveys,
      indexOfFirstRecord,
      indexOfLastRecord,
      attemptFunction,
      auth,
      Number(type)
    );
  }


  useEffect(() => {
    if (surveysSet?.length === 0) {
      setNoDataMsg(true);
    } else {
      setNoDataMsg(false);
    }
  }, [surveysSet]);

  const ClosePupup = () => {
    setShowQualificationPopUp(false);
  };

  const ShowPopup = (surveyData: any) => {
    setSelectedSurveyData(surveyData);
    setShowQualificationPopUp(true);
  };

  return (
    <>
      {showQualificationPopup && (
        <QualificationPopUp
          attemptFunction={attemptFunction.mutate}
          ShowPopup={ClosePupup}
          setSelectedSurveyData={selectedSurveyData}
        />
      )}
      <div className="flex flex-row items-center justify-center h-full px-4 scroll-smooth lg:w-[98vw]">
        <div className="mt-24 lg:ml-[16rem] lg:px-4 w-full">
          <div className="mb-5 md:mb-6">
            <NavCard />
          </div>
          <div className=" text-neutral-600 md:text-base lg:text-base text-xs font-['Poppins'] lg:ml-8 md:ml-3 ml-3">
            {countryChangeMessage && <div>{countryChangeMessage}</div>}
          </div>
          <div className="block md:block lg:hidden">
            <SurveysSub />
          </div>
          <div className="bg-white lg:h-full mb-20 rounded-[22px] mt-4 md:mx-0 h-full w-full lg:w-[76vw] pb-20 lg:pb-0 md:pb-0 mx-0">
            <div className='text-neutral-500 text-lg font-semibold font-["Poppins"] md:text-center lg:text-start text-center pt-6 pb-8 lg:pb-8 md:pb-8 lg:pl-16'>
              Best Surveys for you!
            </div>
            {noDataMsg && !isLoading && (
              <div className="inset-0 flex items-center justify-center text-base font-normal md:inset-0 lg:inset-0 md:mb-14 text-neutral-600 font-Poppins">
                No Data Found !!
              </div>
            )}
            {isLoading && (
              <div className="">
                <div className="text-2xl text-center rounded-full animate-bounce ">
                  <h1 className="visually-hidden">{t("page.referral.Loading")}</h1>
                </div>
              </div>
            )}
            <div>
              <div className="flex flex-wrap items-center md:justify-center lg:justify-start justify-center lg:gap-5 md:gap-4 gap-[0.5rem] lg:ml-16">
                {surveysSet?.map((surveyData, index) => (
                  <SurveyCard
                    key={index}
                    ShowPopup={ShowPopup}
                    minutes={surveyData.loi}
                    points={Math.floor(surveyData.points)}
                    surveyName={surveyData.studyName}
                    attemptFunction={attemptFunction.mutate}
                    surveyData={surveyData}
                  />
                ))}
              </div>

              {surveysSet?.length > 0 ? (
                <div className="flex md:justify-center lg:justify-end lg:pr-[3.5rem] justify-center mt-[3rem] md:mt-12 lg:mt-12 font-poppins">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={Math.ceil(surveyLength / 10)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SurveyList;

import { axiosClient } from "../../api/api";
export const getHistory = async (panelListId: number, page: number) => {
  return axiosClient
    .get("/api/survey/getSurveyHistory", {
      params: {
        panelListId,
        page,
      },
    })
    .then((response) => response.data);
};

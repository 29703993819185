import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Alert, Row, Col } from "antd";
import {
  checkAffiliatesUserExistApi,
  affiliatePostBackApi,
  createAffiliatePanelListUserApi,
  updateAffiliateVoxcoAttributeApi,
} from "./ConfirmAccount.api";
import { Link, useSearchParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { toastrError } from "./../../lib/toastr";

export type confirmAccountPayloadType = {
  panelistId: number;
  activationCode: string;
};

export type confirmAccountponseType = {
  activationToken: string;
};

const ConfirmAccount = () => {
  const [searchParams] = useSearchParams();
  const [isUserExist, setUserExist] = React.useState(1);
  const [sid, setSid] = React.useState("");
  const [geo, setGeo] = React.useState("");
  const [panelListId, setPanelListId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [clickId, setClickId] = React.useState<any>(0);

  // const activateAccount = useMutation(
  //   // eslint-disable-next-line
  //   (payload: confirmAccountPayloadType): any => {
  //     return confirmAccountApi({
  //       panelistId: payload.panelistId,
  //       activationCode: payload.activationCode,
  //     });
  //   },
  //   {
  //     onSuccess: () => {
  //       navigate("/login");
  //     },
  //     onError: () => {
  //       toastrError("Something went wrong!!");
  //     },
  //   }
  // );

  const checkAffilateUserIsExist = useMutation(
    (userId: string): any => {
      return checkAffiliatesUserExistApi(userId);
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setUserExist(response.isExist);
        }
      },
      onError: () => {
        toastrError("Something went wrong!!");
      },
    }
  );

  function maxBountyPostBackHit() {
    if (sid === "max_bounty" && clickId !== "") {
      if (geo === "US") {
        return (
          <img
            src={`https://www.chant3rm1.com/lead_nc.asp?o=21066&r=lead&d=${clickId}&i=${clickId}`}
            style={{ display: "none" }}
          />
        );
      } else if (geo === "CA") {
        return (
          <img
            src={`https://www.chant3rm1.com/lead_nc.asp?o=21174&r=CA&d=${clickId}&i=${clickId}`}
            style={{ display: "none" }}
          />
        );
      }
    }
  }

  //call affiliate url
  useEffect(() => {
    if (!isUserExist && sid !== "" && panelListId !== "" && userId !== "") {
      let postBackUrl: string;
      if (clickId && sid === "interest_media") {
        postBackUrl = "https://imtrk.go2cloud.org/aff_lsr?transaction_id=" + clickId;
        affiliatePostBackApi(postBackUrl);
      } else if (sid === "monetise") {
        postBackUrl =
          "https://monetisetrk.co.uk/p.ashx?a=1068&e=1232&f=pb&r=" + clickId + "&t=" + clickId;
        affiliatePostBackApi(postBackUrl);
      } else if (sid === "max_bounty") {
        if (geo === "US") {
          postBackUrl = `https://www.chant3rm1.com/lead_nc.asp?o=21066&r=lead&d=${clickId}&i=${clickId}`;
        } else if (geo === "CA") {
          postBackUrl = `https://www.chant3rm1.com/lead_nc.asp?o=21174&r=CA&d=${clickId}&i=${clickId}`;
        }
      }
      const createAffiliateBody = {
        panelListId,
        geo,
        clickId,
        sid,
        userId,
        url: postBackUrl || "url not found",
      };
      createAffiliatePanelListUserApi(createAffiliateBody);
      const reqObjectAttr1 = {
        pid: panelListId,
        trigger: "updateattr",
        attrid: 150,
        accesskey: "e8e6d3f0-da95-40e6-947a-baa5ff959b26",
        attrval: sid,
      };
      const reqObjectAttr2 = {
        pid: panelListId,
        trigger: "updateattr",
        attrid: 479,
        accesskey: "e8e6d3f0-da95-40e6-947a-baa5ff959b26",
        attrval: sid,
      };
      const reqObjectAttr3 = {
        pid: panelListId,
        trigger: "updateattr",
        attrid: 161,
        accesskey: "e8e6d3f0-da95-40e6-947a-baa5ff959b26",
        attrval: sid,
      };
      const sendData = {
        pid: panelListId,
        trigger: "narrativeinvite",
        accesskey: "e8e6d3f0-da95-40e6-947a-baa5ff959b26",
      };
      updateAffiliateVoxcoAttributeApi(reqObjectAttr1);
      updateAffiliateVoxcoAttributeApi(reqObjectAttr2);
      updateAffiliateVoxcoAttributeApi(reqObjectAttr3);
      updateAffiliateVoxcoAttributeApi(sendData);
    }
  }, [sid, isUserExist]);

  useEffect(() => {
    const params = searchParams.get("params");
    if (params) {
      const decodeUrl = atob(params);
      // const params = Object.fromEntries(urlSearchParams.entries());
      const urlSearchParams = new URLSearchParams(decodeUrl);
      const urlSearchObject = Object.fromEntries(urlSearchParams.entries());
      if (urlSearchObject.userId) {
        setSid(urlSearchObject.sid);
        setGeo(urlSearchObject.geo);
        setPanelListId(urlSearchObject.panelListId);
        setClickId(urlSearchObject.clickid);
        setUserId(urlSearchObject.userId);
        checkAffilateUserIsExist.mutate(urlSearchObject.userId);
      }
    } else {
      alert("Invalid paramater. We are redirecting to you on the login page.");
      window.location.href = "/login";
    }
  }, []);

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={checkAffilateUserIsExist.isLoading}
      spinner
      text="Loading..."
    >
      <div className="pt-60">
        <Row>
          <Col span={10} offset={7}>
            <Alert message="Your account confimed successfully." type="success" showIcon />
            <div className="mt-8 text-center">
              <Link to={"/login"} className="btn-bs btn-primary">
                Login Your Account
              </Link>
            </div>
            {/* max bounty post back hit */}
            {maxBountyPostBackHit()}
          </Col>
        </Row>
      </div>
    </LoadingOverlay>
  );
};

export default ConfirmAccount;

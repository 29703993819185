import { NavLink, useLocation } from "react-router-dom";

function SurveysSub() {
  const location = useLocation();
  const isActive = (NavLink) => location.pathname.includes(NavLink);

  return (
    <div>
      <ul className="flex justify-center items-center md:text-base text-sm gap-2 md:gap-[3rem] mt-6 font-poppins">
        <li
          className={`flex justify-center items-center w-[6.5rem] h-10 md:w-[8.8rem] md:h-[3rem] text-sky-700 rounded-3xl border border-sky-700 border-opacity-60 cursor-pointer ${
            isActive("/my-surveys")
              ? "bg-cyan-600 bg-opacity-25"
              : "hover:bg-sky-700 hover:bg-opacity-5"
          }`}
        >
          <NavLink to="/my-surveys">Best Match</NavLink>
        </li>
        <li
          className={`flex justify-center items-center w-[6.5rem] h-10 md:w-[8.8rem] md:h-[3rem] text-sky-700 rounded-3xl border border-sky-700 border-opacity-60 cursor-pointer ${
            isActive("/short-survey")
              ? "bg-cyan-600 bg-opacity-25"
              : "hover:bg-sky-700 hover:bg-opacity-5"
          }`}
        >
          <NavLink to="/short-survey">Short Surveys</NavLink>
        </li>
        <li
          className={`flex justify-center items-center w-[6.5rem] h-10 md:w-[8.8rem] md:h-[3rem] text-sky-700 rounded-3xl border border-sky-700 border-opacity-60 cursor-pointer ${
            isActive("/most-point")
              ? "bg-cyan-600 bg-opacity-25"
              : "hover:bg-sky-700 hover:bg-opacity-5"
          }`}
        >
          <NavLink to="/most-point">Most Points</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SurveysSub;

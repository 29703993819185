import ConfirmAccount from "components/ConfirmAccount/ConfirmAccount";

const ConfirmAccountPage = () => {
  return (
    <div className="container mt-0">
      <div>
        <ConfirmAccount />
      </div>
    </div>
  );
};

export default ConfirmAccountPage;

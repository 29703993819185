import React from "react";

const SvgLeftQoute = () => {
  return (
    <svg
      width="60"
      height="60"
      className="inline-block"
      style={{ display: "inline", paddingTop: "-59px", marginLeft: "-59px", marginTop: "-75px" }}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 15C46.3261 15 47.5979 15.5268 48.5355 16.4645C49.4732 17.4021 50 18.6739 50 20V27.5C50 30.25 47.75 32.5 45 32.5H37.5C37.5 34.4891 38.2902 36.3968 39.6967 37.8033C41.1032 39.2098 43.0109 40 45 40H47.5C48.163 40 48.7989 40.2634 49.2678 40.7322C49.7366 41.2011 50 41.837 50 42.5C50 43.163 49.7366 43.7989 49.2678 44.2678C48.7989 44.7366 48.163 45 47.5 45H45C41.6848 45 38.5054 43.683 36.1612 41.3388C33.817 38.9946 32.5 35.8152 32.5 32.5V20C32.5 18.6739 33.0268 17.4021 33.9645 16.4645C34.9021 15.5268 36.1739 15 37.5 15H45ZM22.5 15C23.8261 15 25.0979 15.5268 26.0355 16.4645C26.9732 17.4021 27.5 18.6739 27.5 20V27.5C27.5 30.25 25.25 32.5 22.5 32.5H15C15 34.4891 15.7902 36.3968 17.1967 37.8033C18.6032 39.2098 20.5109 40 22.5 40H25C25.663 40 26.2989 40.2634 26.7678 40.7322C27.2366 41.2011 27.5 41.837 27.5 42.5C27.5 43.163 27.2366 43.7989 26.7678 44.2678C26.2989 44.7366 25.663 45 25 45H22.5C19.1848 45 16.0054 43.683 13.6612 41.3388C11.317 38.9946 10 35.8152 10 32.5V20C10 18.6739 10.5268 17.4021 11.4645 16.4645C12.4021 15.5268 13.6739 15 15 15H22.5Z"
        fill="url(#paint0_linear_517_310)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_517_310"
          x1="30"
          y1="5"
          x2="60"
          y2="45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#056E9C" />
          <stop offset="1" stopColor="#7BB8D2" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgLeftQoute;

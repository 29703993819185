import { useQuery } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { getPanelListCompletedData } from "pages/MyReferralPage/MyReferralPage.api";

export const usePanelistCompleteStatusData = ({ page, fromDate, type }) => {
  //not empty
  const { auth } = useAuth();
  const data = useQuery(
    ["panel", fromDate, page, type],
    () => {
      return getPanelListCompletedData(auth.panelistId, page, fromDate, type);
    },
    {
      enabled: !!fromDate,
      refetchOnWindowFocus: false,
    }
  );
  return data;
};

export const reqDataIntialState = {
  panelListId: +"",
  type: "surveys",
};

export const DataIntialState = {
  panelListId: +"",
  studyId: +"",
  points: +"",
  p_id: +"",
};

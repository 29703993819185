import { axiosClient } from "../../api/api";

export const requestPointsClaimApi = {
  insertPoints: async (payload) => {
    return axiosClient
      .post("/api/survey/addClaimRequests", {
        body: {
          data: payload,
        },
      })
      .then((response) => response.data);
  },
};

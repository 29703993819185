import React from "react";
import { useTranslation } from "react-i18next";
import Constant from "../../constraints/constant.json";


export function PrivacyPolicy() {
  const { t } = useTranslation();
  
  return (
    <div id="privacy-policy" className=" mx-auto font-sans pt-28 bg-white">
      <div className="flex flex-col w-10/12 mx-auto overflow-hidden bg-white lg:flex lg:w-8/12 rounded-xl">
        <div>
          <h1
            data-testid="Privacy Policy"
            className="sm:py-6 sm:mt-24 sm:mr-24 sm:text-5xl sm:font-bold text-2xl font-bold "
          >
            {t("page.policy.Privacy")}{" "}
          </h1>
        </div>
        <div className=" text-sm">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 mt-5  ">
            {t("page.policy.Introduction")}
          </h2>
        </div>
        <div className="flex flex-col">
          <h4 className="flex py-2 text-base sm:text-base font-bold sm:mr-60">
            <span className="  flex font-bold  text-base sm:text-lg flex-wrap mb-2 ">
              {t("page.policy.Effective")}
            </span>{" "}
          </h4>
          <p>{t("page.policy.logitLine")}</p>

          <p className="sm:py-2">{t("page.policy.thirdLine")}</p>
          <p className="sm:py-2">{t("page.policy.fourthLine")}</p>
          <p className="sm:py-2">{t("page.policy.fifthLine")}</p>
          <p className="sm:py-2">{t("page.policy.sixthLine")}</p>
          <p className="sm:py-2">{t("page.policy.seventhLine")}</p>
          <p className="sm:py-2">{t("page.policy.eighthLine")}</p>
          <p className="py-2">{t("page.policy.ninethLine")}</p>
        </div>
        <div className="mt-1">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2 ">{t("page.policy.Collection")}</h2>
          <p>{t("page.policy.Logit")}</p>

          <p className="py-2 before:content-['*'] after:ml-0.5"> {t("page.policy.online")}</p>
          <p className="py-2 before:content-['*'] after:ml-0.5"> {t("page.policy.may")}</p>
          <p className="py-2 before:content-['*'] after:ml-0.5"> {t("page.policy.personal")} </p>
          <p className="py-2 before:content-['*'] after:ml-0.5"> {t("page.policy.through")}</p>
          <p className="py-2 before:content-['*'] after:ml-0.5"> {t("page.policy.identifiable")}</p>
          <p className="py-2 before:content-['#'] after:ml-0.5">
            {" "}
            {t("page.policy.identifiable1")}
          </p>
          <p className="py-2 before:content-['#'] after:ml-0.5">
            {" "}
            {t("page.policy.identifiable2")}
          </p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2"> {t("page.policy.Data")}</h2>
          <p>{t("page.policy.electronically")}</p>
          <p className="py-2">{t("page.policy.personals")}</p>
          <p className="py-2">{t("page.policy.Group")}</p>
          <p className="py-2 after:content-[':'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.collects")}{" "}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.opinion")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.incentives")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.verify")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.suitability")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.account")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.identity")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.personalize")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.relevant")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.respond")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.deliver")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.analytics")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.plicy.browsing")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.offerings")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.aggregated")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.meet")}
          </p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Consent")}</h2>
          <p>{t("page.policy.reasonably")}</p>
          <p className="py-2">{t("page.policy.parties")}</p>
          <p className="py-2">{t("page.policy.time")}</p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Datas")}</h2>
          <p>{t("page.policy.processed")}</p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Children")}</h2>
          <p>{t("page.policy.without")}</p>
        </div>
        <div>
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Sharing")}</h2>
          <p>{t("page.policy.law")}</p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.disclose")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.databases")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.companies")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            {t("page.policy.judgment")}
          </p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Security")}</h2>
          <p>{t("page.policy.governance")}</p>
          <p className="py-2">{t("page.policy.require")}</p>
          <p className="py-2">{t("page.policy.confidentiality")}</p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Retention")}</h2>
          <p>{t("page.policy.sufficient")}</p>
          <p className="py-2">{t("page.policy.retained")}</p>
          <p className="py-2">{t("page.policy.further")}</p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Links")}</h2>
          <p>{t("page.policy.responsibility")}</p>
        </div>
        <div className="mt-3">
          <h2 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Rights")}</h2>
          <p>{t("page.policy.legal")}</p>
          <p className="py-2">{t("page.policy.organizations")}</p>
          <p className="py-2">{t("page.policy.GDPR")}</p>
          <p className="py-2 after:content-[':'] after:ml-0.5 after:text-black-500">
            {t("page.policy.following")}
          </p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.be")}
          </h4>
          <p>{t("page.policy.keep")}</p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.access")}
          </h4>
          <p>
            {t("page.policy.contact")}{" "}
           <a className="text-sky-600" href={"mailto:" + "dataprotection@logitgroup.com"}>
              {Constant.POLICY_LOGITGROUP}
            </a>{" "}
            {t("page.policy.holds")}
          </p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.rectification")}
          </h4>
          <p>{t("page.policy.inaccurate")}</p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.erasure")}
          </h4>
          <p>{t("page.policy.us")}</p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.restrict")}
          </h4>
          <p>{t("page.policy.datas")}</p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.rightto")}
          </h4>
          <p>{t("page.policy.services")}</p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.object")}
          </h4>
          <p>{t("page.policy.circumstances")}</p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.lodge")}
          </h4>
          <p>{t("page.policy.which")}</p>
        </div>
        <div>
          <h1 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Disclosures")}</h1>
          <p>{t("page.policy.more")}</p>
          <br />
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.know")}
          </h4>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {t("page.policy.informed")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {t("page.policy.point")}
          </p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.sold")}
          </h4>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {t("page.policy.neverBeSold")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {t("page.policy.pertain")}
          </p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.personalsi")}
          </h4>
          <p>{t("page.policy.never")}</p>
        </div>
        <div>
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.ones")}
          </h4>
          <p>
            {t("page.policy.pcontact")}{" "}
            <a className="text-sky-600" href={"mailto:" + "dataprotection@logitgroup.com"}>
              {Constant.POLICY_LOGITGROUP}
            </a>{" "}
            {t("page.policy.wish")}
          </p>
          <br />
          <h4 className="flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.price")}
          </h4>
        </div>
        <div className="mt-3">
          <h2 className=" flex py-2 text-sm sm:text-base font-bold sm:mr-60">
            {t("page.policy.California")}
          </h2>
          <p className="after:content-[':'] after:ml-0.5 after:text-black-500">
            {t("page.policy.collected")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {t("page.policy.Identifiers")}
            </b>{" "}
            {t("page.policy.postal")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {t("page.policy.records")}
            </b>{" "}
            {t("page.policy.telephone")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {t("page.policy.Characteristics")}
            </b>{" "}
            {t("page.policy.religion")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {t("page.policy.informations")}{" "}
            </b>{" "}
            {t("page.policy.tendencies")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {" "}
              {t("page.policy.others")}{" "}
            </b>{" "}
            {t("page.policy.history")}
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            <b>{t("page.policy.Geolocation")}</b>
          </p>
          <p className="py-2 before:content-['*'] after:ml-0.5 after:text-black-500">
            {" "}
            <b>{t("page.policy.Professional")}</b>
          </p>
        </div>
        <div>
          <h3 className="flex font-bold  text-lg flex-wrap mb-2">
            {t("page.policy.changepolicy")}
          </h3>
          <p>{t("page.policy.review")}</p>
          <p className="py-2">{t("page.policy.March")}</p>
        </div>
        <div>
          <h3 className="flex font-bold  text-lg flex-wrap mb-2">{t("page.policy.Contactus")} </h3>
          <p>{t("page.policy.exercise")}</p>
          <p>
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {t("page.policy.byemail")}
            </b>{" "}
            <a className="text-sky-600" href={"mailto:" + "dataprotection@logitgroup.com"}>
              {Constant.POLICY_LOGITGROUP}
            </a>
          </p>
          <p>
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {t("page.policy.bytele")}
            </b>{" "}
            {t("page.policy.extension")}
          </p>
          <p>
            <b className="after:content-[':'] after:ml-0.5 after:text-black-500">
              {t("page.policy.post")}
            </b>
          </p>
          <p>{t("page.policy.Churcher")}</p>
          <p>{t("page.policy.President")}</p>
          <p>{t("page.policy.Mall")}</p>
          <p className="py-4 mb-24">{t("page.policy.Toronto")}</p>
        </div>
      </div>
    </div>
  );
}

import MyProfileComponents from "components/MyProfileNew/MyProfileComponents";

function MyProfile() {
  return (
    <>
      <MyProfileComponents />
    </>
  );
}

export default MyProfile;

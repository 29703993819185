import React from "react";
import { UpdatePassword } from "../../components/UpdatePassword";

export const UpdatePasswordPg = () => {
  return (
    <div>
      <UpdatePassword />
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { bonusStatusDetailsApi } from "pages/MyReferralPage/MyReferralPage.api";

export function useBonusPoints() {
  const { auth } = useAuth();
  if (auth?.panelistId) {
    const result = useQuery(
      ["bonusPointsDetails"],
      async () => {
        return bonusStatusDetailsApi(auth?.panelistId);
      },
      {
        refetchOnWindowFocus: false,
      }
    );
    return typeof result !== undefined ? result?.data : null;
  }
}

import { Card } from "components/Card/Card";
import simplicity from "../../assets/simplicity.svg";
import reward from "../../assets/rewards.svg";
import engagement from "../../assets/engagement.svg";
import { useTranslation } from "react-i18next";

const LovedAboutUs = () => {
  const { t } = useTranslation();
  return (
    <div
      id="about"
      className="flex flex-col items-center justify-start w-full gap-3 px-4 py-10 mt-8 mb-14 lg:32 md:mt-20 font-poppins"
    >
      <h2 className="text-[24px] md:text-[48px] pl-5 font-bold text-gradient text-center">
        {t("LovedAboutUs.mostabout.us")}
      </h2>
      <div className="flex justify-center items-center md:w-[768px]">
        <p className=" text-center text-[1em] ml-7 mr-7 text-[#505050] text-base md:text-xl font-normal md:leading-9">
          {t("LovedAboutUs.onceYou.join")}
        </p>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center gap-5 mt-10">
        <Card
          title={t("LovedAboutUs.rewards.title.1")}
          description={t("LovedAboutUs.rewards.decription.1")}
          icon={reward}
          customClass="bg-sky-light "
        />
        <Card
          title={t("LovedAboutUs.simplicity.title.2")}
          description={t("LovedAboutUs.simplicity.decription.2")}
          icon={simplicity}
          customClass="bg-gradient white-color"
        />
        <Card
          title={t("LovedAboutUs.Engagement.title.3")}
          description="Our community thrives on engagement. Panel members love the interactive and engaging opportunities to express their thoughts, shaping the future while earning rewards."
          icon={engagement}
          customClass="bg-sky-light"
        />
      </div>
    </div>
  );
};

export default LovedAboutUs;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { Contact } from "./ContactForm.api";
import ReCAPTCHA from "react-google-recaptcha";
import { toastrError, toastrSuccess } from "./../../lib/toastr";
import SvgRightQaute from "./svg2";
import SvgLeftQoute from "./svg";

const intialState = {
  name: "",
  email: "",
  question: "",
  captchaToken: "",
  errors: {
    name: "",
    email: "",
    question: "",
    captchaToken: "",
  },
};

type FormState = typeof intialState;
type ContactPayload = Omit<FormState, "errors">;
export function ContactForm() {
  const { t } = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [formState, setFormState] = useState<FormState>(intialState);
  const postQuery = useMutation(
    (payload: ContactPayload) => {
      return Contact({
        name: payload.name,
        email: payload.email,
        question: payload.question,
        captchaToken: payload.captchaToken,
      });
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          toastrSuccess(response.message);
          setFormState(intialState);
          recaptchaRef.current?.reset();
        } else {
          toastrError(response.message);
        }
      },
      onError: (err: any) => {
        const repsonse: any = err.response;
        toastrError(repsonse?.data.message);
      },
    }
  );
  function onChangeToken(value) {
    setFormState({ ...formState, ["captchaToken"]: value });
  }
  const validation = (formValues: FormState) => {
    setFormState((old) => {
      return { ...old, errors: { ...old.errors, name: "", email: "", question: "" } };
    });

    const regexName = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/;
    if (!formValues.name.length || !regexName.test(formValues.name)) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, name: t("home.contact.Names") } };
      });
      return false;
    }

    // email validation patterns
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!(formValues.email && regexEmail.test(formValues.email))) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, email: t("home.contact.email") } };
      });
      return false;
    }

    if (!formValues.question.length) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, question: t("home.contact.Questions") } };
      });
      return false;
    }

    if (!formValues.captchaToken.length) {
      setFormState((old) => {
        return { ...old, errors: { ...old.errors, captchaToken: t("home.contact.Captcha") } };
      });
      return false;
    }

    return true;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const valid = validation(formState);
    if (valid) {
      postQuery.mutate(formState);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormState((prevFormValue) => ({
      ...prevFormValue,
      [name]: value,
      errors: {
        ...prevFormValue.errors,
        [name]: null,
      },
    }));
  };

  return (
    <div
      id="contact"
      className=" lg:mt-16 font-poppins w-full h-auto bg-[#F4F8FB] flex flex-col items-center justify-center lg:flex-row lg:justify-center"
    >
      <div className="py-10">
        <div className=" md:-mt-5">
          <div className=" lg:mt-[4.35rem] w-[100%] pl-14 lg:w-[600px] md:mr-4">
            <p className=" relative text-[27px] text-gradient font-medium-[705px] w-[295.38px] md:text-[40px] lg:text-[50px] lg:w-[528px]  lg:mt-[-3.75rem] lg:ml-[-1.5rem] md:ml-[-3.5rem] tracking-normal hero-title pb-2 md:leading-[3.8rem]">
              <SvgLeftQoute />
              {t("home.contact.notTo.stop.question")}
              <SvgRightQaute />
            </p>
            <p className="text-right font-semibold text-[10px] md:text-[22px] text-gradient mt-[2rem] md:mt-[3rem] pr-20 md:pr-[6rem]">
              {t("home.contact.Albert Einstein")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-[332px] my-6 md:ml-3.5 md:w-[450px] flex-col rounded-2xl bg-white">
        <form className="py-6" onSubmit={handleSubmit}>
          <h2 className="my-4 text-center text-gradient text-3xl">
            {t("home.contact.AnyQuestion")}
          </h2>
          <div className="flex flex-col px-6">
            <label htmlFor="Name" className=" text-gray-500 after:content-['*'] after:ml-0.5 after:text-red-500">
              {t("home.contact.Name")}
            </label>
            <input
              className="rounded-lg border px-2 py-2 outline-none"
              placeholder={t("home.contact.form.name")}
              id="name"
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
            />
            {formState.errors.name ? (
              <p className="mr-8 text-red-700" id="email_err_msg">
                {formState.errors.name}
              </p>
            ) : null}
          </div>
          <div className="flex flex-col p-6">
            <label className=" text-gray-500 after:content-['*'] after:ml-0.5 after:text-red-500">{t("home.contact.Email")}</label>
            <input
              className="rounded-lg border px-2 py-2 outline-none"
              placeholder={t("home.contact.form.email")}
              name="email"
              id="email"
              value={formState.email}
              onChange={handleChange}
            />
            {formState.errors.email ? (
              <p className="text-red-700 " id="email_err_msg">
                {formState.errors.email}
              </p>
            ) : null}
          </div>
          <div className="flex flex-col px-6 pb-4">
            <label className=" text-gray-500 after:content-['*'] after:ml-0.5 after:text-red-500">{t("home.contact.Question")}</label>
            <textarea
              className="rounded-lg border px-2 py-2 h-20 outline-none"
              placeholder={t("home.contact.form.question")}
              name="question"
              id="question"
              value={formState.question}
              onChange={handleChange}
            />
            {formState.errors.question ? (
              <p className="text-red-700" id="email_err_msg">
                {formState.errors.question}
              </p>
            ) : null}
          </div>
          <div className="flex justify-center w-full">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={
                process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
                "6LeI0bIlAAAAAIfnUpsRM-4HA8ANCTTl1gis8DX3"
              }
              onChange={onChangeToken}
            />
          </div>
          {formState.errors.captchaToken ? (
            <p className="text-red-700 " id="email_err_msg">
              {formState.errors.captchaToken}
            </p>
          ) : null}
          <div className="flex items-center justify-center p-6">
            <button
              type="submit"
              disabled={postQuery.isLoading}
              className="rounded-2xl w-full text-xl py-3 hover:bg-[#056E9C] text-white bg-[#056f9cf7]"
            >
              {postQuery.isLoading ? "Loading..." : t("home.contact.SUBMIT")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

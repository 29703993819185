import React from "react";
import { ChangeLanguage } from "components/core/ChangeLanguage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth-context";
import { Avatar, Dropdown } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";

export const NavSmallScreen = ({ toggle }: { toggle: () => void }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const items = [
    {
      key: "profile_1",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/my-surveys"}>{t("home.headers.survey")}</Link>,
    },
    {
      key: "profile_2",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/my-profile"}>{t("home.headers.profile")}</Link>,
    },
    {
      key: "profile_3",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/my-referral"}>{t("home.headers.referral")}</Link>,
    },
    {
      key: "profile_4",
      className: `header-ant-dropdown-menu ${!auth?.isVirtualWalletOpen ? "hidden_menu" : ""}`,
      label: <Link to={"/virtual-wallet"}>{t("home.headers.virtual")}</Link>,
    },
    {
      key: "profile_5",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/history"}>{t("home.headers.history")}</Link>,
    },
    {
      key: "profile_6",
      className: "header-ant-dropdown-menu",
      label: <Link to={"/unsubscribe"}>{t("home.headers.unsubscribe")}</Link>,
    },
    {
      key: "profile_7",
      className: "header-ant-dropdown-menu",
      label: (
        <Link
          to={"/logout"}
          // onClick={(event) => {
          //   event.preventDefault();
          //   // navigate(location.state || "/logout", { state: null });
          // }}
        >
          {t("home.headers.logout")}
        </Link>
      ),
    },
  ];
  return (
    <div
      className={
        "block absolute overflow-hidden w-full h-screen top-0 left-0 z-10 flex-col justify-evenly items-center bg-white"
      }
    >
      <button
        data-testid="nav-toggle-btn"
        className="absolute top-0 right-0 py-8 justify-end items-end flex-grow pr-[0.7rem]"
        onClick={toggle}
      >
        <svg
          className="w-8 h-8 text-gray-600"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <ul className=" nav-links m-0 mt-24 min-h-[250px]">
        <li className="flex flex-col justify-end items-end flex-grow pr-5">
          <ChangeLanguage/>
        </li>
        {/* <li className=" nav-text">
          <a href="https://zampliasurveys.com/" className="links ">
            OLD VERSION
          </a>
        </li> */}
        <li className="nav-text">
          <Link data-testid="m_home_link" to={"/"} className="links">
            {t("home.headers.Home")}
          </Link>
        </li>
        <li className=" nav-text">
          <Link data-testid="m_about_link" to={"/about"} className="links">
            {t("home.header.About")}
          </Link>
        </li>
        <li className=" nav-text">
          <Link data-testid="m_faq_link" to={"/faq"} className="links">
            {t("home.header.FAQ")}
          </Link>
        </li>
        <li className=" nav-text">
          <Link data-testid="m_contact_link" to={"/contact"} className="links">
            {t("home.header.Contact")}
          </Link>
        </li>
        {auth ? (
          <>
            <li className=" nav-text">
              <Dropdown
                className="links"
                menu={{
                  items,
                }}
                placement="topLeft"
                // trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar icon={<UserOutlined />} /> Hi!{auth.name ? auth.name.split(" ")[0] : ""}{" "}
                  <DownOutlined />
                </a>
              </Dropdown>
            </li>
          </>
        ) : (
          <>
            <li className=" nav-text">
              <Link data-testid="login_link" to={"/login"} className="links m-5">
                {t("home.headers.Login")}
              </Link>
            </li>
            <li className=" nav-text">
              <Link data-testid="join-now-btn" to={"/join-now"} className="nav-btn m-5">
                {t("btn.join.now")}
              </Link>
            </li>
          </>
        )}
        {/* {toggle ? (
          <>
            <li className=" nav-text">
              <Link data-testid="m_login_link" to={"/login"} className="links">
                {t("home.header.Login")}
              </Link>
            </li>
            <li className="nav-text">
              <Link data-testid="m-join-now-btn" to={"/join-now"} className="nav-btn">
                {t("btn.join.now")}
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="nav-text">
              <Link to={"/my-profile"} className="links">
                {t("home.headers.profile")}
              </Link>
            </li>
            <li className="nav-text">
              <Link to={"/my-surveys"} className="links">
                {t("home.headers.survey")}
              </Link>
            </li>
            <li className="nav-text">
              <Link to={"/history"} className="links">
                {t("home.headers.history")}
              </Link>
            </li>
            <li className="nav-text">
              <Link to={"/unsubscribe"} className="links">
                {t("home.headers.unsubscribe")}
              </Link>
            </li>
            <li className="nav-text">
              <Link to={"/my-referral"} className="links">
                {t("home.headers.referral")}
              </Link>
            </li>
            <li className="nav-text">
              <Link
                to={"/logout"}
                className="links"
                onClick={() => removeLoginCookie("setLoginCookie")}
              >
                {t("home.headers.logout")}
              </Link>
            </li>
          </>
        )} */}
      </ul>
    </div>
  );
};

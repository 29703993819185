import React from "react";
import { Route, Routes } from "react-router-dom";
import { Notification } from "components/Notifications/Notifications";
import { ContactPage } from "pages/ContactPage/ContactPage";
import { Forgot } from "pages/Forgot/Forgot";
import { HistoryPage } from "pages/HistoryPage/HistoryPage";
import { Home } from "pages/Home";
import { Layout } from "pages/Layout";
import { Login } from "pages/Login/Login";
import { MyReferralPage } from "pages/MyReferralPage/MyReferralPage";
import { NotFound } from "pages/NotFound";
import { PrivacyPolicy } from "pages/PrivacyPolicy/PrivacyPolicy";
import { Quotafull } from "pages/Quotafull/Quotafull";
import { TermCondition } from "pages/TermCondition/TermCondition";
import { Unsubscribe } from "pages/Unsubscribe/Unsubscribe";
import { UpdatePasswordPg } from "pages/UpdatePasswordPg/UpdatePasswordPg";
import { Surveyvirtualwallet } from "components/MySurveys/Survey-virtual-wallet";
import { About } from "./components/About/About";
import { FAQ } from "./components/FAQ/FAQ";
import { Logout } from "pages/Logout/Logout";
import { ProtectedPage } from "pages/ProtectedPage";
import { NonProtectedPage } from "pages/NonProtectedPage";
import { NeoBrandsData } from "pages/MyReferralPage/MyReferralRedeemnow";
import { ClaimStudyData } from "pages/MyReferralPage/RedeemNowStudy";
import { OtpRewarddata } from "pages/MyReferralPage/ReferralClaimReward";
import { SurveyComplete } from "pages/SurveyComplete/SurveyComplete";
import { Terminate } from "pages/Terminate/Terminate";
import { SecurityTerminate } from "pages/SecurityTerminate/SecurityTerminate";
import ConfirmAccountPage from "pages/ConfirmAccountPage/ConfirmAccountPage";
import Test from "pages/Test";
import MyProfile from "pages/MyProfileNew/MyProfile";
import MyProfileQuestions from "components/MyProfileNew/MyProfileQuestions";
import OldZampliaSurveys from "pages/OldZampliaSurveys";
import { RaiseQueryPage } from "pages/RaiseQueryPage/RaiseQueryPage";
import SurveyList from "components/Sidebar/SurveyList";
import Dashboard from "components/Dashboard";
import UnclaimedPanelistStudies from "pages/UnclaimedPanelistStudies/UnclaimedPanelistStudiesPage";
import { JoinNowForm } from "components/JoinNowForm/JoinNow";
import MoreSub from "components/Sidebar/Menus/MoreSub";
import { Account } from "components/Sidebar/Account";
import { RedeemStudywisePage } from "pages/RedeemStudywisePage/RedeemStudywisePage";


export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <NonProtectedPage>
              <Home />
            </NonProtectedPage>
          }
        />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/raise-query" element={<RaiseQueryPage />} />
        <Route path="/test" element={<Test />} />
        <Route
          path="/join-now"
          element={
            <NonProtectedPage>
              <JoinNowForm />
            </NonProtectedPage>
          }
        />
        {/* <Route
          path="/join-now"
          element={
            <NonProtectedPage>
              <JoinNow />
            </NonProtectedPage>
          }
        /> */}
        <Route
          path="/alert"
          element={
            <ProtectedPage>
              <Notification />
            </ProtectedPage>
          }
        />
        <Route
          path="/login"
          element={
            <NonProtectedPage>
              <Login />
            </NonProtectedPage>
          }
        />
        <Route
          path="/forgot"
          element={
            <NonProtectedPage>
              <Forgot />
            </NonProtectedPage>
          }
        />
        <Route
          path="/my-profile-category-question"
          element={
            <ProtectedPage>
              <MyProfileQuestions />
            </ProtectedPage>
          }
        />

        <Route
          path="/redeem/form/:service"
          element={
            <ProtectedPage>
              <NeoBrandsData />
            </ProtectedPage>
          }
        />
        <Route
          path="/otp"
          element={
            <ProtectedPage>
              <OtpRewarddata />
            </ProtectedPage>
          }
        />
        <Route
          path="/survey"
          element={
            <ProtectedPage>
              <ClaimStudyData />
            </ProtectedPage>
          }
        />

        <Route
          path="/unsubscribe"
          element={
            <ProtectedPage>
              <Unsubscribe />
            </ProtectedPage>
          }
        />
        <Route path="/terms-and-conditions" element={<TermCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/history"
          element={
            <ProtectedPage>
              <HistoryPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/my-surveys"
          element={
            <ProtectedPage>
              <SurveyList type="1" />
            </ProtectedPage>
          }
        />
        <Route
          path="/most-point"
          element={
            <ProtectedPage>
              <SurveyList type="2" />
            </ProtectedPage>
          }
        />
        <Route
          path="/short-survey"
          element={
            <ProtectedPage>
              <SurveyList type="3" />
            </ProtectedPage>
          }
        />

        <Route
          path="/more"
          element={
            <ProtectedPage>
              <MoreSub />
            </ProtectedPage>
          }
        />
        <Route
          path="/accountsection"
          element={
            <ProtectedPage>
              <Account />
            </ProtectedPage>
          }
        />
        <Route
          path="/my-profile"
          element={
            <ProtectedPage>
              <MyProfile />
            </ProtectedPage>
          }
        />
        <Route
          path="/security-terminate"
          element={
            <ProtectedPage>
              <SecurityTerminate />
            </ProtectedPage>
          }
        />
        <Route
          path="/terminate"
          element={
            // <ProtectedPage>
            <Terminate />
            // </ProtectedPage>
          }
        />
        <Route
          path="/success"
          element={
            // <ProtectedPage>
            <SurveyComplete />
            // </ProtectedPage>
          }
        />
        <Route
          path="/my-referral"
          element={
            <ProtectedPage>
              <MyReferralPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/quota-full"
          element={
            // <ProtectedPage>
            <Quotafull />
            // </ProtectedPage>
          }
        />
        <Route
          path="/update-password"
          element={
            // <ProtectedPage>
            <UpdatePasswordPg />
            // </ProtectedPage>
          }
        />
        <Route
          path="/virtual-wallet"
          element={
            <ProtectedPage>
              <Surveyvirtualwallet />
            </ProtectedPage>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedPage>
              <Dashboard />
            </ProtectedPage>
          }
        />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about" element={<About />} />
        <Route path="/confirmAccount" element={<ConfirmAccountPage />} />

        <Route
          path="/unclaimed-studies"
          element={
            <ProtectedPage>
              <UnclaimedPanelistStudies />
            </ProtectedPage>
          }
        />

        <Route
          path="/survey-redeem"
          element={
            <ProtectedPage>
              <RedeemStudywisePage />
            </ProtectedPage>
          }
        />
      </Route>
      <Route path="/old" element={<OldZampliaSurveys />}></Route>
      <Route path="*" element={<NotFound />}></Route>
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};

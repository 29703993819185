import { axiosClient } from "../../api/api";

export const getCategoryListByProfile = async (userId: number, lang_code: string) => {
  return axiosClient

    .get("/api/survey/getLoopSurveyProfileCategory", {
      params: {
        userId,
        lang_code,
      },
    })
    .then((response) => response.data);
};

export const getQuestionsByProfile = (userId: number, category: number, lang_code: string) => {
  return axiosClient
    .get("/api/survey/getLoopSurveyProfileQuestionAndOptionsByCategory", {
      params: {
        userId,
        category,
        lang_code,
      },
    })
    .then((response) => response.data);
};

export const saveAnswers = async (insertData) => {
  return axiosClient.post("/api/survey/saveLoopSurveyUserAnswer", insertData);
};

export const getCurrentProfileAnswers = async (categoryId: string, userId: number) => {
  return axiosClient
    .get("/api/survey/profileAnswers", {
      params: {
        categoryId,
        userId,
      },
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
    .then((response) => response.data);
};

import walmart from "../../assets/Rewards/walmart-logo-459 1.png";
import amazon from "../../assets/Rewards/amazon-png-logo-vector-6701 1.png";
import visa from "../../assets/Rewards/Visa New 2021 1.png";
import apple from "../../assets/Rewards/pngwing 4.png";
import best from "../../assets/Rewards/best-buy-png-logo-3002 1.png";
import hotels from "../../assets/Rewards/Hotels.com_Logo_2023 1.png";
import { useTranslation } from "react-i18next";

const RewardCompanies = () => {
  const { t } = useTranslation();
  return (
    <div className="my-10">
      <div className="text-[#525252] px-4 mb-4 text-[28px] font-normal font-['Poppins'] leading-[44px] text-center">
        {t("home.rewardCompanies.thoughts")}{" "}
        <span className="text-sky-700 font-medium font-['Poppins'] leading-[44px]">
          {t("home.rewardCompanies.getRewards")}{" "}
        </span>
       
      </div>

      <div className="w-full inline-flex flex-nowrap overflow-x-hidden">
        <ul className="flex items-center justify-center md:justify-start  [&_li]:mx-4  md:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll ">
          <li>
            <img src={walmart} alt="walmart" className="h-8 md:h-full" />
          </li>
          <li>
            <img src={amazon} alt="amazon" className="h-6 md:h-full" />
          </li>
          <li>
            <img src={visa} alt="visa" className="h-8 md:h-full" />
          </li>
          <li>
            <img src={apple} alt="apple" className="h-8 md:h-full" />
          </li>
          <li>
            <img src={hotels} alt="hotels" className="h-5 md:h-full" />
          </li>
          <li>
            <img src={best} alt="best" className="h-8 md:h-full" />
          </li>
        </ul>
        <ul
          className="flex items-center justify-center md:justify-start [&_li]:mx-4  md:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
          aria-hidden="true"
        >
          <li>
            <img src={walmart} alt="walmart" className="h-8 md:h-full" />
          </li>
          <li>
            <img src={amazon} alt="amazon" className="h-6 md:h-full" />
          </li>
          <li>
            <img src={visa} alt="visa" className="h-8 md:h-full" />
          </li>
          <li>
            <img src={apple} alt="apple" className="h-8 md:h-full" />
          </li>
          <li>
            <img src={hotels} alt="hotels" className="h-5 md:h-full" />
          </li>
          <li>
            <img src={best} alt="best" className="h-8 md:h-full" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RewardCompanies;

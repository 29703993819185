import { axiosClient } from "../../api/api";

type formState = {
  userId: number;
  name: string;
  email: string;
  query: string;
  image_upload: File[];
};

export const RaiseQuery = async (FormState: formState) => {
  const { userId, name, email, query, image_upload } = FormState;

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const base64Images: string[] = [];

  for (const file of image_upload) {
    const base64Image = await convertToBase64(file);
    base64Images.push(base64Image);
  }

  return axiosClient.post("/api/user/raiseConcern", {
    userId,
    name,
    email,
    query,
    image_upload: base64Images
  }).then(response => response.data);
};

export const weeks = [
  {
    days: "Mon",
  },
  {
    days: "Tue",
  },
  {
    days: "Wed",
  },
  {
    days: "Thu",
  },
  {
    days: "Fri",
  },
  {
    days: "Sat",
  },
  {
    days: "Sun",
  },
];

export const points = (point: number, vendorCpi: number) => {
  let points = 0;
  if (point) {
    points = point;
    points = Number.isInteger(point) ? points : Math.floor(point);
  } else if (vendorCpi) {
    points = (100 / 100) * vendorCpi * 100;
    points = Number.isInteger(point) ? points : Math.floor(point);
  }
  return points;
};


export function GetRedeemPointValue(url : string) {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const redeemPoint = params.get('redeem_point');
    return redeemPoint;
}
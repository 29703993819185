import React, { useEffect } from "react";
import welmart from "../../assets/dashboard/navCard/walmart.svg";
import apple from "../../assets/dashboard/navCard/apple.svg";
import amazone from "../../assets/dashboard/navCard/amzone.svg";
import visa from "../../assets/dashboard/navCard/visa.svg";
import hotel from "../../assets/dashboard/navCard/hotel.svg";
import lowes from "../../assets/dashboard/navCard/lowes.svg";
import tjmax from "../../assets/dashboard/navCard/tjmax.svg";
import dickes from "../../assets/dashboard/navCard/dickes.svg";
import { Link } from "react-router-dom";
import { useAuth } from "context/auth-context";
import { useQuery } from "@tanstack/react-query";
import { JoinNowBonusBalanceApi } from "./Dasboard.api";
import { useWalletDetails } from "hooks/useWalletDetails";
import { useBonusPoints } from "hooks/useBonusPointsDetails";
import { bonusDetailsType } from "types/useCustomHookTypes";
import { useTranslation } from "react-i18next";

function NavCard() {
  const { t } = useTranslation();

  const [enabled, setEnabled] = React.useState<boolean>(false);
  const svgs = [welmart, apple, amazone, visa, hotel, lowes, tjmax, dickes];
  const { auth } = useAuth();

  const getJoinNowBonusBalance = useQuery(
    ["getBonusPoint", auth.panelistId],
    () => JoinNowBonusBalanceApi(auth.panelistId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const walletDetails: Record<string, number | undefined> = useWalletDetails();
  const bonusDetails: bonusDetailsType | null = useBonusPoints();

  useEffect(() => {
    if (walletDetails && bonusDetails) {
      setEnabled(walletDetails?.TotalWalletBalance >= Number(bonusDetails?.data?.target_points));
    }
  }, [walletDetails, bonusDetails]);
  return (
    <div
      className="relative flex flex-col items-center justify-center w-full p-4 bg-white lg:flex-row lg:justify-between rounded-3xl "
    >
      <div className="flex flex-col pl-1 sm:pl-0">
        <div className="flex w-full sm:justify-center lg:justify-start">
          <div className="flex md:w-auto lg:w-auto w-[10vw]">
            {svgs.map((svg, index) => (
              <img key={index} src={svg} alt={`svg-${index}`} />
            ))}
          </div>
        </div>
        <div>
          {bonusDetails?.data?.isActive ? (
            <div className="text-start font-poppins text-[#4E4E4E] text-xs md:text-sm">
              <p>
                {t("referfriend.deaultmessage.youmay")}{" "}
                <span className="font-semibold ">
                  {" "}
                  {t("referfriend.deaultmessage.redeemrewards")}
                </span>{" "}
                {t("referfriend.deaultmessage.afterreaching")}
                <span className="font-semibold ">
                  &nbsp;{+getJoinNowBonusBalance?.data?.claimPoint}{" "}
                  {t("referfriend.deaultmessage.afterpoint")}&nbsp;
                </span>
                {t("referfriend.deaultmessage.afterpoint2")}
              </p>
            </div>
          ) : (
            <div className="w-full text-sm text-center text-red-600 font-poppins">
              <p>{t("referfriend.deaultmessage.afterclaim")}</p>
            </div>
          )}
      </div>

      <div className="absolute pt-2 right-3 lg:pt-0">
        <Link to="/redeem/form/bonus">
          <button
            disabled={!enabled}
            className={` disabled:bg-gray-400 disabled:cursor-not-allowed bg-[#056E9C] hover:bg-[#05629c] font-poppins font-normal text-[#F6FBFF] md:text-sm text-xs py-3 px-4 rounded-full`}
          >
            {t("page.referral.rewads")}
          </button>
        </Link>
      </div>
    </div>
  </div>
  );
}

export default NavCard;

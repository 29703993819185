import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import Pagination from "../../components/Pagination/Pagination";
import { addStudyClaimRequest, getPanelListCompletedData } from "./MyReferralPage.api";
import { useEffect, useState } from "react";
import { Survey } from "../../components/MySurveys/MySurveys.interface";
import { points } from "./Redeem.fun";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { reqDataIntialState } from "./MyReferralPage.interface";
import { VirtualBalance } from "../../pages/MySurveysPg/MySurveysPg.api";
import LoadingOverlay from "react-loading-overlay-ts";
import { Modal, Radio } from "antd";
const { confirm } = Modal;

type reqDataType = typeof reqDataIntialState;
// type DataType = typeof DataIntialState;

export const ClaimStudyData = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const navigate: NavigateFunction = useNavigate();
  const [checked, setChecked] = useState<boolean>(false);
  // const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [reqData, setReqData] = useState<reqDataType>(reqDataIntialState);
  // const [data, setData] = useState<DataType>(DataIntialState);
  const [claimData, setClaimData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [walletCreatedDate, setWalletCreatedDate] = useState<any>("");

  const getVirtualBalanceBalance = useMutation(["balance"], () => VirtualBalance(auth.panelistId), {
    onSuccess: (response: any) => {
      if (response?.walletCreatedAt) {
        setWalletCreatedDate(response.walletCreatedAt);
      }
    },
  });

  const getPanelListData = useQuery(
    ["panel", reqData?.type],
    () => {
      return getPanelListCompletedData(
        auth.panelistId,
        currentPage,
        walletCreatedDate,
        reqData.type
      );
    },
    {
      enabled: !!walletCreatedDate,
      refetchOnWindowFocus: false,
    }
  );
  const studyClaimRequest = useMutation(
    ["claim"],
    (requestData: any) => addStudyClaimRequest(requestData),
    {
      onSuccess: (response: any) => {
        if (response.success) {
          Modal.success({
            title: "Success!",
            content: response.message,
          });
          getPanelListData.refetch();
        } else {
          Modal.error({
            title: "Encountered an error!",
            content: response.message,
          });
        }
      },
      onError: () => {
        Modal.error({
          title: "Encountered an error!",
          content: "something went wrong",
        });
      },
    }
  );

  const handleCheckboxChange = (event) => {
    setSelectedValues(
      selectedValues.reduce((acc, current) => {
        if (event.target.value === current) {
          return acc;
        } else {
          return [...acc, event.target.value];
        }
      }, [])
    );
  };

  useEffect(() => {
    if (walletCreatedDate) {
      getPanelListData.remove();
      getPanelListData.refetch();
    }
  }, [currentPage]);
  useEffect(() => {
    if (auth.isVirtualWalletOpen) {
      getVirtualBalanceBalance.mutate();
    } else {
      setWalletCreatedDate(auth.date);
    }
  }, []);

  function sendStudyClaimRequest(survey: Survey) {
    confirm({
      title: "Alert!",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to claim this study?",
      onOk() {
        const pointsData = survey.points ? points(survey.points, 0) : points(0, survey.vendorCpi);
        studyClaimRequest.mutate({
          panelListId: auth.panelistId,
          studyId: survey.sid,
          points: pointsData,
          p_id: survey.p_id,
        });
      },
    });
  }
  function btnNeeded(survey: Survey, type: string) {
    let btn;
    if (type === "surveys") {
      btn = (
        <>
          <a
            onClick={() => sendStudyClaimRequest(survey)}
            className="bg-green-600 btn btn-success btn-sm"
          >
            {t("page.unsubscribe.Request")}
          </a>
        </>
      );
      if (survey.isRequested) {
        btn = (
          <>
            <span className="bg-sky-500 btn btn-primary btn-sm">{t("page.unsubscribe.pen")}</span>
          </>
        );
      }
      return btn;
    } else if (type === "reject") {
      btn = (
        <>
          <span className="bg-red-600 btn btn-danger btn-sm">{t("page.unsubscribe.Rejected")}</span>
          {/* {survey.rejectReason && <br />} */}
          {/* {survey.rejectReason && <br />} */}
          {survey.rejectReason && (
            <p className="text-danger reject-reason-span">Reason:- {survey.rejectReason}</p>
          )}
        </>
      );
      return btn;
    } else if (type === "approved") {
      btn = (
        <>
          <span className="bg-green-600 btn btn-success btn-sm">
            {t("page.unsubscribe.Approved")}
          </span>
        </>
      );
      return btn;
    }
  }

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          position: "fixed",
        }),
      }}
      active={getPanelListData.isLoading || studyClaimRequest.isLoading}
      spinner
      text="Loading..."
    >
      <div className="flex flex-row justify-center w-full pt-32 font-sans lg:ml-[15rem] lg:px-52">
        <div className="flex flex-col w-full m-5 overflow-hidden bg-white rounded-lg shadow-lg">
          <p className="mx-5 mt-10 mb-5 text-xl font-thin border-b-2 border-gray-300">
            {t("page.referral.Reward1")}
          </p>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-2">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="flex flex-row">
                <Radio.Group
                  value={reqData.type}
                  onChange={(e) => {
                    setReqData({
                      panelListId: auth.panelistId,
                      type: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                >
                  <Radio.Button value="surveys">{t("page.referral.Completed")}</Radio.Button>
                  <Radio.Button value="approved">{t("page.referral.Approved")}</Radio.Button>
                  <Radio.Button value="reject">{t("page.referral.Rejected")}</Radio.Button>
                </Radio.Group>
              </div>
              {reqData.type === "approved" && checked ? (
                <button
                  className="bg-green-600 btn-2"
                  onClick={() => {
                    navigate("/redeem/form/study", { state: { studyData: claimData } });
                  }}
                >
                  {t("page.claim.Now")}
                </button>
              ) : null}

              <div data-testid="survey" className="overflow-hidden">
                <table className="min-w-full">
                  <thead className="border-b">
                    <tr>
                      {reqData.type === "approved" ? (
                        <th scope="col" className="font-bold table-1-th"></th>
                      ) : null}
                      <th scope="col" className="font-bold text-center table-1-th">
                        #
                      </th>
                      <th scope="col" className="font-bold text-center table-1-th">
                        {t("page.claim.Study")}
                      </th>
                      <th scope="col" className="font-bold text-center table-1-th">
                        {t("page.claim.point3")}
                      </th>
                      <th scope="col" className="font-bold text-center table-1-th">
                        {t("page.claim.dates")}
                      </th>
                      {reqData.type === "surveys" ? (
                        <th scope="col" className="font-bold text-center table-1-th">
                          {t("page.claim.action")}
                        </th>
                      ) : (
                        <th scope="col" className="font-bold text-center table-1-th">
                          {t("page.claim.status")}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody data-testid="survey1">
                    {getPanelListData.data?.data?.length > 0 ? (
                      getPanelListData.data?.data?.map((survey: Survey, i: number) => {
                        return (
                          <tr key={i} className="border-b">
                            {reqData.type === "approved" && (
                              <td className="text-center">
                                <input
                                  id="terms"
                                  name="terms"
                                  type="checkbox"
                                  checked={checked}
                                  onChange={() => {
                                    {
                                      handleCheckboxChange;
                                    }
                                    setChecked(!checked);
                                    setClaimData((prevState) => [
                                      ...prevState,
                                      {
                                        points: survey.points,
                                        studyId: survey.sid,
                                        claimedStudyId: survey.id,
                                        p_id: survey.p_id,
                                      },
                                    ]);
                                  }}
                                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                />
                              </td>
                            )}
                            <td className="text-center table-1-td-col-2">{i + 1}</td>
                            <td className="text-center table-1-td-col-2">{survey.studyName}</td>
                            <td className="text-center table-1-td-col-2">
                              {survey.points
                                ? points(survey.points, 0)
                                : points(0, survey.vendorCpi)}
                            </td>
                            <td className="text-center table-1-td-col-2">{survey.createdAt}</td>
                            <td className="text-center">
                              <button className="table-1-td-col-2">
                                {btnNeeded(survey, reqData.type)}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="mt-10 ml-10">{t("page.mysurvey.nodata")}</p>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                setCurrentPage={setCurrentPage}
                totalPages={getPanelListData?.data?.totalPages}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

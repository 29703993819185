import { toastrSuccess, toastrError } from "../../lib/toastr";
import LoadingOverlay from "react-loading-overlay-ts";
import { useMutation } from "@tanstack/react-query";
import { LoginGoogleapi, getPassword } from "components/LoginForm/LoginForm.api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import { AuthData, useAuth } from "context/auth-context";
import { Modal } from "antd";

export const LoginWithGoogle = () => {
  const [Email, setEmail]: any = useState("");

  const [Password, setPassword] = useState("");
  const [sucessResponce, setSucessResponce] = useState();
  const { setAuth } = useAuth();
  const location = useLocation();
  const { info } = Modal;

  const navigate = useNavigate();

  const initialState = {
    email: "",
    password: "",
    errors: {
      email: "",
      password: "",
    },
  };

  // for login
  const virtualWalletConfirmation: any = (location: any) => {
    info({
      title: "Alert",
      icon: <ExclamationCircleFilled />,
      content: "get errr with that",
      onOk() {
        navigate(location.state || "/dashboard", { state: null });
      },
    });
  };

  type formValue = typeof initialState;
  type LoginPayload = Omit<formValue, "errors">;
  const handleError = () => {
    toastrError("Something went wrong!!");
  };

  // google HandleSucess
  const handleSuccess = (credentialResponse: any) => {
    if (credentialResponse) {
      setSucessResponce(credentialResponse);
      let userEmail: any;
      if (credentialResponse && credentialResponse?.credential) {
        userEmail = jwtDecode(credentialResponse?.credential);
        if (userEmail) {
          setEmail(userEmail?.email);
        }
        userEmail = userEmail?.email;
      } else if (credentialResponse && credentialResponse?.email) {
        userEmail = credentialResponse?.email;
      }

      if (userEmail && credentialResponse?.credential) {
        let verificationGoogle: any;
        verificationGoogle = jwtDecode(credentialResponse?.credential);
        verificationGoogle = verificationGoogle?.email_verified;
        console.log(verificationGoogle);
      }
    } else {
      toastrError("Invalid credentialResponse object");
    }
  };

  const authMutation = useMutation(
    (payload: LoginPayload) => {
      return LoginGoogleapi(payload.email, payload.password);
    },
    {
      onSuccess: (response: AuthData) => {
        if (response.isFlagVirtualWallet) {
          toastrSuccess("login sucesssfuly");
          virtualWalletConfirmation(location);
          setAuth(response);
        } else {
          toastrSuccess("login sucesssfuly");
          setAuth(response);
          navigate(location.state || "/dashboard", { state: null });
        }
      },
      onError: (err: AxiosError) => {
        toastrError(err);
      },
    }
  );

  function login() {
    authMutation.mutate({ email: Email, password: Password });
  }
  const [isPasswordFetched, setIsPasswordFetched] = useState(false);

  // getPassword
  const getpassword = useMutation(() => getPassword(Email), {
    onSuccess: (response) => {
      if (response) {
        setPassword(response.result[0].user_pass);
      }
    },
    onError: () => {
      toastrError("Hello there! It seems you haven't signed up yet.");
    },
  });

  useEffect(() => {
    if (sucessResponce) {
      handleSuccess(sucessResponce);
      getpassword.mutate();

      if (Password && !isPasswordFetched) {
        login();
        setIsPasswordFetched(true);
      }
    }
  }, [sucessResponce, Password]);

  return (
    <>
      <LoadingOverlay
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
        active={authMutation.isLoading}
        spinner
        text="Loading..."
      ></LoadingOverlay>

      <div className=" w-[80%] flex mt-5 ">
        <GoogleLogin
          onSuccess={(credential) => {
            handleSuccess(credential);
          }}
          onError={() => {
            handleError();
          }}
          size="medium"
        />
      </div>
    </>
  );
};

import React from "react";
import { LoginForm } from "../../components/LoginForm/LoginForm";

export const Login = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

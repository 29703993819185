import { axiosClient } from "../../api/api";

export const loginUser = async (username: string, password: string) => {
  return axiosClient
    .post("/api/auth/login", {
      username,
      password,
    })
    .then((res) => res.data);
};

export const LoginGoogleapi = async (username: string, password: string) => {
  return axiosClient
    .post("/api/auth/googlelogin", {
      username,
      password,
    })
    .then((res) => res.data);
};

export const getPassword = async (user_email: string) => {
  return axiosClient
    .post("/api/user/getPassword", {
      user_email,
    })
    .then((res) => res.data);
};

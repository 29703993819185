import React from "react";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

export const About = () => {
  const { t } = useTranslation();
  return (
    <div
      id="about"
      className=" font-sans min-h-screen w-full bg-[#0a2540] clip-Path-1 py-60 sm:py-60"
    >
      <div className="w-10/12 mx-auto lg:flex lg:flex-row">
        <div className=" lg:basis-full xl:basis-2/3 lg:px-4 md:basis-full sm:basis-full">
          <div className="py-4 title-wrapper md:py-1">
            <h1 data-testid="about_title" className="text-teal-400 title-2">
              {t("home.about.title")}
            </h1>
          </div>
          <div className="body-wrapper">
            <p className="font-thin text-white text-md">
              {t("home.about.ZampliaSurveys")}
              <br />
              <br />
              {t("home.about.Surveyswasestablished")}
              <br />
              <br />
              {t("home.about.ZampliaSurveymembers")}
              <br />
              <br />
              {t("home.about.Thereisnocost")}
            </p>
          </div>
        </div>
        <div className="lg:basis-full xl:basis-1/3 lg:px-4 lg:mt-0 md:mt-10 sm:mt-10 md:basis-full sm:basis-full">
          <div className="flex flex-col py-4 lg:mt-0 md:mt-24 xs:mt-24">
            <div className="mb-20">
              <FontAwesomeIcon icon={faTimesCircle} className="text-5xl text-teal-400" />
              <br />
              <br />
              <div>
                <p className="text-sm font-thin text-white">{t("home.about.Wearefully")}</p>
              </div>
            </div>
            <div className="mb-20">
              <FontAwesomeIcon icon={faUserSecret} className="text-5xl text-teal-400" />
              <br />
              <br />
              <div>
                <p className="text-sm font-thin text-white">
                  {t("home.about.Theanswers")}{" "}
                  <HashLink
                    smooth
                    data-testid="privacyLink"
                    to={"/privacy-policy/#privacy-policy"}
                    className="text-teal-400"
                  >
                    {t("home.about.privacypolicy")}
                  </HashLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


import { NavLink } from 'react-router-dom';
import Navbar from '../Navbar';
import {ReactComponent as Refer} from '../../../assets/SideBarIcons/Referral.svg';
import { LiaHistorySolid } from "react-icons/lia";
import { MdOutlineUnsubscribe } from "react-icons/md";
import {ReactComponent as Help} from '../../../assets/SideBarIcons/help.svg';
import {ReactComponent as Logout} from '../../../assets/SideBarIcons/Logout.svg';

export default function MoreSub() {
  return (
    <>
      <div>
        <Navbar />
      </div>

      <div className="lg:hidden md:block block w-full h-full font-poppins ">
        <div className="flex justify-center items-center ">
          <ul className="mt-32 flex flex-col gap-5 mb-28">
            <NavLink to="/my-referral">
              <li className="flex justify-center items-center w-80 h-[3rem] md:w-[26.2rem] md:h-[4rem] pr-1 text-sky-700 md:text-lg rounded-[0.9rem] border border-sky-700 border-opacity-60">
                <span className="flex items-center gap-3">
                  <Refer />
                  Refer a friend
                </span>
              </li>
            </NavLink>
            <NavLink to="/history">
              <li className="flex justify-center items-center w-80 h-[3rem] md:w-[26.2rem] md:h-[4rem] text-sky-700 rounded-[0.9rem] border border-sky-700 md:text-lg border-opacity-60">
                <span className="flex items-center gap-3">
                  <LiaHistorySolid style={{ fontSize: "24px", color: "black" }} />
                  Surveys History
                </span>
              </li>
            </NavLink>
            <NavLink to="/unsubscribe">
              <li className="flex justify-center items-center w-80 h-[3rem] md:w-[26.2rem] md:h-[4rem] pr-5 text-sky-700 md:text-lg rounded-[0.9rem] border border-sky-700 border-opacity-60">
                <span className="flex items-center gap-3">
                  <MdOutlineUnsubscribe style={{ fontSize: "24px", color: "black" }} />
                  Unsubscribe
                </span>
              </li>
            </NavLink>
            <NavLink to="/raise-query">
              <li className="flex justify-center items-center w-80 h-[3rem] md:w-[26.2rem] md:h-[4rem] pr-[5rem]  text-sky-700 md:text-lg rounded-[0.9rem] border border-sky-700 border-opacity-60">
                <span className="flex items-center gap-4">
                  <Help />
                  Help
                </span>
              </li>
            </NavLink>
            <NavLink to="/logout">
              <li className="flex justify-center items-center w-80 h-[3rem] md:w-[26.2rem] md:h-[4rem] pr-[4rem] text-sky-700 md:text-lg rounded-[0.9rem] border border-sky-700 border-opacity-60">
                <span className="flex items-center gap-4">
                  <Logout />
                  Logout
                </span>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </>
  );
}

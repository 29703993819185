/* eslint-disable no-debugger */
import { forgotPassLogging } from "./UpdatePassword.api";
import * as CryptoJS from "crypto-js";
import { AxiosResponse } from "axios";
import { axiosClient } from "../../api/api";

const decryptionJS = (encryptedText: any) => {
  // eslint-disable-next-line no-useless-escape
  const data = encryptedText.replace(/\loop/g, "+");
  const salt = CryptoJS.enc.Hex.parse(data.substr(0, 32));
  const iv = CryptoJS.enc.Hex.parse(data.substr(32, 32));
  const encrypted = data.substring(64);
  const key = CryptoJS.PBKDF2("LOOPSURVEYS12345678909876543210", salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  });
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const finshUpdatePassword = async (tokenId: string | number) => {
  const reqObject = {
    id: tokenId,
  };
  return axiosClient.post("/api/survey/finishUpdatePassword", reqObject).then((response) => {
    forgotPassLogging(
      JSON.stringify(reqObject),
      JSON.stringify(response),
      "update_password_finished"
    );
    return response.data;
  });
};

type logData = {
  emailId: string;
  new_password: string;
  confirm_password: string;
  result: AxiosResponse<any>;
};

export const saveDataInLogs = async (obj: logData) => {
  const { emailId, new_password, confirm_password, result } = obj;
  const data = {
    email: decryptionJS(emailId),
    new_password,
    confirm_password,
  };
  forgotPassLogging(JSON.stringify(data), JSON.stringify(result), "update_password_initiated");
  if (result.data && result.data.success) {
    finshUpdatePassword(emailId);
  }
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getHistory } from "./History.api";
import Pagination from "components/Pagination/Pagination";
import { Participant } from "./History.interface";
import { useAuth } from "context/auth-context";
import { dateFormatHandler, DD_MMM_YYYY } from "./../../lib/date-formatter-moment";

export const History = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getHistoryData = useQuery(
    ["history", currentPage],
    () => getHistory(auth.panelistId, currentPage),
    {
      refetchOnWindowFocus: false,
    }
  );
  if (getHistoryData.isLoading) {
    return (
      <div className="text-2xl text-center rounded-full animate-bounce">
        <span className="visually-hidden">{t("page.referral.Loading")}</span>
      </div>
    );
  }

  return (
    <>
      <div className="overflow-x-scroll md:overflow-hidden">
        <table className="min-w-full">
          <thead className="border-b overflow-x-scroll">
            <tr className="">
              <th
                scope="col"
                className=" text-center text-[11px] sm:text-sm md:text-base font-medium text-[#474747] w-1/5"
              >
                {t("page.history.SurveyName")}
              </th>
              <th
                scope="col"
                className=" text-center text-[11px] sm:text-sm lg:text-base font-medium w-1/5 text-[#474747]"
              >
                {t("page.history.Points")}
              </th>
              <th
                scope="col"
                className="text-center text-[11px] sm:text-sm lg:text-base font-medium  w-1/5 text-[#474747]"
              >
                {t("page.history.Status")}
              </th>
              <th
                scope="col"
                className="text-center text-[11px] sm:text-sm lg:text-base font-medium  w-1/5 text-[#474747]"
              >
                {t("page.history.AttemptDate")}
              </th>
              <th
                scope="col"
                className="text-center text-[11px] sm:text-sm lg:text-base font-medium w-1/5 text-[#474747]"
              >
                {t("page.history.RouterSurveys")}
              </th>
            </tr>
          </thead>
          <tbody data-testid="survey1" className="w-full h-full">
            {getHistoryData.data?.historyList?.length > 0 &&
              getHistoryData.data?.historyList?.map((survey: Participant, i: number) => {
                return (
                  <tr key={i} className="border-b">
                    <td className="text-center text-sm lg:text-base text-[#474747] font-normal table-1-td-col-2">
                      {survey.surveyName}
                    </td>
                    <td className="text-center text-sm lg:text-base text-[#474747] font-normal table-1-td-col-2">
                      {Math.floor(+survey.points)}
                    </td>
                    <td className="text-center text-sm lg:text-base text-[#474747] font-normal table-1-td-col-2">
                      {survey.surveyStatus === 1 && (
                        <span className="text-[#00A411] bg-[#54B94826] text-opacity-[69%] bg-opacity-[13%] px-6 py-1 rounded-3xl">
                          {survey.surveyStatusName}
                        </span>
                      )}
                      {survey.surveyStatus === 2 && (
                        <span className="text-[#E45903] bg-[#BE1E2B30] text-opacity-[69%] bg-opacity-[19%] px-6 py-1 rounded-3xl">
                          {survey.surveyStatusName}
                        </span>
                      )}
                      {survey.surveyStatus === 3 && (
                        <span className="text-[#B60020B0] bg-[#D8002521] text-opacity-[69%] bg-opacity-[13%] px-6 py-1 rounded-3xl">
                          {survey.surveyStatusName}
                        </span>
                      )}
                      {survey.surveyStatus === 0 && (
                        <span className="text-[#DBAD00] bg-[#FFCA0029] bg-opacity-[16%] px-4 py-1 rounded-3xl">
                          Not Completed
                        </span>
                      )}
                      {survey.surveyStatus !== 0 &&
                        survey.surveyStatus !== 1 &&
                        survey.surveyStatus !== 2 &&
                        survey.surveyStatus !== 3 && (
                          <span className="text-[#B60020B0] bg-[#D8002521] text-opacity-[69%] bg-opacity-[13%] px-6 py-1 rounded-3xl">
                            Terminated
                          </span>
                        )}
                    </td>
                    <td className="text-center text-sm lg:text-base text-[#474747] font-normal table-1-td-col-2">
                      {dateFormatHandler(survey.createdAt, DD_MMM_YYYY)}
                    </td>
                    <td className="text-center text-sm lg:text-base text-[#474747] font-normal table-1-td-col-2">
                      {Number(survey.isRouterSurveyAvailable)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-10 sm:mt-0 lg:mt-10 flex justify-center">
        {getHistoryData.data?.historyList && getHistoryData.data?.historyList?.length > 0 ? (
          <Pagination
            setCurrentPage={setCurrentPage}
            totalPages={getHistoryData.data?.totalPages}
            currentPage={currentPage}
          />
        ) : (
          <p className="mt-10">{t("page.mysurvey.nodata")}</p>
        )}
      </div>
    </>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Hero from "../../assets/Hero/hero1.png";
import { useAuth } from "context/auth-context";
import profile1 from "../../assets/Ellipse 1.png";
import profile2 from "../../assets/Ellipse 2.png";
import profile3 from "../../assets/Ellipse 3.png";
import profile4 from "../../assets/Ellipse 6.png";
import Carousel from "../Carousel/Carousel";

export const HeroSession = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  return (
    <div className="flex flex-col-reverse lg:flex-row mt-[5.5rem] font-poppins text-white sm:w-10/12 gap-4 mx-auto md:gap-0">
      <div className="flex flex-col gap-1 md:w-3/5">
        <div className=" px-4">
          <div className="mt-8 lg:mt-5 w-56 h-10 inline-flex justify-center items-center rounded-full bg-[#45E33729] bg-opacity-20 text-[0.875em] px-2 py-1 font-medium text-[#048E12] outline-none leading-7">
            <span className="blink-live w-3 h-3 bg-[#58C603] rounded-full mr-2"></span>
            {t("home.hero.trust")}
          </div>
        </div>
        <div className=" flex flex-col mt-3 px-4 w-full lg:w-10/12">
          <p className=" font-poppins text-[25px] md:text-[30px] lg:text-[45px] text-[#096EA0] font-[650] lg:font-[700]">
            {t("home.hero.title")}
          </p>
          <span className=" text-neutral-600 mt-2 text-[20px] font-poppins md:mt-3 xl:mt-5">
            {t("home.hero.description.part-1")}
            <span className="text-sky-700 text-lg md:text-xl font-normal leading-6 md:leading-5">
              {t("home.hero.description.part-2")}
            </span>
          </span>

          {auth ? (
            <Link
              data-testid="button"
              to="/my-surveys"
              className="flex  items-center justify-center text-xl btn"
            >
              {t("home.headers.survey")}
            </Link>
          ) : (
            <Link
              data-testid="button"
              to="/join-now"
              className="w-40 btn ml-1 md:ml-[0.5rem]  flex items-center justify-center text-xl md:my-10"
            >
              {t("btn.join.now")}
            </Link>
          )}
        </div>
        <div className="w-[340px] h-20 sm:w-full bg-slate-100 rounded-lg border border-l-[#3C97C4] border-l-4 pl-2 pr-1 mr-5 ml-2 relative md:w-96">
          <div className="flex justify-between items-center my-2 md:my-3 md:pr-8">
            <div className="flex items-center justify-start">
              <img className="w-10 h-10 rounded-full border-black absolute left-2" src={profile1} />
              <img
                className="absolute z-2 w-10 h-10  rounded-full border-black left-8"
                src={profile2}
              />
              <img
                className="absolute z-3 w-10 h-10  rounded-full border-black left-14"
                src={profile3}
              />
              <img
                className="absolute z-4 w-10 h-10  rounded-full border-black left-20"
                src={profile4}
              />
            </div>
            <div className="flex flex-col my-auto">
              <div className="text-cyan-600 text-sm font-semibold leading-7">
                {t("home.hero.members")}
              </div>
              <div className="w-14 h-1 bg-sky-700" />
              <div className="text-neutral-700 text-base font-normal font-poppins leading-6">
                {t("home.hero.members.glob")}
              </div>
            </div>
          </div>
        </div>
        <div className="w-48 h-8 text-cyan-600 text-lg font-medium leading-8 mt-4 mb-2 ml-[0.5rem]">
          {t("home.hero.members.reviews")}
        </div>
        <Carousel />
      </div>
      <div className="flex flex-none items-center justify-center mt-4 md:mt-[0px] xl:mt-[6rem] lg:w-2/5">
        <img src={Hero} alt="" className="lg:w-3/4 xl:w-[49.25rem]" />
      </div>
    </div>
  );
};

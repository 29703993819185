import { useEffect, useState } from "react";
import { ButtonGroup, reqDataType } from "components/ButtonGroup/ButtonGroup";
import { useAuth } from "context/auth-context";
import { ClaimedStudiesApproved } from "pages/ClaimedStudiesApproved/ClaimedStudiesApproved";
import { ClaimedStudiesRejected } from "../../pages/ClaimedStudiesRejected/ClaimedStudiesRejected";
import UnclaimedPanelistStudies from "pages/UnclaimedPanelistStudies/UnclaimedPanelistStudiesPage";
import { useNavigate } from "react-router-dom";

const allButtons = [
  { id: 1, name: "Complete Studies" },
  { id: 2, name: "Approved" },
  { id: 3, name: "Rejected" },
];
export const RedeemStudywisePage = () => {
  const userAuthenticationData = useAuth();
  const [reqData, setReqData] = useState<reqDataType>({
    panelListId: Number(userAuthenticationData?.auth?.panelistId),
    type: 1,
  });

  const allowedPanelist = [521511, 516479, 550388];
  const navigate = useNavigate();

  useEffect(() => {
    if (!allowedPanelist.includes(userAuthenticationData?.auth?.panelistId)) {
      navigate("/dashboard");
    }
  }, [userAuthenticationData?.auth?.panelistId]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  console.log(currentPage);

  return (
    <div className="flex flex-row items-center justify-center px-4 scroll-smooth lg:w-[98vw]">
      <div className="mt-[84px] lg:pl-[16rem] w-full fixed top-2 lg:sticky">
        <div className="w-full flex justify-center">
          <ButtonGroup
            reqData={reqData}
            setReqData={setReqData}
            setCurrentPage={setCurrentPage}
            userAuthenticationData={userAuthenticationData}
            allButtons={allButtons}
          />
        </div>
        <div className="flex justify-center">
          {reqData?.type === 1 && <UnclaimedPanelistStudies />}
          {reqData?.type === 2 && <ClaimedStudiesApproved />}
          {reqData?.type === 3 && <ClaimedStudiesRejected />}
        </div>
      </div>
    </div>
  );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { appSupportedLanguages as languages } from "./supported_language";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: languages.map((s) => s.code),
    parseMissingKeyHandler(key) {
      return "???" + key + "???";
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie", "localStorage"],
      lookupCookie: "zslang",
      lookupLocalStorage: "zslang",
      lookupQuerystring: "lang",
    },
    backend: {
      loadPath: "/locales/translations/message_{{lng}}.json",
    },
  });

export { i18n };

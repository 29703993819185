// import axios from "axios";
// const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

// export const axiosClient = axios.create({ baseURL: BACKEND_URL });

import axios from "axios";

const getAccessToken = function () {
  try {
    return JSON.parse(window.localStorage.getItem("auth_ls")).access_token;
  } catch (e) {
    return null;
  }
};

let baseURL = "https://zampliasurveysbackend-development.azurewebsites.net";
if (process.env.REACT_APP_ENV === "production") {
  baseURL = process.env.REACT_APP_API_SERVER || process.env.REACT_APP_API_STAGING;
} else if (process.env.REACT_APP_ENV === "development") {
  baseURL = process.env.REACT_APP_API_STAGING;
} else if (process.env.REACT_APP_ENV === "test") {
  baseURL = process.env.REACT_APP_API_STAGING;
} else {
  console.log("env", process.env);
  baseURL = process.env.REACT_APP_API_SERVER || process.env.REACT_APP_API_STAGING;
}

export const axiosClient = axios.create({
  baseURL,
  headers: {
    access_token: getAccessToken(),
  },
  withCredentials: true,
});

import React, { FC } from "react";

export interface ICard {
  title: any;
  description: any;
  icon?: any;
  customClass?: any;
}

export const Card: FC<ICard> = ({ title, description, icon, customClass }) => {
  return (
    <div
      className={`w-[320px] h-[450px] md:w-[] lg:h-[480px] max-w-sm p-4 rounded-[13px] sm:p-6 ${customClass}`}
    >
      <div className="w-[84px] h-[84px] lg:w-[96px] lg:h-[96px] bg-sky-700 bg-opacity-5 rounded-full flex items-center justify-center mb-4">
        <img src={icon} alt="" />
      </div>
      <h2 className="text-sky-700 text-[22px] lg:text-[26px] font-semibold font-['Poppins'] leading-9 mb-4">
        {title}
      </h2>
      <p className="text-base font-light font-['Poppins'] leading-9">{description}</p>
    </div>
  );
};

import { axiosClient } from "../../api/api";

export const getRedeemRequestHistoryData = async (panelistId: number, page: number) => {
  return axiosClient
    .get("/api/survey/getZampliaSurveysClaimRequest", {
      params: { panelistId, page, requestType: "Study", panel: "ZampliaSurveys" },
    })
    .then((response) => response.data);
};

export const getLoopClaimedSurveysData = async (panelListId: number, id: any) => {
  return axiosClient
    .get("/api/survey/getLoopClaimedSurveys", {
      params: { type: "byRequestId", panelListId, id },
    })
    .then((response) => response.data);
};

export const VirtualBalance = async (panelListId: number) => {
  return axiosClient
    .get("/api/survey/virtualWallet", {
      params: { panelListId },
    })
    .then((response) => response.data);
};

export const PointsBalance = async (panelListId: number) => {
  return axiosClient
    .get("/api/survey/getPointsBalance", {
      params: { panelListId },
    })
    .then((response) => response.data);
};

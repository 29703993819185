import { axiosClient } from "../../api/api";
import axios from "axios";

export type confirmAccountPayload = {
  panelistId: number;
  activationCode: number | string;
};

export const confirmAccountApi = ({ panelistId, activationCode }: confirmAccountPayload) => {
  axiosClient
    .post("api/survey/confirmAccount", {
      panelistId,
      activationCode,
    })
    .then((res) => res.data);
};

export const checkAffiliatesUserExistApi = (userId: string) => {
  return axiosClient
    .get("api/user/checkAffiliateUserExist", {
      params: {
        userId,
      },
    })
    .then((res) => res.data);
};

export const affiliatePostBackApi = (url: any) => {
  axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createAffiliatePanelListUserApi = (createAffiliateBody: any) => {
  axiosClient
    .post("api/user/createAffiliatePanelListUser", createAffiliateBody)
    .then((res) => res.data);
};

export const updateAffiliateVoxcoAttributeApi = (updateAffiliateVoxcoAttributeBody: any) => {
  axios({
    url: "https://cloud1.logitgroup.com/loopsurveys/loopsurveys-endpoint.php",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: updateAffiliateVoxcoAttributeBody,
  });
};
